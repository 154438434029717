<div *ngIf="!translationMode">
    <section class="topNavbar py-2 px-3 sticky-top" id="editNavbar">
        <div class="row align-items-center">
            <div class="col-md-3 col-lg-3 d-flex my-2 my-md-auto flex-nowrap align-content-center align-items-center">
                <button mat-icon-button style="color:var(--warmOrange);" (click)="backTo();"> 
                    <span class="material-symbols-outlined">
                        arrow_back_ios_new
                    </span>
                </button>
                <span class="component-headline text-white fw-light">{{ 'passEditHeadline' | translate }} "{{titleEdit}}"</span>
            </div>
            <!-- desktop -->
            <div class="col-9 d-none d-lg-flex justify-content-end align-items-center">
                <div class="button-group">
                    <button 
                    [ngClass]="{'disabled': translationDisabled}"
                    mat-button 
                    style="color:white;" 
                    (click)="showTranslatedView();" 
                    #tooltip="matTooltip"
                    [matTooltip]="translationDisabled ? ('pass.translate_hint' | translate) : ''"
                    matTooltip="{{'pass.translate_hint' | translate}}"
                    matTooltipPosition="below"
                    aria-label="Button that enables translation Mode">
                    <mat-icon>translate</mat-icon>
                    <span class="d-none d-md-block">{{'pass.translate' | translate}}</span>
                  </button>
                  
                    <!-- <button *ngIf="passLanguages.length > 0 || contextLanguages.length > 0" mat-button style="color:white;" (click)="viewPassLanguageForm();">
                        <mat-icon>language</mat-icon><span class="d-none d-md-block">{{'translationPreview' | translate}}</span>
                    </button> -->

                    <button mat-button style="color:white;" (click)="QRoptions();">
                        <mat-icon>qr_code_2</mat-icon><span class="d-none d-md-block">Sample QR</span>
                    </button>
                    <!-- <button mat-button style="color:white;" (click)="openDialogCoverImage();" ><mat-icon>image</mat-icon><span class="d-none d-md-block">{{'coverImages.titleSingle' | translate}}</span></button> -->

                    <button mat-button style="color:white;" (click)="openDialogEditSettings();">
                        <mat-icon>settings</mat-icon><span class="d-none d-md-block">{{'advancedSettings' | translate}}</span>
                    </button>
                    <button mat-button style="color:white;" (click)="openDialogEditTheme();">
                        <mat-icon>format_color_fill</mat-icon><span class="d-none d-md-block">{{'customizeTheme' | translate}}</span>
                    </button>
                    <!-- <button mat-button style="color:white;" (click)="openContentTypeView();"><mat-icon>tune</mat-icon><span class="d-none d-md-block">{{'contentTypeSettings' | translate}}</span></button> -->
                    <div class="vr custom-vr d-none d-md-inline-block"></div>
                    <button mat-button class="cancel-editing" (click)="backTo();">
                        <!-- (click)="cancelChanges();" -->
                        {{'cancel' | translate}}
                    </button>
                    <button mat-button class="orange-button" (click)="saveEditTemplate();" [disabled]="!changesToSave" >
                        {{'publishDesign' | translate }}
                    </button>
                </div>
            </div>
            <!-- mobile -->
            <div class="col-md-9 d-lg-none">
                <div class="button-group d-flex align-items-center justify-content-end">
                    <button mat-icon-button style="color:white;pointer-events: all;background: rgba(0, 0, 0, 0)!important;"  (click)="showTranslatedView();"
                    matTooltip="{{'pass.translate_hint' | translate}}"
                    matTooltipPosition="below"
                    aria-label="Button that enables translation Mode">
                        <mat-icon>translate</mat-icon>
                    </button>
                    <button *ngIf="passLanguages.length > 0 ||contextLanguages.length > 0" mat-icon-button style="color:white;" (click)="viewPassLanguageForm();">
                        <mat-icon>language</mat-icon>
                    </button>

                    <button mat-icon-button style="color:white;" (click)="QRoptions();">
                        <mat-icon>qr_code_2</mat-icon>
                    </button>

                    <button mat-icon-button style="color:white;" (click)="openDialogEditSettings();">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <!-- <button mat-icon-button style="color:white;" (click)="openDialogCoverImage();" ><mat-icon>image</mat-icon></button> -->
                    <button mat-icon-button style="color:white;" (click)="openDialogEditTheme();">
                        <mat-icon>format_color_fill</mat-icon>
                    </button>
                    <!-- <button mat-icon-button style="color:white;" (click)="openContentTypeView();"><mat-icon>tune</mat-icon></button> -->
                    <div class="vr custom-vr d-none me-3 d-md-inline-block"></div>
                    <button mat-button class="cancel-editing me-2" (click)="backTo();">
                        <!-- (click)="cancelChanges();" -->
                        <span class="d-none d-md-inline-block">{{'cancel' | translate}}</span>
                        <mat-icon class="d-md-none mx-0">cancel</mat-icon>
                    </button>
                    <button mat-button class="orange-button" (click)="saveEditTemplate();" [disabled]="!changesToSave">
                        <span class="d-none d-md-inline-block">{{'publishDesign' | translate }}</span>
                        <mat-icon class="d-md-none mx-0">check_circle</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <!-- mobile-message -->
    <div class="row p-3 d-md-none" style="background-color: #dd2f2f;color:white">
        <div class="col-1 d-flex align-items-center align-content-center"><span class="material-symbols-outlined">error</span></div>
        <div class="col-11">{{'mobile_info' | translate}}</div>
    </div>

    <div class="container">
        <div class="row mt-4">
            <div class="col-xs-6 col-md-6">
                <div class="d-flex mobile-wrapper justify-content-center align-self-center" [class.full-view]="phoneFullHeightView">
                    <section class="mobile">
                        <!--  *ngIf="!editTemp" -->
                        <div class="d-flex flex-column align-items-center mb-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <h2 class="text-center mb-0 px-2">{{'content.preview' | translate}} <span class="text-uppercase">({{languageSelected}})</span></h2>
                            </div>
                        </div>
                        <div class="mobile-frame-container"></div>
                        <div class="mobile-frame">
                            <!-- tablet -->
                            <div *ngIf="editTemp && !this.isTablet">
                                <div    *ngFor="let elem of allWidgets; let isFirst = first; let index = index;" 
                                        [ngClass]="{'first-widget': isFirst}"
                                        style="padding-top: var(--widgetSpacing);" 
                                        [attr.id]="'widget-' + elem.id">
                            
                                    <div *ngIf="elem.type=='annotatedMap'">
                                        <div [app-annotated-map-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='buttons'">
                                        <div [app-buttons-phone]="[elem,editTemp, index]" (returnChanges)="returnChangesActive($event)"></div>
                                    </div>

                                    <div *ngIf="elem.type=='billboardImage'">
                                        <div [app-billboard-image-phone]="[elem,editTemp, index]" (returnChanges)="returnChangesActive($event)"></div>
                                    </div>

                                    <div *ngIf="elem.type=='billboardVideo'">
                                        <div [app-billboard-video-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>
                            
                                    <div *ngIf="elem.type=='callToAction'">
                                        <div [app-call-to-action-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='form'">
                                        <div [app-form-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='image'">
                                        <div [app-image-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='images'" >
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='imageBlocks'">
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>
                                    <div *ngIf="elem.type=='imagesHorizontal'">
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='instagram'">
                                        <div [app-instagram-phone]="[elem,editTemp]"></div>
                                    </div>

                                    <div *ngIf="elem.type=='list'">
                                        <div [app-list-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='products'">
                                        <div [app-products-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='productRegistration'">
                                        <div [app-product-registration-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='shopify'">
                                        <div [app-shopify-phone]="[elem,editTemp]"></div>
                                    </div>

                                    <div *ngIf="elem.type=='spacer'">
                                        <div [app-spacer-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='splashVideo'">
                                        <div [app-splash-video-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='text'">
                                        <div [app-text-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>
                                    <div *ngIf="elem.type=='video'">
                                        <div [app-video-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='3dmodel'">
                                        <div [app-model3d-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>

                                    <div *ngIf="elem.type=='countdown'">
                                        <div [app-countdown-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>

                                    <div *ngIf="elem.type=='composed'">
                                        <div [app-composed-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>

                                </div>
                                <p *ngIf="allWidgets.length==0" class="p-2">{{'empty' | translate}}</p>
                                <!-- <p *ngIf="templateData.widgets.length==0"> Empty</p> -->
                            </div>
                            <!-- desktop -->
                            <div *ngIf="editTemp && this.isTablet" cdkDropList (cdkDropListDropped)="drop($event)">        
                                <div    *ngFor="let elem of allWidgets; let isFirst = first; let index = index;"
                                        style="cursor:grab;padding-top: var(--widgetSpacing);"
                                        [ngClass]="{'first-widget': isFirst}" 
                                        [attr.id]="'widget-' + elem.id" >
                            
                                    <div *ngIf="elem.type=='annotatedMap'" cdkDrag>
                                        <div [app-annotated-map-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='buttons'" cdkDrag>
                                        <div [app-buttons-phone]="[elem,editTemp, index]" (returnChanges)="returnChangesActive($event)"></div>
                                    </div>

                                    <div *ngIf="elem.type=='billboardImage'" cdkDrag>
                                        <div [app-billboard-image-phone]="[elem,editTemp, index]" (returnChanges)="returnChangesActive($event)"></div>
                                    </div>

                                    <div *ngIf="elem.type=='billboardVideo'" cdkDrag>
                                        <div [app-billboard-video-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>
                            
                                    <div *ngIf="elem.type=='callToAction'" cdkDrag>
                                        <div [app-call-to-action-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='form'" cdkDrag>
                                        <div [app-form-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='image'" cdkDrag>
                                        <div [app-image-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>    

                                    <div *ngIf="elem.type=='images'" cdkDrag>
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='imageBlocks'" cdkDrag>
                                        <!-- <div [app-image-blocks-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div> -->
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div> 
                                    <div *ngIf="elem.type=='imagesHorizontal'" cdkDrag>
                                        <!-- <div [app-images-horizontal-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div> -->
                                        <div [app-images-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='instagram'" cdkDrag>
                                        <div [app-instagram-phone]="[elem,editTemp]"></div>
                                    </div>

                                    <div *ngIf="elem.type=='list'" cdkDrag>
                                        <div [app-list-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='products'" cdkDrag>
                                        <div [app-products-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='productRegistration'" cdkDrag>
                                        <div [app-product-registration-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='shopify'" cdkDrag>
                                        <div [app-shopify-phone]="[elem,editTemp]"></div>
                                    </div>

                                    <div *ngIf="elem.type=='spacer'" cdkDrag>
                                        <div [app-spacer-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='splashVideo'" cdkDrag>
                                        <div [app-splash-video-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                        <!-- <app-splash-video-phone data="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></app-splash-video-phone> -->
                                    </div>

                                    <div *ngIf="elem.type=='text'" cdkDrag>
                                        <div [app-text-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]" (returnChanges)=returnChangesActive($event)></div>
                                    </div> 
                                    <div *ngIf="elem.type=='video'" cdkDrag>
                                        <div [app-video-phone]="[elem,editTemp,index]" (returnChanges)=returnChangesActive($event)></div>
                                    </div>

                                    <div *ngIf="elem.type=='3dmodel'" cdkDrag>
                                        <div [app-model3d-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>

                                    <div *ngIf="elem.type=='countdown'" cdkDrag>
                                        <div [app-countdown-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>

                                    <div *ngIf="elem.type=='composed'" cdkDrag>
                                        <div [app-composed-phone]="[elem,editTemp, index]" (returnChanges)=returnChangesActive($event) ></div>
                                    </div>
                                </div>
                                <p *ngIf="allWidgets.length==0" class="p-2">{{'empty' | translate}}</p>
                                <!-- <p *ngIf="templateData.widgets.length==0"> Empty</p> -->
                            </div>
                        </div>
                    </section> 
                </div>
            </div>
            <div class="col-xs-6 col-md-6">
                <div class="d-flex flex-row-reverse bd-highlight mb-4 justify-content-between align-items-center">
                    <!-- /// WIDGETS DROPDOWN /// -->
                    <button [matMenuTriggerFor]="widgets" mat-button style="margin-left: 8px!important;" class="m-1 shadow-sm orange-button">
                        {{'pass.addNewElement' | translate}}
                    </button>
                    <mat-menu #widgets="matMenu" >
                        <div *ngFor="let w of widgetsMenu ">
                            <button *ngIf="w.active" [disabled]="w.type=='productRegistration' && disableProductRegistration" mat-menu-item (click)="addWidget(w.type);">

                                <mat-icon *ngIf="w.type=='annotatedMap'" class="material-symbols-outlined">map</mat-icon>
                                <mat-icon *ngIf="w.type=='annotation'" class="material-symbols-outlined">home_pin</mat-icon>
                                <mat-icon *ngIf="w.type=='annotationGroup'" class="material-symbols-outlined">add_location_alt</mat-icon>
                                <mat-icon *ngIf="w.type=='billboardImage'" class="material-symbols-outlined">photo_album</mat-icon>
                                <mat-icon *ngIf="w.type=='billboardVideo'" class="material-symbols-outlined">subscriptions</mat-icon>
                                <mat-icon *ngIf="w.type=='buttons'" class="material-symbols-outlined">toast</mat-icon>
                                <mat-icon *ngIf="w.type=='callToAction'" class="material-symbols-outlined">badge</mat-icon>
                                <mat-icon *ngIf="w.type=='form'" class="material-symbols-outlined">article</mat-icon>
                                <mat-icon *ngIf="w.type=='instagram'" class="material-symbols-outlined"><i class="bi bi-instagram"></i></mat-icon>
                                <mat-icon *ngIf="w.type=='image'" class="material-symbols-outlined">imagesmode</mat-icon>
                                <mat-icon *ngIf="w.type=='images' || w.type=='imagesHorizontal' || w.type=='imageBlocks'" class="material-symbols-outlined">burst_mode</mat-icon>
                                <mat-icon *ngIf="w.type=='list'" class="material-symbols-outlined">list</mat-icon>
                                <mat-icon *ngIf="w.type=='text'" class="material-symbols-outlined">article</mat-icon>
                                <mat-icon *ngIf="w.type=='products'" class="material-symbols-outlined">shopping_cart</mat-icon>
                                <mat-icon *ngIf="w.type=='splashVideo'" class="material-symbols-outlined">video_file</mat-icon>
                                <mat-icon *ngIf="w.type=='shopify'" class="material-symbols-outlined">shopping_bag</mat-icon>
                                <mat-icon *ngIf="w.type=='spacer'" class="material-symbols-outlined">space_bar</mat-icon>
                                <mat-icon *ngIf="w.type=='video'" class="material-symbols-outlined">slideshow</mat-icon>
                                <mat-icon *ngIf="w.type=='3dmodel'" class="material-symbols-outlined">view_in_ar</mat-icon>
                                <mat-icon *ngIf="w.type=='notification'" class="material-symbols-outlined">mark_chat_unread</mat-icon>
                                <mat-icon *ngIf="w.type=='productRegistration'" class="material-symbols-outlined">box_edit</mat-icon>
                                <mat-icon *ngIf="w.type=='countdown'" class="material-symbols-outlined">av_timer</mat-icon>
                                <mat-icon *ngIf="w.type=='composed'" class="material-symbols-outlined">extension</mat-icon>
                                {{w.translate | translate}}

                            </button>

                            <button *ngIf="!w.active" mat-menu-item disabled><mat-icon>delete</mat-icon> {{w.translate | translate}}</button>
                        </div>

                    </mat-menu>
                   <!--  <span *ngIf="passDefaultLanguage" class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[passDefaultLanguage]}}</span> -->

                <!-- <div >
                        <div class="p-3">

                        <div *ngIf="langaugeSwitcher && !editTemp && passLanguages.length!=0">
                                <mat-chip-listbox *ngFor="let lang of passLanguages; let index=index" aria-label="Langauge selection">
                                    <mat-chip-option class="m-1" *ngIf="lang == languageSelected "  selected disabled style="background-color:var(--warmOrange);">{{languagesList[lang]}}</mat-chip-option>
                                    <mat-chip-option class="m-1" *ngIf="lang != languageSelected" (click)="changePassLanguage(lang);" > {{languagesList[lang]}} </mat-chip-option>
                                </mat-chip-listbox>
                            </div> 
            
                        <div *ngIf="langaugeSwitcher && passLanguages.length==0">
                                <mat-chip-listbox *ngFor="let lang of contextLanguages; let index=index" aria-label="Langauge selection">
                                    <mat-chip-option class="m-1" *ngIf="lang == languageSelected "  selected disabled style="background-color:var(--warmOrange);">{{languagesList[lang]}}</mat-chip-option>
                                    <mat-chip-option class="m-1" *ngIf="lang != languageSelected" (click)="viewPassLanguageForm(lang);" > {{languagesList[lang]}} </mat-chip-option>
                                </mat-chip-listbox>
                            </div>
                        
                            <div *ngIf="langaugeSwitcher && passLanguages.length!=0">
                                <mat-chip-listbox *ngFor="let lang of passLanguages; let index=index" aria-label="Langauge selection">
                                    <mat-chip-option class="m-1" *ngIf="lang == languageSelected "  selected disabled style="background-color:var(--warmOrange);">{{languagesList[lang]}}</mat-chip-option>
                                    <mat-chip-option class="m-1" *ngIf="lang != languageSelected" (click)="viewPassLanguageForm(lang);" > {{languagesList[lang]}} </mat-chip-option>
                                </mat-chip-listbox>
                            </div> -->

                            <!-- <div class="nav-item dropdown">
                                <button mat-button class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color:var(--warmOrange);" >
                                    <span class="text-uppercase">{{languageSelected}}</span>
                                </button>
                                <ul class="dropdown-menu" *ngIf="langaugeSwitcher && passLanguages.length==0">
                                    <div  *ngFor="let lang of contextLanguages; let index=index">
                                        <li *ngIf="lang != languageSelected" (click)="viewPassLanguageForm();" style="cursor:pointer;"> <a class="dropdown-item" >{{languagesList[lang]}}</a> </li>
                                        <li *ngIf="lang == languageSelected"  style="background-color:var(--warmOrange);"> <a class="dropdown-item disabled" >{{languagesList[lang]}} </a> </li>
            
                                    </div>
                                </ul>

                                <ul class="dropdown-menu" *ngIf="langaugeSwitcher && passLanguages.length!=0">
                                    <div  *ngFor="let lang of passLanguages; let index=index">
                                        <li *ngIf="lang != languageSelected"> <a class="dropdown-item"style="cursor:pointer;" >{{languagesList[lang]}}</a> </li>
                                        <li *ngIf="lang == languageSelected" style="background-color:var(--warmOrange);"> <a class="dropdown-item disabled" > {{languagesList[lang]}} </a> </li>
                                    </div>
                                </ul>
                            </div> -->
                        <!-- </div> -->
                    <!-- </div> -->

                    <div>
                        <mat-button-toggle-group class="me-4" name="darkModeToggle" [(ngModel)]="darkMode">
                            <mat-button-toggle matTooltip="Light-Mode" (click)="changeTheme(false);" [value]="false"><mat-icon>light_mode</mat-icon></mat-button-toggle>
                            <mat-button-toggle matTooltip="Dark-Mode" (click)="changeTheme(true);" [value]="true"><mat-icon>dark_mode</mat-icon></mat-button-toggle>
                        </mat-button-toggle-group> 

                        <mat-slide-toggle class="screen-switcher" [(ngModel)]="phoneFullHeightView">{{ 'fullView' | translate }}</mat-slide-toggle>
                    </div>

                </div>
                <!-- widget list -->
                <div *ngIf="allWidgets.length!=0" cdkDropList class="example-list-edit mb-5" (cdkDropListDropped)="drop($event)">
                    <div class="example-box-edit" *ngFor="let el of allWidgets; let i=index;" cdkDrag >
            
                        <div class="d-inline-flex " style="width: 90%;" (click)="clickList(el,i);">
                            <div *ngIf="el.type=='annotatedMap'" class="list-icon" >
                                <span class="material-symbols-outlined" >map</span>
                            </div>
                
                            <div *ngIf="el.type=='annotation'" class="list-icon" >
                                <span class="material-symbols-outlined">home_pin</span>
                            </div>
                
                            <div *ngIf="el.type=='annotationGroup'" class="list-icon" >
                                <span class="material-symbols-outlined">add_location_alt</span>
                            </div>
                
                            <div *ngIf="el.type=='billboardImage'" class="list-icon" >
                                <span class="material-symbols-outlined" >photo_album</span>
                            </div>
                
                            <div *ngIf="el.type=='billboardVideo'" class="list-icon" >
                                <span class="material-symbols-outlined">subscriptions</span>
                            </div>

                            <div *ngIf="el.type=='buttons'" class="list-icon" >
                                <span class="material-symbols-outlined" >toast</span>
                            </div>
                
                            <div *ngIf="el.type=='callToAction'" class="list-icon" >
                                <span class="material-symbols-outlined">badge</span>
                            </div>

                            <div *ngIf="el.type=='form'" class="list-icon" >
                                <span class="material-symbols-outlined">article</span>
                            </div>
                            
                            <div *ngIf="el.type=='instagram'" class="list-icon" >
                                <span class="material-symbols-outlined"><i class="bi bi-instagram"></i></span>
                            </div>
                
                            <div *ngIf="el.type=='image'" class="list-icon" >
                                <span class="material-symbols-outlined">imagesmode</span>
                            </div>
                
                            <!-- <div *ngIf="el.type=='imageBlocks'" class="list-icon" >
                                <span class="material-symbols-outlined">
                                    auto_awesome_mosaic
                                </span>
                            </div>
                
                            <div *ngIf="el.type=='imagesHorizontal'" class="list-icon" >
                                <span class="material-symbols-outlined">
                                    burst_mode
                                </span>
                            </div>

                            <div *ngIf="el.type=='images' && el.layout" class="list-icon" >
                                <span *ngIf="el.layout=='horizontal'" class="material-symbols-outlined">
                                    burst_mode
                                </span>

                                <span *ngIf="el.layout!='horizontal'" class="material-symbols-outlined">
                                    auto_awesome_mosaic
                                </span>
                            </div>
                            <div *ngIf="el.type=='images' && !el.layout" class="list-icon" >
                                <span class="material-symbols-outlined">
                                    burst_mode
                                </span>
                            </div> -->

                            <div *ngIf="el.type=='images' || el.type=='imagesHorizontal' || el.type=='imageBlocks' " class="list-icon" >
                                <span class="material-symbols-outlined">burst_mode</span>
                            </div>
                
                            <div *ngIf="el.type=='list'" class="list-icon" >
                                <span class="material-symbols-outlined">list</span>
                            </div>
                
                            <div *ngIf="el.type=='text'" class="list-icon" >
                                <span class="material-symbols-outlined">article</span>
                            </div>
                
                            <div *ngIf="el.type=='products'" class="list-icon" >
                                <span class="material-symbols-outlined">shopping_cart</span>
                            </div>

                            <div *ngIf="el.type=='productRegistration'" class="list-icon" >
                                <span class="material-symbols-outlined">box_edit</span>
                            </div>
                
                            <div *ngIf="el.type=='splashVideo'" class="list-icon" >
                                <span class="material-symbols-outlined">video_file</span>
                            </div>
                
                            <div *ngIf="el.type=='shopify'" class="list-icon" >
                                <span class="material-symbols-outlined">shopping_bag</span>
                            </div>

                            <div *ngIf="el.type=='spacer'" class="list-icon" >
                                <span class="material-symbols-outlined">space_bar</span>
                            </div>
                
                            <div *ngIf="el.type=='video'" class="list-icon" >
                                <span class="material-symbols-outlined">slideshow</span>
                            </div>
                
                            <div *ngIf="el.type=='3dmodel'" class="list-icon">
                                <span class="material-symbols-outlined">view_in_ar</span>
                            </div>

                            <div *ngIf="el.type=='notification'" class="list-icon">
                                <span class="material-symbols-outlined">mark_chat_unread</span>
                            </div>

                            <div *ngIf="el.type=='countdown'" class="list-icon">
                                <span class="material-symbols-outlined">av_timer</span>
                            </div>

                            <div *ngIf="el.type=='composed'" class="list-icon">
                                <span class="material-symbols-outlined">extension</span>
                            </div>
            
                            <div class="d-flex flex-column align-items-start" style=" width:80%;">
                                <span *ngIf="!el.name" style="font-size: 1.1em;">{{widgetsNames[el.type]}} {{checkTypeNumber(el,i)}}</span>
                                <span *ngIf="el.name" style="font-size: 1.1em;">{{el.name}} </span>
                                <span  style="font-size: 0.8em;" translate>pass.widgets.types.{{el.type}}</span>
                                <span *ngIf="el.type=='annotatedMap'"  style="font-size: 0.8em;" >{{el.annotations.length}} {{'pass.annotations'| translate}}</span>
                            </div>
            
                        </div>
                        <button mat-icon-button class="delete-icon" color="warn" (click)="deleteWidget(el, i);">
                            <span class="material-symbols-outlined">delete</span>
                        </button>
                        
                        <!-- if widgtes empty -->
                        <div *ngIf="allWidgets.length==0" class="example-list-edit"><p>{{'empty' | translate}}</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="translationMode">
    <div [app-pass-templates-translations]="[templateForm.value,oldTemplateData, contextLanguages, languagesPassList ?? contextLanguages, contentTypeId, idToShow, passFirstLanguage ?? contextFirstLanguage]" (returnChangesTranslations)=returnChangesActiveTranslations($event)></div>
    <!-- <div [app-pass-templates-translations]="[templateForm.value,oldTemplateData, passLanguages, languagesPassList ?? contextLanguages, contentTypeId, idToShow, passFirstLanguage ?? contextFirstLanguage]" (returnChangesTranslations)=returnChangesActiveTranslations($event)></div> -->
</div>