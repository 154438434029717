import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[app-spacer-phone]',
  templateUrl: './spacer-phone.component.html',
  styleUrls: ['./spacer-phone.component.css']
})
export class SpacerPhoneComponent {
  @Input('app-spacer-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  divider=false
  spacerData:any

  ngOnInit():void{

    if(this.data[0]){
      this.spacerData = this.data[0]
    }

    if(this.spacerData.divider){
      this.divider=true
    }

    setTimeout( ()=> {
      if(this.spacerData['spacing']){
        const name = 'spacer'+this.spacerData['id']
        document.getElementById(name).style.height = this.spacerData['spacing']+'px'
  
      }
    },300)

  }
}
