import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.css']
})
export class EditUserDialogComponent {
  editUserForm!: FormGroup;
  user: any;
  hostName: any;
  showBetaWidget: boolean = false;
  
  availableRoles: { value: string, display: string }[] = [
    { value: 'accountManager', display: 'Account Manager' },
    { value: 'contentManager', display: 'Content Manager' },
    { value: 'workTaskManager', display: 'Work Task Manager' },
    { value: 'tracer', display: 'Tracer' },
    { value: 'tracingSupervisor', display: 'Tracing Supervisor' },
    { value: 'betaWidgets', display: 'Beta Widgets' }
  ];


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commentService: CommentsService,
    private translate: TranslateService
  ) {
    dialogRef.disableClose = true;
  }
  
  ngOnInit(): void {
    this.user = this.data.user;
    const userRoles = this.user.roles || [];

    this.hostName = environment.firebase.projectId
    if (this.hostName == 'narravero-eu-dev' || this.hostName == 'narravero-eu-test') {
      this.showBetaWidget = true
    }

    this.editUserForm = this.fb.group({
      displayName: [this.user.displayName, Validators.required],
      //email: [this.user.email, [Validators.required, Validators.email]],
      email: [{ value: this.user.email, disabled: true }],
      accountManager: [userRoles.includes('accountManager')],
      contentManager: [userRoles.includes('contentManager')],
      workTaskManager: [userRoles.includes('workTaskManager')],
      tracer: [userRoles.includes('tracer')],
      tracingSupervisor: [userRoles.includes('tracingSupervisor')],
      betaWidgets: [userRoles.includes('betaWidgets')]
    });

    const otherControls = ['accountManager', 'contentManager', 'workTaskManager', 'tracer', 'tracingSupervisor'];

    this.editUserForm.get('admin')?.valueChanges.subscribe(isAdmin => {
      if (isAdmin) {
        // disable all if admin is on
        otherControls.forEach(control => {
          this.editUserForm.get(control)?.patchValue(false);
          this.editUserForm.get(control)?.disable();
        });
      } else {
        // enable all if admin is off
        otherControls.forEach(control => {
          this.editUserForm.get(control)?.enable();
        });
      }
    });
  
    // on init disable other if admin is on
    if (this.editUserForm.get('admin')?.value) {
      otherControls.forEach(control => {
        this.editUserForm.get(control)?.patchValue(false); 
        this.editUserForm.get(control)?.disable();
      });
    }
  }

  cancelEdit(){
    this.dialogRef.close([false])
  }
    saveEdit(): void {
      if (this.editUserForm.valid) {
        const formValue = this.editUserForm.getRawValue();
    
        const updatedRoles = this.availableRoles
          .filter(role => formValue[role.value])
          .map(role => role.value);
    
        // Find custom roles that
        const customRoles = this.user.roles.filter((role: string) => 
          !this.availableRoles.some(availableRole => availableRole.value === role)
        );
        // Combine updated with the custom
        const finalRoles = [...updatedRoles, ...customRoles];
    
        this.dialogRef.close({ ...formValue, roles: finalRoles });
      }
    }
    
}
