import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-advanced-experiences-item',
  templateUrl: './advanced-experiences-item.component.html',
  styleUrls: ['./advanced-experiences-item.component.css']
})
export class AdvancedExperiencesItemComponent {
  routerUrl:any
  idToShow:any
  firstPath:any
  contentypeId:any
  advancedExperienceId:any
  appStoreImageURL:string
  backToTitle:any
  w:string="282"
  h:string="171"
  c:string="bb"
  f:string="png"
  imageUrl:any
  title:any
  subtitle:any
  urlPrefix:any
  coverImageUserRights_delete:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private commentService: CommentsService,
    public dialog: MatDialog,
    private translate: TranslateService

    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    this.firstPath = this.routerUrl.value[2].path  
    if(this.firstPath=="contentTypes"){
      this.contentypeId = this.routerUrl.value[this.routerUrl.value.length-3].path  
      this.backToTitle = translate.instant('contentTypes.contentType')
    }else{
      this.backToTitle = translate.instant('coverImages.title')

    }
  }

  ngOnInit():void{
    this.coverImageUserRights_delete = this.settingsService.userRolesRights$['contentTypes']['D']
     this.getAdvancedExperiencesId()
  }

  getAdvancedExperiencesId(){
    this.commentService.progressSpin.emit(true)
    try{
      this.settingsService.observeAdvancedExperiencesIdFirestore(this.idToShow).then( experience => {
        this.advancedExperienceId = experience

        this.appStoreImageURL = this.advancedExperienceId['appStoreImageURL']

        if(this.appStoreImageURL)
          this.imageUrl = decodeURI(this.appStoreImageURL).replaceAll("{w}", this.w).replaceAll("{h}", this.h).replaceAll("{c}", this.c).replaceAll("{f}", this.f)

       if(this.advancedExperienceId['title'])
         this.title = this.advancedExperienceId['title']

       if(this.advancedExperienceId['subtitle'])
        this.subtitle = this.advancedExperienceId['subtitle']

       if(this.advancedExperienceId['urlPrefix'])
        this.urlPrefix = this.advancedExperienceId['urlPrefix']

        this.commentService.progressSpin.emit(false)
      })

    }catch(error){
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  backTo(){
    if(this.firstPath=="contentTypes"){
      this.router.navigate(['/home', this.settingsService.contextId$, 'contentTypes', this.contentypeId])
    }else{
      this.router.navigate(['/home', this.settingsService.contextId$, 'coverImages'])
    }
  }

  onDelete(){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:this.advancedExperienceId,
        type:"Advanced"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        if(this.firstPath=="contentTypes"){
          this.router.navigate(['/home', this.settingsService.contextId$, 'contentTypes', this.contentypeId])
        }else{
          this.router.navigate(['home', this.settingsService.contextId$, 'coverImages'])
        }

      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS") 
        this.commentService.addSnackBar.emit(message)
      }
    });
  }
}
