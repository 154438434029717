import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CommentsService } from 'src/app/service/comments.service';
import { WidgetEditFiltersEditDialogComponent } from '../widget-edit-filters-edit-dialog/widget-edit-filters-edit-dialog.component';
import { deepCopy } from '../../../../../../backend/utils/object';

interface filterNode {
  field?: string;
  operator?:string;
  value?:boolean;
  and?: filterNode[];
  or?:filterNode[]
  type?:string
}

@Component({
  selector: '[app-widget-edit-filters]',
  templateUrl: './widget-edit-filters.component.html',
  styleUrls: ['./widget-edit-filters.component.css']
})

export class WidgetEditFiltersComponent {
  filtersData:any = {}
  filterTypes= [
    {
      type:'=',
      translate:'pass.widgets.filter.comparisson',
      active:true,
      name:'comparisson'
    },
    {
      type:'and',
      translate:'pass.widgets.filter.and',
      active:true,
      name:'and'
    },
    {
      type:'or',
      translate:'pass.widgets.filter.or',
      active:true,
      name:'or'
    }
  ]

  addDisabed = false
  filterOperatorTypes = [
    {
      type:'==',
      translate: "pass.widgets.filter.operators.equal",
      name:'equal'
    },
    {
      type:'!=',
      translate: "pass.widgets.filter.operators.not_equal",
      name:"not_equal"
    },
    {
      type:'<',
      translate: "pass.widgets.filter.operators.less_than",
      name:"less_than"
    },
    {
      type:'>',
      translate: "pass.widgets.filter.operators.more_than",
      name:"more_than"
    },
    {
      type:'<=',
      translate: "pass.widgets.filter.operators.at_least",
      name:"at_least"
    },
    {
      type:'>=',
      translate: "pass.widgets.filter.operators.at_most",
      name:"at_most"
    }
  ]

  filterOperatorTypesObject = {
    '==':{
      type:'==',
      translate: "pass.widgets.filter.operator.equal"
    },
    '!=':{
      type:'!=',
      translate: "pass.widgets.filter.operator.not_equal"
    },
    '<':{
      type:'<',
      translate: "pass.widgets.filter.operator.less_than"
    },
    '>':{
      type:'>',
      translate: "pass.widgets.filter.operator.more_than"
    },
    '<=':{
      type:'<=',
      translate: "pass.widgets.filter.operator.at_least"
    },
    '>=':{
      type:'>=',
      translate: "pass.widgets.filter.operator.at_most"
    }
  }

  filterKeys:string[]
  filterForm!:FormGroup
  @Input('app-widget-edit-filters') data:any;
  allproperties:filterNode[]
  TREE_DATA:filterNode[]
  treeControl = new NestedTreeControl<filterNode>(node => node?.and || node?.or);
  dataSource = new MatTreeNestedDataSource<filterNode>();
  disableAddFilters = false
  @Output() returnChanges = new EventEmitter<{newFilters: any, widget: any, widgetId:string}>()

  constructor(public dialogRef: MatDialogRef<WidgetEditFiltersComponent>,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private fb: FormBuilder){}

  ngOnInit():void {
    this.filterForm = this.fb.group({
      filters:[]
    })

    if(this.data.filter){
      this.filtersData = deepCopy(this.data?.filter) ?? {}
      //.log('filtersData',this.filtersData)
      const key = Object.keys(this.data.filter)
      this.filterKeys = key
      
      if(this.filterKeys.includes('and')){
        this.treeControl = new NestedTreeControl<filterNode>(node => node?.and );
        this.dataSource.data = [this.filtersData]//.and
        //console.log('dataSource.data',this.dataSource.data)
        this.filterForm.patchValue({filters: this.dataSource.data})
        this.disableAddFilters = true
      }

      if(this.filterKeys.includes('or')){
        this.treeControl = new NestedTreeControl<filterNode>(node => node?.or);
        this.dataSource.data = [this.filtersData]//this.filtersData.or
        this.filterForm.patchValue({filters: this.dataSource.data})
        //console.log('dataSource.data',this.dataSource.data)
        this.disableAddFilters = true
      }

      if(this.filterKeys.includes('field') || this.filterKeys.includes('operator') || this.filterKeys.includes('value')){
        this.filterForm.patchValue({filters: this.filtersData})
        this.disableAddFilters = true
      }

      //console.log(key)
      //console.log(this.treeControl)

    }else{
      this.treeControl = new NestedTreeControl<filterNode>(node => node?.or || node?.and);
      this.dataSource.data = []
    }

    this.filterForm.get('filters').valueChanges.subscribe( value => {
      //console.log('______valueChanges.filters',value)
      this.filtersData = value
      const key = Object.keys(value)
      this.filterKeys = key
      let newFilters = value

      this.dataSource.data = [this.filtersData]
      //console.log('filtersData',this.filtersData)
      //console.log('filterKeys', this.filterKeys)
      if(key.length!=0)
        this.disableAddFilters = true

      if(this.filterKeys.includes('and')){
        this.treeControl = new NestedTreeControl<filterNode>(node => node?.and );
        // this.dataSource.data = [this.filtersData]//.and
      }

      if(this.filterKeys.includes('or')){
        this.treeControl = new NestedTreeControl<filterNode>(node => node?.or);
        // this.dataSource.data = [this.filtersData]//this.filtersData.or
      }

      if(this.filterKeys.includes('field') || this.filterKeys.includes('operator') || this.filterKeys.includes('value')){
        // this.treeControl = new NestedTreeControl<filterNode>(node => node);
      }

      this.refreshTree()
      if(key?.length==0)
        newFilters = undefined
      this.returnChanges.emit({newFilters: newFilters, widget: this.data, widgetId: this.data.id})
    })
  }

  addFilterType(filter){
    //console.log(filter)

    switch(filter.type){
      case '=':{
        this.addDisabed = true
        let dialogRef= this.dialog.open(WidgetEditFiltersEditDialogComponent, {
          data: { 
            type:"add",
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result[0]){
            if(this.filtersData && this.filtersData.length==0){
              this.filtersData = result[1]
              this.filterForm.patchValue({filters: this.filtersData})
              this.dataSource.data = [this.filtersData]
            }else{
              // this.filtersData.push(result[1])
              this.filtersData=result[1]
              this.dataSource.data = [result[1]]
              this.filterForm.patchValue({filters: this.filtersData})
              this.dataSource.data = [this.filtersData]
            }

            this.refreshTree()
          }
        });
        break;
      }

      case 'and':{
        this.filtersData['and'] = []
        this.filterForm.patchValue({filters: this.filtersData})
        // this.dataSource.data = [this.filtersData]

        this.refreshTree()
        break;
      }
      case 'or':{
        this.filtersData['or'] = []

        this.filterForm.patchValue({filters: this.filtersData})
        // this.dataSource.data = [this.filtersData]

        this.refreshTree()
        break;
      }
    }
  }

  editFilterType(node, type, index){

    const entries = Object.entries(node)

    let newNode = node ?? {}
    switch(type){
      case 'or' :{
        node['or'] = deepCopy(entries[0][1])
        delete node['and']
        this.filterForm.patchValue({filters: this.dataSource.data[0]})
        break;
      }
      case 'and' :{
        node['and'] = deepCopy(entries[0][1])
        delete node['or']
        this.filterForm.patchValue({filters: this.dataSource.data[0]})
        break;
      }

      case '=':{
        this.addDisabed = true
        let dialogRef= this.dialog.open(WidgetEditFiltersEditDialogComponent, {
          data: { 
            type:"edit",
            values: node
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          //console.log(result)
          if(result[0]){
            node.field = result[1].field
            node.operator = result[1].operator
            node.value = result[1].value
            // node = result[1]
            //console.log('filtersData',this.filtersData)
            //console.log('dataSource.data.0: ',this.dataSource.data[0])
            // this.dataSource.data = [this.filtersData]
            // this.refreshTree()
            this.filterForm.patchValue({filters: this.dataSource.data[0]})
          }
        });
        break;
      }
    }

  }

  getOperatorText(type){
    return this.filterOperatorTypes.find( op => op.type == type)?.translate
  }


  refreshTree(){
    let _data = deepCopy(this.dataSource.data);
    this.dataSource.data = null;
    this.dataSource.data = _data;

    //console.log('dataSource.data: ', this.dataSource.data)
  }

  hasChild = (_: number, node: filterNode) => (!!node.and && node.and.length >= 0) || (!!node.or && node.or.length >= 0);
  isArrayType = ( _: number,node: filterNode) => node.and || node.or;
  // isArrayType = ( node: filterNode,_?: number) => node.and || node.or;
  isComparisson = (_: number, node: filterNode) => node.field && node.value && node.operator;
  isObject(val): boolean { return typeof val === 'object';}
  getKeysObject(object){return Object.keys(object)}

  addFilterTypeToNode(type, node, index){
    //console.log('addFilterTypeToNode',type, node, index)
    const key = Object.keys(node)
    //console.log('key',key)
    //console.log('node: ',node[key[0]])
    //console.log('filtersData',this.filtersData)

    switch(type.type){
      case '=':{
        // node[key[0]].push({field:'field', value:'value', operator:'='})
        
          let dialogRef= this.dialog.open(WidgetEditFiltersEditDialogComponent, {
            data: { 
              type:"add",
            },
          });
  
          dialogRef.afterClosed().subscribe(result => {
            //console.log('result: ', result)
            if(result[0]){
              let newcomp = {field:result[1].field , operator: result[1].operator, value: result[1].value}
                // node[key[0]].push(newcomp)
                // this.dataSource.data[0][key[0]].push(newcomp)
                node[key[0]].push(newcomp)
                //console.log('filtersData',this.filtersData)
                // this.dataSource.data = [this.filtersData]
                // this.filtersData = this.dataSource.data[0]
                this.filterForm.patchValue({filters: this.dataSource.data[0]})
              // this.refreshTree()
            }
          });
          break;
        
      }
      case 'and':{
        node[key[0]].push({and:[]})
        // this.dataSource.data = [this.filtersData]
        //console.log('filtersData',this.filtersData)

        this.filterForm.patchValue({filters: this.dataSource.data[0]})

        break;
      }
      case 'or':{
        node[key[0]].push({or:[]})
        // this.dataSource.data = [this.filtersData]
        //console.log('filtersData',this.filtersData)

        this.filterForm.patchValue({filters: this.dataSource.data[0]})

        break;
      }

    }
    // console.log(node)

    // this.refreshTree()
  }

  ///-------this is to limit childs of each filter to 2
  getDisableNodeChilds(node){
    // console.log(node)
    let child = false

    // if(node?.and?.length>=2)
    // child = true

    // if(node?.or?.length>=2)
    // child = true

    return child
  }


  deleteAllFilter(){
    this.filtersData = {}
    this.filterKeys = []
    this.dataSource.data = []
    this.refreshTree()
    this.filterForm.patchValue({filters:{}})
    this.disableAddFilters = false
  }


  deleteNode(node, index?){
    //console.log(node, index)

    if(index){

    }
    if(node.field && node.value && node.operator){
      node = {}
    }

    if(node.and){
      node = {}
    }


    //console.log(node)
    this.refreshTree()

  }

  clearFilter(){
    this.filtersData = {}
    this.filterKeys = []
    this.filterForm.patchValue({filters:{}})
  }
   
}
