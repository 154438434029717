<section class="px-3">

    <!-- <div class="ps-0">
        <span *ngIf="countdownData.title" id="title" class="text-wrap" style="color:var(--title); font-size:18pt; word-wrap: break-word;">{{countdownData.title}}</span>
    </div> -->

    <div *ngIf="countdownRunning">

      <div *ngIf="countdownData.targetDate " class="container-alignment" [ngStyle]=" { 'justify-content': countdownAlignmment }" >
        <!-- <h5>Countdown to {{ targetDate | date:'fullDate' }}</h5> -->

          <div class="countdown-container " [style] = "{ 'background-color': countdownData?.backgroundColor ?? 'var(--background)' , color : countdownData?.textColor ?? 'var(--title)' }">

            <div class="d-flex flex-row day justify-content-center" *ngIf="!isMoreThanDay && !isMoreThanWeek "  ><!--[ngStyle]=" { 'justify-content': countdownAlignmment }"-->
              <div class="d-flex flex-column align-items-center ">
                  <span class="left ">{{ timeLeft.hours}} </span>
                  <span class="text pt-2"> {{'pass.countdown.hours' | translate}}</span>
              </div>
              <span class="pt-2 px-1">:</span>

              <div class="d-flex flex-column align-items-center">
                  <span class="left ">{{ timeLeft.minutes }}</span> 
                  <span class="text pt-2"> {{'pass.countdown.minutes' | translate}}</span>
              </div>
              <span class="pt-2 px-1">:</span>
              <div class="d-flex flex-column align-items-center">
                  <span class="left">{{ timeLeft.seconds }} </span> 
                  <span class="text pt-2"> {{'pass.countdown.seconds' | translate}}</span>
              </div>
            </div>

            <div *ngIf="isMoreThanWeek" class="d-flex fex-row day justify-content-center" ><!--[ngStyle]=" { 'justify-content': countdownAlignmment }"-->

              <div class="d-flex flex-column align-items-center">
                <span class="left ">{{ timeLeft.weeks }}</span> 
                <span class="text pt-2"> {{'pass.countdown.weeks' | translate}}</span>
              </div>
              <span class="pt-2 px-1"></span>
              <div class="d-flex flex-column align-items-center">
                  <span class="left">{{ timeLeft.days }} </span> 
                  <span class="text pt-2"> {{'pass.countdown.days' | translate}}</span> 
              </div>
            </div>

            <div *ngIf="isMoreThanDay && !isMoreThanWeek " class="d-flex fex-row day justify-content-center" ><!--[ngStyle]=" { 'justify-content': countdownAlignmment }"-->
              <div class="d-flex flex-column align-items-center">
                <span class="left ">{{ timeLeft.days }}</span> 
                <span class="text pt-2"> {{'pass.countdown.days' | translate}}</span>
              </div>
              <span class="pt-2 px-1"></span>
              <div class="d-flex flex-column align-items-center">
                  <span class="left">{{ timeLeft.hours }} </span> 
                  <span class="text pt-2"> {{'pass.countdown.hours' | translate}}</span> 
              </div>
            </div>
          </div>
      </div>
    </div>

    <div *ngIf="countdownData.targetDateKey && !targetDate">
      <span>Countdown preview is not possible</span>
    </div>

    <div class="container-alignment" [ngStyle]=" { 'justify-content': countdownAlignmment }">
      <div *ngIf="!countdownRunning " class="countdown-container p-3 text-wrap d-flex align-items-center justify-content-center" [style] = "{ 'background-color': countdownData?.backgroundColor ?? 'var(--background)' , color : countdownData?.textColor ?? 'var(--title)'}">
        <span id="countdownMessage" class="text-wrap" style="color:var(--plain); font-family: var(--plainFontName);font-size:14pt; word-wrap: break-word;">{{countdownData.expiredMessage}}</span>
      </div>
    </div>
    
</section>