import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnnotatedMapEditComponent } from '../editWidgets/annotated-map-edit/annotated-map-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../../backend/utils/object';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: '[app-annotated-map-phone]',
  templateUrl: './annotated-map-phone.component.html',
  styleUrls: ['./annotated-map-phone.component.css']
})
export class AnnotatedMapPhoneComponent implements OnInit{
  @Input('app-annotated-map-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()

  annotatedMapData:any
  editMode:any
  annotationsData:any
  newAnnotations=false
  widgetNumber:any
  translationsPass:any
  slideIndex = 1;
  mapWidgetId:any
  staticAnnotations:any = []
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  pairsPath:any ={}
  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }

  slidesMap = 1
  mapsIds:any ={}
  prev_infowindow:any
  routerUrl:any
  idToShow:any

  constructor(public dialog: MatDialog,
    private route:ActivatedRoute,
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path
  }

  ngOnInit():void{
    this.annotatedMapData=this.data[0]
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    this.annotationsData=this.annotatedMapData.annotations
    if(this.annotationsData)
      this.loadStaticAnnotations(this.annotationsData)

    if(this.annotatedMapData){
      this.mapWidgetId = this.annotatedMapData.id
/*       if(this.annotatedMapData.title)
        this.annotatedMapData.title = checkDynamicContent(this.annotatedMapData.title)

      if(this.annotatedMapData.subtitle)
        this.annotatedMapData.subtitle = checkDynamicContent(this.annotatedMapData.subtitle) */
    }

    if(this.data[2] || this.data[2] ==0){
      this.widgetNumber= this.data[2]
    }

    if(this.annotatedMapData.layout){
      if(this.annotatedMapData.layout=='horizontal'){
        if(this.annotationsData){
          this.mapsIds[this.annotatedMapData.id]={}
          this.annotationsData.forEach((element, index) => {
            // if(!element.dynamic){
              if(!element['id'])
                element['id']=this.makeid(5)
              this.mapsIds[this.annotatedMapData.id][index+1] = element.id
            // }
          });

          setTimeout(() => {
            this.initMaps(1)  
          }, 800);
        }
      } 
    }
  }

  openDialogEdit():void{
    const billboardData= this.annotatedMapData
    let dialogRef= this.dialog.open(AnnotatedMapEditComponent, {
      data: { 
        values: this.annotatedMapData,
        widget: this.widgetNumber,
        translations: this.translationsPass,
        oldtranslations: this.annotatedMapData
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.newAnnotations=true
        this.annotatedMapData = result[0];
        this.data[0]=result[0]
        this.returnChanges.emit({newdata: this.data[0], widget: this.widgetNumber, widgetType: this.data[0].type})
      }

    });
  }

  /////////////////////
  previousMap(n){
    this.showMap(this.slidesMap+=n)
  }

  nextMap(n){
    this.showMap(this.slidesMap+=n)
  }

  showMap(n, id?){
    let maps = document.getElementsByClassName("myMaps-"+this.mapWidgetId);

    if (n > maps.length) {this.slidesMap = 1}    
    if (n < 1) {this.slidesMap = maps.length}

    for (let i = 1; i < maps.length +1; i++) {
      const id = this.mapsIds[this.annotatedMapData.id][i]
      if(i==this.slidesMap){
        document.getElementById(id).style.display="block"
        document.getElementById('dot-'+id).style.backgroundColor="var(--warmOrange)"
      }else{
        document.getElementById(id).style.display="none"
        document.getElementById('dot-'+id).style.backgroundColor="#717171"
      }
    }
  }

  initMaps(n, id?){
    let maps = document.getElementsByClassName("myMaps-"+this.mapWidgetId);

    if (n > maps.length) {this.slidesMap = 1}    
    if (n < 1) {this.slidesMap = maps.length}

    for (let i = 1; i < maps.length +1; i++) {
      const id = this.mapsIds[this.annotatedMapData.id][i]
      if(i==this.slidesMap){
        document.getElementById(id).style.display="block"
        document.getElementById('dot-'+id).style.backgroundColor="var(--warmOrange)"
      }else{
        document.getElementById(id).style.display="none"
        document.getElementById('dot-'+id).style.backgroundColor="#717171"
      }
    }
  }

  showMapId(idMAp){
    let maps = document.getElementsByClassName("myMaps-"+this.mapWidgetId);

    for (let i = 1; i < maps.length +1; i++) {
      const id = this.mapsIds[this.annotatedMapData.id][i]
      if(id==idMAp){
        document.getElementById(id).style.display="block"
        document.getElementById('dot-'+id).style.backgroundColor="var(--warmOrange)"
      }else{
        document.getElementById(id).style.display="none"
        document.getElementById('dot-'+id).style.backgroundColor="#717171"
      }
    }
  }

  loadMarkers(annotation){
    if(annotation){
      annotation.forEach((element:any, index) => {
        if(element.coordinate){
          const lats = element
          lats['lat'] = parseFloat(element.coordinate[0])
          lats['lng'] = parseFloat(element.coordinate[1])
          if(!element.badge){
            lats['badge']=(index +1).toString()
          }
          if(element.name){
            lats['title']=element.name
          }
          if(!element.badge){
            lats['badge']=(index +1).toString()
          }
          this.markerPositions.push(lats);
          this.center=lats
        }else{
          const lats = element
          lats['lat'] = parseFloat(element.lat)
          lats['lng'] = parseFloat(element.lng)
          if(element.name){
            lats['title']=element.name
          }
          if(!element.badge){
            lats['badge']=(index +1).toString()
          }
          this.markerPositions.push(lats);
          this.center=lats
        }

        if(element.encodedContent){
          this.getPolygon(element, index)
        }
        if(element.id){
          element['id']=this.makeid(5)
        }
      });
    }
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  getPolygon(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
      pairs = encodedContent.reduce(function(result, value, index, array) {
      if (index % 2 === 0){
        const splited = array.slice(index, index + 2)
        var lat: number = +splited[0];
        var lng: number = +splited[1];
        const value = { lat: lat, lng: lng }
        result.push(value);
      }
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptions[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptions[index].path = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.fillColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.strokeWeight
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].path && this.actualPolygonOptions[index].path[0]){
          this.center=this.actualPolygonOptions[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptions[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptions[index].paths = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].fillColor = markerPosition.fillColor
        }

        if(markerPosition.strokeColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].paths && this.actualPolygonOptions[index].paths[0]){
          this.center=this.actualPolygonOptions[index].paths[0]
          this.zoom = 9
        }
      }
    }
    return pairs
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
      this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  loadStaticAnnotations(annotations){
    this.staticAnnotations = []
    if(annotations){
      annotations.forEach(element => {
        // if(!element.dynamic){
          this.staticAnnotations.push(element)
        // }
/*         if(element.badge)
          element.badge = checkDynamicContent(element.badge)

        if(element.lat)
          element.lat = checkDynamicContent(element.lat)

        if(element.lng)
          element.lng = checkDynamicContent(element.lng)

        if(element.title)
          element.title = checkDynamicContent(element.title)

        if(element.subtitle)
          element.subtitle = checkDynamicContent(element.subtitle)

        if(element.linkURL)
          element.linkURL = checkDynamicContent(element.linkURL) */
      });
    }
  }
}
