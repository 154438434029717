import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { defaultModulesAll, defaultModulesPublisher, defaultModulesTracer } from 'src/modules';
import { userRolesRightsAll } from 'src/roles';

@Injectable({
  providedIn: 'root'
})
export class NavbarAccesService {
  context:boolean=true
  contentTypes:boolean=true
  content:boolean=true
  tridys:boolean=true
  coverImages:boolean=false
  products:boolean=true
  taskTemplates:boolean=true
  workTasks:boolean=true
  users:boolean=true
  reports:boolean=true
  interfaces:boolean=true

  contextChange:Subject<boolean> = new Subject<boolean>();//=true
  contentTypesChange:Subject<boolean> = new Subject<boolean>();//=true
  contentChange:Subject<boolean> = new Subject<boolean>();//=true
  tridysChange:Subject<boolean> = new Subject<boolean>();//=true
  coverImagesChange:Subject<boolean> = new Subject<boolean>();//=false
  productsChange:Subject<boolean> = new Subject<boolean>();//=true
  taskTemplatesChange:Subject<boolean> = new Subject<boolean>();//=true
  workTasksChange:Subject<boolean> = new Subject<boolean>();//=true
  usersChange:Subject<boolean> = new Subject<boolean>();//=true
  reportsChange:Subject<boolean> = new Subject<boolean>();//=true
  interfacesChange:Subject<boolean> = new Subject<boolean>();//=true

  actualRights:any
  constructor() { }

  setAccesModulesAll(){
    const modulesAcces = defaultModulesAll
    this.actualRights = modulesAcces
    if(modulesAcces['contentTypes']){
      if(!modulesAcces['contentTypes']['R']){
        this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false 
        this.coverImagesChange.next(!this.coverImages);//this.coverImages=false
      }else{
        this.contentTypesChange.next(this.contentTypes);//this.contentTypes=true
        this.coverImagesChange.next(this.coverImages);// this.coverImages=true
      }
    }
    
    if(modulesAcces['content']){
      if(!modulesAcces['content']['R']){
        this.contentChange.next(!this.content);//this.content=false
      }else{
        this.contentChange.next(this.content);//this.content=true
      }
    }

    if(modulesAcces['context']){
      if(!modulesAcces['context']['R']){
        this.contextChange.next(!this.context)//this.context=false
      }else{
        this.contextChange.next(this.context)//this.context=true
      }
    }

    if(modulesAcces['interfaces']){
      if(!modulesAcces['interfaces']['R']){
        this.interfacesChange.next(!this.interfaces)//this.interfaces=false
      }else{
        this.interfacesChange.next(this.interfaces)//this.interfaces=true
      }
    }
    
    if(modulesAcces['products']){
      if(!modulesAcces['products']['R']){
        this.productsChange.next(!this.products)//this.products=false
      }else{
        this.productsChange.next(this.products)//this.products=true
      }
    }
    
    if(modulesAcces['reports']){
      if(!modulesAcces['reports']['R']){
        this.tridysChange.next(!this.tridys)///this.tridys=false
        this.reportsChange.next(!this.reports)//this.reports=false
      }else{
        this.tridysChange.next(this.tridys)//this.tridys=true
        this.reportsChange.next(this.reports)//this.reports=true
      }
    }
    
    if(modulesAcces['users']){
      if(!modulesAcces['users']['R']){
        this.usersChange.next(!this.users)//this.users=false
      }else{
        this.usersChange.next(this.users)//this.users=true
      }
    }
    
    if(modulesAcces['workTask']){
      if(!modulesAcces['workTask']['R']){
        this.workTasksChange.next(!this.workTasks)//this.workTasks=false
      }else{
        this.workTasksChange.next(this.workTasks)//this.workTasks=true
      }
    }
    
    if(modulesAcces['workTaskTemplates']){
      if(!modulesAcces['workTaskTemplates']['R']){
        this.taskTemplatesChange.next(!this.taskTemplates)//this.taskTemplates=false
      }else{
        this.taskTemplatesChange.next(this.taskTemplates)//this.taskTemplates=true
      }
    }
  }

  setAccesModulesPublisherTracer(){
    const modulesAcces = defaultModulesAll
    this.actualRights = modulesAcces

    if(modulesAcces['contentTypes']){
      if(!modulesAcces['contentTypes']['R']){
        this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false 
        this.coverImagesChange.next(!this.coverImages);//this.coverImages=false
      }else{
        this.contentTypesChange.next(this.contentTypes);//this.contentTypes=true
        this.coverImagesChange.next(this.coverImages);// this.coverImages=true
      }
    }
    
    if(modulesAcces['content']){
      if(!modulesAcces['content']['R']){
        this.contentChange.next(!this.content);//this.content=false
      }else{
        this.contentChange.next(this.content);//this.content=true
      }
    }

    if(modulesAcces['context']){
      if(!modulesAcces['context']['R']){
        this.contextChange.next(!this.context)//this.context=false
      }else{
        this.contextChange.next(this.context)//this.context=true
      }
    }

    if(modulesAcces['interfaces']){
      if(!modulesAcces['interfaces']['R']){
        this.interfacesChange.next(!this.interfaces)//this.interfaces=false
      }else{
        this.interfacesChange.next(this.interfaces)//this.interfaces=true
      }
    }
    
    if(modulesAcces['products']){
      if(!modulesAcces['products']['R']){
        this.productsChange.next(!this.products)//this.products=false
      }else{
        this.productsChange.next(this.products)//this.products=true
      }
    }
    
    if(modulesAcces['reports']){
      if(!modulesAcces['reports']['R']){
        this.tridysChange.next(!this.tridys)///this.tridys=false
        this.reportsChange.next(!this.reports)//this.reports=false
      }else{
        this.tridysChange.next(this.tridys)//this.tridys=true
        this.reportsChange.next(this.reports)//this.reports=true
      }
    }
    
    if(modulesAcces['users']){
      if(!modulesAcces['users']['R']){
        this.usersChange.next(!this.users)//this.users=false
      }else{
        this.usersChange.next(this.users)//this.users=true
      }
    }
    
    if(modulesAcces['workTask']){
      if(!modulesAcces['workTask']['R']){
        this.workTasksChange.next(!this.workTasks)//this.workTasks=false
      }else{
        this.workTasksChange.next(this.workTasks)//this.workTasks=true
      }
    }
    
    if(modulesAcces['workTaskTemplates']){
      if(!modulesAcces['workTaskTemplates']['R']){
        this.taskTemplatesChange.next(!this.taskTemplates)//this.taskTemplates=false
      }else{
        this.taskTemplatesChange.next(this.taskTemplates)//this.taskTemplates=true
      }
    }
  }

  setAccesModulesTracer(){
    const modulesAcces = defaultModulesTracer
    this.actualRights = modulesAcces

    if(modulesAcces['contentTypes']){
      if(!modulesAcces['contentTypes']['R']){
        this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false 
        this.coverImagesChange.next(!this.coverImages); //this.coverImages=false
      }else{
        this.contentTypesChange.next(this.contentTypes); //this.contentTypes=true
        this.coverImagesChange.next(this.coverImages);// this.coverImages=true
      }
    }
    
    if(modulesAcces['content']){
      if(!modulesAcces['content']['R']){
        this.contentChange.next(!this.content);//this.content=false
      }else{
        this.contentChange.next(this.content);//this.content=true
      }
    }

    if(modulesAcces['context']){
      if(!modulesAcces['context']['R']){
        this.contextChange.next(!this.context)//this.context=false
      }else{
        this.contextChange.next(this.context)//this.context=true
      }
    }

    if(modulesAcces['interfaces']){
      if(!modulesAcces['interfaces']['R']){
        this.interfacesChange.next(!this.interfaces)//this.interfaces=false
      }else{
        this.interfacesChange.next(this.interfaces)//this.interfaces=true
      }
    }
    
    if(modulesAcces['products']){
      if(!modulesAcces['products']['R']){
        this.productsChange.next(!this.products)//this.products=false
      }else{
        this.productsChange.next(this.products)//this.products=true
      }
    }
    
    if(modulesAcces['reports']){
      if(!modulesAcces['reports']['R']){
        this.tridysChange.next(!this.tridys)///this.tridys=false
        this.reportsChange.next(!this.reports)//this.reports=false
      }else{
        this.tridysChange.next(this.tridys)//this.tridys=true
        this.reportsChange.next(this.reports)//this.reports=true
      }
    }
    
    if(modulesAcces['users']){
      if(!modulesAcces['users']['R']){
        this.usersChange.next(!this.users)//this.users=false
      }else{
        this.usersChange.next(this.users)//this.users=true
      }
    }
    
    if(modulesAcces['workTask']){
      if(!modulesAcces['workTask']['R']){
        this.workTasksChange.next(!this.workTasks)//this.workTasks=false
      }else{
        this.workTasksChange.next(this.workTasks)//this.workTasks=true
      }
    }
    
    if(modulesAcces['workTaskTemplates']){
      if(!modulesAcces['workTaskTemplates']['R']){
        this.taskTemplatesChange.next(!this.taskTemplates)//this.taskTemplates=false
      }else{
        this.taskTemplatesChange.next(this.taskTemplates)//this.taskTemplates=true
      }
    }
  }

  setAccesModulesPublisher(){
    const modulesAcces = defaultModulesPublisher
    this.actualRights = modulesAcces

    if(modulesAcces['contentTypes']){
      if(!modulesAcces['contentTypes']['R']){
        this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false 
        this.coverImagesChange.next(!this.coverImages);//this.coverImages=false
      }else{
        this.contentTypesChange.next(this.contentTypes);//this.contentTypes=true
        this.coverImagesChange.next(this.coverImages); // this.coverImages=true
      }
    }
    
    if(modulesAcces['content']){
      if(!modulesAcces['content']['R']){
        this.contentChange.next(!this.content);//this.content=false
      }else{
        this.contentChange.next(this.content);//this.content=true
      }
    }

    if(modulesAcces['context']){
      if(!modulesAcces['context']['R']){
        this.contextChange.next(!this.context)//this.context=false
      }else{
        this.contextChange.next(this.context)//this.context=true
      }
    }

    if(modulesAcces['interfaces']){
      if(!modulesAcces['interfaces']['R']){
        this.interfacesChange.next(!this.interfaces)//this.interfaces=false
      }else{
        this.interfacesChange.next(this.interfaces)//this.interfaces=true
      }
    }
    
    if(modulesAcces['products']){
      if(!modulesAcces['products']['R']){
        this.productsChange.next(!this.products)//this.products=false
      }else{
        this.productsChange.next(this.products)//this.products=true
      }
    }
    
    if(modulesAcces['reports']){
      if(!modulesAcces['reports']['R']){
        this.tridysChange.next(!this.tridys)///this.tridys=false
        this.reportsChange.next(!this.reports)//this.reports=false
      }else{
        this.tridysChange.next(this.tridys)//this.tridys=true
        this.reportsChange.next(this.reports)//this.reports=true
      }
    }
    
    if(modulesAcces['users']){
      if(!modulesAcces['users']['R']){
        this.usersChange.next(!this.users)//this.users=false
      }else{
        this.usersChange.next(this.users)//this.users=true
      }
    }
    
    if(modulesAcces['workTask']){
      if(!modulesAcces['workTask']['R']){
        this.workTasksChange.next(!this.workTasks)//this.workTasks=false
      }else{
        this.workTasksChange.next(this.workTasks)//this.workTasks=true
      }
    }
    
    if(modulesAcces['workTaskTemplates']){
      if(!modulesAcces['workTaskTemplates']['R']){
        this.taskTemplatesChange.next(!this.taskTemplates)//this.taskTemplates=false
      }else{
        this.taskTemplatesChange.next(this.taskTemplates)//this.taskTemplates=true
      }
    }
  }

  setAccesRoles( userRolesRights$?:any){
    if(userRolesRights$){
      this.actualRights = userRolesRights$
      
      if(userRolesRights$['contentTypes']){
        if(!userRolesRights$['contentTypes']['R']){
          this.contentTypesChange.next(!this.contentTypes); //this.contentTypes=false
          this.coverImagesChange.next(!this.coverImages); //this.coverImages=false
        }else{
          this.contentTypesChange.next(this.contentTypes); //this.contentTypes=true
          this.coverImagesChange.next(this.coverImages); //this.coverImages=true
        }
      }else{
        if(!userRolesRightsAll['contentTypes']['R']){
          this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false
          this.coverImagesChange.next(!this.coverImages);//this.coverImages=false
        }else{
          this.contentTypesChange.next(this.contentTypes);//this.contentTypes=true
          this.coverImagesChange.next(this.coverImages);//this.coverImages=true
        }
      }
      
      if(userRolesRights$['content']){
        if(!userRolesRights$['content']['R']){
          this.contentChange.next(!this.content);//this.content=false
        }else{
          this.contentChange.next(this.content);//this.content=true
        }
      }else{
        if(!userRolesRightsAll['content']['R']){
          this.contentChange.next(!this.content);//this.content=false
        }else{
          this.contentChange.next(this.content);//this.content=true
        }
      }

      if(userRolesRights$['context']){
        if(!userRolesRights$['context']['R']){
          this.contextChange.next(!this.context);//this.context=false
        }else{
          this.contextChange.next(this.context);//this.context=true
        }
      }else{
        if(!userRolesRightsAll['context']['R']){
          this.contextChange.next(!this.context);//this.context=false
        }else{
          this.contextChange.next(this.context);//this.context=true
        }
      }

      if(userRolesRights$['interfaces']){
        if(!userRolesRights$['interfaces']['R']){
          this.interfacesChange.next(!this.interfaces);//this.interfaces=false
        }else{
          this.interfacesChange.next(this.interfaces);//this.interfaces=true
        }
      }
      
      if(userRolesRights$['products']){
        if(!userRolesRights$['products']['R']){
          this.productsChange.next(!this.products);//this.products=false
        }else{
          this.productsChange.next(this.products);//this.products=true
        }
      }
      
      if(userRolesRights$['tridys']){
        if(!userRolesRights$['tridys']['R']){
          this.tridysChange.next(!this.tridys);//this.tridys=false
          this.reportsChange.next(!this.reports);//this.reports=false
        }else{
          this.tridysChange.next(this.tridys);//this.tridys=true
          this.reportsChange.next(this.reports);//this.reports=true
        }
      }
      
      if(userRolesRights$['users']){
        if(!userRolesRights$['users']['R']){
          this.usersChange.next(!this.users);//this.users=false
        }else{
          this.usersChange.next(this.users);//this.users=true
        }
      }
     
      if(userRolesRights$['workTask']){
        if(!userRolesRights$['workTask']['R']){
          this.workTasksChange.next(!this.workTasks);//this.workTasks=false
        }else{
          this.workTasksChange.next(this.workTasks);//this.workTasks=true
        }
      }

      if(userRolesRights$['reports']){
        if(!userRolesRights$['reports']['R']){
          this.reportsChange.next(!this.reports);//this.workTasks=false
        }else{
          this.reportsChange.next(this.reports);//this.workTasks=true
        }
      }
      
      if(userRolesRights$['workTaskTemplates']){
        if(!userRolesRights$['workTaskTemplates']['R']){
          this.taskTemplatesChange.next(!this.taskTemplates);//this.taskTemplates=false
        }else{
          this.taskTemplatesChange.next(this.taskTemplates);//this.taskTemplates=true
        }
      }

      if(!userRolesRights$['context'] && !userRolesRights$['users'] && 
          !userRolesRights$['interfaces'] && !userRolesRights$['contentTypes'] && 
          !userRolesRights$['passTemplates'] && !userRolesRights$['content'] && 
          !userRolesRights$['media'] && !userRolesRights$['workTaskTemplates'] &&
          !userRolesRights$['workTask'] && !userRolesRights$['products'] &&
          !userRolesRights$['tridys'] && !userRolesRights$['reports']){
        // this.updateNavBarWithRoles(userRolesRightsAll)
        this.updateNavBarWithRolesAll()
      }
      
    }else{
      this.updateNavBarWithRolesAll()
    }
  }

  updateNavBarWithRolesAll(){
    this.actualRights = userRolesRightsAll

    if(!userRolesRightsAll['contentTypes']['R']){
      this.contentTypesChange.next(!this.contentTypes);//this.contentTypes=false
      this.coverImagesChange.next(!this.coverImages);//this.coverImages=false
    }else{
      this.contentTypesChange.next(this.contentTypes);//this.contentTypes=true
      this.coverImagesChange.next(this.coverImages);//this.coverImages=true
    }

    if(!userRolesRightsAll['content']['R']){
      this.contentChange.next(!this.content);//this.content=false
    }else{
      this.contentChange.next(this.content);//this.content=true
    }

    if(!userRolesRightsAll['context']['R']){
      this.contextChange.next(!this.context);//this.context=false
    }else{
      this.contextChange.next(this.context);//this.context=true
    }

    if(!userRolesRightsAll['products']['R']){
      this.productsChange.next(!this.products);//this.products=false
    }else{
      this.productsChange.next(this.products);//this.products=true
    }

    if(!userRolesRightsAll['tridys']['R']){
      this.tridysChange.next(!this.tridys);//this.tridys=false
    }else{
      this.tridysChange.next(this.tridys);//this.tridys=true
    }

    if(!userRolesRightsAll['interfaces']['R']){
      this.interfacesChange.next(!this.interfaces);//this.interfaces=false
    }else{
      this.interfacesChange.next(this.interfaces);//this.interfaces=true
    }

    if(!userRolesRightsAll['reports']['R']){
      this.reportsChange.next(!this.reports);//this.reports=false
    }else{
      this.reportsChange.next(this.reports);//this.reports=true
    }

    if(!userRolesRightsAll['users']['R']){
      this.usersChange.next(!this.users);//this.users=false
    }else{
      this.usersChange.next(this.users);//this.users=true
    }

    if(!userRolesRightsAll['workTask']['R']){
      this.workTasksChange.next(!this.workTasks);//this.workTasks=false
    }else{
      this.workTasksChange.next(this.workTasks);//this.workTasks=true
    }

    if(!userRolesRightsAll['workTaskTemplates']['R']){
      this.taskTemplatesChange.next(!this.taskTemplates);//this.taskTemplates=false
    }else{
      this.taskTemplatesChange.next(this.taskTemplates);//this.taskTemplates=true
    }
  }

}
