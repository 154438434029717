<h2 mat-dialog-title>Filter</h2>

<mat-dialog-content>
    <div class="pt-2 ">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="filterForm">
                <mat-form-field appearance="outline" class="w-100 ">
                    <mat-label>{{'filters.key' | translate}}</mat-label>
                    <input matInput type="text" class="form-control" placeholder=" {{'filters.key' | translate}}" formControlName="key" required>
                </mat-form-field>
        
        
                <mat-form-field appearance="outline"  class="w-100 p-0">
                    <mat-label>{{'filters.operator' | translate}}</mat-label>
                    <mat-select formControlName="operator" required>
                        <mat-option *ngFor="let operator of operatorsList" value="{{operator.id}}" >{{operator.translation | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100 ">
                    <mat-label>{{'filters.value' | translate}}</mat-label>
                    <input matInput type="text" class="form-control" placeholder=" {{'filters.value' | translate}}" formControlName="value" >
                    <mat-hint>{{'filters.value_empty' | translate}}</mat-hint>
                </mat-form-field>

            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button"
    (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>