export const environment = {
  production: false,
  useEmulators: false,
  firebase : {
    apiKey: "AIzaSyA-hR1OYY95ij0Nr-6Hj985SKxsDw2fU6U",
    authDomain: "narravero-eu-dev.firebaseapp.com",
    projectId: "narravero-eu-dev",
    storageBucket: "narravero-eu-dev.appspot.com",
    messagingSenderId: "700375310981",
    appId: "1:700375310981:web:54487acf4816eb263cbfec",
    measurementId: "G-G8MTE6VXR7"
  },
  tagHostName:"https://nv2.at/",
  urlHostName:"api.narravero.dev"
  
};
