import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-task-template-field-new-option-dialog',
  templateUrl: './task-template-field-new-option-dialog.component.html',
  styleUrls: ['./task-template-field-new-option-dialog.component.css']
})
export class TaskTemplateFieldNewOptionDialogComponent {
  optionsForm!:FormGroup
  tasktemplateUserRights_update:any

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateFieldNewOptionDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private settingsService: SettingsService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(){
    this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']

    this.optionsForm = this.fb.group({
      option:[]
    })

    if(this.data.values){
      this.optionsForm.patchValue({option: this.data.values})
    }
  }

  onSave(){ 
    this.dialogRef.close([this.optionsForm.value.option, true])
  }

  onCancel(){
    this.dialogRef.close([this.optionsForm.value.option, false])

  }
}
