
<google-map 
        width="100%"
        height="50vh"
        [center]="center"
        [zoom]="zoom"
        [mapTypeId]="mapTypeId"
        style="border-radius:10px;" 
>
        <map-marker 
                *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition"
                [options]="markerOptions">
        </map-marker>
</google-map>