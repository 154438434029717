
  <section class="jumbotron container mt-5 py-5 p-5 w-75 m-auto rounded" style="border:1px solid black; position: relative; top:10vh;">
    <div class="container">
      <p>{{'home.hi' | translate}} {{accountName}}</p>
      <h1 class="display-4">Narravero</h1>
      <p class="lead">{{ 'home.welcome' | translate}}</p>
      <p class="lead">{{'home.tool' | translate }}</p>
      <p style=" color:var(--accentGlobal);"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</p>
    </div>
  </section>

 