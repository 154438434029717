<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="fieldForm" class="m-1">
                <div class="input-group">
                    <mat-form-field appearance="outline" class="w-100" >
                      <mat-label>{{'content.type' | translate}} </mat-label>
                      <mat-select formControlName="type" class="input-group" required>
                        <mat-option *ngFor="let option of typesOptions; let index=index" value="{{option.type}}">
                            <mat-icon class="material-symbols-outlined" style="color:var(--warmOrange);">{{option.icon}}</mat-icon>
                            {{option.translations | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'content.key' | translate}}</mat-label>
                    <input formControlName="key" type="text" matInput placeholder="Key" required>
                        <mat-error *ngIf="fieldForm.get('key').errors && fieldForm.get('key').errors['required']">Please enter a key</mat-error>
                    
                </mat-form-field>
        
                <mat-form-field  appearance="outline" class="w-100">
                    <mat-label>{{'contentTypes.title' | translate}}</mat-label>
                    <input matInput formControlName="title"  placeholder="{{'contentTypes.title' | translate}}" type="text" required>
                </mat-form-field>
        
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();">{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>
