<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="valueForm">
                <!-- <input type="text" class="form-control" formControlName="value"> -->
                <mat-form-field appearance="outline" class="w-100 ">
                    <mat-label>{{'taskTemplates.value' | translate}}</mat-label>
                    <input matInput type="text" class="form-control" placeholder=" {{'taskTemplates.value' | translate}}" formControlName="value">
                </mat-form-field>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" 
    (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>