import { Component, EventEmitter, Inject, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: 'app-images-edit',
  templateUrl: './images-edit.component.html',
  styleUrls: ['./images-edit.component.css']
})
export class ImagesEditComponent {
  oldData:any
  imagesEditData:any
  imagesEditForm!:FormGroup
  urls:any
  allImages:any
  layoutSelected:any
  layoutImages:any
  layoutBad=false
  files: FileHandle[] = []
  drop=false
  url:any={}
  imagesToDelete:any = []
  layoutTypes:any = [
    {
      value:'horizontal',
      name:'Horizontal, scrolling',
      translate:this.translate.instant('pass.images.layouts.imagesHorizontal'),
      images:2
    },
    {
      value:'twoHorizontal',
      name:'2 Images, Horizontal',
      translate:this.translate.instant('pass.images.layouts.twoHorizontal'),
      images:2
    },
    {
      value:'twoVertical',
      name:'2 Images, Vertical',
      translate:this.translate.instant('pass.images.layouts.twoVertical'),
      images:2
    },
    {
      value:'threeLayout1',
      name:'3 Images, Layout 1',
      translate:this.translate.instant('pass.images.layouts.threeLayout1'),
      images:3
    },
    { 
      value:'threeLayout2',
      name:'3 Images, Layout 2',
      translate:this.translate.instant('pass.images.layouts.threeLayout2'),
      images:3
    },
    { 
      value:'fourLayout1',
      name:'4 Images, Layout 1',
      translate:this.translate.instant('pass.images.layouts.fourLayout1'),
      images:4
    },
    { 
      value:'fourLayout2',
      name:'4 Images, Layout 2',
      translate:this.translate.instant('pass.images.layouts.fourLayout2'),
      images:4
    }
  ]
  minimumImg:any
  newImage:any=[]
  widgetNumber:any
  passId:any
  imagesData:any=[]
  allImagesAfterDelete:any=[]
  imgFromImgNew:any=[]
  newImagesURLs:any = []
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()
  selectedtab:any
  languagesList = languagesList
  defaultLanguage:string
  //selectedtab:any 
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  imagesSizes:any = {}

  constructor(
    public dialogRef: MatDialogRef<ImagesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private imageCompressionService: ImageCompressionService,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate:TranslateService,
    private settingsService: SettingsService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage
    this.oldData = this.data.values
    this.imagesEditData = this.data.values
    this.passId = this.data['passId']
    if(this.data['url']){
        this.url = deepCopy(this.data['url'])

    }else{
      if(this.imagesEditData.images){
        this.imagesEditData.images.forEach((image, index) => {
          if(image.url){
            this.supportUrl(image.url, index)
          }
        });
      }
    }

/*     if(this.imagesEditData.mediaURL){
      this.selectedtab = 1
    }else{
      this.selectedtab=0
    } */
    this.allImages=this.imagesEditData.images
    this.imagesData=this.imagesEditData.images

    if(this.imagesEditData.layout){
      this.layoutSelected=this.imagesEditData.layout
    }else{
      this.layoutSelected="horizontal"
    }
    this.widgetNumber= this.data['widget']
    this.layoutTypes.forEach((lay:any)=>{
      if(lay.value==this.layoutSelected){
        this.layoutImages=lay.images
      }
    })
    if(this.layoutSelected=="imagesHorizontal"){
      this.layoutImages="2"
    }

    this.imagesEditForm = this.fb.group({
      id:[],
      layout:[],
      type:[],
      title:[],
      subtitle:[],
      images:[],
      mediaURL:[],
      name:[],
      filter:[]

    })
    let {id, layout, type, title, subtitle, images, mediaURL, name, filter} = this.imagesEditData
    images?.forEach(async image => {
      if(image.size?.length == 0 || (image.size && image.size[0] == 0 && image.size[1] == 0) || !image.size ){
        await this.uploadService.calculateImgFileSize(this.url[image.url]).then(value => {
          this.imagesSizes[image.url] = value
          image.size = value
        })
      }
    });

    this.imagesEditForm.patchValue({
      id: id ? id : undefined,
      layout: layout ? layout : undefined,
      type: type ? type :undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle :undefined,
      images: images ? images : undefined,
      mediaURL: mediaURL ? mediaURL : undefined,
      name: name ? name : undefined,
      filter: filter ? filter : undefined
    })

    if(this.imagesEditForm.value.type == "imagesHorizontal"){
      this.imagesEditForm.patchValue({layout: "horizontal"})
      this.imagesEditForm.patchValue({type: "images"})
    }

    if(this.imagesEditForm.value.type == "imageBlocks"){
      this.imagesEditForm.patchValue({type: "images"})
    }
    if(this.layoutImages>this.imagesEditForm.value?.images?.length){
      this.layoutBad=true
    }else{
      this.layoutBad=false
    }

    this.imagesEditForm.valueChanges.subscribe( value =>{
      this.layoutSelected = value.layout
      
      this.layoutTypes.forEach((lay:any)=>{
        if(lay.value==this.layoutSelected){
          this.layoutImages=lay.images
        }
      })
      if(this.layoutSelected=="imagesHorizontal"){
        this.layoutImages="2"
      }

      if(this.layoutImages>this.imagesEditForm.value?.images?.length){
        this.layoutBad=true
      }else{
        this.layoutBad=false
      }

      if(value.images){
        const allImages = value.images
        value?.images.forEach( async image =>{
          if(image.size?.length == 0 || (image.size && image.size[0] == 0 && image.size[1] == 0) || !image.size ){
            await this.uploadService.calculateImgFileSize(this.url[image.url]).then(value => {
              this.imagesSizes[image.url] = value
            })
          }
        })
      }
    })

  }

  async readURL(event: any): Promise<void> {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }

      let imageToUpload = file;
  
      if (file.size > maxSizeInBytes) {
        try {
          const compressedImage = await this.imageCompressionService.compressImage(file, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          imageToUpload = compressedImage;
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      }
  
      const reader = new FileReader();
      this.newImage.push(imageToUpload);
      const image = imageToUpload;
      let newindex = this.newImage.length - 1;
      reader.readAsDataURL(imageToUpload);
  
      reader.onload = async (e) => {
        if (e.target) {
          const url = e.target.result as string;
          if (url) {
            let newimg = { url: url , size: undefined};
  
            try {
              const uploadURL = await this.uploadService.uploadNewImages(this.passId, image, newindex, this.widgetNumber);
              let size

              await this.uploadService.calculateImgFileSize(url).then(value => {
                this.imagesSizes[uploadURL] = size
                size = value
              })

              this.newImagesURLs.push(uploadURL);
              if (uploadURL) {
                newimg = { url: uploadURL, size };
                const a = this.imagesEditForm.value.images;
                this.imagesEditForm.patchValue({
                  images: [...a, newimg]
                });
                const index = this.imagesEditForm.value.images.length - 1;
                this.url[uploadURL] = url;
                this.imgFromImgNew.push({ form: a.length, new: this.newImage.length - 1 });
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

    files.forEach(async (file, index) => {
      const fileToUpload = file.file;

      // Check if the file size exceeds the maximum allowed size for upload
      if (fileToUpload.size > maxAllowedSizeInBytes) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
      }

      let imageToUpload = fileToUpload;

      // Compress the image if it exceeds the 2 MB size limit
      if (fileToUpload.size > maxSizeInBytes) {
          try {
              const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
              if (!compressedImage) {
                  const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
                  this.commentService.addSnackBar.emit(message);
                  return;
              }
              imageToUpload = compressedImage;
          } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
              this.commentService.addSnackBar.emit(message);
              return;
          }
      }
  
      this.newImage.push(imageToUpload);
      const image = imageToUpload;
      const newUrl = files[index].url;
      let newindex = this.newImage.length - 1;
      if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {
        if (newUrl) {
          this.drop = true;
          let newimg = { url: newUrl , size: undefined};
  
          try {
            const uploadURL = await this.uploadService.uploadNewImages(this.passId, image, newindex, this.widgetNumber);
            this.newImagesURLs.push(uploadURL);
            this.supportUrl(uploadURL, newindex)

            let size
              await this.uploadService.calculateImgFileSize(newUrl['changingThisBreaksApplicationSecurity']).then(value => {
                console.log('size',size)
                this.imagesSizes[uploadURL] = size
                size = value
              })
  
            if (uploadURL) {
              newimg = { url: uploadURL, size: size };
  
              const a = this.imagesEditForm.value.images;
              this.imagesEditForm.patchValue({
                images: [...a, newimg]
              });
              const index = this.imagesEditForm.value.images.length - 1;
              this.url[uploadURL] = newUrl;
              this.imgFromImgNew.push({ form: a.length, new: this.newImage.length - 1 });
            }
  
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      } else {
        const message = this.translate.instant('SnackBarConstants.UNSUPPORTED_IMG_TYPE');
        this.commentService.addSnackBar.emit(message);
      }
    });
  }

  deleteImg(x:any,index:any){
    this.allImagesAfterDelete = []
    const allimages = deepCopy(this.imagesEditForm.value.images)
    allimages.splice(index,1)

    this.imagesEditForm.patchValue({images: allimages})

    this.allImages = allimages
    this.allImagesAfterDelete = allimages
    // var a:any=[]
    //   this.imagesEditForm.value.images.forEach((element:any, indexImg:any) => {
    //     console.log(element, indexImg)
    //     if(element.url!=x && indexImg != index){
    //       a.push(element)
    //     }
    //   });
      
    //   this.allImages.forEach( (img, ind) =>{
    //     if(img['url']!=x && ind != index){
    //       this.allImagesAfterDelete.push(img)
    //     }
    //   })

    //   this.imagesEditForm.patchValue({
    //     images: a
    //   })
      
    //   this.allImages=a
      
    //   this.imgFromImgNew.forEach( (img, inde)=>{
    //     if(img['form']==index){
    //       this.newImage.splice(img['new'], 1)
    //     }
    //   })
    //   if(this.url[index]){
    //     this.url[index]=""
    //   }

    //   if(this.url[x]){
    //     this.url[x]=""
    //     delete this.url[x] 
    //   }
      this.imagesToDelete.push(x)
  }

  editImg(image, index){
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: image,
        type: this.imagesEditForm.value.type,
        passId: this.passId,
        widgetNumber:this.widgetNumber

      },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        if(result[0].url){
          if(result[0].url!=this.imagesEditForm.value.images[index].url){
            this.url[index]=""
          }
          let imagesform = []
          this.imagesEditForm.value.images.forEach ( (im, i)=>{
            if(i==index){
              const data = result[0]
              delete data.visibility
              imagesform.push(data)

            }else{
               imagesform.push(im)
            }
          })

          if(imagesform[index].url){
            this.supportUrl(imagesform[index].url, index)
            
          }

          this.imagesEditForm.patchValue({images: imagesform})
        }
        
      }
    });
  }

  openMediaURL(){
    const index = this.imagesEditForm.value.images.length
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: '',
        type: this.imagesEditForm.value.type,
        passId: this.passId,
        widgetNumber:this.widgetNumber
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[0].url){
          const data = result[0]
          delete data.visibility
          let imagesform = deepCopy(this.imagesEditForm.value.images)
          imagesform.push(data)
          // imagesform.push({url: result[0].url})
          this.supportUrl(result[0].url, index)
          this.imagesEditForm.patchValue({images: imagesform})
        }
      }
    });
  }

  async saveEdit(){
    if(this.imagesEditForm.value.mediaURL){
      this.commentService.progressSpin.emit(false)
      this.returnChanges.emit({newdata: this.imagesData, widget: this.widgetNumber, widgetType: this.imagesData.type,images: this.urls})
      this.dialogRef.close([this.imagesEditForm.value, true, this.newImage]);
    }else{
      if(!this.layoutBad){
        this.imagesEditForm.value.images.forEach(async image => {
          if(this.url[image.url]){
            if(image.size?.length == 0 || (image.size && image.size[0] == 0 && image.size[1] == 0) || !image.size ){
              await this.uploadService.calculateImgFileSize(this.url[image.url]).then(value => {
                this.imagesSizes[image.url] = value
                image.size = value
              })
            }
          }
        });
        try{
          if(this.newImage.length!=0){        
            this.commentService.progressSpin.emit(true)
              if(this.newImage.length!=0){
  
                this.commentService.progressSpin.emit(false)
                this.returnChanges.emit({newdata: this.imagesData, widget: this.widgetNumber, widgetType: this.imagesData.type,images: this.urls})
                this.dialogRef.close([this.imagesEditForm.value, true, this.newImage]);
              }
          }else{
            
            if(this.allImagesAfterDelete.length>=this.layoutImages){
              this.commentService.progressSpin.emit(false)
              this.returnChanges.emit({newdata: this.imagesData, widget: this.widgetNumber, widgetType: this.imagesData.type,images: this.urls})
              this.dialogRef.close([this.imagesEditForm.value, true, this.newImage]);
            }
  
            if(this.imagesEditForm.value.images.length>=this.layoutImages){
              this.commentService.progressSpin.emit(false)
              this.returnChanges.emit({newdata: this.imagesData, widget: this.widgetNumber, widgetType: this.imagesData.type,images: this.urls})
              this.dialogRef.close([this.imagesEditForm.value, true, this.newImage]);
            }
          }
        }catch(error){
          console.log(error)
          this.commentService.progressSpin.emit(false)
        }
        
      }else{
        const message = this.translate.instant("SnackBarConstants.MORE_IMAGES_NEEDED")
        this.commentService.addSnackBar.emit(message)
      }
    }
  }

  cancelEdit(){

    if(this.newImagesURLs){
      this.newImagesURLs.forEach( url => {
        const parts = url.split("/")
        const urlimage = 'passes/' + parts[2] + "/" + parts[3]
        this.uploadService.deleteFromStorage(urlimage)
      })
    }
    this.dialogRef.close([this.oldData, false]);
  }

  returnChangesActive(event: any){
    const data = event.newdata
    removeUndefinedValuesFromObject(data)
    
  }

  supportUrl(url, index){
    if(url ){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName
        
        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.url[url]=firebaseUrl
            this.url[index]=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.url[url]=firebaseUrl
              this.url[index]=firebaseUrl
            }
          }
        }
  
        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.url[index]=firebaseUrl
          this.url[url]=firebaseUrl
        }

        if(imgUrl.protocol == "context:"){
          const parts = url.match(/context:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
              if(index>0 && index<parts.length){
                  path += "/" + parte
              }
          })
          const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
          this.url[index]=firebaseUrl
          this.url[url]=firebaseUrl
        }
      }catch(error){}
    }
  }


  returnChangesFilters($event){
    console.log($event)
    this.imagesEditForm.patchValue({filter: $event.newFilters})
  }
}
