import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { ReportService } from '../service/report.service';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-add-filter-field-dialog',
  templateUrl: './report-add-filter-field-dialog.component.html',
  styleUrls: ['./report-add-filter-field-dialog.component.css']
})
export class ReportAddFilterFieldDialogComponent {
  operatorOptions = [
    {
      name:"(==) equal to",
      translation: 'filters.operators.equal',
      value:"="
    },
    {
      name:"(!=) not equal to",
      translation: 'filters.operators.not_equal',
      value:"!="
    },
    {
      name:"(>) greather than",
      translation: 'filters.operators.greather_than',
      value:">"
    },
    {
      name:"(<) less than",
      translation: 'filters.operators.less_than',
      value:"<"
    },
    {
      name:"(>=) greter than or equal to",
      translation: 'filters.operators.greather_than_equal_to',
      value:">="
    },
    {
      name:"(<=) less than or equal to",
      translation: 'filters.operators.less_than_equal_to',
      value:"<="
    },
    {
      name:"(inrange) value between",
      translation: 'filters.operators.inrange',
      value:"inrange"
    }
/*     {
      name:"(in) equal to any of the following",
      translation: 'filters.operators.in',
      value:"in"
    },
    {
      name:"(!in) not equal to any of the following",
      translation: 'filters.operators.not_in',
      value:"!in"
    },
    {
      name:"(contains) an array containing",
      translation: 'filters.operators.contains',
      value:"contains"
    },
    {
      name:"(!contains) array not containing",
      translation: 'filters.operators.not_contains',
      value:"!contains"
    },
    {
      name:"(startsWith) starts with..",
      translation: 'filters.operators.startsWith',
      value:"startsWith"
    },
    {
      name:"(!startsWith) not starting with..",
      translation: 'filters.operators.not_startsWith',
      value:"!startsWith"
    }, */
  ]

  operatorOptionsFixedValue =[
    {
      name:"(==) equal to",
      translation: 'filters.operators.equal',
      value:"=",
      disabled:false
    },
    {
      name:"(!=) not equal to",
      translation: 'filters.operators.not_equal',
      value:"!=",
      disabled:false
    },
    {
      name:"(>) greather than",
      translation: 'filters.operators.greather_than',
      value:">",
      disabled:false
    },
    {
      name:"(<) less than",
      translation: 'filters.operators.less_than',
      value:"<",
      disabled:false
    },
    {
      name:"(>=) greter than or equal to",
      translation: 'filters.operators.greather_than_equal_to',
      value:">=",
      disabled:false
    },
    {
      name:"(<=) less than or equal to",
      translation: 'filters.operators.less_than_equal_to',
      value:"<=",
      disabled:false
    },
    {
      name:"(inrange) value between",
      translation: 'filters.operators.inrange',
      value:"inrange",
      disabled:false
    }
   /* 
    {
      name:"(in) equal to any of the following",
      translation: 'filters.operators.in',
      value:"in"
    },
    {
      name:"(!in) not equal to any of the following",
      translation: 'filters.operators.not_in',
      value:"!in"
    },
    {
      name:"(contains) an array containing",
      translation: 'filters.operators.contains',
      value:"contains"
    },
    {
      name:"(!contains) array not containing",
      translation: 'filters.operators.not_contains',
      value:"!contains"
    },
    {
      name:"(startsWith) starts with..",
      translation: 'filters.operators.startsWith',
      value:"startsWith"
    },
    {
      name:"(!startsWith) not starting with..",
      translation: 'filters.operators.not_startsWith',
      value:"!startsWith"
    },
    {
      name:"(exists) the field has a value..",
      translation: 'filters.operators.exists',
      value:"exists"
    },
    {
      name:"(!exists) the field has no value..",
      translation: 'filters.operators.not_exists',
      value:"!exists"
    }, */
  ]

  typeOptions = [
    {
      name:"Text",
      type:"string",
      translation:"typeOptions.text"
    },
    {
      name:"Number",
      type:"number",
      translation:"typeOptions.number"

    },
    {
      name:"Decimal",
      type:"decimal",
      translation:"typeOptions.decimal"

    },
    {
      name:"Boolean",
      type:"boolean",
      translation:"typeOptions.boolean"

    },
    {
      name:"Date",
      type:"date",
      translation:"typeOptions.date"

    },
    {
      name:"Time",
      type:"time",
      translation:"typeOptions.time"

    },
    {
      name:"Date/Time",
      type:"dateTime",
      translation:"typeOptions.dateTime"

    },
    {
      name:"Media",
      type:"media",
      translation:"typeOptions.media"

    },
  ]
  addForm!:FormGroup
  type:any
  selectedType: string;

  constructor(
    public dialogRef: MatDialogRef<ReportAddFilterFieldDialogComponent>,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private reportSettings: ReportService,
    private commentsService:CommentsService,
    private translate: TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(){
    
    if(this.data && this.data['type']){
      this.type= this.data['type']

      if(this.data['type']=="add_filter"){
        this.addForm = this.fb.group({
          key:[ , Validators.required],
          operator:['=', Validators.required],
          displayName:[, Validators.required],
          description:[, Validators.required],
          type:[]
        })
  
      }

      if(this.data['type']=="edit_filter"){ 
        const {key, operator, description,type,displayName} = this.data.values['data']
        this.addForm = this.fb.group({
          key:key ? key : undefined,
          operator: operator ? operator : '=',
          displayName: displayName ? displayName : undefined,
          description:description ? description : undefined,
          type:type ? type : undefined
        })
      }
      if(this.data['type']=="add_field"){
        this.addForm = this.fb.group({
          key:[, Validators.required],
          displayName:[, Validators.required],
          type:[, Validators.required]
        })
      }


      if(this.data['type']=="edit_field"){ 
        const {key, displayName,type} = this.data.values['data']
        this.addForm = this.fb.group({
          key:key ? key : undefined,
          displayName: displayName ? displayName : undefined,
          type:type ? type : undefined
        })
      }

      if(this.data['type']=="add_fixedFilter"){
        this.addForm = this.fb.group({
          key:[, Validators.required],
          operator:['=', Validators.required],
          description:[],
          value:[],
          type:[, Validators.required],
          displayName:[, Validators.required],
          valuerange1:[],
          valuerange2:[]
        })
        this.addForm.get('type').valueChanges.subscribe(value => {
          this.selectedType = value
        });
      }

      if(this.data['type']=="edit_fixedFilter"){
        let {key, operator, description,value,displayName, type} = this.data.values['data']
        let valuerange1, valuerange2
        if(type=='date' && operator!= 'inrange'){
          value = this.isDate(value)
        }

        if(type == 'string' || type == 'boolean' || type == 'media'){
          const a = this.operatorOptionsFixedValue.find( operator => operator.value === 'inrange')
          a.disabled=true
        }else{
          const a = this.operatorOptionsFixedValue.find( operator => operator.value === 'inrange')
          a.disabled=false
        }

        if(!type){
          const a = this.operatorOptionsFixedValue.find( operator => operator.value === 'inrange')
          a.disabled=false
        }
        
        if(operator == 'inrange'){
          const rangeRegex = /^(\[|\()(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)(\]|\))$/
          const match = rangeRegex.exec(value)
          if (match) {
            const startInclusive = match[1] === '['
            const endInclusive = match[6] === ']'
            const startValue = parseFloat(match[2])
            const endValue = parseFloat(match[4])
            valuerange1 = startValue
            valuerange2 = endValue
            if( type == 'dateTime'){
              let date = new Date(startValue).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
              valuerange1 = date[2] +'-'+ date[1] +'-' + date[0] + 'T' + new Date(startValue).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })

              let date2 = new Date(endValue).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
              valuerange2 = date2[2] +'-'+ date2[1] +'-' + date2[0] + 'T' + new Date(endValue).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
            }

            if(type=='date'){
              const dateisoRegex = /^(\-?\d+(\.\d+)?)T(\-?\d+(\.\d+)?)(\Z)$/
              valuerange1 = new Date(startValue).toISOString()
              valuerange2 = new Date(endValue).toISOString()
            }

            if(type == 'time'){
              let range1 = match[2]
              let range2 = match[4]
              let date1 = range1[0] + range1[1] + ':'+ range1[2]+range1[3]
              let date2 = range2[range2.length-4] + range2[range2.length-3] + ':'+ range2[range2.length-2]+range2[range2.length-1]
              valuerange1 = date1
              valuerange2 = date2
            }
          }
        }

        this.addForm = this.fb.group({
          key:key ? key : undefined,
          type: type ? type : undefined,
          operator: operator ? operator: '=' ,
          description: description ? description : undefined,
          value:value ? value : undefined,
          displayName: displayName ? displayName : undefined,
          valuerange1:valuerange1 ?? undefined,
          valuerange2:valuerange2 ?? undefined
        })
      }

      this.addForm.get('type').valueChanges.subscribe(value => {
        this.selectedType = value;
        if(value == 'string' || value == 'boolean' || value == 'media'){
          const a = this.operatorOptionsFixedValue.find( operator => operator.value === 'inrange')
          a.disabled=true
          if(this.addForm.value.operator=='inrange')
          this.addForm.patchValue({operator:'='})
        }else{
          const a = this.operatorOptionsFixedValue.find( operator => operator.value === 'inrange')
          a.disabled=false
        }
        if(this.addForm.value.operator=='inrange' && !value)
          this.addForm.patchValue({operator:'='})
      });

    }
  }

  onCancel(){
    this.dialogRef.close([this.addForm.value, false])
  }

  onTypeChange(value: string): void {
    this.selectedType = value;
  }

  onSave(){
    const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")

    if(this.data['type']=="add_filter"){
      const {key, operator, name, description,type, displayName} = this.addForm.value
      if(key && operator && description && displayName){
        const data = {
          key: key ? key : undefined,
          operator: operator ? operator : undefined,
          displayName: displayName ? displayName : undefined,
          description: description ? description : undefined,
          type: type ? type :undefined
        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true, 'filter'])
      }else{
        this.commentsService.addSnackBar.emit(message)
      }
      
    }

    if(this.data['type']=="edit_filter"){
      const {key, operator, name, description,type, displayName} = this.addForm.value
      if(key && operator && description && displayName){
        const data = {
          key: key ? key : undefined,
          operator: operator ? operator : undefined,
          displayName: displayName ? displayName : undefined,
          description: description ? description : undefined,
          type: type ? type :undefined
        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true, 'edit_filter'])
      }else{

        this.commentsService.addSnackBar.emit(message)

      }
    }

    if(this.data['type']=="add_field"){
      const {key, displayName, type} = this.addForm.value
      if(key && displayName && type){
        const data = {
          key: key ? key : undefined,
          displayName: displayName ? displayName : undefined,
          type: type ? type : undefined
        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true,'result_field'])
      }else{
        this.commentsService.addSnackBar.emit(message)
      }
    }

    if(this.data['type']=="edit_field"){
      const {key, displayName, type} = this.addForm.value
      if(key && displayName && type){
        const data = {
          key: key ? key : undefined,
          displayName: displayName ? displayName : undefined,
          type: type ? type : undefined
        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true,'edit_result_field'])
      }else{
        this.commentsService.addSnackBar.emit(message)
      }
    }

    if(this.data['type']=="add_fixedFilter"  ){
      const {key, operator, description, value, type, displayName} = this.addForm.value
      if(key && operator && type && displayName){

        let data = {
          key: key ? key : undefined,
          operator: operator ? operator : undefined,
          description: description ? description : undefined,
          value: value ? value : undefined,
          type: type ? type : undefined,
          displayName: displayName ? displayName : undefined
        }

        if(operator == 'inrange'){
          const {value, valuerange1, valuerange2} = this.addForm.value
          data.value = '[' + valuerange1 + ',' + valuerange2 + ']'
          if(type=='date'){
            let datestart = new Date(valuerange1).getTime()
            let dateend = new Date(valuerange2).getTime()
            data.value = '[' + datestart + ',' + dateend + ']'
          }
          if(type=='dateTime'){
            let datestart = new Date(valuerange1).getTime()
            let dateend = new Date(valuerange2).getTime()
            data.value = '[' + datestart + ',' + dateend + ']'
          }
        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true,'fixed_filters'])
      }else{
        this.commentsService.addSnackBar.emit(message)
      }
      
    }

    if( this.data['type']=="edit_fixedFilter" ){
      let {key, operator, description,value, type,displayName} = this.addForm.value
      let rangeValue
      if(key && operator && type && displayName){
        
        if(operator == 'inrange'){
          const { valuerange1, valuerange2} = this.addForm.value
          let dateStart, dateEnd

          if(type == 'dateTime' || type== 'date' || type== 'time'){
            if( type == 'dateTime'){
              let datestart = new Date(valuerange1).getTime()
              let dateend = new Date(valuerange2).getTime()
              rangeValue = '[' + datestart + ',' + dateend + ']'
              value = rangeValue
            }
    
            if(type == 'time'){
              let datestart = valuerange1.replace(':','')
              let dateend = valuerange2.replace(':','')
              value = '[' + datestart + ',' + dateend + ']'
              rangeValue = '[' + datestart + ',' + dateend + ']'
              value = rangeValue
            }

            if(type=='date' ){

              if(typeof valuerange1 === 'string'){
                dateStart = Date.parse(valuerange1)
                dateEnd = Date.parse(valuerange2)
                rangeValue = deepCopy('[' + Date.parse(valuerange1) + ',' + Date.parse(valuerange2) + ']')
                // value = '[' + Date.parse(valuerange1) + ',' + Date.parse(valuerange2) + ']'
                value = rangeValue
              }
              
              if(typeof valuerange1 === 'object'){
                rangeValue = '[' + Date.parse(valuerange1) + ',' + Date.parse(valuerange2) + ']'
                value=rangeValue
                dateStart = Date.parse(valuerange1)
                dateEnd = Date.parse(valuerange2)
                // value = '[' + Date.parse(valuerange1) + ',' + Date.parse(valuerange2) + ']'
              }
              
            }
          }else{
            value = '[' + valuerange1 + ',' + valuerange2 + ']'
          }
          // rangeValue = '[' + dateStart + ',' + dateEnd + ']'
        }else
        rangeValue = value

        let data = {
          key: key ? key : undefined,
          operator: operator ? operator : undefined,
          description: description ? description : undefined,
          value: rangeValue ? value : undefined,
          type: type ? type : undefined,
          displayName: displayName ? displayName : undefined

        }
        removeUndefinedValuesFromObject(data)
        this.dialogRef.close([data,true,'edit_fixed_filters'])
      }else{
        this.commentsService.addSnackBar.emit(message)

      }
    }
  }

  isDate(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toDateString()
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toDateString()
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate
            // dateValue = newdate.toDateString()
      }catch(errro){}
    }
   return dateValue
  }

  isTime(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }
    //   if( typeof element === 'object'){
    //     const date = new Date(element)
    //     dateValue = date.toUTCString()//----------
    //   }
    //   if( typeof element === 'string'){
    //     const date = new Date(element)
    //     dateValue = date.toUTCString()//----------
    //   }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }catch(errro){}
    }
   return dateValue
  }
}
