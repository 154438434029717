export const contentypeForMetadata = {
    "css": "text/css",
    'csv' : "text/csv",
    'doc' : "application/msword",
    'docx' : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "html": "text/html",
    'jpg' : "image/jpeg",
    'jpeg' : "image/jpeg",
    'mov' : "video/quicktime",
    "tiff": "image/tiff",
    'ttf' : "font/ttf",
    'txt' : "text/plain",
    'otf' : "font/otf",
    'pdf' : "application/pdf",
    'png' : "image/png",
    "svg": "image/svg+xml",
    'vcf' : "text/x-vcard",
    'webp' : "image/webp",
    "xml": "application/xml",
    'xls' : "application/vnd.ms-excel",
    'xlsx' : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "zip": "application/zip",
    //-----------
    "3g2": "video/3gpp2",
    "3gp": "video/3gpp",
    "asf": "video/x-ms-asf",
    "avi": "video/x-msvideo",
    "f4v": "video/x-f4v",
    "fli": "video/x-fli",
    "flv": "video/x-flv",
    "fvt": "video/vnd.fvt",
    "h261": "video/h261",
    "h263": "video/h263",
    "h264": "video/h264",
    "jpgv": "video/jpeg",
    "jpm": "video/jpm",
    "m4v": "video/x-m4v",
    "mj2": "video/mj2",
    "movie": "video/x-sgi-movie",
    'mp4' : "video/mp4",
    "mpeg": "video/mpeg",
    "mxu": "video/vnd.mpegurl",
    "ogv": "video/ogg",
    "pyv": "video/vnd.ms-playready.media.pyv",
    "qt": "video/quicktime",
    "uvh": "video/vnd.dece.hd",
    "uvm": "video/vnd.dece.mobile",
    "uvp": "video/vnd.dece.pd",
    "uvs": "video/vnd.dece.sd",
    "uvu": "video/vnd.uvvu.mp4",
    "uvv": "video/vnd.dece.video",
    "viv": "video/vnd.vivo",
    "webm": "video/webm",
    "wm": "video/x-ms-wm",
    "wmv": "video/x-ms-wmv",
    "wmx": "video/x-ms-wmx",
    "wvx": "video/x-ms-wvx",
}
