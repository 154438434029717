import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';;

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  }
  
  @Injectable()
  export class CanDeactivateGuard implements CanDeactivate<any> {

    canDeactivate(component: any): Observable<boolean> | Promise<boolean> | boolean {
        if (component.canDeactivate) {
          return component.canDeactivate();
        } else {
          return true;
        }
      }
  }