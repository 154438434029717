<section class="container">

    <ng-container *ngIf="userLength === 0">
        <div class="row justify-content-center my-5">
            <div class="col-8 text-center my-5 py-5">
                <h1 class="mb-1">{{'topNavbar.users' | translate}}</h1>
                <p class="lead pb-3" style="font-family:'MyFont-Light';">{{'empty_users' | translate}}</p>
                <div [matTooltip]="!usersUserRights_create ? ('home.tooltip' | translate) : null">
                    <button mat-button 
                    class="orange-button" 
                    id="btn-create" 
                    (click)="openDialogCreateUser();" 
                    [disabled]="!usersUserRights_create"
                    >
                    <mat-icon>person_add</mat-icon> {{'add_new' | translate}}
                    </button>
                </div>
            </div>
          </div>
    </ng-container>

    <div [ngClass]="{'d-none': userLength === 0}">
        <div class="row mt-4">

            <div class="mt-3">
                <h1 class="mb-1">{{'topNavbar.users' | translate}}</h1>
                <p style="font-family:'MyFont-Light';">{{'headlines.userManagement' | translate}}</p>
            </div>

            <div class="col-12">

                <section class="mt-1 filter-section d-flex flex-row flex-wrap align-items-center justify-content-between">    
                    <div [matTooltip]="!usersUserRights_create ? ('home.tooltip' | translate) : null" class="d-flex flex-row flex-wrap">
                        <button mat-button 
                                class="orange-button my-3" 
                                id="btn-create" 
                                (click)="openDialogCreateUser();" 
                                [disabled]="!usersUserRights_create">
                            <mat-icon>person_add</mat-icon> {{'add_new' | translate}}
                        </button>
                    </div>
                
                    <mat-form-field class="pt-3 mb-0 mt-0 white-input-bg">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </section>
    
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="col-12 mb-4">
    
                <div class="mat-elevation-z8 ounded shadow-sm">
                    <table mat-table [dataSource]="dataSource" class="mat-table" matSort matSortDisableClear>
            
                        <!-- Username -->
                        <ng-container matColumnDef="displayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.displayName' | translate }} </th>
                            <td mat-cell *matCellDef="let user"> {{user.displayName}} </td>
                        </ng-container>
            
                        <!-- Email -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.email' | translate }} </th>
                            <td mat-cell *matCellDef="let user"> {{user.email}} </td>
                        </ng-container>

                        <!-- Creation Date -->
                        <ng-container matColumnDef="creationDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'creation_date' | translate }} </th>
                            <td mat-cell *matCellDef="let user"> {{ user.creationDate | date: 'dd.MM.yyyy, HH:mm' }} </td>
                        </ng-container>

                        <!-- Roles Column -->
                        <ng-container matColumnDef="roles">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.roles' | translate }} </th>
                            <td mat-cell *matCellDef="let user">
                                <div *ngFor="let role of user.roles" 
                                    class="role-chip m-1"
                                    [ngClass]="getRoleColor(role)" 
                                    matTooltip="{{ formatRoleName(role) }}" matTooltipPosition="above">
                                    {{ getRoleAbbreviation(role) }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> {{ 'Status' | translate }} </th>
                            <td mat-cell *matCellDef="let user">
                                <span *ngIf="user.disabled">{{'users.disabled' | translate}}</span>
                                <span *ngIf="!user.disabled">{{ user.state ? ('users.' + user.state | translate) : '-' }}</span>
                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let user">

                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="editUser(user);" [disabled]="!usersUserRights_create">
                                        <mat-icon class="material-symbols-outlined">edit</mat-icon> {{'edit' | translate}}
                                    </button>
                                    <button *ngIf="(user.state === 'registered' && !user.disabled)" mat-menu-item (click)="resetPassword(user.email);" [disabled]="!usersUserRights_create || !user.email">
                                        <mat-icon class="material-symbols-outlined">passkey</mat-icon> {{'users.resetPassword' | translate}}
                                    </button>
<!--                                     <button *ngIf="(user.state === 'registered' && !user.disabled)" mat-menu-item (click)="disableUser(user);" [disabled]="!(user.state === 'registered') || !usersUserRights_create">
                                        <mat-icon class="material-symbols-outlined">lock</mat-icon> {{'users.disable' | translate}}
                                    </button> -->
                                    <button *ngIf="user.state === 'invited'" mat-menu-item (click)="reinviteUser(user);" [disabled]="!usersUserRights_create">
                                        <mat-icon class="material-symbols-outlined">forward_to_inbox</mat-icon> {{'users.reinvite' | translate}}
                                    </button>
<!--                                     <button *ngIf="user.disabled" mat-menu-item (click)="enableUser(user);" [disabled]="!usersUserRights_create">
                                        <mat-icon class="material-symbols-outlined">lock_open</mat-icon> {{'users.enable' | translate}}
                                    </button>
                                    <button mat-menu-item (click)="deleteUser(user);" disabled>
                                        <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                                    </button> -->
                                </mat-menu>
                            </td>
                        </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                    </table>
            
                </div>
    
            </div>
        </div>
    </div>

    <mat-paginator *ngIf="userLength > 0" class="pb-2" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons
                [length]="userLength" [disabled]="userLength == 0">
    </mat-paginator>

</section>