import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-composed-phone]',
  templateUrl: './composed-phone.component.html',
  styleUrls: ['./composed-phone.component.css']
})
export class ComposedPhoneComponent {
  @Input('app-composed-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()

  idToShow:any
  routerUrl:any
  composedData:any
  editMode = false
  widgetNumber:any
  
  constructor(private router: ActivatedRoute){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.composedData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
  }

}
