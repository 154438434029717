import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { deepCopy } from '../../../../../../backend/utils/object';
import { isDateTimeGetTimeSeconds } from '../../../../../../backend/src/filter';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-countdown-edit',
  templateUrl: './countdown-edit.component.html',
  styleUrls: ['./countdown-edit.component.css']
})
export class CountdownEditComponent {
  countdownEdiForm!:FormGroup
  countdownData:any
  oldData:any
  widgetNumber:any
  passId:any
  panelOpenState = false
  languagesList = languagesList
  defaultLanguage:string
  alignmentTypes= [
    {
      value:'left',
      translation:'pass.countdown.alignment_types.left'
    },
    {
      value:'center',
      translation:'pass.countdown.alignment_types.center'
    },
    {
      value:'right',
      translation:'pass.countdown.alignment_types.right'
    }
  ]
  invalid_background = false
  invalid_textColor = false
  colorPalette:any=[]

  defaultColors = {
    textColor:'#FF5E38',
    backgroundColor:'#ffffff',
  }
  lessMinDate = false
  
  constructor(public dialogRef: MatDialogRef<CountdownEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private translate: TranslateService
  ){
    dialogRef.disableClose = true;
  }


  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.countdownData = this.data.values
    this.oldData = deepCopy(this.countdownData)
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widget']

    this.countdownEdiForm = this.fb.group({
      type:[],
      id:[],
      // title:[],
      name:[],
      targetDate:[],
      targetDateKey:[],
      expiredMessage:[, [Validators.required]],
      filter:[],
      alignment:[],
      backgroundColor:[],
      textColor:[]
    })

    const { type, id, /*title,*/ name, targetDate, targetDateKey, expiredMessage,filter, alignment, textColor, backgroundColor} = this.countdownData
    this.initializePaletteColors()
    let date, valuerange1
    if(targetDate){
       date = isDateTimeGetTimeSeconds(targetDate)
      let dateEx = new Date(date).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
      const year = String(dateEx[2]).padStart(4,'0')
      valuerange1 = year +'-'+ dateEx[1] +'-' + dateEx[0] + 'T' + new Date(date).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
    }

    this.countdownEdiForm.patchValue({
      name: name ? name : undefined,
      type:type ? type : undefined,
      id: id ? id : undefined,
      // title:title ? title : undefined,
      targetDate:valuerange1 ? valuerange1 : undefined,
      targetDateKey:targetDateKey ? targetDateKey : undefined,
      expiredMessage: expiredMessage ? expiredMessage : undefined,
      filter: filter ? filter : undefined,
      alignment: alignment ? alignment : undefined,
      backgroundColor:backgroundColor ? backgroundColor : this.defaultColors.backgroundColor,
      textColor: textColor ? textColor : this.defaultColors.textColor
    })

    this.countdownEdiForm.get("targetDate").valueChanges.subscribe( value => {
      this.checkMinimumDate(value)
    })
    

    this.countdownEdiForm.valueChanges.subscribe( value => {
      console.log(value)
      this.checkInvalidColors()
    })



  }

  initializePaletteColors(){
    let accent  = document.documentElement.style.getPropertyValue('accent')
    let background = document.documentElement.style.getPropertyValue('background')

    var colors=[]
    const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
    const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
    const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
    const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
    colors.push(warmOrange)
    colors.push(darkblue)
    colors.push(pureWhite)
    colors.push(salbei)
    this.colorPalette=colors
  }

  checkInvalidColors(){
    if(this.countdownEdiForm.controls['backgroundColor'].hasError('invalidColor')){
      this.invalid_background=true
    }else{
      this.invalid_background=false
    }

    if(this.countdownEdiForm.controls['textColor'].hasError('invalidColor')){
      this.invalid_textColor=true
    }else{
      this.invalid_textColor=false
    }
  }

  dialogCancel(){
    this.commentService.progressSpin.emit(true)
    this.dialogRef.close([this.oldData, false])
    this.commentService.progressSpin.emit(false)

  }

  dialogSave(){
    if(this.countdownEdiForm.value.expiredMessage){
      this.commentService.progressSpin.emit(true)
      if(this.countdownEdiForm.value.targetDate){
        let date = new Date(this.countdownEdiForm.value.targetDate)
        const minDate = new Date('1970-01-01T00:00').getFullYear()

        this.countdownEdiForm.patchValue({targetDate: date})
        if(date.getTime() < minDate)
          this.countdownEdiForm.patchValue({targetDate: new Date('1970-01-01T00:00')})

      }
      this.dialogRef.close([this.countdownEdiForm.value, true])
      this.commentService.progressSpin.emit(false)
    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentService.addSnackBar.emit(message)
      this.commentService.progressSpin.emit(false)
    }

  }

  checkMinimumDate(value){
    const minDate = new Date('1970-01-01T00:00').getFullYear()
    const date = new Date(value).getFullYear()

    if(date){
      if (minDate > date)
        this.lessMinDate = true
      else
        this.lessMinDate = false
    }
  }

  returnChangesFilters($event){
    console.log($event)
    this.countdownEdiForm.patchValue({filter: $event.newFilters})
  }

}
