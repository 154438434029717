import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {CallToAction} from './../../../../../../backend/src/callToAction.dto'
import { removeUndefinedValuesFromObject, supportUrlStructure } from '../../../../../../backend/utils/object';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-call-to-action-edit]',
  templateUrl: './call-to-action-edit.component.html',
  styleUrls: ['./call-to-action-edit.component.css']
})
export class CallToActionEditComponent implements OnInit {
  callToActionEditform!:FormGroup
  oldData:any
  callToActionEditData:any
  url:any
  panelOpenState = false;
  allNetworks:any=[]
  allNetworksTypes:any=[]
  allNetworksURLS:any=[]
  newImage:any=[]
  widgetNumber:any
  passId:any
  posibleNetworks:any=[
    {
      name:"facebook" ,
      added:false,
      url:""
    },
    {
      name:"instagram",
      added:false,
      url:""
    },
    
    {
      name:"linkedin",
      added:false,
      url:""

    },
    {
      name:"snapchat",
      added:false,
      url:""

    },
    {
      name:"tiktok",
      added:false,
      url:""

    },
    {
      name:"twitter",
      added:false,
      url:""

    },
    {
      name:"youtube",
      added:false,
      url:""

    }


  ]
  changes=false
  imageSizeNew:any=[]
  files: FileHandle[] = []
  drop=false
  urlData:any
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  addUrl: boolean = false;
  languagesList = languagesList
  defaultLanguage:string
  dynamicUrl = false

  constructor(public dialogRef: MatDialogRef<CallToActionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private imageCompressionService: ImageCompressionService,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate:TranslateService,
    private settingsService: SettingsService){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage
    this.callToActionEditData = this.data.values
    this.oldData=this.data.values
    this.url=this.callToActionEditData.imageUrl
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']

    if(this.data['url']){
      this.urlData = this.data['url']
    }else{
      if(this.callToActionEditData.imageUrl)
        this.supportUrl(this.callToActionEditData.imageUrl)
    }

    this.callToActionEditform = this.fb.group({
      id:[],
      title:[],
      imageSize:[],
      //hasLogin:[],
      imageUrl:[],
      logoUrl:[],
      socialNetworks:[],
      type:[],
      facebook:[],
      instagram:[],
      linkedin:[],
      youtube:[],
      tiktok:[],
      twitter:[],
      snapchat:[],
      name:[],
      filter:[]
    })
    const {id, type, filter,title, imageUrl,imageSize, logoUrl, socialNetworks,name} = this.callToActionEditData
   
    this.callToActionEditform.patchValue({
      id: id ? id : undefined,
      title: title ? title : undefined,
      //hasLogin: hasLogin ? hasLogin : undefined,
      imageUrl:imageUrl ? imageUrl :undefined,
      logoUrl:logoUrl ? logoUrl : undefined,
      socialNetworks:socialNetworks ? socialNetworks : undefined,
      type:type ? type : undefined,
      imageSize:imageSize ? imageSize : undefined,
      name: name ? name : undefined,
      filter: filter ? filter: undefined
    })

    this.callToActionEditform.valueChanges.subscribe( async (value:any) => {
      this.allNetworks.forEach( net => {
        if(net.type == "facebook"){
          net.url = value.facebook
        }
        if(net.type == "instagram"){
          net.url = value.instagram
        }
        if(net.type == "linkedin"){
          net.url = value.linkedin
        }
        if(net.type == "youtube"){
          net.url = value.youtube
        }
        if(net.type == "tiktok"){
          net.url = value.tiktok
        }
        if(net.type == "twitter"){
          net.url = value.twitter
        }
        if(net.type == "snapchat"){
          net.url = value.snapchat
        }
      })

      if(this.callToActionEditform.value?.imageSize?.length == 0 || !this.callToActionEditform.value.imageSize || (this.callToActionEditform.value?.imageSize && this.callToActionEditform.value?.imageSize[0] == 0 && this.callToActionEditform.value?.imageSize[1] == 0) ){
        if(!this.dynamicUrl){
          await this.uploadService.calculateImgFileSize(this.urlData).then(value => {
            this.callToActionEditform.patchValue({imageSize: value})
          })
        }
      }
    })

    this.callToActionEditform.get("imageUrl").valueChanges.subscribe( (newurl) => {
      if(this.addUrl){
        this.url = newurl
        // this.supportUrl(newurl)
      }
      if(newurl?.match(/\${(.+)\}/))
        this.dynamicUrl = true
      else
        this.dynamicUrl = false
    })

    this.initializeNetworks()
  }

  initializeNetworks(){
    this.callToActionEditData.socialNetworks.forEach((n:any) => {
      this.posibleNetworks.forEach((pn:any)=> {
        if(n.type==pn.name){
          pn.added=true
          pn.url=n.url
          const tn= {
            type:n.type,
            url: n.url
          }
          this.callToActionEditform.patchValue({[pn.name]: pn.url})
          this.allNetworks.push(tn)
        }
      })
    })

  }

  async readURL(event: any): Promise<void> {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }

      let imageToUpload = file;
  
      if (file.size > maxSizeInBytes) {
        try {
          const compressedImage = await this.imageCompressionService.compressImage(file, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          imageToUpload = compressedImage;
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      }
  
      var reader = new FileReader();
      this.newImage = imageToUpload;
  
      reader.readAsDataURL(imageToUpload);
      reader.onload = async (event) => {
        if (event.target) {
          const urlNew = event.target.result as string;
  
          if (urlNew) {
            this.changes = true;
  
            try {
              const uploadURL = await this.uploadService.uploadNewCallToActionImage(this.passId, this.newImage, this.widgetNumber);
  
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
                this.callToActionEditform.patchValue({imageUrl: this.url});
              }
  
            } catch (error) {
              console.log(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {
      // File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.size > maxSizeInBytes) {
      // File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        this.newImage = compressedImage;
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {
      // File is less than or equal to 2 MB, no compression needed
      this.newImage = fileToUpload;
    }
  
    const newUrl = files[0].url;
  
    if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {
      if (newUrl) {
        this.drop = true;
        this.changes = true;
  
        try {
          const uploadURL = await this.uploadService.uploadNewCallToActionImage(this.passId, this.newImage, this.widgetNumber);
  
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
            this.callToActionEditform.patchValue({imageUrl: this.url});
          }
  
  
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    }
  }
  
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  addNetwork(x:any){
    const n= {
      type:x,
      url:""
    }
    if(!this.allNetworksTypes.includes(n.type)){
      this.posibleNetworks.forEach((pn:any) => {
        if(pn.name==x){
          pn.added=true
          n.url=pn.url
        }
      })
      this.allNetworks.push(n)
      this.callToActionEditform.patchValue({
        socialNetworks: this.allNetworks
      })

    }
   
  }

  removeNetwork(r:any){
    this.posibleNetworks.forEach((pn:any) => {
      if(pn.name==r){
        pn.added=false

        this.allNetworks.forEach((element:any, index:any) => {
          if(element.type==r){
            this.allNetworks.splice(index,1)
            this.callToActionEditform.patchValue({
              socialNetworks: this.allNetworks
            })
          }
        });
      }
    })
  }

  updateSocialNetwork(sn:any){
    const input=document.getElementById(sn) as HTMLInputElement | null;
    const x= input?.value
   
    this.allNetworks.forEach((value:any, index:any)=>{
      if(value.type==sn){
        value.url=x
      }
    })

    this.callToActionEditform.patchValue({
      socialNetworks: this.allNetworks
    })

    this.callToActionEditform.patchValue({
      [sn]: x
    })
  }

  onCancelEdit(){
    this.dialogRef.close([this.oldData, false])

  }

  editImg(url){
    const data = { 
      url: this.url
    }
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: data,
        type: this.callToActionEditform.value.type,
        passId: this.passId

      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const {fallbackURL , url} = result[0]
        if(url){
          if(url!=this.callToActionEditform.value.imageUrl){
            this.urlData=""
          }
          this.url=url
          this.changes=false
          this.callToActionEditform.patchValue({imageUrl: url})
          this.uploadService.calculateImageSize(url)
          if(this.url){
            this.supportUrl(this.url)
            
          }
          setTimeout(() => {
            const size = this.uploadService.imageSizeNew
            if(this.uploadService.imageSizeNew){
              this.callToActionEditform.patchValue({imageSize: size})
            }
          },600)
        }
      }
    });
  }

  async onSaveEdit(){
    this.commentService.progressSpin.emit(true)
      if(this.changes){
        const newUrl = this.uploadService.getNewCallToActionImage()
        const imageSize = this.uploadService.getCallToActionImageSize()
        this.callToActionEditform.patchValue({imageSize})

        if(imageSize.length==0)
        this.callToActionEditform.patchValue({imageSize: [0,0]})

        this.url = newUrl
        this.callToActionEditform.patchValue(newUrl)
      }

      if(this.callToActionEditform.value.imageUrl && !this.dynamicUrl){
        const url = supportUrlStructure(this.callToActionEditform.value.imageUrl,this.passId,this.settingsService.contextId$)

        if(this.callToActionEditform.value?.imageSize?.length == 0 || !this.callToActionEditform.value.imageSize || (this.callToActionEditform.value?.imageSize && this.callToActionEditform.value?.imageSize[0] == 0 && this.callToActionEditform.value?.imageSize[1] == 0) ){
          await this.uploadService.calculateImgFileSize(url).then(value => {
            this.callToActionEditform.patchValue({imageSize: value})
          })
        }
      }else{
        this.callToActionEditform.patchValue({imageSize: undefined})
      }
      if(this.url){
        const data = this.callToActionEditform.value
        delete data.facebook
        delete data.instagram
        delete data.linkedin
        delete data.snapchat
        delete data.tiktok
        delete data.twitter
        delete data.youtube

        this.callToActionEditform.value.socialNetworks.forEach( social => {
          if(!social.url){
            delete social.url
          }
        })
        
        
        this.commentService.progressSpin.emit(false)
        
        this.dialogRef.close([data, true, this.newImage]);

      }
      else{
        const message = this.translate.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
        this.commentService.addSnackBar.emit(message)
      }
  }

  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  deleteImg(url){
    this.changes=true
    this.url=""
    this.callToActionEditform.patchValue({imageUrl: this.url, imageSize: undefined})
    this.urlData=""
  }

  supportUrl(url){
    this.urlData = url
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName
      
        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }

        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }

        if(imgUrl.protocol == "context:"){
          const parts = url.match(/context:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
              if(index>0 && index<parts.length){
                  path += "/" + parte
              }
          })
          const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
          this.urlData=firebaseUrl
        }
      }catch(errro){}
    }
  }

  checkNewUrl(){
    this.url = this.callToActionEditform.value.imageUrl
    this.supportUrl(this.callToActionEditform.value.imageUrl)
  }


  returnChangesFilters($event){
    console.log($event)
    this.callToActionEditform.patchValue({filter: $event.newFilters})
  }

}
