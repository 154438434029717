export const defaultContentData={
    text:{
        "id":"",
        "key":"New",
        "type":"string",
    },

    number:{
        "id":"",
        "key":"New",
        "type":"number",
    },

    boolean:{
        "id":"",
        "key":"New",
        "type":"boolean",
    },

    list:{
        "id":"",
        "key":"New",
        "type":"array",
    },

    object:{
        "id":"",
        "key":"New",
        "type":"object",
    },
    date:{
        "id":"",
        "key":"New",
        "type":"date",
    }





}