<h2 mat-dialog-title>{{'contentTypes.delete_btn' | translate}}</h2>
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <div class="p-3">
                <p>{{'contentTypes.delete_msg' | translate}}</p>
                <p class="delete-infos">
                    <strong>{{'contentTypes.displayName' | translate}}</strong>: {{contentTypeName}}<br>
                    <strong>{{'creation_date' | translate}}</strong>: {{isDate(creationDate)}}
                </p>
                <hr>
                <p><strong style="font-family: 'MyFont-Bold', sans-serif;color:#f46565;">{{'delete_ask' | translate}}</strong></p> 
            </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelDeleteContentType();">
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="deleteContentType();" >{{'delete' | translate}}</button> 
</mat-dialog-actions>