<section class="container">
    <div class="row mt-4">

        <div class="mt-4 mb-3">
            <h1 class="mb-1">{{'topNavbar.finishedTasks' | translate}}</h1>
            <div class="d-flex flex-row justify-content-between">
                <p style="font-family:'MyFont-Light';">{{'headlines.subFinishedTasks' | translate}}</p>
                <mat-form-field class="white-input-bg">
                    <mat-label>{{'search_title' | translate}}</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <div *ngIf="finishedTasks?.length === 0" class="p-2 empty-list" >{{'finished.empty' | translate}}</div>

            <div class="shadow-sm mat-elevation-z8 m-auto pb-4" id="table-container" >
                <table mat-table [dataSource]="listdata" matSort class="rounded">
            
                <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="rounded"> {{'title' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="width: 60%;"> 
                    <span *ngIf="element.title" class="text-wrap text-break">{{element.title}}</span>
                </td>
                </ng-container>
            
                <ng-container matColumnDef="creationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm'}}</span> 
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="timeStamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm'}} </span>        
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}"></tr>
            
            
                    <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-2" colspan="4">{{'no_matching_data' | translate}}</td>
    
                </table> 
            
                <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of f.tasks" showFirstLastButtons class="pb-2"></mat-paginator>
            
            </div>
        </div>
    </div>

</section>

