import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import * as JSZip from 'jszip';
import { saveAs } from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  storage = getStorage();

  constructor(private http: HttpClient) { }


  downloadImage(url: string, fileName:string) :string{
    let linkDwnld = ''
    console.log(url, fileName)
    if(url && fileName){
      this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
        // Create a link element
        let linkurl = ''
        const link = document.createElement('a');
        linkurl= URL.createObjectURL(blob);
        link.href = linkurl

        link.download = fileName//'CoverImage.jpg'; // Set the download filename
        linkDwnld = linkurl
        console.log(linkDwnld)

        // Append link to the body, click it, and then remove it
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    }, error => {
        console.error('Error downloading the image: ', error);
    });
    }

    return linkDwnld
  }


  getDownloadUrlFromStroageImg(ur, route){
    const listRef = ref(this.storage, route);
    getDownloadURL(listRef)
    .then((url) => {
      // `url` is the download URL for 'images/stars.jpg'
  
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
      console.log(url)
      // Or inserted into an <img> element
      // const img = document.getElementById('myimg');
      // img.setAttribute('src', url);
    })
    .catch((error) => {
      // Handle any errors
    });
  }


  downloadImageForZip(url){
    this.http.get(url, { responseType: 'blob'})//{ responseType: "arraybuffer" }
    .subscribe(x => this.saveAsZip(x));
  }

  saveAsZip(content){
    var zip = new JSZip;
    zip.file("image", content);
    zip.generateAsync({ type: "blob" })
      .then(blob => saveAs(blob,'image.zip'));
  }

}
