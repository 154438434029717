import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-instagram-phone]',
  templateUrl: './instagram-phone.component.html',
  styleUrls: ['./instagram-phone.component.css']
})
export class InstagramPhoneComponent {
  @Input('app-instagram-phone') data:any;

}
