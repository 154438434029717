<section class="px-3">
    <div *ngIf="listData.items && listData.items.length!=0">

        <div *ngIf="style=='definition'">
            <div class="d-flex flex-column definition-content">
               <div *ngFor="let item of listData.items">
                    <div *ngIf="item.value" class="d-flex flex-column">
                        <span class="fw-bold" id="bold"><strong>{{item.title}}</strong></span> <!-- this needs to colored differently -->
                        <span class="fw-light ps-4" id="plain">{{item.value}}</span>
                    </div>
               </div>
            </div>
        </div>

        <div *ngIf="style=='list'">
            <ul>
                <div *ngFor="let item of listData.items">
                    <li *ngIf="item.value">
                        <div>
                            <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="item.title">
                                <span class="fw-bold text-start " id="bold"><strong>{{item.title}}</strong></span> <!-- this needs to colored differently -->
                                <span class="fw-light text-end" id="plain">{{item.value}}</span>
                            </div>
        
                            <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="!item.title">
                                <span class="fw-light text-start" id="plain">{{item.value}}</span>
                            </div>
                        </div>
                    </li>
                </div>  
            </ul> 
        </div>

        <div *ngIf="style=='table'">
            <table>
                <!-- <tr>
                    <th></th>
                </tr> -->
                <div *ngFor="let item of listData.items">
                    <tr *ngIf="item.value" class="w-100 d-flex justify-content-between">
                        <td id="plain" class="w-50">{{item.title}}</td> <!-- this needs to colored differently -->
                        <td id="plain" class="w-50">{{item.value}}</td>
                    </tr>
                </div>
            </table>
        </div>

    </div>
</section>