import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { httpsCallable } from '@angular/fire/functions';
import {Functions} from "@angular/fire/functions";
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.css']
})
export class CreateUserDialogComponent {
  createUserForm!:FormGroup
  allInvitesUsers:any = []
  availableRoles: { value: string, display: string }[] = [
    { value: 'accountManager', display: 'Account Manager' },
    { value: 'contentManager', display: 'Content Manager' },
    { value: 'workTaskManager', display: 'Work Task Manager' },
    { value: 'tracer', display: 'Tracer' },
    { value: 'tracingSupervisor', display: 'Tracing Supervisor' }
  ];

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
    private settingsService:SettingsService,
    private functions: Functions,
    private commentService:CommentsService,
    private translate: TranslateService
    ){
    
  }

  ngOnInit():void{
    this.createUserForm = this.fb.group({
      displayName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      roles: [[], Validators.required]
    })

  }

  cancelNewUSer(){
    this.dialogRef.close([false])
  }

  addUserToList(): void {
    if (this.createUserForm.valid) {
      const formValue = this.createUserForm.value;
      
      const userWithDisplayRoles = {
        ...formValue,
        displayRoles: formValue.roles.map((role: string) => 
          this.availableRoles.find(r => r.value === role)?.display
        )
      };

      this.allInvitesUsers.push(userWithDisplayRoles);
      this.sendInvitation();
      this.createUserForm.reset();
    } else {
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY");
      this.commentService.addSnackBar.emit(message);
    }
  }

  sendInvitation(): void {
    this.commentService.progressSpin.emit(true);
  
    if (this.allInvitesUsers.length > 0) {
      const users = this.allInvitesUsers.map(user => ({
        displayName: user.displayName,
        email: user.email,
        roles: user.roles
      }));
  
      const data = {
        contextId: this.settingsService.contextId$,
        users: users,
      };
  
      const inviteUser = httpsCallable(this.functions, 'admin-registerUsers');
  
      inviteUser(data).then((result) => {
        const userIds = result.data['userIds'];
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.INVITE_OK");
        this.commentService.addSnackBar.emit(message);
        this.dialogRef.close([true, userIds, result]);
      })
      .catch((error) => {
        console.error(error);
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.INVITE_FAILED");
        this.commentService.addSnackBar.emit(message);
        this.dialogRef.close([false]);
      });
    } else {
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY");
      this.commentService.addSnackBar.emit(message);
    }
  }
  
}
