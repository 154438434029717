import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
@Component({
  selector: 'app-model3d-view',
  templateUrl: './model3d-view.component.html',
  styleUrls: ['./model3d-view.component.css']
})
export class Model3dViewComponent implements OnInit{
  @ViewChild('canvasView') private canvasRef!: ElementRef;
  viewData:any
  editMode:any
  modelToRender: any

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  constructor(public dialogRef: MatDialogRef<Model3dViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],){  
      dialogRef.disableClose = true
  }

  create(){
    const scene = new THREE.Scene()
    const loader = new GLTFLoader()
    this.modelToRender= './../../assets/models/Case_ORCA_Open.glb'
    // loader.load('./../../assets/models/Case_ORCA_Open.glb', function(glb){
    loader.load(this.modelToRender, function(glb){
      scene.background = new THREE.Color(0xffffff)
      const root= glb.scene
      scene.add(root)
    }, function (xhr) {
    }, function (error){
    })

    const light = new THREE.DirectionalLight(0xffffff,1)
    light.position.set(2,2,5)
    scene.add(light)

    const sizes = {
      width: window.innerWidth,
      height: window.innerWidth,
    }

    const camera = new THREE.PerspectiveCamera(75, 500/500, 0.1, 100)
    // const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1, 100)

   
    //set camera position
    camera.position.set(0,5,10)
    camera.rotation.set(-0.4,0,0)
    scene.add(camera)
    
    
    const renderer = new THREE.WebGL1Renderer({
      canvas: this.canvas
    })
    // renderer.setSize(700, 700)
    // renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    // renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    renderer.shadowMap.enabled=true
    
    const controls = new OrbitControls(camera,this.canvas)
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.minDistance = 1.2;
    controls.maxDistance = 20;
    controls.update();

    let component: Model3dViewComponent = this;
    (function render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());
  }

  ngOnInit():void{
    this.viewData= this.data.values
    this.modelToRender=this.viewData.url

  }

  ngAfterViewInit(): void {
    this.create()
  }

  cancelView(){
    this.dialogRef.close()
  }
}
