import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';

@Component({
  selector: 'app-cancel-changes-dialog',
  templateUrl: './cancel-changes-dialog.component.html',
  styleUrls: ['./cancel-changes-dialog.component.css']
})
export class CancelChangesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CancelChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private translate: TranslateService,
    private commentService:CommentsService
    ){

      dialogRef.disableClose = true;
  }

  declineCancel(){
    this.dialogRef.close(false);
  }

  acceptCancel(){
    const message = this.translate.instant("SnackBarConstants.UPDATE_DISMISS")
    this.commentService.addSnackBar.emit(message)
    this.dialogRef.close(true);
  }
}
