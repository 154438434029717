
<section class="px-3">

    <!-- registered -->    
    <div *ngIf="localStorageService.get('registeredView_' + idToShow + '_' + widgetId)" class="d-flex flex-column" style="padding-top: 5px;">
        <div *ngIf="registerProductData.registered.showImage">
<!--             src="./../../assets/img/default.jpg" -->
            <img 
            src="{{imageUrl}}"
            [ngStyle]="{
                'border-left': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-right': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-top': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-top-left-radius': 'var(--cornerRadius)', 
                'border-top-right-radius': 'var(--cornerRadius)'
            }" 
            class="w-100" 
            onerror="this.src='./../../assets/img/default.jpg'">
            <div class="p-3" 
            [ngStyle]="{'background-color': registerProductData.style.backgroundColor,
            'border-left': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
            'border-right': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
            'border-bottom': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor}"
            style="border-bottom-left-radius: var(--cornerRadius); border-bottom-right-radius: var(--cornerRadius);">
                <!-- text -->
                <div class="d-flex flex-column" [ngStyle]="{'color': registerProductData.style.foregroundColor}">
                    <p class="textPrimary" id="textPrimary" style=" word-break: break-word;" >
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && !registerProductData.registered.textAlign" [innerHTML]="registeredMarkdownText"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='center'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: center;"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='right'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: right;"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='left'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: left;"></span>
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!registerProductData.registered.showImage">
            <div class="p-3" 
            [ngStyle]="{'background-color': registerProductData.style.backgroundColor,
            'border': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor}"
            style="border-radius: var(--cornerRadius);">
                <!-- text -->
                <div class="d-flex flex-column" [ngStyle]="{'color': registerProductData.style.foregroundColor}">
                    <p class="textPrimary" id="textPrimary" style=" word-break: break-word;" >
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && !registerProductData.registered.textAlign" [innerHTML]="registeredMarkdownText"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='center'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: center;"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='right'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: right;"></span>
                        <span id="{{registerProductData.id}}" *ngIf="registeredMarkdownText && registerProductData.registered.textAlign && registerProductData.registered.textAlign=='left'" [innerHTML]="registeredMarkdownText" style=" word-break: break-word; text-align: left;"></span>
                    </p>
                </div>
            </div>
        </div>

    </div>
    <!-- unregistered -->
    <div *ngIf="!localStorageService.get('registeredView_' + idToShow + '_' + widgetId)">
        <div *ngIf="registerProductData.unregistered.showImage">
            <img 
            src="{{imageUrl}}"
            [ngStyle]="{
                'border-left': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-right': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-top': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
                'border-top-left-radius': 'var(--cornerRadius)', 
                'border-top-right-radius': 'var(--cornerRadius)'
            }" 
            class="w-100" 
            onerror="this.src='./../../assets/img/default.jpg'">

            <div class="p-3" 
            [ngStyle]="{'background-color': registerProductData.style.backgroundColor,
            'border-left': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
            'border-right': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor,
            'border-bottom': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor}"
            style="border-bottom-left-radius: var(--cornerRadius); border-bottom-right-radius: var(--cornerRadius);">
                <!-- text -->
                <div class="d-flex flex-column" [ngStyle]="{'color': registerProductData.style.foregroundColor}">
                    <div *ngIf="registerProductData.unregistered.text" id="{{registerProductData.id}}" class="textPrimaryPre" style=" word-break: break-word; text-align: center;">{{registerProductData.unregistered.text}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="!registerProductData.unregistered.showImage">
            <div class="p-3" 
            [ngStyle]="{'background-color': registerProductData.style.backgroundColor,
            'border': registerProductData.style.borderWidth + 'px solid ' + registerProductData.style.borderColor}"
            style="border-radius: var(--cornerRadius);">
                <!-- text -->
                <div class="d-flex flex-column" [ngStyle]="{'color': registerProductData.style.foregroundColor}">
                    <div *ngIf="registerProductData.unregistered.text" id="{{registerProductData.id}}" class="textPrimaryPre" style=" word-break: break-word; text-align: center;">{{registerProductData.unregistered.text}}</div>
                </div>
            </div>
        </div>
    </div>

</section>