import { Component, Input } from '@angular/core';
import { deepCopy, supportUrlStructure } from '../../../../backend/utils/object';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../service/settings.service';


@Component({
  selector: '[app-map-advanced-markers-horizontal]',
  templateUrl: './map-advanced-markers-horizontal.component.html',
  styleUrls: ['./map-advanced-markers-horizontal.component.css']
})
export class MapAdvancedMarkersHorizontalComponent {
  @Input('app-map-advanced-markers-horizontal') data:any;
  mapData:any
  annotationData:any
  editMode:any
  firstMap:any
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 3;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  pairsPath:any ={}
  mapTypeId: google.maps.MapTypeId

  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }

  index:any
  idToShow:any
  linkURL:any = {}
  routerUrl:any
  map:google.maps.Map
  directionTranslation = this.translate.instant('pass.map.open_direction')

  constructor(
    private router: ActivatedRoute,
    private translate:TranslateService,
    private settingsService: SettingsService
  ) { 
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit(): void {
    this.mapData=this.data[0]
    this.annotationData=this.data[1]
    this.index = this.data[2]
    if(this.data[3])
    this.idToShow = this.data[3]

    if(this.mapData.annotationGroup)
      this.annotationData=this.mapData.annotationGroup

    this.mapTypeId= google.maps.MapTypeId.ROADMAP

    if(this.mapData.style=='satellite')
      this.mapTypeId= google.maps.MapTypeId.SATELLITE
    
    this.loadMarkers()

    setTimeout(() => {
      this.createMap(this.mapData.id, this.annotationData.id)        
    }, 500);
  }
  
  ngOnChanges():void{
    this.annotationData=this.data[1]
  }

  updateMarkers(){
   this.markerPositions=this.annotationData 
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    infoWindow.open(marker);
  }

  loadMarkers(){
    if(this.annotationData && !this.annotationData.dynamic){
      const element = this.annotationData
      const index = this.index
      if(element.coordinate){
        const lats = element
        lats['lat'] = parseFloat(element.coordinate[0])
        lats['lng'] = parseFloat(element.coordinate[1])
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        if(element.name){
          lats['title']=element.name
        }
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        this.markerPositions.push(lats);
        this.center=lats
      }else{
        const lats = element
        lats['lat'] = parseFloat(element.lat)
        lats['lng'] = parseFloat(element.lng)
        if(element.name){
          lats['title']=element.name
        }
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        this.markerPositions.push(lats);
        this.center=lats
      }

      if(element.linkURL)
          this.supportLinkUrl(element.linkURL, element.id)

      if(element.encodedContent){
        this.getPolygon(element, index)
      }
    }
  }


  getPolygon(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
      pairs = encodedContent.reduce(function(result, value, index, array) {
      if (index % 2 === 0){
        const splited = array.slice(index, index + 2)
        var lat: number = +splited[0];
        var lng: number = +splited[1];
        const value = { lat: lat, lng: lng }
        result.push(value);
      }
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptions[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptions[index].path = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.fillColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.strokeWeight
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].path && this.actualPolygonOptions[index].path[0]){
          this.center=this.actualPolygonOptions[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptions[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptions[index].paths = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].fillColor = markerPosition.fillColor
        }

        if(markerPosition.strokeColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].paths && this.actualPolygonOptions[index].paths[0]){
          this.center=this.actualPolygonOptions[index].paths[0]
          this.zoom = 9
        }
      }
    }
    return pairs
  }

  createMap(i, annotationId){
    try{
      var mapProp= {
        center:new google.maps.LatLng(51.508742,-0.120850),
        zoom:5,
      };
      const id = "map-"+i+'-annotation-'+annotationId
      this.map = new google.maps.Map(document.getElementById(id),mapProp);
      this.initMap(i,annotationId)
    }catch(e){
      console.log(e)
    }
  }

  async initMap(i,annotationId) {
    const id = "map-"+i+'-annotation-'+annotationId
    // // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let annotations
    let title = "Map-" + i +'-annotation-'+annotationId
    let mapWidgetId = this.mapData.id
    if(this.mapData.title){
      title =this.mapData.title
    }

    let mapTypeId="roadmap" 
    if(this.mapData && this.mapData.style && this.mapData.style=='satellite'){
        mapTypeId = this.mapData.style   
    } 
    this.map = new Map(document.getElementById(id), {
      zoom: 4,
      center: position,
      mapId: "dev",
      mapTypeId: mapTypeId,
      disableDefaultUI: true,
    });

    if(this.markerPositions && this.markerPositions[0]){
        const annotation = deepCopy(this.markerPositions[0])
        const annotationId = annotation.id
        const index = this.index

          if(annotation.type){
            if(annotation.type=="point"){
              let pos = annotation
              if(annotation.coordinate){
                pos['lat']=annotation.coordinate[0]
                pos['lng']=annotation.coordinate[1]
              }

              if(!annotation.badge)
                pos['badge']=(index +1).toString()
              
              if(pos.name)
                pos['title']=pos.name

              let linkURL = supportUrlStructure(pos.linkURL, this.idToShow,this.settingsService.contextId$ )
              let contentString = "this is an example"
              contentString ='<div class="d-flex flex-column">'
                if(pos.title)
                    contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
                if(pos.subtitle)
                    contentString +='<span>'+pos.subtitle+' </span>'
                if(pos.linkURL){
                    contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                        contentString +='<span class="material-symbols-outlined">link</span>'
                    contentString +='</a>'
                }
                if(!pos.title && !pos.linkURL && !pos.subtitle)
                    contentString +='<span>...</span>'

                    if(pos['directions']){
                      const directions = this.openMapDirection(annotation)
                      contentString += '<a href='+directions+' target="_blank" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+this.directionTranslation+'</button></a>'
                    }
                contentString +=' </div> '      
              
              let beachFlagImg = document.createElement('div');
              let pinColor = '#FF5E38'
              let textColor = 'black'

              if(annotation.pinBackgroundColor)
                pinColor = annotation.pinBackgroundColor
              else if(annotation.pinColor)
                pinColor = annotation.pinColor

              if(annotation.pinForegroundColor )
                  textColor = annotation.pinForegroundColor

              const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                  <title>${annotation.badge ?? index}</title>
                  <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                      <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                      <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                    </g>
              </svg>`

              beachFlagImg.innerHTML = svg

              let pinData = {
                glyph: pos.badge,
              }
              
                pinData['background']=pinColor
                pinData['borderColor'] = annotation.pinForegroundColor
                pinData['glyphColor']= textColor
              
              const pin = new PinElement(pinData);
              const infowindow = new google.maps.InfoWindow({
                content: contentString,
                ariaLabel: index+'',
              });

              const aaa = new google.maps.marker.AdvancedMarkerElement({
                map:this.map,
                content: beachFlagImg,
                position: pos,
                title: 'annotation_'+index,
              });

              aaa.addListener("click", () => {
                infowindow.open({
                  anchor: aaa,
                  map:this.map,
                });
              });
            
              // const marker = new AdvancedMarkerElement({
              //   map: this.map,
              //   position: pos,
              //   title:"annotation-"+index,
              //   content:pin.element
              // });

              // marker.addListener("click", () => {
              //   infowindow.open({
              //     anchor: marker,
              //     map:this.map,
              //   });
              // });
              this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
            }

            if(annotation.type=="polygon"){
              if(annotation.encodedContent){
                const encodedContent = annotation['encodedContent'].split(",")
                let pairs
                pairs = encodedContent.reduce(function(result, value, indexV, array) {
                if (indexV % 2 === 0){
                  const splited = array.slice(indexV, indexV + 2)
                  var lat: number = +splited[0];
                  var lng: number = +splited[1];
                  const value = { lat: lat, lng: lng }
                  result.push(value);
                }
                return result;
              }, []);

                this.pairsPath[annotationId] = pairs
                this.actualPolygonOptions[mapWidgetId]={}
                this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
                this.actualPolygonOptions[mapWidgetId][index].paths = pairs

                if(annotation.fillColor)
                  this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

                if(annotation.strokeColor)
                  this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

                if(annotation.lineWidth)
                  this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

                if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                  this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                  this.zoom = 9
                  const newcenter = new google.maps.LatLng(this.center)
                  this.map.setCenter(newcenter);
                  this.map.setZoom(this.zoom)
                }

                const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
                polygon.setMap(this.map);
              }
            }

            if(annotation.type=="line"){
              if(annotation.encodedContent){
                const encodedContent = annotation['encodedContent'].split(",")
                let pairs
                pairs = encodedContent.reduce(function(result, value, indexV, array) {
                if (indexV % 2 === 0){
                  const splited = array.slice(indexV, indexV + 2)
                  var lat: number = +splited[0];
                  var lng: number = +splited[1];
                  const value = { lat: lat, lng: lng }
                  result.push(value);
                }
                return result;
              }, []);

                this.pairsPath[annotationId] = pairs
                this.actualPolygonOptions[mapWidgetId]={}
                this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
                this.actualPolygonOptions[mapWidgetId][index].path = pairs

                if(annotation.strokeColor)
                  this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

                if(annotation.lineWidth)
                  this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

                if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                  this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                  this.zoom = 11
                  const newcenter = new google.maps.LatLng(this.center)
                  this.map.setCenter(newcenter);
                  this.map.setZoom(this.zoom)
                }

                const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
                line.setMap(this.map);
              }
            }
          }else{
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name
            
              let linkURL = supportUrlStructure(pos.linkURL, this.idToShow,this.settingsService.contextId$ )
              
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
              if(pos.title)
                contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
              if(pos.subtitle)
                contentString +='<span>'+pos.subtitle+' </span>'
              if(pos.linkURL){
                contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                    contentString +='<span class="material-symbols-outlined">link</span>'
                contentString +='</a>'
              }
              if(!pos.title && !pos.linkURL && !pos.subtitle)
                contentString +='<span>...</span>'
              
              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href='+directions+' target="_blank" style="flex-grow: 1;padding-left: 10px; "><button mat-button class="secondary-gray-button w-100"><mat-icon>open_in_new</mat-icon>'+directionTranslation+'</button></a>'
              }
            contentString +=' </div> '      
            
            const pin = new PinElement({
              glyph: pos.badge,
            });

            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });

            const marker = new AdvancedMarkerElement({
              map: this.map,
              position: pos,
              title:"annotation-"+index,
              content:pin.element
            });

            marker.addListener("click", () => {
              infowindow.open({
                  anchor: marker,
                  map:this.map,
              });
            });
            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }
        
    }
  }

  showArrays(event: any) {
    // Since this polygon has only one path, we can call getPath() to return the
    // MVCArray of LatLngs.
    // @ts-ignore
    // const polygon = this as google.maps.Polygon;
    // const vertices = polygon.getPath();
  
    // let contentString =
    //   "<b>Bermuda Triangle polygon</b><br>" +
    //   "Clicked location: <br>" +
    //   event.latLng.lat() +
    //   "," +
    //   event.latLng.lng() +
    //   "<br>";
  
    // // Iterate over the vertices.
    // for (let i = 0; i < vertices.getLength(); i++) {
    //   const xy = vertices.getAt(i);
  
    //   contentString +=
    //     "<br>" + "Coordinate " + i + ":<br>" + xy.lat() + "," + xy.lng();
    // }

    // this.infoWindow = new google.maps.InfoWindow();
    // // Replace the info window's content and position.
    // this.infoWindow.setContent(contentString);
    // this.infoWindow.setPosition(event.latLng);
    // this.infoWindow.open(this.googlemaps);
  }


  supportLinkUrl(linkurl, id){
    this.linkURL[id] = linkurl
    if(linkurl){
      try{
      const imgUrl = new URL(linkurl)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.linkURL[id]=firebaseUrl
        }else{
          const newparts = linkurl.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.linkURL[id]=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.linkURL[id]=firebaseUrl
      }
      }catch(erro){
      }
    }
  }

  openMapDirection(markerPosition){
    let linkdirection = 'https://www.google.com/maps/dir//'
    let linkdirectionMap = 'https://www.google.com/maps/dir//'
    let pinInMaps = 'https://maps.google.com/?q='//<lat>,<lng>
    if(markerPosition.lat && markerPosition.lng){
      linkdirection += markerPosition.lat +',' + markerPosition.lng
      linkdirectionMap += markerPosition.lat +',' + markerPosition.lng + '/@' + markerPosition.lat +',' + markerPosition.lng
      pinInMaps += markerPosition.lat +',' + markerPosition.lng
    }
    // return pinInMaps
    return linkdirectionMap
  }
}