
<h2 mat-dialog-title>{{'contentTypes.new_pass_template' | translate}}</h2>

<mat-dialog-content style="width:350px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-1 rounded m-auto w-100" id="section">

            <form [formGroup]="newContentForm">
                <div class="d-flex flex-column">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{'contentTypes.name' | translate}}</mat-label>
                        <input type="text" matInput formControlName="displayName" placeholder="{{'contentTypes.name' | translate}}">
                    </mat-form-field>

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{'defaultLanguage' | translate}}</mat-label>
                        <mat-select formControlName="language">
                            <mat-option *ngFor="let lang of languagesList | keyvalue" [value]="lang.key">{{lang.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelNewContentType();">
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="saveNewContentType();" >
        {{'save' | translate}}
    </button> 
</mat-dialog-actions>
