import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  constructor(private imageCompress: NgxImageCompressService) { }

   async compressImage(file: File, maxSizeInBytes: number): Promise<File | null> {
    try {
      const base64String = await this.fileToBase64(file);
      //console.log(`Original size: ${file.size} bytes`);
  
      const compressedBase64 = await this.imageCompress.compressFile(base64String, -1, 30, 30); // Adjusting quality
      const compressedFile = this.dataURLtoFile(compressedBase64, file.name);
  
      //console.log(`Compressed size: ${compressedFile.size} bytes`);
  
      if (compressedFile.size > maxSizeInBytes) {
        return null; // Compression size not enough
      }
  
      return compressedFile;
    } catch (error) {
      console.error("Compression failed", error);
      return null;
    }
  }

  private fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  private dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}


