import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
    private settingsService: SettingsService,
    private authService: AuthService){
    
  }

  canActivate(
    route: import("@angular/router").ActivatedRouteSnapshot,
    state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    return this.canActivateChild(route, state)
  }

  canActivateChild(route: import("@angular/router").ActivatedRouteSnapshot,
    state: import("@angular/router").RouterStateSnapshot): Observable<boolean> {

    return new Observable<boolean>((observer) => {
      const auth = getAuth(); // Get the Firebase auth instance
      
      onAuthStateChanged(auth, (user) => {
        // Perform user authentication check
        if (auth.currentUser && auth.currentUser.uid ) {
          observer.next(false);
          setTimeout( () => {
            this.router.navigate(['home'])
          }, 100)

        } else {
          // User is not authenticated or email is not verified, redirect to login page
          console.log('User is not authenticated or email is not verified, redirect to login page');
          // this.authService.signOut()
          observer.next(true);

        }
        observer.complete();
      });
    });
  }
  
}
