export interface IField{
    id:String
    key: String
    title: String
    type: String //[text|longText|number|decimal|currency|date|time|dateTime|boolean|weight]
}

export interface IDisplaySection {
    title?: String
    fields: IField[]
    id:String
}

export interface IContextType{
    contextId?:string
    id?:string
    passTemplateId:string
    passId?:string
    displayName?:string
    name?:string
    timeStamp?:Date
    titleKey?:string
    subtitleKeys?:string[]
    creationDate?:Date
    schema?:object
    advancedExperienceId?:string
    nfcURLPrefix?:string
    displaySections?:IDisplaySection[]
}

export class ContentType{
    contextId: string|undefined = undefined
    id: string|undefined = undefined
    passTemplateId: string|undefined = undefined
    passId: string|undefined = undefined
    displayName: string|undefined = undefined
    name: string|undefined = undefined
    timeStamp:Date|undefined = undefined
    titleKey: string|undefined = undefined
    subtitleKeys: string[]|undefined = undefined
    creationDate: Date|undefined = undefined
    schema: object|undefined = undefined
    advancedExperienceId:string | undefined = undefined
    nfcURLPrefix:string | undefined = undefined
    displaySections?:IDisplaySection | undefined = undefined

    constructor(){}

    setFromAny(element:any){
        const {id,contextId, passTemplateId,passId, displayName, name, timeStamp, titleKey,
            subtitleKeys,schema,creationDate,advancedExperienceId,nfcURLPrefix,displaySections} = element

        this.id = id ? id : this.id
        this.contextId = contextId ? contextId : this.contextId
        this.passTemplateId = passTemplateId ? passTemplateId : this.passTemplateId
        this.passId = passId ? passId : this.passId
        this.displayName = displayName ? displayName : this.displayName
        this.name = name ? name : this.name
        this.timeStamp = timeStamp ? timeStamp : this.timeStamp
        this.titleKey = titleKey ? titleKey : this.titleKey
        this.subtitleKeys = subtitleKeys ? subtitleKeys : this.subtitleKeys
        this.creationDate = creationDate ? creationDate : this.creationDate
        this.schema = schema ? schema : this.schema
        this.advancedExperienceId = advancedExperienceId ? advancedExperienceId : this.advancedExperienceId
        this.nfcURLPrefix = nfcURLPrefix ? nfcURLPrefix : this.nfcURLPrefix
        this.displaySections = displaySections ? displaySections : this.displaySections
    }

    getId(){
        if(!this.id) return undefined

        return this.id
    }

    getPassTemplateId(){
        if(!this.passTemplateId) return undefined

        return this.passTemplateId
    }

    get(){
        return this
    }
}