<h2 mat-dialog-title>{{'reports.duplicate_report_template' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-1 rounded m-auto w-100" id="section" >
             <form [formGroup]="duplicateContentForm">
                <div class="d-flex flex-column">
                   
                    <small>{{'reportDuplicationInfo_p1' | translate}} "<strong>{{reportToDuplicateTitle}}</strong>" {{'reportDuplicationInfo_p2' | translate}}</small>

                    <br>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{'reports.displayName' | translate}}</mat-label>
                        <input type="text" matInput formControlName="displayName" placeholder="{{'reports.newTitle' | translate}}">
                    </mat-form-field>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelDuplication();">
        {{'cancel' | translate}}
    </button>
     <button mat-button class="orange-button" (click)="duplicateReportTemplate()">
        {{'duplicate' | translate}}
    </button>
</mat-dialog-actions>