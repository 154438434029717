import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy } from '../../../../../../backend/utils/object';

@Component({
  selector: 'app-composed-edit-content-dialog',
  templateUrl: './composed-edit-content-dialog.component.html',
  styleUrls: ['./composed-edit-content-dialog.component.css']
})
export class ComposedEditContentDialogComponent {
  contentData:any
  contentDialogForm!:FormGroup
  oldData:any
  contentTitle:string
  contentDataType:any
  nodeAttributes:any = {}
  fontOptions = [ 
    {
      value:'plain',
      translation:'pass.plain'
    },
    {
      value:'bold',
      translation:'pass.bold'
    }, 
    
    {
      value:'italic',
      translation:'pass.italic'
    }, 
    {
      value:'title',
      translation:'pass.title'
    },
  
    {
      value:'subtitle',
      translation:'pass.subtitle'
    }
  ]
  colorOptions = [ 
    {
      value:'background',
      translation:'pass.background'
    }, 
    {
      value:'primary',
      translation:'pass.primary'
    }, 
    {
      value:'secondary',
      translation:'pass.secondary'
    }, 
    {
      value:'accent',
      translation:'pass.accent'
    }, 
    {
      value:'title',
      translation:'pass.title'
    },
    {
      value:'subtitle',
      translation:'pass.subtitle'
    }
  ]
  paddingOptions = [
    {
      value:'top', 
      translation:'pass.composed.top'
    }, 
    {
      value:'leading',
      translation:'pass.composed.leading'
    }, 
    {
      value:'trailing',
      translation:'pass.composed.trailing'
    },
  
    {
      value:'bottom',
      translation:'pass.composed.bottom'
    }
  ]
  horizontalAlignmentOptions = [
    {
      value:'center',
      translation:'pass.composed.center'
    }, 
    {
      value:'leading',
      translation:'pass.composed.leading'
    }, 
  
    {
      value:'trailing',
      translation:'pass.composed.trailing'
    }
  ]
  verticalAlignmentOptions = [
    {
      value:'top',
      translation:'pass.composed.top'
    }, 
    {
      value:'center',
      translation:'pass.composed.center'
    }, 
    {
      value:'bottom',
      translation:'pass.composed.bottom'
    }
  ]
  alignmentOptions = [ 
    {
      value:'topLeading',
      translation:'pass.composed.topLeading'
    }, 
    {
      value:'topCenter',
      translation:'pass.composed.topCenter'
    }, 
    {
      value:'topTrailing',
      translation:'pass.composed.topTrailing'
    } 
  ]

  constructor(public dialogRef: MatDialogRef<ComposedEditContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private fb: FormBuilder){
      dialogRef.disableClose=true
  }

  ngOnInit(): void {
    console.log(this.data)
    this.contentData = deepCopy(this.data?.values)
    this.nodeAttributes = this.contentData?.attributes ?? {}

    this.contentDataType = this.contentData.type
    console.log('--', this.contentDataType)
    this.oldData = deepCopy(this.data?.values)
    this.contentTitle = this.data?.title

    const { text, url, font, fontSize, spacing, padding, horizontalAlignment, verticalAlignment, alignment, width, height, aspectRatio,
      cornerRadius, radius, foregroundColor, backgroundColor, fillColor, strokeColor, color} = this.nodeAttributes

    this.contentDialogForm = this.fb.group({
      text:[{value: text ? text:undefined, disabled:false}],//*text
      url:[{value: url ? url: undefined, disabled:false}],//*image
      font:[{value: font ? font: undefined, disabled:false}], /// *******plain, bold, italic, title, subtitle
      fontSize: [{value: fontSize ? fontSize: undefined, disabled:false}], // in pt
      spacing: [{value: spacing ? spacing: undefined, disabled:false}], // in pt/dp
      padding: [{value: padding ? padding: undefined, disabled:false}], // {top, leading, trailing, bottom : float}
      horizontalAlignment: [{value: horizontalAlignment ?  horizontalAlignment:undefined, disabled:false}], // leading, center, trailing
      verticalAlignment: [{value: verticalAlignment ? verticalAlignment: undefined, disabled:false}], // top, center, bottom
      alignment: [{value: alignment ? alignment: undefined, disabled:false}], // topLeading, topCenter, topTrailing
      width: [{value: width ? width: undefined, disabled:false}],
      height: [{value: height ? height: undefined, disabled:false}],
      aspectRatio: [{value: aspectRatio ? aspectRatio : undefined, disabled:false}],
      cornerRadius: [{value: cornerRadius ? cornerRadius : undefined, disabled:false}],
      radius: [{value: radius ? radius :undefined, disabled:false}],
      foregroundColor: [{value: foregroundColor ? foregroundColor: undefined, disabled:false}],
      backgroundColor: [{value: backgroundColor ? backgroundColor :undefined, disabled:false}],
      fillColor: [{value: fillColor ? fillColor :undefined, disabled:false}],
      strokeColor: [{value: strokeColor ? strokeColor: undefined, disabled:false}],
      color: [{value: color? color: undefined, disabled:false}],
    })
  }

  getFormatedDataAttributes(type, data){
    switch(type){
      case 'text':{
        return  { 
                  text: data.text ? data.text : undefined,
                  font: data.font ? data.font : undefined, 
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }
      }

      case 'image':{
        return  { 
                  url: data.url ? data.url : undefined,
                  width: data.width ? data.width : undefined,
                  height: data.height ? data.height : undefined
                }      
      }
      case 'hstack':{
        return  { 
                  verticalAlignment: data.verticalAlignment ? data.verticalAlignment : undefined,
                  spacing: data.spacing ? data.spacing : undefined, 
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }      
      }

      case 'vstack':{
        return  { 
                  horizontalAlignment: data.horizontalAlignment ? data.horizontalAlignment : undefined,
                  spacing: data.spacing ? data.spacing : undefined, 
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }     
      }
      case 'zstack':{
        return  { 
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }      
      }

      case 'circle':{
        return  {
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }      
      }

      case 'rectangle':{
        return  { 
                  width: data.width ? data.width : undefined, 
                  height: data.height ? data.height : undefined 
                }      
      }
      default: 
        return {}
    }
  }

  onSave(){
    this.dialogRef.close([true, this.getFormatedDataAttributes(this.contentData.type, this.contentDialogForm.value)])
  }

}
