import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { ImageBlocks } from '../../../../../../backend/src/imageBlocks.dto';
import { removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';

@Component({
  selector: 'app-image-blocks-edit',
  templateUrl: './image-blocks-edit.component.html',
  styleUrls: ['./image-blocks-edit.component.css']
})
export class ImageBlocksEditComponent implements OnInit {
  oldData:any
  imageBlocksEditData:any
  imageBlocksEditForm!:FormGroup
  urls:any
  allImages:any
  layoutSelected:any
  layoutImages:any
  layoutBad=false
  layoutTypes:any = [
    {
      value:'twoHorizontal',
      name:'2 Images, Horizontal',
      images:2
    },
    {
      value:'twoVertical',
      name:'2 Images, Vertical',
      images:2
    },
    {
      value:'threeLayout1',
      name:'3 Images, Layout 1',
      images:3
    },
    { 
      value:'threeLayout2',
      name:'3 Images, Layout 2',
      images:3
    },
    { 
      value:'fourLayout1',
      name:'4 Images, Layout 1',
      images:4
    },
    { 
      value:'fourLayout2',
      name:'4 Images, Layout 2',
      images:4
    }
  ]
  newImage:any=[]
  widgetNumber:any
  passId:any
  imagesData:any=[]
  allImagesAfterDelete:any=[]
  imgFromImgNew:any=[]


  constructor(
    public dialogRef: MatDialogRef<ImageBlocksEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private uploadService: UploadService,
    private commentService: CommentsService,
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.oldData=this.data.values
    this.imageBlocksEditData=this.data.values
    this.allImages=this.imageBlocksEditData.images
    this.imagesData=this.imageBlocksEditData.images
    this.layoutSelected=this.imageBlocksEditData.layout
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']
    this.layoutTypes.forEach((lay:any)=>{
      if(lay.value==this.layoutSelected){
        this.layoutImages=lay.images
      }
    })

    this.imageBlocksEditForm = this.fb.group({
      id:[],
      layout:[],
      type:[],
      title:[],
      subtitle:[],
      images:[]
    })
    const {id, layout, type, title, subtitle, images} = this.imageBlocksEditData
    this.imageBlocksEditForm.patchValue({
      id: id ? id : undefined,
      layout: layout ? layout : undefined,
      type: type ? type :undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle :undefined,
      images: images ? images : undefined
    })
        

    this.imageBlocksEditForm.valueChanges.subscribe( (value:any)=>{
      this.layoutSelected = value.layout
      this.layoutTypes.forEach((lay:any)=>{
        if(lay.value==this.layoutSelected){
          this.layoutImages=lay.images
        }
      })

      if(this.layoutImages>this.imageBlocksEditForm.value.images){
        this.layoutBad=true
      }else{
        this.layoutBad=false
      }
    })
  }


  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      this.newImage.push(event.target.files[0])
      const image = event.target.files[0]
      const index = this.newImage.length -1
      reader.readAsDataURL(event.target.files[0]); 

      reader.onload = async (event) => {
        if(event.target){
          const url = event.target.result;
          if(url){
            var newimg=
            {
              "url": url
            }
            const a=this.imageBlocksEditForm.value.images

            this.imageBlocksEditForm.patchValue({
              images: [...a, newimg]
            })
            this.imgFromImgNew.push({form: a.length, new : this.newImage.length -1})

          }
          
        }
 
      }
    }

  }


  editImg(x:any){

  }
  
  deleteImg(x:any,index:any){
  
    this.allImagesAfterDelete = []

    var a:any=[]
      this.imageBlocksEditForm.value.images.forEach((element:any, index:any) => {
        if(element.url!=x){
          a.push(element)
        }
      });


      this.allImages.forEach( (img, ind) =>{
        if(img['url']!=x){
          this.allImagesAfterDelete.push(img)
        }
      })

      this.imageBlocksEditForm.patchValue({
        images: a
      })
      
      this.allImages=a
      
      this.imgFromImgNew.forEach( (img, inde)=>{
        if(img['form']==index){
          this.newImage.splice(img['new'], 1)
        }
      })

  }


  async saveEdit(){
    try{
      if(!this.layoutBad){
        if(this.newImage.length!=0)
          await this.uploadService.uploadImageBlocks(this.passId, this.newImage, this.widgetNumber)
  
        this.commentService.progressSpin.emit(true)
        setTimeout(() =>{
          if(this.newImage.length!=0){
            const newUrl = this.uploadService.getImageBlocks()
  
            if(this.allImagesAfterDelete.length==0){
              newUrl.forEach(u =>{
                this.allImages.push(u)
              })
              this.imageBlocksEditForm.patchValue({
                images: this.allImages
              })
    
            }else{
              newUrl.forEach(u =>{
                this.allImagesAfterDelete.push(u)
              })
              this.imageBlocksEditForm.patchValue({
                images: this.allImagesAfterDelete
              })
            }
          }
  
          this.commentService.progressSpin.emit(false)
          this.dialogRef.close([this.imageBlocksEditForm.value, true, this.newImage]);
  
        }, 2500);
      }
    }catch(error){

    }
    
  }
}
