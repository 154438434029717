
<h2 mat-dialog-title>{{'cancel' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <p>{{'cancel_changes' | translate}}</p>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="declineCancel()" >{{ "No" | translate}}</button>
    <button mat-button class="orange-button" (click)="acceptCancel();">{{ "Yes" | translate}}</button>
</mat-dialog-actions>
