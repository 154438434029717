<h2 mat-dialog-title>{{fieldForm.value.title}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="fieldForm">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'taskTemplates.wt_title' | translate}}</mat-label>
                    <input matInput name="title" formControlName="title" class="form-control" placeholder="{{'taskTemplates.wt_title' | translate}}"  type="text" >
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'taskTemplates.key' | translate}}</mat-label>
                    <input matInput name="key" formControlName="key" class="form-control" placeholder="{{'taskTemplates.key' | translate}}" id="key" type="text" required>
                </mat-form-field>
        
                <div class="form-control p-3 mb-3  d-flex justify-content-between">
                    <label for="title" >{{'taskTemplates.mandatory' | translate}}</label>
                    <mat-slide-toggle formControlName="mandatory" ></mat-slide-toggle>
                </div>        

        
                <div *ngIf="fieldForm.value.type=='options'">
                    <!-- <mat-tab-group >
                        <mat-tab style="color:var(--warmOrange);" label="{{'taskTemplates.static' | translate}}" defaultColor="blue"> -->

                            <div>
                                <div class="d-flex flex-row justify-content-between align-items-center mt-1">
                                    <span class="text-muted">{{'taskTemplates.OPTIONS' | translate}}</span>
                                    <button mat-icon-button (click)="addNewOptionToField();">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                            add_circle
                                        </span>
                                    </button>
                                </div>
        
                                <mat-list role="list" class="list-group">
                                    <mat-list-item *ngFor="let option of fieldForm.value.options; let index=index;" role="listitem" style="color:var(--warmOrange); cursor:pointer;" class="list-group-item" (click)="editOptionToField(option, index);" > 
                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                            <span style="color:var(--warmOrange);"> {{option}}</span>
                                            <button mat-icon-button (click)="deleteOption(option, index);"  >
                                                <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                                    delete
                                                </span>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </div>

                        <!-- </mat-tab>
                      </mat-tab-group> -->
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onSave();">{{'apply' | translate}}</button>
</mat-dialog-actions>
