
<h2 mat-dialog-title>{{'taskTemplates.new' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-1 rounded m-auto w-100" id="section" >

            <form [formGroup]="newTaskTemplateForm">
                <div class="d-flex flex-column">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{'contentTypes.displayName' | translate}}</mat-label>
                        <input type="text" matInput formControlName="title" placeholder="{{'contentTypes.displayName' | translate}}" required>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline"  class="w-100 p-0">
                        <mat-label>{{'taskTemplates.wt_group' | translate}}</mat-label>
                        <mat-select formControlName="groupId">
                            <mat-option  > - </mat-option>
                            <mat-option *ngFor="let group of taskTemplateGroups" value="{{group.id}}">{{group.title}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <br>
                    <small>(*) {{'required' | translate}}</small>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelNewTask();">
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="saveNewTask();" >
        {{'save' | translate}}
    </button> 
</mat-dialog-actions>

