import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterTridy'
})
export class FilterTridyPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
        if(it.contentName){
            if(it.id){
                if(it.contentType){
                    if(it.payload && it.payload.name){
                        return it.contentName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.contentName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText);
                    }
                }else{

                    if(it.payload && it.payload.name){
                        return it.contentName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.contentName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) ;
                    }
                    
                }
            }else{
                if(it.contentType){
                    if(it.payload && it.payload.name){
                        return it.contentName.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.contentName.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText);
                    }
                }else{
                    if(it.payload && it.payload.name){
                        return it.contentName.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.contentName.toLowerCase().includes(searchText);
                    }
                }
            }
        }else{
            if(it.id){
                if(it.contentType){
                    if(it.payload && it.payload.name){
                        return it.id.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.id.toLowerCase().includes(searchText) || it.contentType.toLowerCase().includes(searchText);
                    }
                }else{
                    if(it.payload && it.payload.name){
                        return it.id.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return it.id.toLowerCase().includes(searchText);
                    }
                }
            }else{
                if(it.contentType){
                    if(it.payload && it.payload.name){
                        return it.contentType.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                    }else{
                        return  it.contentType.toLowerCase().includes(searchText);
                    }
                }else{
                    if(it.payload && it.payload.name){
                        return it.payload.name.toLowerCase().includes(searchText);
                    }
                }
            }
        }
    });
   }
}