<h2 mat-dialog-title>{{contentTitle | translate}}</h2>

<mat-dialog-content style="max-width: 600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="contentDialogForm">

                <mat-form-field *ngIf="contentDataType=='text'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.text' | translate}}</mat-label>
                    <input formControlName="text" type="text" matInput placeholder="{{'pass.text' | translate}}">
                </mat-form-field>

                <mat-form-field *ngIf="contentDataType=='image'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.url' | translate}}</mat-label>
                    <input formControlName="url" type="text" matInput placeholder="{{'pass.url' | translate}}">
                </mat-form-field>

                <mat-form-field *ngIf="contentDataType=='text'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.font' | translate}}</mat-label>
                    <mat-select formControlName="font">
                        <mat-option *ngFor="let font of fontOptions" [value]="font.value">{{ font.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.fontSize' | translate}}</mat-label>
                    <input formControlName="fontSize" type="number" matInput placeholder="{{'pass.composed.fontSize' | translate}}" min="0">
                </mat-form-field> -->

                <mat-form-field *ngIf="contentDataType=='hstack' || contentDataType=='vstack'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.spacing' | translate}}</mat-label>
                    <input formControlName="spacing" type="number" matInput placeholder="{{'pass.composed.spacing' | translate}}" min="0">
                </mat-form-field>

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.padding' | translate}}</mat-label>
                    <mat-select formControlName="padding">
                        <mat-option *ngFor="let padding of paddingOptions" [value]="padding.value">{{ padding.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field *ngIf="contentDataType=='vstack'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.horizontalAlignment' | translate}}</mat-label>
                    <mat-select formControlName="horizontalAlignment">
                        <mat-option *ngFor="let horizontalAlignment of horizontalAlignmentOptions" [value]="horizontalAlignment.value">{{ horizontalAlignment.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="contentDataType=='hstack'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.verticalAlignment' | translate}}</mat-label>
                    <mat-select formControlName="verticalAlignment">
                        <mat-option *ngFor="let verticalAlignment of verticalAlignmentOptions" [value]="verticalAlignment.value">{{ verticalAlignment.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field *ngIf="contentDataType=='zstack' appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.alignment' | translate}}</mat-label>
                    <mat-select formControlName="alignment">
                        <mat-option *ngFor="let alignment of alignmentOptions" [value]="alignment.value">{{ alignment.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.width' | translate}}</mat-label>
                    <input formControlName="width" type="number" matInput placeholder="{{'pass.composed.width' | translate}}" min="0">
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.height' | translate}}</mat-label>
                    <input formControlName="height" type="number" matInput placeholder="{{'pass.composed.height' | translate}}" min="0">
                </mat-form-field>

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.aspectRatio' | translate}}</mat-label>
                    <input formControlName="aspectRatio" type="number" matInput placeholder="{{'pass.composed.aspectRatio' | translate}}" min="0">
                </mat-form-field> -->

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.cornerRadius' | translate}}</mat-label>
                    <input formControlName="cornerRadius" type="number" matInput placeholder="{{'pass.composed.cornerRadius' | translate}}" min="0">
                </mat-form-field> -->

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.radius' | translate}}</mat-label>
                    <input formControlName="radius" type="number" matInput placeholder="{{'pass.composed.radius' | translate}}" min="0">
                </mat-form-field> -->

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.foregroundColor' | translate}}</mat-label>
                    <mat-select formControlName="foregroundColor">
                        <mat-option *ngFor="let foregroundColor of colorOptions" [value]="foregroundColor.value">{{ foregroundColor.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.backgroundColor' | translate}}</mat-label>
                    <mat-select formControlName="backgroundColor">
                        <mat-option *ngFor="let backgroundColor of colorOptions" [value]="backgroundColor.value">{{ backgroundColor.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <!-- <mat-form-field *ngIf="contentDataType=='circle' || contentDataType=='rectangle'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.fillColor' | translate}}</mat-label>
                    <mat-select formControlName="fillColor">
                        <mat-option *ngFor="let fillColor of colorOptions" [value]="fillColor.value">{{ fillColor.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="contentDataType=='circle' || contentDataType=='rectangle'" appearance="outline" class="w-100">
                    <mat-label>{{'pass.composed.strokeColor' | translate}}</mat-label>
                    <mat-select formControlName="strokeColor">
                        <mat-option *ngFor="let strokeColor of colorOptions" [value]="strokeColor.value">{{ strokeColor.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

            </form>
        </section>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="oldData" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
