<section class="topNavbar">
    <button mat-button style="color:var(--warmOrange);" (click)="backTo();">
        <span class="material-symbols-outlined" style="font-size: 10px;">
            arrow_back_ios
        </span>
        {{'products.title' | translate}}
    </button>
</section>

<div class=" pb-1 d-flex justify-content-between align-items-center" style="width:90%; margin:auto; max-width: 1000px;">
    
    <span style="font-size:20px;">{{'products.product' | translate}}</span>


    <button mat-fab extended style="background-color:white; color:var(--accentGlobal);" id="btn-delete" (click) = "deleteProduct();" [disabled]="!productsUserRights_delete">
        <mat-icon>delete</mat-icon>
        {{'products.delete_btn' | translate}}
    </button>
</div>

<section class="container-rows mt-1 mb-2" >
    
    <form [formGroup]="productForm" >

        <div class="d-flex flex-row flex-wrap">
            <div class=" p-1 container-column" >
            
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_id' | translate}}</mat-label>
                    <input matInput type="text" formControlName="productId" name="product-id" placeholder="{{'products.p_id' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>
                
            
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_title' | translate}}</mat-label>
                    <input matInput type="text" formControlName="title" name="title" placeholder="{{'products.p_title' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>

            
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_manufacturer' | translate}}</mat-label>
                    <input matInput type="text" formControlName="manufacturer" name="manufacturer" placeholder="{{'products.p_manufacturer' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.DESCRIPTION' | translate}}</mat-label>
                    <textarea matInput formControlName="description" name="description" class="form-control" placeholder="{{'products.p_description' | translate}}" [readonly]="!this.productsUserRights_update"></textarea>
                </mat-form-field>
                
            </div>

            <div class="p-1 container-column" >
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_price' | translate}}</mat-label>
                    <input matInput type="number" step="0.01" formControlName="price" name="price" placeholder="{{'products.p_price' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_type' | translate}}</mat-label>
                    <input matInput type="text" formControlName="type" name="type" placeholder="{{'products.p_type' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_group' | translate}}</mat-label>
                    <input matInput type="text" formControlName="group" name="group" placeholder="{{'products.p_group' | translate}}" [readonly]="!this.productsUserRights_update">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_tags' | translate}}</mat-label>
                    <input matInput type="text" formControlName="tags" name="tags" placeholder="{{'products.p_tags' | translate}}" [readonly]="!this.productsUserRights_update">
                    <mat-hint>{{'products.p_tags_definition' | translate}}</mat-hint>
                </mat-form-field>
                <!-- <p class="text-muted">{{'products.p_tags_definition' | translate}}</p> -->
            </div>
        </div>
       

        
        <div class="insertImgContainerS rounded">
            <label class="labelFileS rounded"> +
                <!-- <span class="material-symbols-outlined">
                    add
                    </span> Add Image -->
                <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);" [disabled]="!this.productsUserRights_update"> 
            </label>

            <!-- <button mat-fab extended style="background-color:var(--accentGlobal); color:white;" >
                <mat-icon>add</mat-icon>
                {{'products.delete_btn' | translate}}
                <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);"> 

              </button> -->


            <div *ngIf="!changes" class="d-flex flex-row flex-wrap" style="max-width: 500px;">
                <div *ngFor="let im of allImages; let index=index;" class="d-flex flex-row flex-nowrap" style="margin-left:20px; margin-bottom:10px;" >
                    
                    <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">

                    <img  src="{{im.url}}" height="120" style="width:auto; object-fit: cover; border-radius:10px;"  onerror="this.src='./../../assets/img/default.jpg'">
                    <button  mat-icon-button style="color:var(--pureWhite); background-color: var(--warmOrange); right:50px;" (click)="deleteImg(im.url, index)" [disabled]="!this.productsUserRights_update" >
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>

                </div>
            </div>

            <div *ngIf="changes" class="d-flex flex-row flex-wrap" style="max-width: 500px;">
                <div *ngFor="let im of productForm.value.images; let index=index;" class="d-flex flex-row flex-nowrap" style="margin-left:20px; margin-bottom:10px;" >
                    <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">
                    <img  src="{{im.url}}" height="120" style="width:auto; object-fit: cover; border-radius:10px;"  onerror="this.src='./../../assets/img/default.jpg'">

                    <button  mat-icon-button style="color:var(--pureWhite); background-color: var(--warmOrange); right:50px;" (click)="deleteImg(im.url, index)" [disabled]="!this.productsUserRights_update" >
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>
                </div>
            </div>
        </div>

    </form>

    <div class="pb-3 d-flex flex-row flex-wrap justify-content-between" style="width:90%; margin:auto;">
        <button  mat-button style="color:var(--warmOrange);width: 40%;" id="btn-cancel" (click)="backTo();" [disabled]="!productsUserRights_update">
            {{'cancel' | translate}}
        </button>
        
        <button mat-raised-button style="width:40%; background-color:var(--warmOrange); color:var(--pureWhite);" id="btn-save" (click)="saveProduct();" [disabled]="!productsUserRights_update">
            {{'save' | translate}}
        </button> 
    </div>
</section>


