import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TridysService {

  constructor(private firestore: AngularFirestore,
    private settingsService: SettingsService) { }

  async createTridy(tridyForm):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const tridysRef = this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS)
     .doc().ref

      const newId = tridysRef.id
      let newTridy = tridyForm
      newTridy['creationDate'] = new Date()
      newTridy['timeStamp'] = new Date()
      newTridy['id'] = newId

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS)
        .doc(newId).set({...newTridy})

        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })

  }

  async createTridyFromContentType(data, contentTypeId):Promise<string>{

    return new Promise(async (resolve, reject) => {
  
      let newTridy = data
      newTridy['creationDate'] = new Date()
      newTridy['timeStamp'] = new Date()
      //newTridy['id'] = contentTypeId
      newTridy['contentType'] = contentTypeId

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS)
        .doc(contentTypeId).set({...newTridy}, {merge:true})

        resolve(contentTypeId)
      }catch (error) {
        reject(error)
      }
    })

  }

  deleteTridy(tridyId){
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_TRIDYS)
        .doc(tridyId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}
