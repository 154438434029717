interface IShopify{
    accessToken?:string
    storeName?:string
}

interface IModules{
    shopify?:IShopify
}

export interface IAccount{
    id?: string
    displayName:string
    languages?:string[]
}

export class Account{

    id: string|undefined = undefined
    displayName: string|undefined = undefined
    languages: string[]|undefined = undefined

    constructor(){}

    setFromAny(element:any){
        const {id, customer, displayName, modules,key,accessToken, storeName, nfcURLPrefix, languages } = element

        this.id = id ? id : this.id
        this.displayName = displayName ? displayName : this.displayName
        this.languages = languages ? languages : this.languages
    }

    getId(){
        if(!this.id) return undefined

        return this.id
    }

    get(){
        return this
    }

    setLanguages(data){
        this.languages = data.languages
    }

    getDisplayName(){
        if(!this.displayName) return undefined

        return this.displayName
    }
}