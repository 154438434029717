import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-value-content-dialog',
  templateUrl: './edit-value-content-dialog.component.html',
  styleUrls: ['./edit-value-content-dialog.component.css']
})
export class EditValueContentDialogComponent implements OnInit {
  editValueForm!:FormGroup
  editValueData:any
  editIndex:any
  typeValue:any

  constructor(public dialogRef: MatDialogRef<EditValueContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder){

      dialogRef.disableClose = true;

}
  
ngOnInit():void{
  this.editValueData = this.data[1]
  this.editIndex = this.data[0]
  this.typeValue = this.data[2]
  this.editValueForm = this.fb.group({
    value: [this.sanitizeValue(this.editValueData), this.typeValue === 'number' ? [Validators.pattern('^[0-9]*$')] : []],
    date:[],
    time:[]
  })

  if(this.typeValue=="object"){
   
    if(this.editValueData.nanoseconds || this.editValueData.seconds){
      const fireBaseTime = new Date(
        this.editValueData.seconds * 1000 + this.editValueData.nanoseconds / 1000000,
      );
      this.formatDate(fireBaseTime)
      this.formatTime(fireBaseTime)    
      this.editValueForm.patchValue({value:fireBaseTime})
    }else{
      const fireBaseTime = new Date(this.editValueData)
      const hour = new Date(this.editValueData).getHours()
      const minutes = new Date(this.editValueData).getMinutes()
      const seconds = new Date(this.editValueData).getSeconds()
      const time = hour + ':' + minutes
      this.editValueForm.patchValue({value:fireBaseTime, date:fireBaseTime, time:time})
    }
  }

  this.editValueForm.valueChanges.subscribe(value =>{
      if(this.typeValue=="object"){
        // const alld = value.date + " " + value.time
        // const date = new Date(alld)
        // value.value = date
        try{
          let newdate 
          if(value.date){
            newdate = new Date(value.date)
          }

          if(value.time){
            const hoursminute = value.time.split(':')
            const newtime = new Date(value.date).setHours(hoursminute[0], hoursminute[1], 0)
            newdate.setTime(newtime)
          }
          value.value = newdate
        }catch(e){}
      }
  })
}

  cancelEditSubtitleKey(){
    const values = [this.editValueData,false,  this.editIndex]
    this.dialogRef.close(values);
  }

  sanitizeValue(value: any): any {
    if (this.typeValue === 'number') {
      // Remove non-numeric characters
      return (typeof value === 'string') ? value.replace(/[^0-9]/g, '') : value;
    }
    return value;
  }

  saveEditSubtitleKey() {
    if (this.editValueForm.valid) {
      let sanitizedValue = this.editValueForm.value.value;
  
      if (this.typeValue === 'number') {
        sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
        sanitizedValue = sanitizedValue ? Number(sanitizedValue) : null;
      }
      const values = [sanitizedValue, true, this.editIndex];
      this.dialogRef.close(values);
    } else {
      console.error('Form is invalid');
    }
  }
  

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    // Allow only numeric values
    input.value = value.replace(/[^0-9]/g, '');
    this.editValueForm.get('value')?.setValue(input.value, { emitEvent: false });
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    this.editValueForm.patchValue({date: [year, month, day].join('-')})
    return [year, month, day].join('-');
  }

  formatTime(date){
    var dateToinput=""
    const d = new Date(date)

    let hours = d.getHours()
    const minutes = d.getMinutes()
    const m = d.toTimeString()
    if(hours<10){
      const h = '0' + hours
      dateToinput = h + ":" + minutes
    }else{
      dateToinput = hours + ":" + minutes
    }

    if(minutes<10){
      const m = '0' + minutes
      dateToinput = hours + ":" + m
    }else{
      dateToinput = hours + ":" + minutes
    }

    this.editValueForm.patchValue({time:dateToinput})
   return dateToinput
  }
}