<section style="min-width: 500px;  padding:10px; overflow: auto; max-height: 70vh;" >
    <div style="display: flex; align-items: center;justify-content: space-between;">
        
        <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
            {{'cancel' | translate}}
        </button>

        <button mat-button class="orange-button"
        (click)="onSave();">{{'save' | translate}}</button>

    </div>
    <h1>{{'tridys.title' | translate}}</h1>

    <form [formGroup]="tridysForm">
        <div class="form-group m-1 ">
            <label for="tridyId" >{{'tridys.tridy' | translate}}</label>
            <input matInput type="text"  name="tridyId" class="form-control" placeholder="{{'tridys.tridy' | translate}}"  readonly>
        </div>


        <mat-form-field appearance="outline" class="mt-2 w-100">
            <mat-label>{{'contentTypes.contentType' | translate}}</mat-label>
            <mat-select formControlName="contentType">
            <!-- <mat-select [(value)]="contentType"> -->
            <mat-option *ngFor="let content of allContentTypes" [value]="content.id">
                {{content.displayName}}
            </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-2 w-100">
            <mat-label>{{'tridys.finishedTaks' | translate}}</mat-label>
            <mat-select >
            <!-- <mat-select [(value)]="contentType"> -->
            <mat-option *ngFor="let ftask of finishedTasks" [value]="ftask.id">
                {{ftask.title}}
                <span class="text-muted">{{ftask.creationDate.toDate() | date }}</span>

            </mat-option>
            </mat-select>
        </mat-form-field>

        <label for="finishedTaks">{{'tridys.finished' | translate}}</label>

        <div class="form-group m-1 " *ngIf="finishedTasks">
            <div *ngFor="let ftask of finishedTasks" class="item-content mb-2" >
                <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                    <div class="d-flex flex-column flex-nowrap">
                        <!-- <div class="d-flex flex-row flex-wrap justify-content-between align-items-center"> -->
                        <span>{{ftask.title}}</span>
                        <span class="text-muted">{{ftask.creationDate.toDate() | date }}</span>
                    </div>
                    <span class="material-symbols-outlined text-muted">
                        keyboard_arrow_right
                    </span>
                </div>
            </div>
        </div>
    </form> 
</section>