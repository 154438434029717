export const defaultFieldsDSection = {
    text:{
        "key": "newField",
        "type": "text",
        "id": "3IWd4N",
        "title": "New Field",
    },
    longText:{
        "key": "newField",
        "title": "New Field",
        "id": "4iJBdT",
        "type": "longText"
    },
    number:{
        "key": "newField",
        "type": "number",
        "title": "New Field",
        "id": "3vLhEu"
    },
    decimal:{
        "id": "4sttKg",
        "key": "newField",
        "title": "New Field",
        "type": "decimal"
    },

    currency:{
        "id": "4sttKg",
        "key": "newField",
        "title": "New Field",
        "type": "currency"
    },
    date:{
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LIsd",
        "type": "date"
    },
    time:{
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LI",
        "type": "time"
    },
    dateTime:{
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LI",
        "type": "dateTime"
    },
    
    boolean:{
        "key": "newField",
        "id": "9sLfi",
        "type": "boolean",
        "title": "New Field"
    },

    weight:{
        "key": "newField",
        "id": "9sLfi",
        "type": "weight",
        "title": "New Field"
    },
}

export const newSection = {
    fields:[],
    title: "New Section",
    id:""
}

export const defaultFieldsTypes = [
    "Text",
    "Long Text",
    "Number",
    "Decimal Number",
    "Currency",
    "Date/Time",
    "Switch",
    "Weight",

]
