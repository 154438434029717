<h2 mat-dialog-title>{{'pass.map.ANNOTATIONS_dynamic' | translate}}</h2>

<mat-dialog-content style="width: 600px;">

    <div class="hint-text">{{'dynamic_coordinates_hint' | translate}}</div>

    <div class="py-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="propertiesForm">

                <div>
                    <div class="text-muted pb-4">{{'pass.map.coordinate' | translate}}</div>

                    <div class="d-flex flex-row">
                        <div class="pe-2 flex-grow-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.map.latitude' | translate}}</mat-label>
                                <input matInput type="text" id="newLat" formControlName="lat" placeholder="{{'pass.map.latitude' | translate}}" >
                            </mat-form-field>
                        </div>
                        <div class="ps-2 flex-grow-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.map.longitude' | translate}}</mat-label>
                                <input matInput type="text" id="newLng" formControlName="lng"  placeholder="{{'pass.map.longitude' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.map.title' | translate}}</mat-label>
                            <input matInput type="text" id="titleMap" formControlName="title" placeholder="{{'pass.title' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.map.subtitle' | translate}}</mat-label>
                            <input matInput type="text" id="subtitleMap" formControlName="subtitle" placeholder="{{'pass.subtitle' | translate}}">
                        </mat-form-field>
                    </div>
                </div>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.map.badge' | translate}}</mat-label>
                    <input matInput type="text" id="bagdeMap" formControlName="badge" placeholder="{{'pass.map.badge' | translate}}">
                </mat-form-field>

             
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.linkURL' | translate}}</mat-label>
                    <input matInput type="url" id="linkURLMap" formControlName="linkURL" placeholder="{{'pass.linkURL' | translate}}">
                </mat-form-field>
        
                <div>
                    <div class="text-muted text-uppercase pb-3">{{'style' | translate}}</div>

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pinColor' | translate}}</mat-label>
                        <input matInput type="string" formControlName="pinColor" placeholder="{{'pinColor' | translate}}">
                    </mat-form-field>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="[false]" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();"  >{{'save' | translate}}</button>
</mat-dialog-actions>