


    <div *ngIf="contentData.type=='text'" class="ps-1">
        <span *ngIf="contentData?.attributes?.text" 
            [ngStyle]=" {'width': contentData?.attributes?.width ? contentData?.attributes?.width : '100%',
                          'height': contentData?.attributes?.height ? contentData?.attributes?.height: '100%',
                          'font-family': fontfamily,

                        }">
            {{contentData?.attributes?.text}}
        </span>
    </div>

    <div *ngIf="contentData.type=='image'" class="ps-1">
        <img *ngIf="contentData?.attributes?.url" src="{{contentData?.attributes?.url}}" onerror="this.src='./../../assets/img/default.jpg'" 
                alt="..."
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 280,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 400,
                }"
            />
    </div>

    <div *ngIf="contentData.type=='hstack'" class="d-flex flex-row  justify-content-center p-1" [style]="{ 'align-items' : verticalAlignment, 'align-content': horizontalAlignment  }">
        <section *ngFor="let content of contentData?.children" [style]="{ 'padding.px' : contentData?.attributes?.spacing ? contentData?.attributes?.width : 0 }">
            <div [app-composed-content-phone]="[content]"></div>
        </section>
    </div>


    <div *ngIf="contentData.type=='vstack'" class="d-flex flex-column  justify-content-center p-1" [style]="{ 'align-items' : horizontalAlignment , 'align-content' :horizontalAlignment }">
        <section *ngFor="let content of contentData?.children" [style]="{ 'padding.px' : contentData?.attributes?.spacing ? contentData?.attributes?.width : 0 }">
            <div [app-composed-content-phone]="[content]"></div>
        </section>
    </div>

    <div *ngIf="contentData.type=='circle'" class="ps-1">
        <div style="border-radius: 50%; background-color: black;"
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 200,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 200,
                }">
        </div>
    </div>


    <div *ngIf="contentData.type=='rectangle'" class="ps-1">
        <div style="background-color: black;"
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 280,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 200,
                }">
        </div>
    </div>
