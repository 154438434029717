import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentTypeService } from '../service/content-type.service';
import { PassDefinitionsService } from '../service/pass-definitions.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-content-types-dialog',
  templateUrl: './delete-content-types-dialog.component.html',
  styleUrls: ['./delete-content-types-dialog.component.css']
})
export class DeleteContentTypesDialogComponent {
contentTypeId:any
passTemplateId:any
contentTypeName:any
creationDate:any

  constructor(public dialogRef: MatDialogRef<DeleteContentTypesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private contentTypeService: ContentTypeService,
    private passService: PassDefinitionsService,
    private commentService: CommentsService,
    private translate: TranslateService){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.contentTypeId = this.data.values['id']
    this.passTemplateId = this.data.values['passTemplateId']
    this.contentTypeName = this.data.values['displayName']
    this.creationDate = this.data.values['creationDate']
    if(!this.passTemplateId){
      if(this.data['newPass']){
        this.passTemplateId = this.data['newPass']
      }
    }
  }

  deleteContentType(){
    let deleteCompleted = false
    try{
      this.commentService.progressSpin.emit(true)
      if(this.contentTypeId){
        const d = this.contentTypeService.deleteContentType(this.contentTypeId)
      }
        
      if(this.passTemplateId){
        const p = this.passService.deletePassDefinition(this.passTemplateId)
      }
      deleteCompleted = true
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.DELETE_OK")
      this.commentService.addSnackBar.emit(message)
      this.dialogRef.close([true, deleteCompleted])
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  cancelDeleteContentType(){
    this.dialogRef.close([false])
  }

  isDate(element){
    let dateValue
    dateValue = element

    if(element['nanoseconds'] || element['seconds']){
      const NS_TO_MS_MULTIPLIER = 1/1000000
      const SEC_TO_MS_MULTIPLIER = 1000
      const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
      const date = new Date(timestampInMilliseconds)
      element= date
      dateValue = date.toDateString()
    }
   return dateValue
  }
}