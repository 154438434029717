<section class="px-2">
    <!-- <span *ngIf="formData.title" style="font-family: var(--titleFontName);color:var(--title);">{{formData.title}}</span>
    <span *ngIf="formData.subtitle" style="font-family: var(--subtitleFontName);color:var(--subtitle);">{{formData.title}}</span> -->

    <div  *ngIf="formData.title" id="titleTextForm" class="titleSection text-break text-wrap"><span id="title">{{formData.title}}</span></div>
    <div *ngIf="formData.subtitle" id="subtitleTextForm" class="subtitleSection text-break text-wrap"><span id="subtitle">{{formData.subtitle}}</span></div>

    <form [formGroup]="sectionsForm">

        <div *ngFor="let section of sections; let sectionIndex = index" class="ps-1">
            <!-- ********{{section.id}} -->

            <p style="font-family: var(--titleFontName); color:var(--title);">{{section.title}}</p>
            <span *ngIf="section.subtitle" style="font-family: var(--subtitleFontName); color:var(--subtitle);">{{section.subtitle}}</span>

            <div *ngIf="section.fields" id={{section.id}} class="ps-2 " style="font-family: var(--plainFontName); color:var(--primary);">

                <div *ngFor="let field of section.fields; let indexField = index " >
                    <!-- ++{{field.id}}----{{field.key}} -->
                    <div *ngIf="field.type=='text'" class="w-100 pt-2">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);" >
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput type="text" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" >
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.type=='longText'" class="w-100 pt-2">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);" >
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <textarea matInput formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" ></textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.type=='number'" class="w-100 pt-2">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);" >
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput type="number" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" >
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.type=='decimal'" class="w-100 pt-2">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);">
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput type="number" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" step="0.1" >
                        </mat-form-field>
                    </div>


                    <div *ngIf="field.type=='date'" class="w-100 pt-1">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName);">
                            <mat-label style="font-family: var(--plainFontName);">{{field.title}}</mat-label>
                            <input matInput type="date" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" >
                        </mat-form-field>

                        <!-- <mat-form-field  appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);">
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field> -->
                        
                    </div>

                    <div *ngIf="field.type=='time'" class="w-100 pt-1">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);">
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput type="time" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" >
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.type=='dateTime'" class="w-100 pt-1">
                        <mat-form-field appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);">
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <input matInput type="datetime-local" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" >
                        </mat-form-field>
                    </div>

                    <div *ngIf="field.type=='boolean'" class=" p-2 mb-3 d-flex flex-nowrap justify-content-between align-items-center border rounded">
                        <span class="w-75 text-wrap" style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}} <span *ngIf="field.mandatory">*</span></span>

                        <mat-slide-toggle class="example-margin" [required]="field.mandatory" formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}">
                        </mat-slide-toggle>
                    </div>
                    

                    <div *ngIf="field.type=='options'" class=" ">
                        <mat-form-field  appearance="outline" class="w-100" style="font-family: var(--plainFontName); color:var(--primary);">
                            <mat-label style="font-family: var(--plainFontName); color:var(--primary);">{{field.title}}</mat-label>
                            <mat-select formControlName="{{sectionIndex}}.{{section.id}}-{{indexField}}.{{field.id}}_{{field.type}}" [required]="field.mandatory" multiple>
                              <mat-option style="font-family: var(--plainFontName); color:var(--primary);" *ngFor="let option of field.options; let index = index" value="{{option}}" >{{option}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div> 
            
            </div>
        </div>
            {{data.pass | json}}
        <div class= "pb-2" *ngIf="!submitForm">
            <!-- <button mat-button class="w-100" style="background-color:var(--accent); color:white;font-family: var(--plainFontName); " >{{formData.submitTitle ? formData.submitTitle : 'submit' | translate}}</button> -->
            <button mat-button class="w-100" id="formPhoneBtn-{{formData.id}}">{{formData.submitTitle ? formData.submitTitle : 'submit' | translate}}</button>
        </div>
        <div class= "pb-2" *ngIf="submitForm">
            <button mat-button class="w-100" id="formPhoneBtn-{{formData.id}}" >{{formData.submitAgainTitle ? formData.submitAgainTitle : 'submit' | translate}}</button>
        </div>
    </form>

</section>