import { Component } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskTemplatesGroupsDialogComponent } from '../task-templates-groups-dialog/task-templates-groups-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-task-templates-groups',
  templateUrl: './task-templates-groups.component.html',
  styleUrls: ['./task-templates-groups.component.css']
})
export class TaskTemplatesGroupsComponent {
allGroups:any
tasktemplateUserRights_create:any
tasktemplateUserRights_update:any
tasktemplateUserRights_delete:any
emptyList=false
groupSelected:any
InGroupList:any
othersList:any
taskTemplates:any
activeDelete=false
activeEdit = false

constructor(private settingsService: SettingsService, 
            private commentsService: CommentsService,
            public dialog: MatDialog,
            private translate:TranslateService){

}

ngOnInit(): void {
  this.tasktemplateUserRights_create = this.settingsService.userRolesRights$['workTaskTemplates']['C']
  this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']
  this.tasktemplateUserRights_delete = this.settingsService.userRolesRights$['workTaskTemplates']['D']

  if(!this.tasktemplateUserRights_create){
    document.getElementById('btn-create').style.color="#e0e0e0"
  }
  this.getTasksTemplatesGroups()
  this.getTasksTemplates()
}

  getTasksTemplatesGroups(){
    try{
      this.commentsService.progressSpin.emit(true)
      this.allGroups = this.settingsService.observeContextsWorkTasksTemplatesGroupsFirestoreWithId()
      if(this.allGroups.length==0){
        this.emptyList=true
      }    
      this.commentsService.progressSpin.emit(false)

    }catch(error){
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }


  getTasksTemplates(){
    try{
      this.commentsService.progressSpin.emit(true)
      this.taskTemplates = this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithId()
      if(this.taskTemplates.length==0){
        this.emptyList=true
      }    

      if(!this.groupSelected){
        this.othersList = this.taskTemplates
      }
      this.commentsService.progressSpin.emit(false)
    }catch(error){
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }

  addGroup(){
    if(!this.activeEdit){
      let dialogRef= this.dialog.open(TaskTemplatesGroupsDialogComponent, {
        data: { 
          contextId: this.settingsService.contextId$,
          sortIndex: this.settingsService.settingsWorkTaskTemplatesGroupsSize,
          option: 'create'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.getTasksTemplatesGroups()
        }
      });
    }
  }

  deleteGroup(taskSelected, indexSelected){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:taskSelected,
        type:"TaskTemplateGroup"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getTasksTemplatesGroups()
        
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentsService.addSnackBar.emit(message)
      }
    });
  }

  editGroup(taskSelected, indexSelected){
    let dialogRef= this.dialog.open(TaskTemplatesGroupsDialogComponent, {
      data: { 
         contextId: this.settingsService.contextId$,
         option: 'edit',
         group: taskSelected
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getTasksTemplatesGroups()
      }
    });
  }

  filterByGroup(groupSelected, indexSelected){
    if(!this.activeEdit){
      this.othersList=[]
      this.InGroupList=[]
      this.groupSelected = groupSelected.id

      this.taskTemplates.forEach((task, index) => {
        if(task.groupId){
          if(task.groupId==this.groupSelected){
            this.InGroupList.push(task)
          }else{
            this.othersList.push(task)
          }
        }else{
          this.othersList.push(task)
  
        }
      });
    }
  }

  activeEditTaskGroup(){
    this.activeEdit = true
  }

  doneEditTaskGroups(){
    this.activeEdit=false
  }

  

  done(){
    if(this.activeEdit){
      this.doneEditTaskGroups()
    }
  }
}
