import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { from,Observable } from 'rxjs';
import { CommentsService } from './comments.service';
import { TranslateService } from '@ngx-translate/core';
import { browserLocalPersistence, getAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user$: Observable<any>
  currentUser:any
  linkedUser:any
  private auth:any

  constructor(private fireauth: AngularFireAuth, 
    private router:Router,
    private commentsService: CommentsService,
    private translate: TranslateService
    ) { 
    this.user$= this.fireauth.authState
  }

  async signOut() {
    await this.fireauth.signOut()
    this.router.navigate(['logIn'])
    setTimeout(() => {
    window.location.reload();
    }, 300);
    return Promise.resolve()
  }

  signInWithEmailAndPassword(cred:any) {
    return from(this.fireauth.signInWithEmailAndPassword(cred.email, cred.password))
  }

  resetPassword(email:string){
    return this.fireauth.sendPasswordResetEmail(email)
  }

  signInWithEmailLink(emailLink, emailConfirmation){
    this.commentsService.progressSpin.emit(true)
    // Confirm the link is a sign-in with email link.
    if (this.fireauth.isSignInWithEmailLink(emailLink)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = emailConfirmation
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
        this.commentsService.progressSpin.emit(false)

      }
      // The client SDK will parse the code from the link for you.
      this.fireauth.signInWithEmailLink(email, window.location.href)
        .then((result) => {
          this.linkedUser = result
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');

          this.router.navigate(
                  ['setPassword', result.user.uid],
                  { queryParams: { setPassword: 'active' } }
                );
              
          this.commentsService.progressSpin.emit(false)
            return result
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log(error)
          this.commentsService.progressSpin.emit(false)
          const object = { data:"loged user"}
          return object

          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  signInWithEmailLinkPersistence(emailLink, emailConfirmation){
    this.auth = getAuth();
      
      return this.auth.setPersistence(browserLocalPersistence)
      .then(() => {
        
        this.commentsService.progressSpin.emit(true)
        // Confirm the link is a sign-in with email link.
        if (this.fireauth.isSignInWithEmailLink(emailLink)) {

          var email = emailConfirmation
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
            this.commentsService.progressSpin.emit(false)

          }

          this.fireauth.signInWithEmailLink(email, window.location.href)
            .then((result) => {
              this.linkedUser = result
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');

              this.router.navigate(
                      ['setPassword', result.user.uid],
                      { queryParams: { setPassword: 'active' } }
                    );

              this.commentsService.progressSpin.emit(false)
              return result

            })
            .catch((error) => {
              console.log(error)
              this.commentsService.progressSpin.emit(false)
              const object = { data:"loged user"}
              return object

            });
        }
        
      })
      .catch((error) => {
        // Handle Errors here.
        const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
        this.commentsService.addSnackBar.emit(message)
        console.error('Error enabling local persistence:', error);
        this.commentsService.progressSpin.emit(false)
      });
  }
}
