
<h2 mat-dialog-title>{{'interfaces.add_mapping' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="mappingForm">
                <mat-form-field *ngIf="type=='media'" appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.key' | translate}}</mat-label>
                    <input formControlName="key" type="text" matInput placeholder="{{'interfaces.name' | translate}}">
                </mat-form-field>
        
                <mat-form-field *ngIf="type=='ids'" appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.type' | translate}}</mat-label>
                    <input formControlName="type" type="text" matInput placeholder="{{'interfaces.info' | translate}}">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.path' | translate}}</mat-label>
                    <input formControlName="path" type="text" matInput placeholder="{{'interfaces.displayName' | translate}}">
                </mat-form-field>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="dialogSave();"  >{{'save' | translate}}</button>
</mat-dialog-actions>
