import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';

@Injectable({
  providedIn: 'root'
})
export class InterfacesService {

  constructor(private firestore: AngularFirestore,
    private settingsService: SettingsService) { }


  async createInterface(data):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const interfacesRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_INTERFACES)
      .doc().ref

      const newId = interfacesRef.id

      let newInterface = removeUndefinedValuesFromObject(data)
      newInterface['creationDate'] = new Date()
      newInterface['timeStamp'] = new Date()
      newInterface['id'] = newId

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_INTERFACES)
        .doc(newId).set({...newInterface})

        resolve(newId)
        
      }catch (error) {
        reject(error)
      }
    })

  }



  updateInterface(data:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
        
      try{
        removeUndefinedValuesFromObject(data)
        data['timeStamp']= new Date()
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_INTERFACES)
        .doc(data.id)
        .update(data)
        
        resolve()
      }catch(error){
        reject(error)
      }
    }) 
  }

  deleteInterface(interfaceId):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_INTERFACES)
        .doc(interfaceId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}
