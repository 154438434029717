import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-task-template-edit-section-dialog',
  templateUrl: './task-template-edit-section-dialog.component.html',
  styleUrls: ['./task-template-edit-section-dialog.component.css']
})
export class TaskTemplateEditSectionDialogComponent implements OnInit {

  editSectionForm!:FormGroup

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateEditSectionDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    ){

      dialogRef.disableClose = true;
    }


  ngOnInit(): void {
    this.editSectionForm = this.fb.group({
      title:[]
    })

    this.editSectionForm.patchValue({title: this.data.values})
    this.editSectionForm.valueChanges.subscribe( (value:any)=>{
    })

  }
  onCancelSettings(){
    this.dialogRef.close([this.data.values, false])
  }

  onSaveSettings(){
    this.dialogRef.close([this.editSectionForm.value, true])

  }
}
