import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { ContentTypeService } from '../service/content-type.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from '../../../../backend/utils/object';
import { ContentType } from '../../../../backend/src/contentType.dto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { defaultFields } from '../../../../backend/src/deafultFieldsFormWidget.dto';
import { defaultFieldsDSection } from '../../../../backend/src/displaySection.dto';
import { TaskTemplateEditSectionDialogComponent } from '../task-template-edit-section-dialog/task-template-edit-section-dialog.component';
import { ContentTypeDisplayTemplateFieldDialogComponent } from '../content-type-display-template-field-dialog/content-type-display-template-field-dialog.component';
import { ReportService } from '../service/report.service';

@Component({
  selector: 'app-content-type-display-template-dialog',
  templateUrl: './content-type-display-template-dialog.component.html',
  styleUrls: ['./content-type-display-template-dialog.component.css']
})
export class ContentTypeDisplayTemplateDialogComponent {

  oldData:any
  allSections:any = []
  routerUrl:any
  idToShow:any
  contenttype:ContentType
  contentData:any
  displaySectionsData:any
  contentTypeUserRights_update:any
  contentTypeUserRights_delete:any
  displaySectionsForm!:FormGroup
  phoneFullHeightView = false;
  allIds:any=[]
  allKeys:any = []
  addedFields:any = 0
  duplicateKeys = false
  fieldTypeTitles: { [key: string]: string } = {
    boolean: 'Boolean Field',
    currency: 'Currency Field',
    dateTime: 'DateTime Field',
    time: 'Time Field',
    date: 'Date Field',
    decimal: 'Decimal Field',
    longText: 'Long Text Field',
    number: 'Number Field',
    text: 'Text Field',
    weight: 'Weight Field'
  };

  elementsOptions = [
    {
      name: 'text',
      icon:'text_format',
      translations:'taskTemplates.add_text',
      type:'text'
    },
    {
      name: 'longText',
      icon:'subject',
      translations:'taskTemplates.add_longText',
      type:'longText'
    },
    {
      name: 'number',
      icon:'pin',
      translations:'taskTemplates.add_number',
      type:'number'
    },
    {
      name: 'decimal',
      icon:'numbers',
      translations:'taskTemplates.add_decimal',
      type:'decimal'
    },
    {
      name: 'currency',
      icon:'euro',
      translations:'taskTemplates.add_currency',
      type:'currency'
    },
    {
      name: 'date',
      icon:'calendar_today',
      translations:'taskTemplates.add_date',
      type:'date'
    },
    {
      name: 'time',
      icon:'access_time',
      translations:'taskTemplates.add_time',
      type:'time'
    },
    {
      name: 'dateTime',
      icon:'calendar_month',
      translations:'taskTemplates.add_dateTime',
      type:'dateTime'
    },
    {
      name: 'boolean',
      icon:'toggle_on',
      translations:'taskTemplates.add_switch',
      type:'boolean'
    },
    {
      name: 'weight',
      icon:'weight',
      translations:'taskTemplates.add_weight',
      type:'weight'
    }

  ]

  savedData = true

  constructor(
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private reportService: ReportService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    public dialogRef: MatDialogRef<ContentTypeDisplayTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],){
  }
  
  ngOnInit(){
    this.idToShow = this.data['id']
    this.contentData = deepCopy(this.data['contentType'])

    this.displaySectionsForm = this.fb.group({
      sections:[],
    })
    
    if(this.contentData){
      this.contenttype = new ContentType()
      this.contenttype.setFromAny(this.contentData)
      this.oldData = deepCopy(this.contentData)

      if(this.contenttype.displaySections){
        this.displaySectionsData = this.contenttype.displaySections
        if(this.displaySectionsData)
          this.allSections=this.displaySectionsData
        else
          this.allSections=[]

        this.initializeForm()
      }else{
        this.initializeDefaultForm()
      }

    }else{
      this.getContentTypeId()
    }

    this.contentTypeUserRights_update = this.settingsService.userRolesRights$['contentTypes']['U']
    this.contentTypeUserRights_delete = this.settingsService.userRolesRights$['contentTypes']['D']

    this.displaySectionsForm.valueChanges.subscribe(value => {
      this.savedData = false
    })

    this.displaySectionsForm.get('sections').valueChanges.subscribe(value => {
      this.checkDuplicatesKeys()
    })
  }


  initializeForm(){
    const {displaySections} =  this.contenttype

    this.displaySectionsForm.patchValue({
      sections: displaySections ? displaySections : []
    })
  }

  initializeDefaultForm(){
    this.allSections = []

    this.displaySectionsForm.patchValue({
      sections: []
    })

  }

  drop(event: CdkDragDrop<string[]>, index:number) {

    moveItemInArray(this.allSections[index].fields, event.previousIndex, event.currentIndex);
    this.displaySectionsForm.patchValue({formSections: this.allSections})
  }

  dropPanel(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allSections, event.previousIndex, event.currentIndex);
    this.displaySectionsForm.patchValue({ formSections: this.allSections });
  }

  addFieldToSection(fieldType, section){
    const id= this.makeid(6)
    const _defaultFields = defaultFieldsDSection
    if(!this.allIds.includes(id)){
      this.allIds.push(id)
    }
    const newField = deepCopy(_defaultFields[fieldType])
    newField['id']=id
    const fieldTitle = this.fieldTypeTitles[fieldType] || 'New Field';
    newField['title'] = `${fieldTitle} ${this.addedFields}`;
    newField['key']+=''+this.addedFields
    if(this.allKeys.includes(newField['key'])){
      const random = this.makeid(4)
      newField['key']+=''+ random
      newField['title']+=''+random
    }
    this.addedFields++;
    this.allSections[section].fields.push(newField)
    this.allKeys.push(newField.key)
    this.displaySectionsForm.patchValue({section: this.allSections})
  }

  async getContentTypeId(){
    try{
      this.commentService.progressSpin.emit(true)
      this.contenttype = await this.settingsService.observeContextsContentTypesIdFirestore(this.idToShow)
      this.contentData= new ContentType 
      this.contentData.setFromAny(this.contenttype)

      if(!this.contentData['id']){
        this.contentData['id']= this.idToShow
      }
  
      this.commentService.progressSpin.emit(false)

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)

    }
  }

  editTitleSection(title, index){
    let dialogRef= this.dialog.open(TaskTemplateEditSectionDialogComponent, {
      data: { 
          values: title
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.allSections[index].title = result[0].title
        this.displaySectionsForm.patchValue({sections: this.allSections})
      }
    });
  
  }

  duplicateSection(section, index){
    const newSection = deepCopy(section)

    if(newSection.fields){
      newSection.fields.forEach((field, index) => {
        field.id = this.makeid(6)
        this.allIds = field.id
        field.key += '_copy'
        this.allKeys.push(field.key)
      });
    }
    if(newSection.title)
      newSection.title += '_copy'
    newSection.id = deepCopy(this.makeid(6))
    this.allSections.push(newSection)
    this.displaySectionsForm.patchValue({sections: this.allSections})
  }

  deleteSection(title, index){
    this.allSections.splice(index,1)
    const sections = deepCopy(this.allSections)
    this.displaySectionsForm.patchValue({sections: sections})
  }

  openFieldDialog(item, fieldNumber, index){
    let dialogRef= this.dialog.open(ContentTypeDisplayTemplateFieldDialogComponent, {
      data: { 
          values: item
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.allSections[index].fields[fieldNumber] = result[1]
        this.displaySectionsForm.patchValue({sections: this.allSections})
      }
    });
  }

  deleteFieldOfSection(fieldNumber, indexSection){
    const sections = this.allSections
    sections[indexSection].fields.splice(fieldNumber,1)
    this.displaySectionsForm.patchValue({sections: sections})
  }

  addSection(){
    const sections = deepCopy(this.allSections)

    const title = this.translate.instant('workTask.newSection')
    const nuevo = {
      title: title,
      fields: [],
      id: ''
    }
    nuevo.id = deepCopy(this.makeid(5))
    this.allSections.push(nuevo)
    this.displaySectionsForm.patchValue({section: this.allSections})
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  checkDuplicatesKeys(){
    let allFieldsNumber = 0
    this.allIds=[]
    this.allKeys=[]
    let changes = false
    this.duplicateKeys = false

    this.allSections.forEach((section, index) => {
      if(section.fields ){
        section.fields.forEach( (field, number) => {
          if(field.id){
            if(this.allIds.includes(field.id)){
              field.id = this.makeid(6)
              changes = true
              this.allIds.push(field.id)
            }else{
              this.allIds.push(field.id)
            }
          }
           
          if(field.key){
            allFieldsNumber++
            this.addedFields = allFieldsNumber
            if(this.allKeys.includes(field.key)){
              this.duplicateKeys = true
              this.allKeys.push(field.key)
            }else{
              this.allKeys.push(field.key)
            }
          }else{
            if(field.key==""){
              if(this.allKeys.includes(field.key)){
                this.duplicateKeys = true
                this.allKeys.push("")
              }else{
                this.allKeys.push("")
              }
            }
          }
        })
      }
    });

    if(changes){
      this.displaySectionsForm.patchValue({sections : this.allSections})
    }
  }

  createReportTemplate(){
    this.commentService.progressSpin.emit(true)
    if(this.savedData){
      let reportData = {
        displayName : this.contenttype.displayName,
        contentTypeId: this.contenttype.id,
        resultFields: [],
        filters:[]
      }
      const data = deepCopy(this.displaySectionsForm.value.sections)
      if(data){
        let columns = []
        data.forEach(section => {
          if(section.fields){
            const fields = section.fields
            fields.forEach(field => {
              //----------------------------------------------
              // TODO:  ignore types that, for now, are not supported on reports
              if(field.type!='currency' && field.type!='longText' && field.type != 'weight'){
                if(field.type=='text'){
                  field.type = 'string'
                }
                let newField = {
                  displayName : field.title,
                  key: field.key,
                  type:field.type
                }
                columns.push(newField)
              }
              
            });
          }
        });
        reportData.resultFields = columns
      }

      try{
        const a = this.reportService.createReport(reportData, this.settingsService.contextId$)
        if(a){
          this.commentService.progressSpin.emit(false)
          const message = this.translate.instant( "SnackBarConstants.REPORT_CREATED_SUCCESS")
          this.commentService.addSnackBar.emit(message)
        }else{
          this.commentService.progressSpin.emit(false)
          const message = this.translate.instant( "SnackBarConstants.CREATE_WORK_TASK_FAILED")
          this.commentService.addSnackBar.emit(message)
        }
        
      }catch(error){
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant( "SnackBarConstants.CREATE_WORK_TASK_FAILED")
        this.commentService.addSnackBar.emit(message)
      }

    }else{
      const message = this.translate.instant( "SnackBarConstants.SAVE_CHANGES_FIRST")
      this.commentService.addSnackBar.emit(message)
    }
  }

  onSave(){
    const data = deepCopy(this.displaySectionsForm.value.sections)
    this.savedData = true
    this.dialogRef.close([true, data])
  }

  onCancel(){
    this.dialogRef.close([false, this.oldData])
  }
  
}
