import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-map-marker-edit]',
  templateUrl: './map-marker-edit.component.html',
  styleUrls: ['./map-marker-edit.component.css']
})
export class MapMarkerEditComponent implements OnInit {
  @Input('app-map-marker-edit') data:any;
  mapData:any
  annotationsData:any
  firstMap:any

  @Output() outputFromMap: EventEmitter<any> = new EventEmitter()

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  constructor() { }

  ngOnInit(): void {
    this.mapData=this.data
    if(this.mapData.annotations){
      this.annotationsData=this.mapData.annotations
    }
    if(this.mapData.annotationGroup){
      this.annotationsData=this.mapData.annotationGroup

    }
    if(this.mapData.style){
      this.markerOptions['mapTypeId'] = this.mapData.style
    }
    this.loadMarkers()
  }
  

  loadMarkers(){
    if(this.annotationsData){
      this.annotationsData.forEach((element:any) => {
        if(element.coordinate){
          var lats={
            lat: parseFloat(element.coordinate[0]),
            lng: parseFloat(element.coordinate[1]),
            name: element.name,
  
          }
          this.markerPositions.push(lats);
        }else{
          var lats={
          lat: parseFloat(element.lat),
          lng: parseFloat(element.lng),
          name: element.name,

          }
          this.markerPositions.push(lats);
        }
        
        
        this.center=lats
      });
    }
  }

  sendData(){
    this.outputFromMap.emit(this.markerPositions)
  }
  addMarker(event: google.maps.MapMouseEvent) {
    if(event.latLng != null){
       this.markerPositions.push(event.latLng.toJSON());
    }
    this.outputFromMap.emit(this.markerPositions)
  }
}
