<h2 mat-dialog-title>{{'pass.widgets.imgs' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->
<mat-dialog-content style="width:600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">
            <form [formGroup]="imagesEditForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="input-group">
                  <mat-form-field  appearance="outline" class="w-100">
                    <mat-label>{{'pass.layout' | translate}}</mat-label>
                    <mat-select formControlName="layout" class="input-group m-1">
                      <mat-option *ngFor="let lay of layoutTypes" [value]="lay.value">
                        {{lay.translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p *ngIf="layoutBad" style="color:var(--accentGlobal);">{{'pass.layout_error' | translate}} {{layoutImages}} {{'pass.images_error' | translate}}</p>
                </div>
        
    <mat-accordion class="form-field-section">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title style="color:#595c5f;">
                    <mat-icon class="material-symbols-outlined">image</mat-icon> <span class="px-2">{{'pass.static' | translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-2">
                <div *ngIf="!imagesEditForm.value.images|| imagesEditForm.value?.images?.length == 0" appDragAndDrop (files)="filesDropped($event)">
                    <mat-card class="upload-section text-center justify-content-center">
                        <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                            <div class="p-2 mb-4">
                                <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                <h2 class="px-3">{{'pass.fileUploadMessage' | translate}}
                                    <button type="button" class="labelImage" (click)="triggerFileInput()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'pass.browse' | translate}}</strong></button>
                                    {{'pass.or' | translate}}
                                    <button type="button" class="labelImage" (click)="openMediaURL();"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'enterURL' | translate}}</strong></button>
                                    <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                </h2>    
                            </div>
                            <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                        </div>
                    </mat-card>
                </div>

                <div *ngIf="imagesEditForm.value?.images?.length > 0" appDragAndDrop (files)="filesDropped($event)">
                    <mat-hint>{{'dragDrop-files' | translate}}</mat-hint>
                    
                    <div class="d-flex my-3">
                        <button mat-button 
                                class="secondary-gray-button me-2"
                                (click)="triggerFileInput()"
                        >
                            <mat-icon>add_photo_alternate</mat-icon> {{'pick_image' | translate}}
                        </button>
                        <input #fileInput type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                        <button mat-button 
                                class="secondary-gray-button ms-2"
                                (click)="openMediaURL();"
                        >
                            <mat-icon>add_link</mat-icon> {{'enterURL' | translate}}
                        </button>
                    </div>
                    <mat-divider class="mb-4"></mat-divider>
                    <div class="mt-2 imageContainer">
                        <div class="row g-3">
                          <div *ngFor="let im of imagesEditForm.value.images; let index = index" class="col-6 col-md-4 col-lg-4 d-flex flex-column position-relative">
                            <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">
                            <div class="image-wrapper position-relative" style="width: 100%; height: 130px; overflow: hidden;">
                              <img *ngIf="!url[im.url]" 
                                   [src]="im.url" 
                                   class="img-fluid rounded" 
                                   style="object-fit: cover; height: 100%; width: 100%;" 
                                   onerror="this.src='./../../assets/img/default.jpg'" 
                                   alt="{{im.url}}">
                      
                              <img *ngIf="url[im.url]" 
                                   [src]="url[im.url]" 
                                   class="img-fluid rounded" 
                                   style="object-fit: cover; height: 100%; width: 100%;" 
                                   onerror="this.src='./../../assets/img/default.jpg'" 
                                   alt="{{im.url}}">
                      
                              <div class="image-buttons position-absolute top-50 start-50 translate-middle d-flex gap-2">
                                <button mat-icon-button  
                                        style="background-color: #fff; color: var(--warmOrange);" 
                                        (click)="deleteImg(im.url, index)">
                                  <span class="material-symbols-outlined">delete</span>
                                </button>
                                <button mat-icon-button
                                        style="background-color: #fff; color: var(--warmOrange);" 
                                        (click)="editImg(im, index)">
                                  <span class="material-symbols-outlined">edit</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                      
                </div>  
            </div> 
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="this.imagesEditData.mediaURL">
            <mat-expansion-panel-header>
                <mat-panel-title style="color:#595c5f;">
                    <mat-icon class="material-symbols-outlined">link</mat-icon> <span class="px-2">{{'pass.dynamic' | translate}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.mediaURL' | translate}}</mat-label>
                    <input formControlName="mediaURL" type="url" matInput placeholder="{{'pass.mediaURL' | translate}}">
                </mat-form-field>
           </div>
        </mat-expansion-panel>
    </mat-accordion>
            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "imagesEditData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelEdit();">{{'cancel' | translate}}</button>
    <!-- <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="oldData">{{'cancel' | translate}}</button> -->
    <button mat-button class="orange-button" (click)="saveEdit();">{{'apply' | translate}}</button>
</mat-dialog-actions>
