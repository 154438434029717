import { Component, OnInit } from '@angular/core';
import { StorageService } from '../service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { UploadService } from '../service/upload.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit{
  storage:any=[]
  storageMedia:any={
    items:[],
    folder: []

  }
  storageItems:any={
    items:[],
    folder: []

  }

  storagePassDefinitions:any=[]
  storageWorkTasks:any=[]
  storagePasses:any=[]
  panelOpenState = false;
  routerUrl:any
  idToShow:any
  currentRoute:any
  folder:any
  folderRoute:any
  newImage:any

  constructor(private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate: TranslateService

    ){  

      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
      this.currentRoute=this.routerUrl.value[0].path
    }

  ngOnInit():void{
    this.folderRoute=""
    this.storage = []
    this.storageItems.items = []
    this.storageMedia.items = []
    this.getDataStorage()
  }

  getDataStorage(){
    try{
      this.commentService.progressSpin.emit(true)
      this.folderRoute='Contexts/' + this.settingsService.contextId$
      this.folder = this.storageService.getStorageContentOfContextId("")
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  backTo(){
    this.router.navigate(['home',this.settingsService.contextId$,'content'])
  }

  
  openFolder(folder:string){
    switch(folder){
      case 'Items':{
        break;
      }

      case 'Media':{
        const route = 'home/'+ this.settingsService.contextId$ +'/content/media/Media'
        this.router.navigateByUrl( route, {state: this.storageMedia})
        break;
      }

      case 'WorkTasks':{
        break;
        
      }

      case 'PassDefinitions':{
        break;
      }

      case 'passes':{
        break;
      }
    }
  }


  addFileOnFolder(){
  }

  triggerFileInput() {
    document.getElementById('myfile')?.click();
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.newImage = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = async (event) => {
        if(event.target){
          const urlNew = event.target.result;
        
          if(urlNew){
              try{
                this.commentService.progressSpin.emit(true)
              
                const a = await  this.uploadService.addToStorage(this.newImage,this.folderRoute)
                setTimeout(() =>{
                  const url = this.uploadService.getAddedToStorage()
                  this.commentService.progressSpin.emit(false)
                  const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_OK")
                  this.commentService.addSnackBar.emit(message)
                  this.getDataStorage()
                }, 800);

              }catch(error){
                console.log(error)
                const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
                this.commentService.addSnackBar.emit(message)
              }
          }
        }
      }
    }
  }

  createFolderStorage(){
    const a = this.uploadService.createFolderStorage(this.folderRoute, 'newFolder')
    this.getDataStorage()
  }
}