import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { ContentType } from '../../../../../../backend/src/contentType.dto';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { deepCopy } from '../../../../../../backend/utils/object';
import { ComposedEditContentDialogComponent } from '../composed-edit-content-dialog/composed-edit-content-dialog.component';



interface composedNode{
  content: contentNode[];
}
interface contentNode {
  attributes?: attributesNode;
  type:string; //text, image, hstack, vstack, zstack, circle, rectangle, (overlay)
  children?:contentNode[];//**for : hstack, vstack, zstack */
}

interface attributesNode{
  text?:string;//*text
  url?:string;//*image
  font?:string /// *******plain, bold, italic, title, subtitle
  fontSize?:number; // in pt
  spacing?: number; // in pt/dp
  padding?:string; // {top, leading, trailing, bottom : float}
  horizontalAlignment?: string; // leading, center, trailing
  verticalAlignment?: string; // top, center, bottom
  alignment?: string; // topLeading, topCenter, topTrailing
  width?: number;
  height?:number;
  aspectRatio?: number;
  cornerRadius?: number;
  radius?:number;
  foregroundColor?:string;
  backgroundColor?:string;
  fillColor?:string;
  strokeColor?:string;
  // color?:string; // background, primary, secondary, accent, title, subtitle
}

@Component({
  selector: 'app-composed-edit',
  templateUrl: './composed-edit.component.html',
  styleUrls: ['./composed-edit.component.css']
})
export class ComposedEditComponent {
  composedEditForm!:FormGroup
  composedEditData:any
  widgetNumber:any
  oldData:any
  passId:any

  oldSchema:any
  allproperties:contentNode[]
  TREE_DATA:contentNode[]
  treeControl = new NestedTreeControl<contentNode>(node => node?.children  );
  dataSource = new MatTreeNestedDataSource<contentNode>();
  composedOptions = [
    {
      name:'Text',
      translate:'pass.composed.text',
      value:'text',
      icon:'text_format'
    },
    {
      name:'Image',
      translate:'pass.composed.image',
      value:'image',
      icon:'image'
    },
    {
      name:'HStack',
      translate:'pass.composed.hstack',
      value:'hstack',
      icon:'view_column'
    },
    {
      name:'VStack',
      translate:'pass.composed.vstack',
      value:'vstack',
      icon:'table_rows'
    },
    {
      name:'ZStack',
      translate:'pass.composed.zstack',
      value:'zstack',
      icon:'desktop_landscape'
    },
    {
      name:'Circle',
      translate:'pass.composed.circle',
      value:'circle',
      icon:'circle'
    },
    {
      name:'Rectangle',
      translate:'pass.composed.rectangle',
      value:'rectangle',
      icon:'rectangle'
    }
  ]

  composedTypesAttributes = {
    text:{
      text:'string',
      font : 'string',//
      width: 'number',
      height:'number'
    },
  
    image : {
      url: 'string',
      width: 'number',
      height:'number'
    },
  
    hstack:{
      alignment:'string', spacing: 'number', width: 'number',height:'number'
    },
    vstack:{
      alignment:'string', spacing: 'number', width: 'number',height:'number'
    },
    zstack:{
      width: 'number',height: 'number'
    },
    circle:{width:  'number',height: 'number'},
    rectangle:{width: 'number',height:'number'}
  }

  composedTypes = {
    text:{
      attributes: { text:undefined, font : undefined, width: undefined, height:undefined },
      type:"text"
    },
    image : {
      attributes: { url: undefined, width: undefined, height:undefined },
      type:"image"
    },
    hstack:{
      type:"hstack",
      children: [],
      attributes: { alignment:undefined, spacing: undefined, width: undefined,height:undefined }
    },
    vstack:{
      children: [],
      attributes: { alignment: undefined, spacing: undefined, width: undefined, height: undefined },
      type:"vstack"
    },
    zstack:{
      children: [],
      attributes:{ width: undefined,height: undefined },
      type:"zstack"
    },
    circle:{
      attributes: {width:  undefined,height: undefined },
      type:"circle"
    },
    rectangle:{
      attributes:{ width: undefined,height:undefined },
      type:"rectangle"
    }
  }
  constructor(public dialogRef: MatDialogRef<ComposedEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private fb: FormBuilder){
      dialogRef.disableClose=true
  }


  ngOnInit(): void {
    console.log(this.data)
    this.composedEditData=this.data.values
    this.oldData=deepCopy(this.data.values)
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']

    this.composedEditForm = this.fb.group({
      id:[],
      type:[],
      subtitle:[],
      title:[],
      content:[],
      name:[],
      filter:[]
    })

    const {id, type,filter, subtitle, title,content, name } = this.composedEditData
    this.composedEditForm.patchValue({
      id: id ? id : undefined , 
      type: type ? type : undefined, 
      subtitle: subtitle ? subtitle : undefined, 
      title: title ? title : undefined, 
      name: name ? name : undefined,
      filter: filter ? filter :undefined
    })
    this.allproperties=content
    if(!content || content?.length ==0)
    this.allproperties=[]

    this.dataSource.data = this.allproperties
  }

  refreshTree(){
    let _data = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = _data;
    this.allproperties = this.dataSource.data
    console.log('dataSource',this.dataSource.data)
    console.log('allproperties: ', this.allproperties)
    }

  hasChild = (_: number, node: contentNode) => !!node.children && node.children?.length >= 0;
  isArrayType = (_: number, node: contentNode) => node.type=='vstack' || node.type=='hstack' || node.type=='zstack' || node.children;


  returnChangesFilters($event){
    console.log($event)
    this.composedEditForm.patchValue({filter: $event.newFilters})
  }

  async saveChanges(){
    this.commentService.progressSpin.emit(true)

    this.dialogRef.close([this.composedEditForm.value, true]);
    this.commentService.progressSpin.emit(false)      
  }


  addChildrenNode(node, composed){
    if(node.children){
      node.children.push(this.composedTypes[composed.value])
    }else{
      node.children = [ this.composedTypes[composed.value] ]
    }
    this.composedEditForm.patchValue({content: this.allproperties})
    this.dataSource.data = this.allproperties
    this.refreshTree()
  }


  addContentNode(composed){
    console.log(this.composedTypes[composed.value])
    if(this.allproperties?.length!=0){
      this.allproperties.push(this.composedTypes[composed.value])
    }else{
      this.allproperties = [ this.composedTypes[composed.value] ]
    }
    
    this.composedEditForm.patchValue({content: this.allproperties})
    this.dataSource.data = this.allproperties
    this.refreshTree()
  }

  getAncestors(array, type) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].type === type) {
          return [array[i]];
        }
        const a = this.getAncestors(array[i].children, type);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  onLeafNodeClick(node: contentNode) {
    const ancestors = this.getAncestors(this.dataSource.data, node.type);
    let indexBreadcrumbs=""
    let breadcrumbs = "";
    ancestors.forEach((ancestor,index) => {
      breadcrumbs += `${ancestor.type}/`;
      indexBreadcrumbs += `${index}/`;
    });

    let all={ancestors, breadcrumbs}
    return all 
  }

  deleteAllContent(){
    this.allproperties = []
    this.composedEditForm.patchValue({content: this.allproperties})
    this.dataSource.data = this.allproperties
    this.refreshTree()
  }

  editNodeAttributes(node, index){
    console.log(node, index)
    let dialogRef= this.dialog.open(ComposedEditContentDialogComponent, {
      data: { 
        type:"editNodeAttribute",
        values:node,
        attributesTypes:this.composedTypesAttributes[node.type],
        title: 'pass.composed.' + node.type
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        // let properties = node.properties
        // if(properties){
        //   if(properties.length==0){
        //     properties.push(result[1])
        //   }else{
        //     properties.push(result[1])
        //   }
        // }else{
        //   properties=[]
        //   properties.push(result[1])
        // }
        node.attributes = result[1]
        this.composedEditForm.patchValue({content: this.allproperties})
        this.dataSource.data = this.allproperties
        this.refreshTree()

      }

    });
  }

}
