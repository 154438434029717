<section class="topNavbar"  >
    <button mat-button style="color:var(--warmOrange);" (click)="backTo();">
        <span class="material-symbols-outlined" style="font-size: 10px;">
            arrow_back_ios
        </span>
        <span> {{displayName}} </span>
    </button>
</section>

<div class="pt-2 pb-3">
    <div class="ps-4 pe-4 d-flex flex-wrap align-items-center" id="title-delete">

        <div class="d-flex flex-nowrap flex-row justify-content-center" id="title-tridy">
            <span class="fs-4">{{'contentTypes.content_schema' | translate}}</span>
        </div>

    </div>
    
    <section class="mt-2 mb-3 m-auto" id="section" >
        <div id="tree-title-section" class="d-flex flex-row justify-content-between align-items-center">
            <span class="text-muted text-uppercase">{{'contentTypes.key_value' | translate }}</span>

            <button mat-icon-button style="color:var(--warmOrange);" (click)="addProperty();"> 
                <span class="material-symbols-outlined">
                    add_circle
                </span>
            </button>
        </div>


       <div id="tree-section">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree w-100 m-auto shadow-sm ">
                <mat-tree-node *matTreeNodeDef="let node;let index=index" matTreeNodeToggle class=" border-bottom" (click)="onLeafNodeClick(node)">

                    <div class="d-flex flex-row flex-wrap justify-content-start align-items-center ps-2">
                        <mat-icon *ngIf="node.type=='string'" style="color:var(--warmOrange);">font_download</mat-icon>
                        <mat-icon *ngIf="node.type=='number'" style="color:var(--warmOrange);">tag</mat-icon>
                        <mat-icon *ngIf="node.type=='array'" style="color:var(--warmOrange);">list</mat-icon>
                        <mat-icon *ngIf="node.type=='object'" style="color:var(--warmOrange);">calendar_view_month</mat-icon>
                        <mat-icon *ngIf="node.type=='boolean'" style="color:var(--warmOrange);">toggle_on</mat-icon>
                        <mat-icon *ngIf="node.type=='date'" style="color:var(--warmOrange);">calendar_month</mat-icon>

                        <span class="p-1" style="color:black;" >{{node.key}}</span>
                    </div>
                </mat-tree-node>

                <mat-nested-tree-node *matTreeNodeDef="let node;let index=index; when: hasChild" >
                    <div class="mat-tree-node border-bottom ">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.key">
                            <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>

                        <div *ngIf="node.type!='object'" class="d-flex flex-row flex-wrap justify-content-start align-items-center ps-2">
                            <mat-icon *ngIf="node.type=='string'" style="color:var(--warmOrange);">font_download</mat-icon>
                            <mat-icon *ngIf="node.type=='number'" style="color:var(--warmOrange);">tag</mat-icon>
                            <mat-icon *ngIf="node.type=='array'" style="color:var(--warmOrange);">list</mat-icon>
                            <mat-icon *ngIf="node.type=='object'" style="color:var(--warmOrange);">calendar_view_month</mat-icon>
                            <mat-icon *ngIf="node.type=='boolean'" style="color:var(--warmOrange);">toggle_on</mat-icon>
                            <mat-icon *ngIf="node.type=='date'" style="color:var(--warmOrange);">calendar_month</mat-icon>

                            <span class="p-1" style="color:black;" >{{node.key}}</span>
                        </div>

                        <div *ngIf="node.type=='object'" class="d-flex flex-row flex-wrap justify-content-between align-items-center ps-2">
                            <div  class="d-flex flex-row justify-content-start align-items-center ps-2">
                                <mat-icon *ngIf="node.type=='string'" style="color:var(--warmOrange);">font_download</mat-icon>
                                <mat-icon *ngIf="node.type=='number'" style="color:var(--warmOrange);">tag</mat-icon>
                                <mat-icon *ngIf="node.type=='array'" style="color:var(--warmOrange);">list</mat-icon>
                                <mat-icon *ngIf="node.type=='object'" style="color:var(--warmOrange);">calendar_view_month</mat-icon>
                                <mat-icon *ngIf="node.type=='boolean'" style="color:var(--warmOrange);">toggle_on</mat-icon>
                                <mat-icon *ngIf="node.type=='date'" style="color:var(--warmOrange);">calendar_month</mat-icon>

                                <span class="p-1" style="color:black;" >{{node.key}}</span>
                            </div>

                            <button mat-icon-button class="ms-5" style="color:var(--warmOrange);" (click)="addNewProperty(node,index);"> 
                                <span class="material-symbols-outlined">
                                    add_circle
                                </span>
                            </button>
                        </div>
                    </div>

                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                        role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
                </mat-nested-tree-node>

                <mat-nested-tree-node *matTreeNodeDef="let node;let index=index; when: isArrayType" >
                    <div class="mat-tree-node border-bottom">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.key">
                            <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <div class="d-flex flex-row justify-content-start flex-wrap align-items-center ps-2">
                            <mat-icon *ngIf="node.type=='string'" style="color:var(--warmOrange);">font_download</mat-icon>
                            <mat-icon *ngIf="node.type=='number'" style="color:var(--warmOrange);">tag</mat-icon>
                            <mat-icon *ngIf="node.type=='array'" style="color:var(--warmOrange);">list</mat-icon>
                            <mat-icon *ngIf="node.type=='object'" style="color:var(--warmOrange);">calendar_view_month</mat-icon>
                            <mat-icon *ngIf="node.type=='boolean'" style="color:var(--warmOrange);">toggle_on</mat-icon>
                            <mat-icon *ngIf="node.type=='date'" style="color:var(--warmOrange);">calendar_month</mat-icon>

                            <span class="p-1" style="color:black;" >{{node.key}}</span>
                        </div>

                    </div>

                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                        role="group">
                        <ng-container matTreeNodeOutlet>
                            <div class="mat-tree-node border-bottom">
                                <div class="d-flex flex-row flex-wrap justify-content-start align-items-center ps-2">
                                    <mat-icon *ngIf="!node.items" style="color:var(--warmOrange);">font_download</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='string'" style="color:var(--warmOrange);">font_download</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='number'" style="color:var(--warmOrange);">tag</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='array'" style="color:var(--warmOrange);">list</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='object'" style="color:var(--warmOrange);">calendar_view_month</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='boolean'" style="color:var(--warmOrange);">toggle_on</mat-icon>
                                    <mat-icon *ngIf="node.items && node.items['type']=='date'" style="color:var(--warmOrange);">calendar_month</mat-icon>

                                    <span class="p-1" style="color:black;" >List-Items</span>
                                    
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-nested-tree-node>
                
            </mat-tree>   
       </div>

        
        <div class="p-4 pt-2 d-flex flex-wrap flex-row" id="button-display">
            <button mat-button style="color:var(--warmOrange); width: 40%;" class="shadow-sm" (click)="onCancel();">
                {{'cancel' | translate}}
            </button>
        
            <button mat-button style="width: 40%;" class="shadow-sm orange-button" (click)="onSave();">
                {{'save' | translate}}
            </button>
        </div>

    </section>

</div>
