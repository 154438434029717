import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-model3d-edit',
  templateUrl: './model3d-edit.component.html',
  styleUrls: ['./model3d-edit.component.css']
})
export class Model3dEditComponent implements OnInit {
  model3dEditData:any
  model3dEditForm!:FormGroup
  oldData:any
  url:any
  nweModelToRender:any
  languagesList = languagesList
  defaultLanguage:string

  @ViewChild('canvasPhone') private canvasRef!: ElementRef;
  @ViewChild('canvasEdit') private canvasRefEdit!: ElementRef;
  modelTorender: any;


  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  private get canvasEdit(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  constructor(public dialogRef: MatDialogRef<Model3dEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder){

      dialogRef.disableClose = true;

  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.oldData= this.data.values
    this.model3dEditData= this.data.values
    this.url=this.model3dEditData.url

    this.model3dEditForm = this.fb.group({
      id:[this.model3dEditData.id],
      type:[this.model3dEditData.type],
      url:[this.model3dEditData.url],
      name:[]
    })

    this.model3dEditForm.patchValue({
      name: this.model3dEditData.name ? this.model3dEditData.name : undefined
    })

    this.url=this.model3dEditForm.value.url

    this.model3dEditForm.valueChanges.subscribe((value:any)=>{
      this.url=this.model3dEditForm.value.url
      this.modelTorender= this.url
      if(this.url)
        this.createNew()
    })
  }


  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        if(event.target){
          const urlNew = event.target.result;
       
          if(urlNew){
            this.url=urlNew
         
            this.model3dEditForm.patchValue({
              url:urlNew
            })
          }
        }
      }
    }
  }

  create(){
    const scene = new THREE.Scene()

    const loader = new GLTFLoader()
    var model3d!:any;
    this.modelTorender=this.url
    this.modelTorender= './../../assets/models/Case_ORCA_Open.glb'
    loader.load(this.modelTorender, function(glb){
      scene.background = new THREE.Color(0xffffff)
      model3d=glb.scene

      const root= glb.scene
      scene.add(root)
    }, function (xhr) {
    }, function (error){
    })

    const light = new THREE.DirectionalLight(0xffffff,1)
    light.position.set(2,2,5)
    scene.add(light)


    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    const camera = new THREE.PerspectiveCamera(75, 500/500, 0.1, 100)
    //set camera position
    camera.position.set(0,5,10)
    camera.rotation.set(-0.4,0,0)
    scene.add(camera)
    
    const renderer = new THREE.WebGL1Renderer({
      canvas: this.canvas
    })


    renderer.setSize(this.canvas.clientWidth, this.canvas.clientWidth);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    renderer.shadowMap.enabled=true
    
    let component: Model3dEditComponent = this;
    (function render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());

    (function animate() {
      requestAnimationFrame(animate);
      model3d.rotation.y += 0.01

      renderer.render(scene, camera);
    }());
  }


  createNew(){
    this.canvas.style.display=""
    
    const scene = new THREE.Scene()

    const loader = new GLTFLoader()
    var model3d!:any;
    this.modelTorender=this.url

    loader.load(this.modelTorender, function(glb){
      scene.background = new THREE.Color(0xffffff)
      model3d=glb.scene
      const root= glb.scene
      scene.add(root)
    }, function (xhr) {
    }, function (error){
    })

    const light = new THREE.DirectionalLight(0xffffff,1)
    light.position.set(2,2,5)
    scene.add(light)


    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    const camera = new THREE.PerspectiveCamera(75, 500/500, 0.1, 100)
    // const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1, 100)

    //set camera position
    camera.position.set(0,5,10)
    camera.rotation.set(-0.4,0,0)
    scene.add(camera)
    
    const renderer = new THREE.WebGL1Renderer({
      canvas: this.canvas
    })

    // renderer.setSize(370, 370)
    renderer.setSize(this.canvas.clientWidth, this.canvas.clientWidth);
    // renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    renderer.shadowMap.enabled=true
    
    let component: Model3dEditComponent = this;
    (function render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());

    // (function animate() {
    //   requestAnimationFrame(animate);
    //   model3d.rotation.y += 0.01

    //   renderer.render(scene, camera);
    // }());
  }

  deleteModel(){
    this.url=""
    this.model3dEditForm.patchValue({
      url:this.url
    })

    this.canvas.style.display="none"
  }

  ngAfterViewInit(): void {
    this.create()
  }

  onCancelEdit(){
    this.dialogRef.close([this.oldData, false])
  }

  onSaveEdit(){
    this.dialogRef.close([this.model3dEditForm.value, true])
  }
}
