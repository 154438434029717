import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-confirmation-dialog',
  templateUrl: './update-confirmation-dialog.component.html',
  styleUrls: ['./update-confirmation-dialog.component.css']
})
export class UpdateConfirmationDialogComponent {
  title:any
  message:any
  confrmQuestion:any
  tridyCount:any

  constructor(
    public dialogRef: MatDialogRef<UpdateConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    if(this.data['type'] == 'passTemplate'){
      this.title = this.data['title']
      this.message = this.data['message']
      this.confrmQuestion = this.data['question']
      this.tridyCount = this.data['tridyCount']
      this.message.replace( 'xxx', this.tridyCount)
    }
  }

  onCancel(){
    this.dialogRef.close([false])
  }

  onSave(){
    this.dialogRef.close([true])
  }
}

