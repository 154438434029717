<section class="container">
    <div class="row mt-4">
        <div class="col-12">

            <div class="topNavbar mb-1"> 
                <button mat-button class="m-1" style="color:var(--warmOrange);" (click)="backTo();"> 
                    <mat-icon>arrow_back_ios_new</mat-icon> {{'goBack' | translate}}
                </button>
                <span class="fs-5">Details</span>
                <button mat-button class="m-1 secondary-gray-button" (click)="QRoptions()"> 
                    <mat-icon> qr_code_2_add</mat-icon> Sample QR
                </button>
                <!-- <button mat-fab extended style="background-color:white; color:var(--accentGlobal);" id="button-tridy" (click) = "deleteTridy();">
                    <mat-icon>delete</mat-icon>
                    {{'tridys.delete' | translate}}
                </button> -->
            </div>

            <div class="my-4 section-title">
                <mat-icon class="custom-card-icon material-symbols-outlined">info</mat-icon>{{'tridys.tridy_info' | translate}}
            </div>

            <mat-card class="mb-2">
                <mat-card-content class="my-2">
                    <span class="font-weight-bold">ID: </span> <span style="opacity:0.65;padding-right: 5px;">{{idToShow}}</span><mat-icon matTooltip="{{'copyToClipboard' | translate}}" [cdkCopyToClipboard]="idToShow" class="copy_id">content_copy</mat-icon><br>
                    <span class="font-weight-bold" *ngIf="tridyData && tridyData.creationDate">{{'creation_date' | translate}}: </span> <span  *ngIf="tridyData && tridyData.creationDate" style="opacity:0.65;">{{tridyData.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm'}}</span>
                </mat-card-content>
            </mat-card>

            <div class="my-4 section-title">
                <mat-icon class="custom-card-icon material-symbols-outlined">task</mat-icon>{{'tridys.finished' | translate}}:
            </div>

            <mat-card class="mb-2">
                <mat-card-content class="my-2">
                    <!--<mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'contentTypes.contentType' | translate}}</mat-label>
                        <mat-select [(value)]="contentType">
                            <mat-option *ngFor="let content of allContentTypes" [value]="content.id" disabled>
                            {{content.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
            
                    <div *ngIf="finishedTasks">
                        <div *ngFor="let ftask of finishedTasks;" class="item-content my-1" routerLink="finishedTasks/{{ftask.id}}">
                            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                <div class="d-flex flex-column flex-nowrap">
                                    <span style="color:var(--warmOrange);">{{ftask.title}}</span>
                                    <div>
                                        <span class="text-muted">{{ftask.creationDate.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>  
                                        <span *ngIf="ftask.timeStamp" class="text-muted">  - {{ftask.timeStamp.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>
                                    </div>
                                </div>
                                <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </div>
            
                    <div class="p-3" *ngIf="finishedTasks.length==0">
                        <span class="text-muted">{{'tridys.no_finishedTasks' | translate}}</span>
                    </div>
                </mat-card-content>
            </mat-card>


            <div *ngIf="incoming && incoming.length!=0">

                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">input</mat-icon>{{'workTask.incoming_tridys' | translate}}:
                </div>
                
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                
                        <div> 
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let incom of incoming; let index">
                                    <div class="d-flex flex-row justify-content-between align-items-center" > <!--(click)="redirectTo(incom)"-->
                                        <div class="d-flex flex-column">
                                            <span class="text-muted">{{getContentTypeIdData(incom.contentTypeId, 'displayName')}}</span>
                                            <span>{{incom.tridyId}}</span>
                    
                                            <div class="d-flex flex-column text-muted">
                                                <span>{{isDateTime(getTRIDYIdData(incom.contentTypeId, 'creationDate')) }} </span>
                                                <span>{{isDateTime(getTRIDYIdData(incom.contentTypeId, 'timeStamp')) }}</span>
                                            </div>
                                        </div>
                                        <!-- <span class="material-symbols-outlined">arrow_forward_ios</span> -->
                                    </div>
                                </li>
                            </ul>
                        </div>
     
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="outgoing && outgoing.length!=0">

                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">output</mat-icon>{{'workTask.outgoing_tridys' | translate}}:
                </div>
                
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                        <div> 
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let incom of outgoing; let index">
                                    <div class="d-flex flex-row justify-content-between align-items-center" > <!--(click)="redirectTo(incom)"-->
                                        <div class="d-flex flex-column">
                                            <span class="text-muted">{{getContentTypeIdData(incom.contentTypeId, 'displayName')}}</span>
                                            <span>{{incom.tridyId}}</span>
                    
                                            <div class="d-flex flex-column text-muted">
                                                <span>{{isDateTime(getTRIDYIdData(incom.contentTypeId, 'creationDate')) }} </span>
                                                <span>{{isDateTime(getTRIDYIdData(incom.contentTypeId, 'timeStamp')) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="my-4 section-title">
                <mat-icon class="custom-card-icon material-symbols-outlined">description</mat-icon>{{'tridys.payloadData' | translate}}:
            </div>
            <mat-card class="mb-4">
                <mat-card-content class="p-3">
                    <div class="p-3" *ngIf="allpayload.length==0">
                        <span class="text-muted">{{'tridys.no_payload_data' | translate}}</span>
                    </div>
                    <div *ngFor="let payload of allpayload; let last = last">
                        <div *ngIf="!isObject(payload[1])" class="d-flex justify-content-between">
                            <!-- <div class="input-group mt-1 mb-1 w-100" *ngIf="payload[0]!='name' && payload[0]!='description' && payload[0]!='tagline' && payload[0]!='array' && payload[0]!='map'"> -->
                            <div class="py-2 px-4 key-styles"><span class="font-weight-bold">{{payload[0]}}</span></div>
                            <div class="py-2 px-4 value-styles">
                                <span *ngIf="!payload[1].nanoseconds && !payload[1].seconds">{{payload[1]}}</span>
                                <span *ngIf="payload[1].nanoseconds || payload[1].seconds">{{payload[1].toDate() | date: 'dd.MM.yyyy, HH:mm' }}</span>
                            </div>
                        </div>
                        <div *ngIf="isObject(payload[1]) && payload[1].contentType" class="d-flex justify-content-between">
                            <div class="py-2 px-4 key-styles">
                                <span class="font-weight-bold">{{payload[0]}}</span> <span class="material-symbols-outlined text-muted" style="vertical-align: middle;font-size: 20px;">attach_file</span>
                            </div>
                            <div class="py-2 px-4 value-styles">
                                <div>{{getObjectData(payload[1])}}</div>
                                <div *ngIf="mediaData && media && media[payload[0]]">
                                    <a *ngIf="urlData[payload[0]]" href="{{urlData[payload[0]]}}" target="_blank"><img width="100" src="{{urlData[payload[0]]}}" class="p-2"></a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isObject(payload[1]) && !payload[1].contentType" class="d-flex justify-content-between">
                            <div class="py-2 px-4 key-styles"><span class="font-weight-bold">{{payload[0]}}</span></div>
                            <span class="py-2 px-4 value-styles" *ngIf="!payload[1]"> - </span>
                            <span class="py-2 px-4 value-styles" *ngIf="payload[1] && !payload[1].nanoseconds && !payload[1].seconds"> * </span>
                            <span class="py-2 px-4 value-styles" *ngIf="payload[1] && payload[1].nanoseconds || payload[1].seconds"> {{payload[1].toDate() | date: 'dd.MM.yyyy, HH:mm' }} </span>
                        </div>
                        <mat-divider *ngIf="!last"></mat-divider>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="mb-4">
                <mat-accordion *ngIf="tridyArray" class="w-100 mt-4">
                
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'tridys.array' | translate}}
                        </mat-panel-title>
                        <mat-panel-description>
                            
                        </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p *ngFor="let array of tridyArray">{{array}}</p>
                    </mat-expansion-panel>
                </mat-accordion>
        
                <mat-accordion *ngIf="tridyMap" class="w-100 mt-2">
                    
                    <mat-expansion-panel (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'tridys.map' | translate}}
                        </mat-panel-title>
                        <mat-panel-description>
                            
                        </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p *ngFor="let map of tridyMap | keyvalue"> {{map.key}} : {{map.value}}</p>
                    </mat-expansion-panel>
                </mat-accordion>  
            </div>
        </div>
    </div>
</section>




