
<section class="map-marker-widget m-1">
    <google-map  height="250px"
                width="100%"
                [center]="center"
                [zoom]="zoom"
                [mapTypeId]="mapTypeId"
                [options]="{disableDefaultUI: true}">
                <ng-container class="map-widget-corner" *ngFor="let markerPosition of markerPositions; let i=index" style="border-radius: var(--cornerRadius);">
                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='point' && !markerPosition['dynamic'] ">
                        <map-marker #marker="mapMarker"  
                                [position]="markerPosition"
                                [options]="markerOptions" 
                                [label]="markerPosition['badge']" 
                                (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                        </map-marker>
                        
                        <map-info-window #infoWindow="mapInfoWindow"> 
                            <div class="d-flex flex-column">
                                <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                <a *ngIf="markerPosition['linkURL'] && linkURL[markerPosition['id']]" href="{{linkURL[markerPosition['id']]}}" target="_blank" style="color:var(--warmOrange);">
                                    <!-- <a *ngIf="markerPosition['linkURL']" href="{{markerPosition['linkURL']}}" target="_blank" style="color:var(--warmOrange);"> -->
                                    <span class="material-symbols-outlined">
                                        link
                                    </span>
                                </a>
                                <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>

                                <a *ngIf="markerPosition['directions']" href="{{openMapDirection(markerPosition)}}" target="_blank" class="mt-1">
                                    <button mat-button class="secondary-gray-button w-100">
                                        <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                    </button>
                                </a>
                            </div>
                        </map-info-window>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='line' && !markerPosition['dynamic']">
                        <map-polyline [options]="actualPolygonOptions[i]">
                        </map-polyline>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon' && !markerPosition['dynamic']">
                        <ng-container>
                            <map-polygon #polygon [options]="actualPolygonOptions[i]" (click)="showArrays(polygon)">

                            </map-polygon>
                        </ng-container>
                    </div>

                    <div *ngIf="!markerPosition['type'] && !markerPosition['dynamic']">
                        <map-marker #marker="mapMarker"  
                            [position]="markerPosition"
                            [options]="markerOptions" 
                            [label]="markerPosition['badge']" 
                            (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                        </map-marker>
                        <map-info-window #infoWindow="mapInfoWindow"> 
                            <div class="d-flex flex-column">
                                <p *ngIf="markerPosition['title']">{{ markerPosition['title']}}</p>
                                <span *ngIf="markerPosition['subtitle']">{{ markerPosition['subtitle'] }} </span>
                                <a *ngIf="markerPosition['linkURL'] && linkURL[markerPosition['id']]" href="{{linkURL[markerPosition['id']]}}" target="_blank" style="color:var(--warmOrange);">
                                    <!-- <a *ngIf="markerPosition['linkURL']" href="{{markerPosition['linkURL']}}" target="_blank" style="color:var(--warmOrange);"> -->
                                    <span class="material-symbols-outlined">
                                        link
                                    </span>
                                </a>
                                <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>
                            </div>
                            
                        </map-info-window>
                    </div>

                </ng-container>

                <!-- <ng-container *ngFor="let markerPosition of markerPositions; let i=index">
                    <map-marker #marker="mapMarker"  
                                [position]="markerPosition"
                                [options]="markerOptions" 
                                [label]="markerPosition['badge']" 
                                (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                    </map-marker>

                    <map-info-window #infoWindow="mapInfoWindow"> 
                        <div class="d-flex flex-column">
                            <p *ngIf="markerPosition['title']">{{ markerPosition['title']}}</p>
                            <span *ngIf="markerPosition['subtitle']">{{ markerPosition['subtitle'] }} </span>
                            <a *ngIf="markerPosition['linkURL']" href="{{markerPosition['linkURL']}}" target="_blank" style="color:var(--warmOrange);">
                                <span class="material-symbols-outlined">
                                    link
                                </span>
                            </a>
                            <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>
                        </div>
                        
                    </map-info-window>
                </ng-container> -->

                <!-- <map-polyline [options]="polylineOptions">
                </map-polyline> -->

                <!-- <ng-container>
                    <map-polygon #polygon [options]="polygonOptions" (click)="showArrays(polygon)">

                    </map-polygon>
                </ng-container> -->
    </google-map>
</section>
    