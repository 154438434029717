export const defaultFields = {
    text:{
            "key": "newField",
            "type": "text",
            "id": "3IWd4N",
            "title": "New Field",
            "mandatory": false
    },
    longText:{
        "key": "newField",
        "title": "New Field",
        "mandatory": false,
        "id": "4iJBdT",
        "type": "longText"
    },
    number:{
        "key": "newField",
        "type": "number",
        "title": "New Field",
        "mandatory": false,
        "id": "3vLhEu"
    },
    decimal:{
        "id": "4sttKg",
        "mandatory": false,
        "key": "newField",
        "title": "New Field",
        "type": "decimal"
    },
    date:{
        "mandatory": false,
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LIsd",
        "type": "date"
    },
    time:{
        "mandatory": false,
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LI",
        "type": "time"
    },
    dateTime:{
        "mandatory": false,
        "key": "newField",
        "title": "New Field",
        "id": "2CH5LI",
        "type": "dateTime"
    },
    
    boolean:{
        "key": "newField",
        "id": "9sLfi",
        "mandatory": false,
        "type": "boolean",
        "title": "New Field"
    },
    options:{
        "mandatory": false,
        "title": "New Field",
        "type": "options",
        "id": "44Z9I5",
        "key": "newField"
    }
    
}

export const newSection = {
    fields:[],
    title: "New Section",
    id:""
}

export const defaultFieldsTypes = [
    "Display",
    "Hidden",
    "Text",
    "Long Text",
    "Number",
    "Decimal Number",
    "Currency",
    "Date/Time",
    "Switch",
    "List of options",
    "Weight",
    "Tridy",
    "Barcode / QR",
    "Document / Image"
]

export const newTaskTemplate = {
    actions:[],
    formSections:[],
    sortIndex: 999,
    tridySource:"select",
    visibleInPass:true,
    showImages:false,
    showComment:false


}