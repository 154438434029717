import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as MarkdownIt from 'markdown-it';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../backend/utils/object';
import { LocalStorageService } from 'src/app/service/local-storage-service.service';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-product-registration-phone]',
  templateUrl: './product-registration-phone.component.html',
  styleUrls: ['./product-registration-phone.component.css']
})
export class ProductRegistrationPhoneComponent {  @Input('app-product-registration-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, translations:any}>()

  registerProductData:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  widgetId:any
  linkUrl:any
  translationsPass:any
  imageUrl:any
  markdown:any
  url:any
  registeredMarkdownText:any =""
 /*  unregisteredMarkdownText:any ="" */
  dynamic=false
  languagesPassList:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private settingsService: SettingsService
    ){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path   
  }

  ngOnInit():void{ this.registerProductData=this.data[0] 
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    this.languagesPassList = this.data[4]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    this.widgetId = this.data[0].id

    if (this.registerProductData.registered.text) { 
      //this.registerProductData.text = checkDynamicContent(this.registerProductData.text)
      this.markdown = new MarkdownIt({ breaks: true });
      const registeredText = this.markdown.render(this.registerProductData.registered.text);
      this.registeredMarkdownText = registeredText;
    }

    if(this.registerProductData.image && this.registerProductData.image.url){
      try{
        const partsd = this.registerProductData.image.url.includes("${")
        if(partsd){
          this.dynamic = true
          //this.registerProductData.image.url = checkDynamicContent(this.registerProductData.image.url)
        }

        const imgUrl = new URL(this.registerProductData.image.url)
        this.url = this.registerProductData.image.url
        let url = supportUrlStructure(this.registerProductData.image.url, this.idToShow, this.settingsService.contextId$)
        if(url)
        this.imageUrl=url   
        
        // let hostname = environment.urlHostName
        // if(imgUrl.protocol=="pass:" ){  
        //   const parts = this.registerProductData.image.url.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.imageUrl=firebaseUrl
        //   }else{
        //     const newparts = this.registerProductData.image.url.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.imageUrl=firebaseUrl
        //     }
        //   }
        // }
        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.registerProductData.image.url.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })

        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.url=firebaseUrl
        // }
      }catch(errro){
      }
    }


  }

}
