import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-shopify-phone]',
  templateUrl: './shopify-phone.component.html',
  styleUrls: ['./shopify-phone.component.css']
})
export class ShopifyPhoneComponent {
  @Input('app-shopify-phone') data:any;


}
