import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { getAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { defaultModulesAll, defaultModulesPublisher, defaultModulesTracer } from 'src/modules';

@Injectable({
  providedIn: 'root'
})
export class PagesGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router,
    private commentsService: CommentsService,
    private settingsService: SettingsService,
    private translate:TranslateService){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
        const path = state.url.split("/")
      
      if(!this.settingsService.currentUser$){
        this.settingsService.checkReloadPAage(state.url)
      }else{
        if(this.settingsService.contextModules$){
          if(this.settingsService.contextModules$.publisher  && !this.settingsService.contextModules$.tracer){
            const value = this.checkModulesPUBLISHER(path)
            return value
          }

          if(this.settingsService.contextModules$.tracer && !this.settingsService.contextModules$.publisher ){
            const value = this.checkModulesTRACER(path)
            return value
          }

          if( !this.settingsService.contextModules$.publisher && !this.settingsService.contextModules$.tracer ){
            const value = this.checkModulesALL(path)
            return value
          }

          if( this.settingsService.contextModules$.publisher && this.settingsService.contextModules$.tracer ){
            const value = this.checkModulesALL(path)
            return value
          }
        }else{
          const page = path[path.length-1]

          if(path[path.length-1]=='contentTypes' || path[path.length-2] == 'contentTypes' 
              || path[path.length-1]=='coverImages' || path[path.length-2]=='coverImages' 
              || path[path.length-2]=='passTemplate'){

            if(!this.settingsService.userRolesRights$['contentTypes'] || !this.settingsService.userRolesRights$['contentTypes']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(page=='interfaces'){
            if(!this.settingsService.userRolesRights$['interfaces'] || !this.settingsService.userRolesRights$['interfaces']['R'] ){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(path[path.length-1]=='content' || path[2] == 'content' ){
            if(!this.settingsService.userRolesRights$['content'] || !this.settingsService.userRolesRights$['content']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }
          

          if(path[path.length-1]=='tridys' || path[path.length-2] == 'tridys'  ){
            if(!this.settingsService.userRolesRights$['tridys'] || !this.settingsService.userRolesRights$['tridys']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if( path[path.length-1]=='reports' || path[path.length-2]=='reports' || path[path.length-2]=='filter'){
            if(!this.settingsService.userRolesRights$['reports'] || !this.settingsService.userRolesRights$['reports']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(path[path.length-1]=='products' || path[path.length-2] == 'products' ){

            if(!this.settingsService.userRolesRights$['products'] || !this.settingsService.userRolesRights$['products']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(path[path.length-1]=='taskTemplates' || path[path.length-2] == 'taskTemplates' || path[path.length-1]=='taskTemplatesGroups'){
            if(!this.settingsService.userRolesRights$['workTaskTemplates'] || !this.settingsService.userRolesRights$['workTaskTemplates']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(path[path.length-1]=='finishedTasks' || path[path.length-2] == 'finishedTasks' ){
            if(!this.settingsService.userRolesRights$['workTask'] || !this.settingsService.userRolesRights$['workTask']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          if(path[path.length-1]=='users' || path[path.length-2] == 'users' ){
            if(!this.settingsService.userRolesRights$['users'] || !this.settingsService.userRolesRights$['users']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

          //______________________-
          if(path[path.length-1]=='interfaces' || path[path.length-2] == 'interfaces' ){
            if(!this.settingsService.userRolesRights$['interfaces'] || !this.settingsService.userRolesRights$['interfaces']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }
          //______________________-
          if(path[path.length-1]=='accountLanguages' || path[path.length-2] == 'accountLanguages' ){
            if(!this.settingsService.userRolesRights$['context'] || !this.settingsService.userRolesRights$['context']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }
          //______________________-
          if(path[path.length-1]=='account' || path[path.length-2] == 'account' ){
            if(!this.settingsService.userRolesRights$['account'] || !this.settingsService.userRolesRights$['account']['R']){
              const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
              this.commentsService.addSnackBar.emit(message)
              return false
            }
          }

        }
      }
    return true;
  }


  checkRoleRoutes(path){
    const page = path[path.length-1]

    if(path[path.length-1]=='contentTypes' || path[path.length-2] == 'contentTypes' 
        || path[path.length-1]=='coverImages' || path[path.length-2]=='coverImages' 
        || path[path.length-2]=='passTemplate'){

      if(!this.settingsService.userRolesRights$['contentTypes'] || !this.settingsService.userRolesRights$['contentTypes']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(page=='interfaces'){
      if(!this.settingsService.userRolesRights$['interfaces'] || !this.settingsService.userRolesRights$['interfaces']['R'] ){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='content' || path[2] == 'content' ){
      if(!this.settingsService.userRolesRights$['content'] || !this.settingsService.userRolesRights$['content']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    

    if(path[path.length-1]=='tridys' || path[path.length-2] == 'tridys'  ){
      if(!this.settingsService.userRolesRights$['tridys'] || !this.settingsService.userRolesRights$['tridys']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if( path[path.length-1]=='reports' || path[path.length-2]=='reports' || path[path.length-2]=='filter'){
      if(!this.settingsService.userRolesRights$['reports'] || !this.settingsService.userRolesRights$['reports']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='products' || path[path.length-2] == 'products' ){

      if(!this.settingsService.userRolesRights$['products'] || !this.settingsService.userRolesRights$['products']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='taskTemplates' || path[path.length-2] == 'taskTemplates' || path[path.length-1]=='taskTemplatesGroups'){
      if(!this.settingsService.userRolesRights$['workTaskTemplates'] || !this.settingsService.userRolesRights$['workTaskTemplates']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='finishedTasks' || path[path.length-2] == 'finishedTasks' ){
      if(!this.settingsService.userRolesRights$['workTask'] || !this.settingsService.userRolesRights$['workTask']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='users' || path[path.length-2] == 'users' ){
      if(!this.settingsService.userRolesRights$['users'] || !this.settingsService.userRolesRights$['users']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    //______________________-
    if(path[path.length-1]=='interfaces' || path[path.length-2] == 'interfaces' ){
      if(!this.settingsService.userRolesRights$['interfaces'] || !this.settingsService.userRolesRights$['interfaces']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='accountLanguages' || path[path.length-2] == 'accountLanguages' ){
      if(!this.settingsService.userRolesRights$['account'] || !this.settingsService.userRolesRights$['account']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    if(path[path.length-1]=='account' || path[path.length-2] == 'account' ){
      if(!this.settingsService.userRolesRights$['account'] || !this.settingsService.userRolesRights$['account']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    return true

  }

  checkModulesPUBLISHER(path){
    const page = path[path.length-1]
    const moduleAccess = defaultModulesPublisher

    if(path[path.length-1]=='contentTypes' || path[path.length-2] == 'contentTypes' 
        || path[path.length-1]=='coverImages' || path[path.length-2]=='coverImages' 
        || path[path.length-2]=='passTemplate'){

      if(!moduleAccess['contentTypes'] || !moduleAccess['contentTypes']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(page=='interfaces'){
      if(!moduleAccess['interfaces'] || !moduleAccess['interfaces']['R'] ){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='content' || path[2] == 'content' ){
      if(!moduleAccess['content'] || !moduleAccess['content']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    

    if(path[path.length-1]=='tridys' || path[path.length-2] == 'tridys'  ){
      if(!moduleAccess['tridys'] || !moduleAccess['tridys']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if( path[path.length-1]=='reports' || path[path.length-2]=='reports' || path[path.length-2]=='filter'){
      if(!moduleAccess['reports'] || !moduleAccess['reports']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='products' || path[path.length-2] == 'products' ){
      if(!moduleAccess['products'] || !moduleAccess['products']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='taskTemplates' || path[path.length-2] == 'taskTemplates' || path[path.length-1]=='taskTemplatesGroups'){
      if(!moduleAccess['workTaskTemplates'] || !moduleAccess['workTaskTemplates']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='finishedTasks' || path[path.length-2] == 'finishedTasks' ){
      if(!moduleAccess['workTask'] || !moduleAccess['workTask']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='users' || path[path.length-2] == 'users' ){
      if(!moduleAccess['users'] || !moduleAccess['users']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    //______________________-
    if(path[path.length-1]=='account' || path[path.length-2] == 'account' ){
      if(!moduleAccess['account'] || !moduleAccess['account']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    if(path[path.length-1]=='accountLanguages' || path[path.length-2] == 'accountLanguages' ){
      if(!moduleAccess['accountLanguages'] || !moduleAccess['accountLanguages']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    return true

  }

  checkModulesTRACER(path){
    const page = path[path.length-1]
    const moduleAccess = defaultModulesTracer

    if(path[path.length-1]=='contentTypes' || path[path.length-2] == 'contentTypes' 
        || path[path.length-1]=='coverImages' || path[path.length-2]=='coverImages' 
        || path[path.length-2]=='passTemplate'){

      if(!moduleAccess['contentTypes'] || !moduleAccess['contentTypes']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(page=='interfaces'){
      if(!moduleAccess['interfaces'] || !moduleAccess['interfaces']['R'] ){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='content' || path[2] == 'content' ){
      if(!moduleAccess['content'] || !moduleAccess['content']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    
    if(path[path.length-1]=='tridys' || path[path.length-2] == 'tridys'  ){
      if(!moduleAccess['tridys'] || !moduleAccess['tridys']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if( path[path.length-1]=='reports' || path[path.length-2]=='reports' || path[path.length-2]=='filter'){
      if(!moduleAccess['reports'] || !moduleAccess['reports']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='products' || path[path.length-2] == 'products' ){
      if(!moduleAccess['products'] || !moduleAccess['products']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='taskTemplates' || path[path.length-2] == 'taskTemplates' || path[path.length-1]=='taskTemplatesGroups'){
      if(!moduleAccess['workTaskTemplates'] || !moduleAccess['workTaskTemplates']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='finishedTasks' || path[path.length-2] == 'finishedTasks' ){
      if(!moduleAccess['workTask'] || !moduleAccess['workTask']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='users' || path[path.length-2] == 'users' ){
      if(!moduleAccess['users'] || !moduleAccess['users']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    //______________________-
    if(path[path.length-1]=='account' || path[path.length-2] == 'account' ){
      if(!moduleAccess['account'] || !moduleAccess['account']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    if(path[path.length-1]=='accountLanguages' || path[path.length-2] == 'accountLanguages' ){
      if(!moduleAccess['accountLanguages'] || !moduleAccess['accountLanguages']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    return true

  }

  checkModulesALL(path){
    const page = path[path.length-1]
    const moduleAccess = defaultModulesAll

    if(path[path.length-1]=='contentTypes' || path[path.length-2] == 'contentTypes' 
        || path[path.length-1]=='coverImages' || path[path.length-2]=='coverImages' 
        || path[path.length-2]=='passTemplate'){

      if(!moduleAccess['contentTypes'] || !moduleAccess['contentTypes']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(page=='interfaces'){
      if(!moduleAccess['interfaces'] || !moduleAccess['interfaces']['R'] ){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='content' || path[2] == 'content' ){
      if(!moduleAccess['content'] || !moduleAccess['content']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    
    if(path[path.length-1]=='tridys' || path[path.length-2] == 'tridys'  ){
      if(!moduleAccess['tridys'] || !moduleAccess['tridys']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if( path[path.length-1]=='reports' || path[path.length-2]=='reports' || path[path.length-2]=='filter'){
      if(!moduleAccess['reports'] || !moduleAccess['reports']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='products' || path[path.length-2] == 'products' ){
      if(!moduleAccess['products'] || !moduleAccess['products']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='taskTemplates' || path[path.length-2] == 'taskTemplates' || path[path.length-1]=='taskTemplatesGroups'){
      if(!moduleAccess['workTaskTemplates'] || !moduleAccess['workTaskTemplates']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='finishedTasks' || path[path.length-2] == 'finishedTasks' ){
      if(!moduleAccess['workTask'] || !moduleAccess['workTask']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    if(path[path.length-1]=='users' || path[path.length-2] == 'users' ){
      if(!moduleAccess['users'] || !moduleAccess['users']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    //______________________-
    if(path[path.length-1]=='account' || path[path.length-2] == 'account' ){
      if(!moduleAccess['account'] || !moduleAccess['account']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }
    if(path[path.length-1]=='accountLanguages' || path[path.length-2] == 'accountLanguages' ){
      if(!moduleAccess['accountLanguages'] || !moduleAccess['accountLanguages']['R']){
        const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED")
        this.commentsService.addSnackBar.emit(message)
        return false
      }
    }

    return true

  }
  
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
