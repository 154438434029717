<!-- <p>billboard-video-phone works!</p> -->

<section>
<!--     <button *ngIf="editMode" class="edit-button-top" (click)="openDialogEdit();">
        <span class="material-symbols-outlined">
        edit_square
        </span>
    </button> -->
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px;z-index:1; position: absolute;color:white;filter: drop-shadow(2px 4px 6px black);">
        finance_chip
    </span>

    <vg-player *ngIf="!noUrl">

        <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="400">
            <source *ngIf="!url" src="{{billboardVideoData.url}}" type="video/mp4">
            <source *ngIf="url" src="{{url}}" type="video/mp4">
        </video>
    </vg-player>

    <div *ngIf="noUrl" class="d-flex justify-content-center align-items-center" style="height: 200px;">
        <p>{{'pass.preview_no_video_url' | translate}}</p>
    </div>
</section>
