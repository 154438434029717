import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-spacer-edit',
  templateUrl: './spacer-edit.component.html',
  styleUrls: ['./spacer-edit.component.css']
})
export class SpacerEditComponent {
  spacerData:any
  oldData:any
  widgetNumber:any
  spacerForm!:FormGroup
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<SpacerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService
  ){
  dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.spacerData = this.data.values
    this.widgetNumber = this.data['widget']

    this.spacerForm = this.fb.group({
      type:[],
      id:[],
      divider:[],
      spacing: [],
      name:[],
      filter:[]
    })

    const {type, filter,id, divider, spacing,name } = this.spacerData
    this.spacerForm.patchValue({
      type: type ? type : undefined,
      id: id ? id : undefined,
      divider: divider ? divider : false,
      spacing: spacing ? spacing : undefined,
      name: name ? name : undefined,
      filter:filter ? filter : undefined
    })
  }

  dialogCancel(){
    this.dialogRef.close([this.oldData,false]);
  }

  dialogSave(){
    this.commentService.progressSpin.emit(true)

    this.dialogRef.close([this.spacerForm.value, true]);
    this.commentService.progressSpin.emit(false)

  }

  returnChangesFilters($event){
    console.log($event)
    this.spacerForm.patchValue({filter: $event.newFilters})
  }

  
}
