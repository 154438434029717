import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';
import {Functions, httpsCallable} from "@angular/fire/functions";
import { CommentsService } from './comments.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class AdvancedExperiencesService {
  existingUrlPrefixes:any=[]
  existingCoverImages:any
  constructor(private settingsService: SettingsService,
    private firestore: AngularFirestore,
    private functions: Functions,
    private commentService:CommentsService,
    private translate:TranslateService
    ) { }

    deleteAdvancedExperience(advancedId):Promise<void>{
      return new Promise(async (resolve, reject) => {
        try {
          const d = this.firestore
          .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES)
          .doc(advancedId)
          .delete()
          resolve(d)
        } catch (error) {
          reject(error)  
          console.log(error)      
        } 
      })
    }


    createAdvancedExperienceFirestore(data){
      return new Promise(async (resolve, reject) => {
        try{
    
          const AdvExpRef = this.firestore
          .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES).doc().ref
    
          const newId = AdvExpRef.id
          data['creationDate'] = new Date()
          data['timeStamp'] = new Date()
         
          this.firestore
          .collection(NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES).doc(newId)
          .set({...data})
    
          resolve(newId)
        }catch(error){
          console.log(error)
        }
      }) 
    }

    getActualUrlPrefixes(){
      this.commentService.progressSpin.emit(true)
      const createExperience = httpsCallable(this.functions, 'appStoreConnect-requestExperiences');
      createExperience( ).then((result) => {
          this.existingCoverImages = result.data['data']
          const data = result.data;
          const sanitizedMessage = data;

          result.data['data'].forEach(element => {
            this.existingUrlPrefixes.push(element.link)
          });

          this.commentService.progressSpin.emit(false)
        })
        .catch((error) => {
          this.commentService.progressSpin.emit(false)
          console.log(error)
          const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
          this.commentService.addSnackBar.emit(message)
          this.commentService.addSnackBar.emit(error)
        });
  }

}
