import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from 'src/app/service/settings.service';
import { TaskTemplateFieldNewOptionDialogComponent } from 'src/app/task-template-field-new-option-dialog/task-template-field-new-option-dialog.component';
import { removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';

@Component({
  selector: 'app-form-edit-field-dialog',
  templateUrl: './form-edit-field-dialog.component.html',
  styleUrls: ['./form-edit-field-dialog.component.css']
})
export class FormEditFieldDialogComponent implements OnInit{
  fieldForm!:FormGroup
  fieldData:any
  
  constructor(
    public dialogRef: MatDialogRef<FormEditFieldDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private translateService : TranslateService,
    private commentService: CommentsService

  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.fieldData = this.data.values
    this.fieldForm = this.fb.group({
      id:[],
      key:[, Validators.required],
      mandatory:[],  
      title:[],
      type:[],
      options:[],

    })
    const {type, id, title, key, mandatory, options} = this.fieldData

    this.fieldForm.patchValue({
      id: id ? id : undefined,
      type: type ? type : undefined,
      title: title ? title : undefined,
      key: key ? key : undefined,
      mandatory: mandatory ? mandatory : false,
      options: options ? options : [],
    })

    this.fieldForm.get("title").valueChanges.subscribe( newtitlevalue =>{
        this.fieldForm.get("key").setValue( newtitlevalue)
    })

  }

  addNewOptionToField(){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: ''
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options = this.fieldForm.value.options
        let value = result[0]
        if(!value){
          value = ''
        }
        options.push(value)
        this.fieldForm.patchValue({...options})
      }
    });
  }

  editOptionToField(optionSelected, indexSelected){
    let dialogRef = this.dialog.open(TaskTemplateFieldNewOptionDialogComponent, {
      data: { 
         values: optionSelected,
         index: indexSelected
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        let options =[]
        this.fieldForm.value.options.forEach( (option, index)=> {
          if(index==indexSelected){
            options.push(result[0])
          }else{
            options.push(option)
          }
        })
        this.fieldForm.patchValue({options: options})
      }
    });
  }

  deleteOption(optionSelected, indexSelected){
    let options = []
    this.fieldForm.value.options.forEach((option, index) => {
      if(index!=indexSelected)
      options.push(option)
    });
    this.fieldForm.patchValue({options: options})
  }

  onSave(){
    if(this.fieldForm.value.key){
      let data
      if(this.fieldForm.value.type=='options'){
        data = this.fieldForm.value
      }else{
        const {type, id, title, key, mandatory} = this.fieldForm.value
        data = {type, id, title, key, mandatory}
      }
      this.dialogRef.close([ data, true])
    }else{
      const message = this.translateService.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentService.addSnackBar.emit(message)
    }
  }
  onCancel(){
    this.dialogRef.close([this.fieldData, false])
  }
}
