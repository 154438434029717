<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="schemaPropertyForm" class="m-1">
                <div class="input-group" >
                    <mat-form-field appearance="outline" class="w-100" >
                      <mat-label>{{'content.type' | translate}} </mat-label>
                      <mat-select formControlName="type" class="input-group" required>
                        <mat-option *ngFor="let option of typesOptions; let index=index" value="{{option.type}}">
                            <mat-icon style="color:var(--warmOrange);">{{option.mat_icon}}</mat-icon>
                            <div class="d-flex align-items-center">
                                <!-- <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                    {{option.icon}}
                                </span> -->
                            
                                <span class="ps-3"> {{option.name}}</span>
                
                            </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="input-group" *ngIf="schemaPropertyForm.value.type=='array'" >
                    <mat-form-field appearance="outline" class="w-100" >
                      <mat-label>{{'content.listItemsType' | translate}} </mat-label>
                      <mat-select formControlName="listItemsType" class="input-group" >
                        <mat-option *ngFor="let option of listTypesOptions; let index=index" value="{{option.type}}">
                            <mat-icon style="color:var(--warmOrange);">{{option.mat_icon}}</mat-icon>
                            <div class="d-flex align-items-center">
                                <!-- <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                    {{option.icon}}
                                </span> -->
                            
                                <span class="ps-3"> {{option.name}}</span>
                
                            </div>
                        </mat-option>
        
                      </mat-select>
                      <mat-error *ngIf="schemaPropertyForm.get('type').errors && schemaPropertyForm.get('type').errors['required']">Please select a type</mat-error>
        
                    </mat-form-field>
                </div>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'content.key' | translate}}</mat-label>
                    <input formControlName="key" type="text" matInput placeholder="Key" required>
                        <mat-error *ngIf="schemaPropertyForm.get('key').errors && schemaPropertyForm.get('key').errors['required']">Please enter a key</mat-error>
                    
                </mat-form-field>
        
                <mat-form-field  appearance="outline" class="w-100">
                    <mat-label>{{'contentTypes.description' | translate}}</mat-label>
                    <input matInput formControlName="description"  placeholder="{{'contentTypes.description' | translate}}" type="text" >
                </mat-form-field>
        
                <div class="p-3 form-control mb-1 d-flex justify-content-between">
                    <label for="value">{{'contentTypes.mandatory' | translate}}</label>
                    <mat-slide-toggle class="example-margin" formControlName="mandatory" ></mat-slide-toggle>  
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();">{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>
