<h2 mat-dialog-title>{{'contentTypes.displayTemplate' | translate}}</h2>

<mat-dialog-content class="custom-width" >

    <div class="d-flex flex-row bd-highlight mb-4 align-items-center justify-content-between">
        <button mat-button style="color:var(--warmOrange);" (click)="createReportTemplate();" > {{'contentTypes.createReportTemplate' | translate}} </button>

        <div [matTooltip]="!contentTypeUserRights_update ? ('home.tooltip' | translate) : null">
            <button mat-button style="margin-left: 8px!important;" class="m-1 shadow-sm button-task orange-button" (click)="addSection();" [disabled]="!contentTypeUserRights_update">
                {{'taskTemplates.wt_section'  | translate}}
            </button>
        </div>
    </div>
    <div class="container">
        <div class="row mt-2">
            <div class="col-xs-4 col-md-4">

                <div *ngFor="let section of allSections; let index = index;" class="pt-2 pb-2" >
                    <!-- <form>
                        <div class="section-title">{{section.title}}</div>
                        <div *ngFor="let item of section.fields; let fieldNumber = index;">
                            <div class="d-flex flex-row flex-wrap">
                                <div class="pb-4 d-flex w-100 justify-content-between " *ngIf="item.type=='boolean'">
                                    <div>{{item.title}}</div>
                                    <div class="text-align-right px-1">
                                        <mat-slide-toggle disabled="true"></mat-slide-toggle>
                                    </div>
                                </div>
                                
                                <mat-form-field appearance="outline" *ngIf="item.type=='date'" class="w-100">
                                    <mat-label>{{item.title}}</mat-label>
                                    <input matInput [matDatepicker]="picker" disabled>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                               
                                <mat-form-field appearance="outline" *ngIf="item.type=='dateTime'" class="w-100 " >
                                    <mat-label>{{item.title}}</mat-label>
                                    <input matInput type="dateTime-local" disabled/>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="item.type=='time'" class="w-100 ">
                                    <mat-label>{{item.title}}</mat-label>
                                    <input matInput type="time" disabled/>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="item.type=='longText'" class="w-100">
                                    <mat-label>{{item.title}}</mat-label>
                                    <textarea matInput disabled></textarea>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="item.type=='decimal' || item.type=='weight' || item.type=='number' || item.type=='currency'" class=" w-100">
                                    <mat-label>{{item.title}}</mat-label>
                                    <input type="number" name="{{item.title}}" matInput disabled>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="item.type=='text'" class="w-100 ">
                                    <mat-label>{{item.title}}</mat-label>
                                    <input type="text" name="{{item.title}}" matInput disabled>
                                </mat-form-field>
                            </div>
                        </div>
                    </form> -->
                    <span>{{section.title}}</span>
                    
                    <div class="list-group">
                        <div *ngFor="let item of section.fields; let fieldNumber = index;" class="list-group-item">
                            <div class="d-flex flex-row justify-content-between flex-wrap">
                                <span class="bold text-wrap text-break">{{item.title}}</span>
                                <span class="text-muted text-wrap text-break ps-2">{{item.key}}</span>
                            </div>
                            
                        </div>
                    </div>
                    
                           
                </div>
            </div>

            <div class="col-xs-8 col-md-8 mb-5">
                <!-- <div class="d-flex flex-row bd-highlight mb-4 align-items-center justify-content-end">
                    <div [matTooltip]="!contentTypeUserRights_update ? ('home.tooltip' | translate) : null">
                        <button mat-button class="m-1 shadow-sm button-task orange-button" (click)="addSection();" [disabled]="!contentTypeUserRights_update">
                            {{'taskTemplates.wt_section'  | translate}}
                        </button>
                    </div>
                </div> -->

                <div class="p-4 mt-5 text-center" style="color: #a2a2a2;" *ngIf="allSections.length==0">{{'workTask.noFields' | translate}}</div>

                <mat-accordion class="form-field-section" multi cdkDropList (cdkDropListDropped)="dropPanel($event)">
                    <mat-expansion-panel cdkDrag *ngFor="let section of allSections; let index = index;" [expanded]="index === 0">

                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span id="truncate-text">{{section.title}}</span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <button mat-icon-button [matMenuTriggerFor]="editDelete" style="color:var(--warmOrange);" (click)="$event.stopPropagation()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #editDelete="matMenu">
                                        <button mat-menu-item (click)="editTitleSection(section.title, index)" [disabled]="!contentTypeUserRights_update"><mat-icon>edit</mat-icon> {{"taskTemplates.editSectionTitle" | translate}}</button>
                                        <button mat-menu-item (click)="duplicateSection(section, index);" [disabled]="!contentTypeUserRights_update"><mat-icon>content_copy</mat-icon> {{"taskTemplates.duplicateSection" | translate}}</button>
                                        <button mat-menu-item (click)="deleteSection(section.title, index);" [disabled]="!contentTypeUserRights_update"><mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{"taskTemplates.deleteSection" | translate}}</button>
                                    </mat-menu>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <!-- CONTENT -->
                            <div class="expansion-panel-content">
                                <div cdkDropList class="shadow-sm rounded" (cdkDropListDropped)="drop($event,index)">

                                    <div cdkDrag *ngFor="let item of section.fields; let fieldNumber = index;" id="fields-list" (click)="openFieldDialog(item, fieldNumber, index);" class="d-flex"> <!-- cdkDrag -->
                                        
                                        <div class="d-flex flex-row flex-nowrap">
                                            <span *ngIf="item.type=='boolean'" class="material-symbols-outlined p-2 text-orange">toggle_on</span>
                                            <span *ngIf="item.type=='currency'" class="material-symbols-outlined p-2 text-orange">euro</span>
                                            <span *ngIf="item.type=='dateTime'" class="material-symbols-outlined p-2 text-orange">calendar_month</span>
                                            <span *ngIf="item.type=='date'" class="material-symbols-outlined p-2 text-orange">calendar_today</span>
                                            <span *ngIf="item.type=='time'" class="material-symbols-outlined p-2 text-orange">access_time</span>
                                            <span *ngIf="item.type=='decimal'" class="material-symbols-outlined p-2 text-orange">numbers</span>
                                            <span *ngIf="item.type=='longText'" class="material-symbols-outlined p-2 text-orange">subject</span>
                                            <span *ngIf="item.type=='number'" class="material-symbols-outlined p-2 text-orange">pin</span>
                                            <span *ngIf="item.type=='text'" class="material-symbols-outlined p-2 text-orange">text_format</span>
                                            <span *ngIf="item.type=='weight'" class="material-symbols-outlined p-2 text-orange">weight</span>
                            
                                            <div class="d-flex flex-column justify-content-center">
                                                <span class="text-break text-wrap">{{item.title}}</span>
                                                <span class="text-muted text-break text-wrap" style="font-size: 11px;">{{item.key}}</span>
                                            </div>
                                        </div>
                            
                                        <button mat-icon-button [disabled]="!contentTypeUserRights_update" (click)="deleteFieldOfSection(fieldNumber, index);" id="btn-delete-field" class="delete-icon">
                                            <span class="material-symbols-outlined">
                                                delete
                                            </span>
                                        </button>
                                        <!-- </div> -->

                                    </div>
                                </div>

                                <mat-action-row>
                                    <div [matTooltip]="!contentTypeUserRights_update ? ('home.tooltip' | translate) : null">
                                        <button mat-button class="m-1 shadow-sm button-task orange-button" [matMenuTriggerFor]="fieldTypes" [disabled]="!contentTypeUserRights_update">
                                            {{'workTask.addNewElement' | translate}}
                                        </button>
                                        <mat-menu #fieldTypes="matMenu" xPosition="before" yPosition="below">
                                            <button mat-menu-item *ngFor="let element of elementsOptions; let indexElement = index" (click)="addFieldToSection(element.type, index);" [disabled]="!contentTypeUserRights_update">
                                                <mat-icon class="material-symbols-outlined text-orange">{{element.icon}}</mat-icon> {{element.translations | translate}}
                                            </button>

                                        </mat-menu>
                                    </div>
                                </mat-action-row>

                            </div>

                </mat-expansion-panel>
                </mat-accordion>

            </div>

        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="onSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
