<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content class="custom-width">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <div *ngIf="settingsData.contextIdsNames.length==1" class="pe-2">
                <mat-card class="my-2">
                    <mat-card-content class="p-3">
                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                            <div><strong>{{'account' | translate}}</strong></div>
                            <div><span class="text-muted">{{selected}}</span></div>
                        </div>
                        <mat-divider class="my-3"></mat-divider>
                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                            <div><strong>{{'user' | translate}}</strong></div>
                            <div><span class="text-muted">{{settingsData.user}}</span></div>
                        </div>
                    </mat-card-content>
                </mat-card>    
            </div>

            <div *ngIf="settingsData.contextIdsNames.length>1">
                <form [formGroup]="settingsForm" class="pe-1">
                    <div class="input-group m-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'selectAccount' | translate}}</mat-label>
                            <mat-select [(value)]="selected" formControlName="id" class="input-group m-1">
                              <mat-option *ngFor="let name of settingsData.contextIdsNames" value="{{name}}">{{name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
                <mat-card class="my-2">
                    <mat-card-content class="p-3">
                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                            <div><span>{{'user' | translate}}</span></div>
                            <div><span class="text-muted">{{settingsData.user}}</span></div>
                        </div>
                    </mat-card-content>
                </mat-card>    
            </div>

        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <div *ngIf="settingsData.contextIdsNames.length>1">
        <button *ngIf="actualRoute!='/selectAccount'" mat-button style="color:var(--warmOrange);" (click)="onCancelSettings();" >
            {{'cancel' | translate}}
        </button>
        <button mat-button class="orange-button" (click)="onSaveSettings();">
            {{'register.confirm' | translate}}
        </button>
    </div>
    <div *ngIf="settingsData.contextIdsNames.length==1">
        <button *ngIf="actualRoute!='/selectAccount'" mat-button style="color:var(--warmOrange);" (click)="onCancelSettings();" >
            {{'cancel' | translate}}
        </button>

        <button mat-button class="orange-button" (click)="onSaveSettings();">
            {{'register.confirm' | translate}}
        </button>
    </div>
</mat-dialog-actions>
