import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from '../service/upload.service';
import { deepCopy } from '../../../../backend/utils/object';

@Component({
  selector: 'app-language-editor-dialog',
  templateUrl: './language-editor-dialog.component.html',
  styleUrls: ['./language-editor-dialog.component.css']
})
export class LanguageEditorDialogComponent {
  oldData:any
  languageForm:any
  columns:any
  translationsWidget:any = {}
  translations: any
  languageData:any
  panelOpenState = false;
  langtranslations:any=[]
  widgetData:any
  widgetType:any
  allLanguages:any
  valuesTranslated:any
  originalDataFrom:FormGroup
  accountLanguages:any
  languagesToShow:any = []
  firstLanguageContext:any
  languagesList = languagesList
  emptyLanguages = false
  newImage:any=[]
  passId:any
  widgetNumber:any
  passLanguages:any

  annotationMapData:any
  constructor(public dialogRef: MatDialogRef<LanguageEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private uploadService: UploadService){

      dialogRef.disableClose = true;
}

  ngOnInit():void{
    this.accountLanguages = this.settingsService.languagesContexts$
    this.firstLanguageContext = this.accountLanguages[0]
    this.columns = this.data['columns']
    this.translations = deepCopy(this.data['translations'])
    this.languageData = this.data['fields']
    this.widgetData = this.data.values
    this.widgetType = this.data['type']
    this.allLanguages = this.data['languages']
    this.valuesTranslated = deepCopy(this.data['valuesTranslated'])

    if(this.data['annotationMapData'])
      this.annotationMapData = this.data['annotationMapData']
    
    if(this.data['passId']){
      this.passId = this.data['passId']
    }

    if(this.data['widgetNumber'])
      this.widgetNumber = this.data['widgetNumber']

    if(this.allLanguages){
      this.passLanguages = this.allLanguages
      if(this.allLanguages.length==0){
        this.emptyLanguages = true
        this.firstLanguageContext = ""
      }else{
      this.firstLanguageContext = this.allLanguages[0]

      }
    }else{
      this.passLanguages = this.accountLanguages
      if(this.accountLanguages.length==0){
        this.emptyLanguages = true
        this.firstLanguageContext = ""
      }else{
        this.firstLanguageContext = this.accountLanguages[0]
      }

    }

    // this.accountLanguages.forEach( ln =>{
      this.passLanguages.forEach( ln =>{
      if(this.translations){
        if(this.translations[ln]){
          this.translationsWidget[ln]=[]
        }
      }
    })

    const keys = Object.keys(this.valuesTranslated)

    switch(this.widgetType) {
      case "text": {
        this.originalDataFrom = this.fb.group({
          id: [],
          type:[],
          title:[],
          subtitle:[],
          text:[]
        })

        this.originalDataFrom.patchValue({
          id: this.widgetData.id ? this.widgetData.id : undefined,
          type: this.widgetData.type ? this.widgetData.type : undefined,
          title: this.widgetData.title ? this.widgetData.title : undefined,
          subtitle: this.widgetData.subtitle ? this.widgetData.subtitle : undefined,
          text: this.widgetData.text ? this.widgetData.text : undefined
        })

        this.originalDataFrom.valueChanges.subscribe( value =>{
        })
        
        this.languageForm = this.fb.group({})

        if(keys.length!=0){
          this.languageForm = this.fb.group( this.valuesTranslated)
        }
        break;
      }

      case "annotatedMap": {
        this.originalDataFrom = this.fb.group({
          id: [],
          type:[],
          title:[],
          subtitle:[],
          annotations:[],
          layout:[],
          style:[]
        })

        this.originalDataFrom.patchValue({
          id: this.widgetData.id ? this.widgetData.id : undefined,
          type: this.widgetData.type ? this.widgetData.type : undefined,
          title: this.widgetData.title ? this.widgetData.title : undefined,
          subtitle: this.widgetData.subtitle ? this.widgetData.subtitle : undefined,
          annotations: this.widgetData.annotations ? this.widgetData.annotations : undefined,
          layout: this.widgetData.layout ? this.widgetData.layout : undefined,
          style: this.widgetData.style ? this.widgetData.style : undefined
        })

        this.originalDataFrom.valueChanges.subscribe( value =>{
        })
        
        this.languageForm = this.fb.group({})

        if(keys.length!=0){
          this.languageForm = this.fb.group( this.valuesTranslated)
        }
        break;
      }

      case "annotations": {
        this.originalDataFrom = this.fb.group({
          name:[],
          lat:[],
          lng:[],
          badge:[],
          coordinate:[],
          id:[],
          subtitle:[],
          title:[],
          linkURL:[],

          encodedContent:  [],
            fillColor : [],
            lineCap: [],
            lineWidth: [],
            strokeColor:  [],
            type: [] ,

            pinColor:[],
            lineColor:[],
        })

        this.originalDataFrom.patchValue({
          name:this.widgetData.name ? this.widgetData.name : undefined,
          lat:this.widgetData.lat ? this.widgetData.lat : undefined,
          lng:this.widgetData.lng ? this.widgetData.lng : undefined,
    
          badge:this.widgetData.badge ? this.widgetData.badge : undefined,
          coordinate:this.widgetData.coordinate ? this.widgetData.coordinate : undefined,
          id:this.widgetData.id ? this.widgetData.id : undefined,
          subtitle:this.widgetData.subtitle ? this.widgetData.subtitle : undefined,
          title:this.widgetData.title ? this.widgetData.title :  undefined,
          linkURL: this.widgetData.linkURL ? this.widgetData.linkURL : undefined,

          encodedContent: this.widgetData.encodedContent ? this.widgetData.encodedContent : undefined,
          fillColor: this.widgetData.fillColor ? this.widgetData.fillColor : undefined,
          lineCap: this.widgetData.lineCap ? this.widgetData.lineCap : undefined,
          lineWidth: this.widgetData.lineWidth ? this.widgetData.lineWidth : undefined,
          strokeColor: this.widgetData.strokeColor ? this.widgetData.strokeColor : undefined,
          type: this.widgetData.type ? this.widgetData.type : undefined,

          pinColor: this.widgetData.pinColor ? this.widgetData.pinColor: undefined,
          lineColor: this.widgetData.lineColor ? this.widgetData.lineColor : undefined
        })

        this.originalDataFrom.valueChanges.subscribe( value =>{
        })
        
        this.languageForm = this.fb.group({})

        if(keys.length!=0){
          this.languageForm = this.fb.group( this.valuesTranslated)
        }
        break;
      }

      case "image": {
        this.originalDataFrom = this.fb.group({
          id: [],
          type:[],
          title:[],
          subtitle:[],
          url:[],
        })

        this.originalDataFrom.patchValue({
          id: this.widgetData.id ? this.widgetData.id : undefined,
          type: this.widgetData.type ? this.widgetData.type : undefined,
          title: this.widgetData.title ? this.widgetData.title : undefined,
          subtitle: this.widgetData.subtitle ? this.widgetData.subtitle : undefined,
          url: this.widgetData.url ? this.widgetData.url : undefined,
        })

        this.originalDataFrom.valueChanges.subscribe( value =>{
        })
        
        this.languageForm = this.fb.group({})

        if(keys.length!=0){
          this.languageForm = this.fb.group( this.valuesTranslated)
        }
        break;
      }

      case "buttons" : {
        const keys = Object.keys(this.data.values)

        this.originalDataFrom = this.fb.group({
          backgroundColor:[],
          borderColor:[],
          customize:[],
          foregroundColor:[],
          id:[],
          title:[],
          url:[]
        })

        this.originalDataFrom.patchValue({
          backgroundColor:this.widgetData.backgroundColor ? this.widgetData.backgroundColor : undefined,
          borderColor:this.widgetData.borderColor ? this.widgetData.borderColor : undefined,
          customize:this.widgetData.customize ? this.widgetData.customize : undefined,
          foregroundColor:this.widgetData.foregroundColor ? this.widgetData.foregroundColor : undefined,
          id: this.widgetData.id ? this.widgetData.id : undefined,
          title: this.widgetData.title ? this.widgetData.title : undefined,
          url: this.widgetData.url ? this.widgetData.url : undefined,
        })

        this.originalDataFrom.valueChanges.subscribe( value =>{
        })
        
        this.languageForm = this.fb.group({})

        if(keys.length!=0){
          this.languageForm = this.fb.group( this.valuesTranslated)
        }
        
        break;
      }
    }
    // this.accountLanguages.forEach(k => {
      this.passLanguages.forEach(k => {
      //------------------------widgetData on first Language translations
      // if(k==this.firstLanguageContext){
      //   this.columns.forEach( name => {
      //     const langName = `${k}.${this.widgetData.id}.${name}`
      //       if(this.widgetData[name]){
      //         this.languageForm.addControl(langName, new FormControl(this.widgetData[name]))
      //       }else{
      //         this.languageForm.addControl(langName, new FormControl(''))
      //       }
          
      //   })
      // }else{
        this.columns.forEach( name => {
          let langName = `${k}.${this.widgetData.id}.${name}`
          if(this.data['widgetId']){
            langName = `${k}.${this.data['widgetId']}.${name}`
          }
          if(this.annotationMapData){
            langName = `${k}.${this.annotationMapData.id}${name}`
          }
          if(keys.length!=0){
            if(this.valuesTranslated[langName]){
              this.languageForm.addControl(langName, new FormControl(this.valuesTranslated[langName]))
            }else{
              this.languageForm.addControl(langName, new FormControl(undefined))
            }
          }else{
            this.languageForm.addControl(langName, new FormControl(undefined))
          }
        })
      // }
    });

    this.langtranslations = Object.entries(this.languageForm.value)
    this.langtranslations.forEach( value =>{
      const key = value[0].split(".")
      const keylang = key[0]
      let field = 'pass.'+key[2]
      let fieldName = this.translateService.instant(field)

      if(field ==='pass.annotations' ){
        field = 'pass.'+key[4]
        fieldName = this.translateService.instant(field)

        value.push(fieldName)
        value.push(key[4])
      }else{
        value.push(fieldName)
        value.push(key[2])
      }
      
      if(this.translationsWidget[keylang]){
        this.translationsWidget[keylang].push(value)
      }else{
        this.translationsWidget[keylang] = []
        this.translationsWidget[keylang].push(value)
      }
    })

  }

  readDocument(event:any, btnLng){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.newImage=event.target.files[0]
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = async (event) => { // called once readAsDataURL is completed
        if(event.target){
          const urlNew = event.target.result;
          try{
            const uploadURL = await this.uploadService.uploadDocument(this.passId,this.newImage, this.widgetNumber)
            if(uploadURL){
              this.languageForm.patchValue({[btnLng[0]]: uploadURL})
            }
          }catch(error){
            console.log(error)
          }
        }
      }
    }
  }
  
  onCancelEdit(){
    this.dialogRef.close([this.valuesTranslated, false])
  }

  async onSaveEdit(){
    this.commentService.progressSpin.emit(true)
    this.dialogRef.close([this.languageForm.value, true]);
    this.commentService.progressSpin.emit(false)
  }
}