import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { supportUrlStructure } from '../../../../backend/utils/object';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: '[app-map-advanced-markers]',
  templateUrl: './map-advanced-markers.component.html',
  styleUrls: ['./map-advanced-markers.component.css']
})
export class MapAdvancedMarkersComponent {
  @Input('app-map-advanced-markers') data:any;
  mapWidgetData:any
  mapWidgetId:any

  actualPolygonOptions={}
  pairsPath ={}
  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true
  }

  slidesMap = 1
  slidesMapWidgets = {}
  mapsIds={}
  widgetNumber:any
  mapWidgetId$: Subject<any> = new Subject<any>()
  zoom = 3;

  center:google.maps.LatLng
  map:google.maps.Map
  directionTranslation = this.translate.instant('pass.map.open_direction')
  idToShow:any

  constructor(private translate : TranslateService,
    private settingsService: SettingsService){
    this.directionTranslation = this.translate.instant('pass.map.open_direction')
  }

  ngOnInit():void{
    if(this.data[0]){
      this.mapWidgetData = this.data[0]
      if(this.mapWidgetData.id){
        this.mapWidgetId = this.mapWidgetData.id
      }
    }
    if(this.data[1]){
      this.mapWidgetId = this.data[1]
      this.mapWidgetId$.next(this.mapWidgetId)
      setTimeout(() => {
        this.createMap(this.mapWidgetId)        
      }, 500);
    }

    if(this.data[3])
    this.idToShow = this.data[3]
  }

  createMap(i){
    try{
      var mapProp= {
        center:new google.maps.LatLng(51.508742,-0.120850),
        zoom:5,
      };
      const id = "map-"+i
      this.map = new google.maps.Map(document.getElementById(id),mapProp);
      this.initMap(i)
    }catch(e){
      console.log(e)
    }
  }

  async initMap(i) {
    const id = "map-"+i
    // // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let prev_infowindow
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let annotations
    let title = "Map-" + i
    let mapWidgetId = this.mapWidgetData.id
    if(this.mapWidgetData.title){
      title =this.mapWidgetData.title
    }

    if(this.mapWidgetData.annotations && this.mapWidgetData.annotations.length>0 ){
      annotations = this.mapWidgetData.annotations
      position = this.mapWidgetData.annotations[0]
      if(position['coordinate']){
          position['lat']=annotations[0].coordinate[0]
          position['lng']=annotations[0].coordinate[1]
      }
    }
    let mapTypeId="roadmap" 
    if(this.mapWidgetData && this.mapWidgetData.style && this.mapWidgetData.style=='satellite'){
        mapTypeId = this.mapWidgetData.style   
    } 
    this.map = new Map(document.getElementById(id), {
      zoom: 4,
      center: position,
      mapId: "dev",
      mapTypeId: mapTypeId,
      disableDefaultUI: true,
    });

    if(annotations){
      for(let t=0; t<annotations.length; t++){
        const annotation = annotations[t]
        const annotationId = annotation.id
        const index = t

        if(annotation){
          if(annotation.type){
            if(annotation.type=="point"){
              let pos = annotation
              if(annotation.coordinate){
                pos['lat']=annotation.coordinate[0]
                pos['lng']=annotation.coordinate[1]
              }

              if(!annotation.badge)
                pos['badge']=(index +1).toString()
              
              if(pos.name)
                pos['title']=pos.name

              let linkURL = supportUrlStructure(pos.linkURL, this.idToShow,this.settingsService.contextId$ )
              let contentString = "this is an example"
              contentString ='<div class="d-flex flex-column">'
                if(pos.title)
                    contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
                if(pos.subtitle)
                    contentString +='<span>'+pos.subtitle+' </span>'
                if(pos.linkURL){
                    contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                        contentString +='<span class="material-symbols-outlined">link</span>'
                    contentString +='</a>'
                }
                if(!pos.title && !pos.linkURL && !pos.subtitle)
                    contentString +='<span>...</span>'

              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                contentString += '<a href='+directions+' target="_blank" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+this.directionTranslation+'</button></a>'
              }
              contentString +=' </div> '      
              
              let beachFlagImg = document.createElement('div');
              let pinColor = '#FF5E38'
              let textColor = 'black'

              if(annotation.pinBackgroundColor)
                pinColor = annotation.pinBackgroundColor
              else if(annotation.pinColor)
                pinColor = annotation.pinColor

              if(annotation.pinForegroundColor )
                  textColor = annotation.pinForegroundColor

              const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                  <title>${annotation.badge ?? index}</title>
                  <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                      <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                      <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                    </g>
              </svg>`

              beachFlagImg.innerHTML = svg
              let pinData = {
                glyph: pos.badge,
              }
              
                pinData['background']=pinColor
                pinData['borderColor'] = textColor
                pinData['glyphColor']= textColor
              
              const pin = new PinElement(pinData);
              const infowindow = new google.maps.InfoWindow({
                content: contentString,
                ariaLabel: index+'',
              });

              const aaa = new google.maps.marker.AdvancedMarkerElement({
                map:this.map,
                content: beachFlagImg,
                position: pos,
                title: 'annotation_'+index,
              });

              aaa.addListener("click", () => {
                if(prev_infowindow){ prev_infowindow.close()}
                infowindow.open({
                  anchor: aaa,
                  map:this.map,
                });
                prev_infowindow = infowindow
              });
            
              // const marker = new AdvancedMarkerElement({
              //   map: this.map,
              //   position: pos,
              //   title:"annotation-"+index,
              //   content:pin.element
              // });
              this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
            }

            if(annotation.type=="polygon"){
              if(annotation.encodedContent){
                const encodedContent = annotation['encodedContent'].split(",")
                let pairs
                pairs = encodedContent.reduce(function(result, value, indexV, array) {
                if (indexV % 2 === 0){
                  const splited = array.slice(indexV, indexV + 2)
                  var lat: number = +splited[0];
                  var lng: number = +splited[1];
                  const value = { lat: lat, lng: lng }
                  result.push(value);
                }
                return result;
              }, []);

                this.pairsPath[annotationId] = pairs
                this.actualPolygonOptions[mapWidgetId]={}
                this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
                this.actualPolygonOptions[mapWidgetId][index].paths = pairs

                if(annotation.fillColor)
                  this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

                if(annotation.strokeColor)
                  this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

                if(annotation.lineWidth)
                  this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

                if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                  this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                  this.zoom = 9
                  const newcenter = new google.maps.LatLng(this.center)
                  this.map.setCenter(newcenter);
                  this.map.setZoom(this.zoom)
                }

                const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
                polygon.setMap(this.map);
              }
            }

            if(annotation.type=="line"){
              if(annotation.encodedContent){
                const encodedContent = annotation['encodedContent'].split(",")
                let pairs
                pairs = encodedContent.reduce(function(result, value, indexV, array) {
                if (indexV % 2 === 0){
                  const splited = array.slice(indexV, indexV + 2)
                  var lat: number = +splited[0];
                  var lng: number = +splited[1];
                  const value = { lat: lat, lng: lng }
                  result.push(value);
                }
                return result;
              }, []);

                this.pairsPath[annotationId] = pairs
                this.actualPolygonOptions[mapWidgetId]={}
                this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
                this.actualPolygonOptions[mapWidgetId][index].path = pairs

                if(annotation.strokeColor)
                  this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

                if(annotation.lineWidth)
                  this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

                if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                  this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                  this.zoom = 11
                  const newcenter = new google.maps.LatLng(this.center)
                  this.map.setCenter(newcenter);
                  this.map.setZoom(this.zoom)
                }

                const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
                line.setMap(this.map);
              }
            }
          }else{
            if(annotation.lat || annotation.lng || annotation.coordinate){
              let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name
            
            let linkURL = supportUrlStructure(pos.linkURL, this.idToShow,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
              if(pos.title)
                contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
              if(pos.subtitle)
                contentString +='<span>'+pos.subtitle+' </span>'
              if(pos.linkURL){
                contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                    contentString +='<span class="material-symbols-outlined">link</span>'
                contentString +='</a>'
              }
              if(!pos.title && !pos.linkURL && !pos.subtitle)
                contentString +='<span>...</span>'
              
              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href='+directions+' target="_blank" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
                
            contentString +=' </div> '    
            
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg
            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }
          }
        }
      }
    }
  }

  openMapDirection(markerPosition){
    let linkdirection = 'https://www.google.com/maps/dir//'
    let linkdirectionMap = 'https://www.google.com/maps/dir//'
    let pinInMaps = 'https://maps.google.com/?q='//<lat>,<lng>
    if(markerPosition.lat && markerPosition.lng){
      linkdirection += markerPosition.lat +',' + markerPosition.lng
      linkdirectionMap += markerPosition.lat +',' + markerPosition.lng + '/@' + markerPosition.lat +',' + markerPosition.lng
      pinInMaps += markerPosition.lat +',' + markerPosition.lng
    }

    // return pinInMaps
    return linkdirectionMap
  }
}
