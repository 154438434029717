import { Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { NetworkConstants } from 'src/network.constants';
import { CommentsService } from './comments.service';
import { Router } from '@angular/router';
import {Functions} from "@angular/fire/functions";
import { deepCopy, prepareDataToFirestoreUpdates, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { SettingsService } from './settings.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as XLSX from 'xlsx/xlsx.mjs';
import { isDate, isDateTime, isTime } from '../../../../backend/src/filter';

export interface IReportTemplate{
  contextId: string,
  reportTemplateId: string,
  filters: IFilter[],
  resultFields: object[]
}
export interface IFilter{
  key:string,
  operator:string,
  type:string
  displayName:string,
  description:string
  value?:string
}

export interface IFilterValue{
  key:string,
  operator:string,
  type:string
  displayName:string,
  description:string
  value:string
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  reportsResults:any={}
  reportTemplate:any={}
  regionOrCustomDomain = 'europe-west1'
  filtersValues:any={}
  fixedFilters:any={}
  reportIdLastSelected$:any
  lastReportTemplate$: Subject<any> = new Subject<any>()
  lastReportResults$:Subject<any[]> = new Subject<any[]>()

  contextIdChange$:Subject<string> = new Subject<string>();
  lastReportTemplateCahnges$ = new BehaviorSubject<any>({})


  constructor(private firestore: AngularFirestore,
    private commentsService: CommentsService,
    private route: Router,
    private functions: Functions,
    private settingsService: SettingsService,
    private translate:TranslateService
    ) { }


  createReport(data, contextId){
    try{
      data['timeStamp'] = new Date()
      data['creationDate'] = new Date()

      const docRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(contextId)
      .collection(NetworkConstants.COLLECTION_REPORTS).doc().ref
      const newId = docRef.id

      data['id'] = newId

      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
            .doc(contextId)
            .collection(NetworkConstants.COLLECTION_REPORTS).doc(newId)
            .set({...data}, {merge:true})

      return true



    }catch(error){
      console.log(error)
      return false

    }
  }

  updateReportAndReload(values){
    return new Promise<boolean>(async (resolve, reject) => {
      const data = removeUndefinedValuesFromObject(values)

      try{
        data['timeStamp'] = new Date()
        const newdata = prepareDataToFirestoreUpdates(values)

        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_REPORTS)
        .doc(data.id)
        .update(newdata)
        const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
        this.commentsService.addSnackBar.emit(message)

        resolve(true)
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
        this.commentsService.addSnackBar.emit(message)

        reject(error)
      }
    }) 
  }

  updateReport(values){
    return new Promise<void>(async (resolve, reject) => {
      const data = removeUndefinedValuesFromObject(values)

      try{
        data['timeStamp'] = new Date()
        const newdata = prepareDataToFirestoreUpdates(values)
       
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_REPORTS)
        .doc(data.id)
        .update(newdata)
        const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
        this.commentsService.addSnackBar.emit(message)

        resolve()
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
        this.commentsService.addSnackBar.emit(message)

        reject(error)
      }
    }) 
  }

  reports_createReport( contextId, reportTemplate, action?:string){
    try{
      this.lastReportTemplate$.next(reportTemplate)
      this.lastReportTemplateCahnges$.next(reportTemplate)
      
      this.filtersValues = []
      this.commentsService.progressSpin.emit(true)

      let returnReports = undefined
      const reportTemplateId = reportTemplate.id
      let filters = []
      let resultFields = []

      if(reportTemplate['filters']){
        reportTemplate['filters'].forEach( filter =>{
          filters.push(filter)
        })
      }
      if(reportTemplate.filters && reportTemplate.filters[0] &&  !reportTemplate.filters[0].key){
        filters=[]
      }

      if(reportTemplate['resultFields']){
        reportTemplate['resultFields'].forEach( resultField =>{
          resultFields.push(resultField)
        })
      }
      let values = {
        contextId: contextId,
        reportTemplateId: reportTemplateId,
        filters: reportTemplate.filters,
        resultFields: resultFields
      }

      if(reportTemplate['fixedFilters']){
        values['fixedFilters'] = reportTemplate.fixedFilters
      }

      const createReport = httpsCallable(this.functions, 'reports-createReport' );
      createReport(values).then((result) => {
        this.setReportIdLastOpened(reportTemplateId)
        const data = result.data;
        returnReports = data['result']
        let newReportTemplate = data['template']
        // let newReportTemplate = deepCopy(data['template'])
        //------------------ set filters as columns of the table, add missing filter for each column
        if(!newReportTemplate.filters)
          newReportTemplate.filters = []

        if(newReportTemplate.resultFields){
          newReportTemplate.resultFields.forEach( column => {
            if( newReportTemplate.filters){
              //add default filter if there is no filter for that key already
              const inFilter = newReportTemplate.filters.find( fil => fil.key == column.key)
              if(!inFilter){
                const filter = column
                filter.description='Filter "' + filter['key'] + '" by: '
                filter.operator ='='
                newReportTemplate.filters.push(filter)
              }
            }
          })
        }

  ////////////////////
        this.reportsResults[values.reportTemplateId]= data['result']
        this.lastReportResults$.next(data['result'])
        this.reportTemplate[values.reportTemplateId]= data['template']
        this.lastReportTemplate$.next(data['template'])
        this.lastReportTemplateCahnges$.next(data['template'])
  ////////////////////
        this.reportsResults[values.reportTemplateId].forEach(element => {
          if(element.creationDate && element.creationDate!=null){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element.creationDate._seconds * SEC_TO_MS_MULTIPLIER + element.creationDate._nanoseconds * NS_TO_MS_MULTIPLIER
            // Date takes the amount in milliseconds and build a Date object
            const date = new Date(timestampInMilliseconds)
            element.creationDate= date
          }else{
            element.creationDate=undefined
          }

          if(element.creationDate && element.timeStamp!=null){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element.timeStamp._seconds * SEC_TO_MS_MULTIPLIER + element.timeStamp._nanoseconds * NS_TO_MS_MULTIPLIER
            // Date takes the amount in milliseconds and build a Date object
            const date = new Date(timestampInMilliseconds)
            element.timeStamp= date
          }else{
            element.timeStamp=undefined
          }

        });

        setTimeout(() => {
          if(action){
            let valueFilter =""
            // filters.forEach((filter, index) => {
            //   valueFilter += index + '-' + filter.key + '-' + filter.value;
            //   this.filtersValues[filter.key] = filter.value;
            // })
            const newRoute = `home/${this.settingsService.contextId$}/reports/${reportTemplateId}/filter/${valueFilter}`
            this.route.navigate([newRoute])

          }else{
            this.route.navigate(['home', this.settingsService.contextId$,'reports',reportTemplateId])
          }
          this.commentsService.progressSpin.emit(false)
          }, 500);

        return returnReports
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(error)
        this.commentsService.progressSpin.emit(false)
        const message_ = this.translate.instant("SnackBarConstants.LOAD_FAILED")
        this.commentsService.addSnackBar.emit(message_)
      });
    }catch(error){
      console.log(error)
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }

  getReports(id){
    if(this.reportsResults[id]){
    this.lastReportResults$.next(this.reportsResults[id])
      return this.reportsResults[id]
    }
    else
      return this.reportsResults
  }

  getTemplate(id){
    if(this.reportTemplate[id])
      return this.reportTemplate[id]
    else
      return this.reportTemplate
  }

  getFilterValues(key?:string){
    if(this.filtersValues[key])
      return this.filtersValues[key]
    else
      return this.filtersValues
  }

  setReportIdLastOpened(id){
    this.reportIdLastSelected$ = id
  }

  getReportIdLastOpened(){
   return this.reportIdLastSelected$ 
  }


  setReportNewFilters(data){
    this.reportTemplate[data.id].filters = data.filters
    this.lastReportTemplateCahnges$.next( this.reportTemplate[data.id])
    this.lastReportTemplate$.next(this.reportTemplate[data.id])
  }

  reportExportFile(contextId, report, fileType ){//report-list export selected
    try{
      let returnReports = undefined
      this.commentsService.progressSpin.emit(true)
      const values = {
        contextId: contextId,
        reportTemplateId: report.id,
        storeAsFileType: fileType
      }

      const createReport = httpsCallable(this.functions, 'reports-createReport' );
      createReport(values).then((result) => {
        const data = result.data;
        returnReports = data['result']
        const fileName = data['filename']
        const token = data['token']
        const hostname  = `https://${environment.urlHostName}`
        const routeToOpen = `${hostname}/v1/download/${fileName}?token=${token}`
        window.open(routeToOpen, '_blank');
        this.commentsService.progressSpin.emit(false)

        return returnReports
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(error)
        this.commentsService.progressSpin.emit(false)
        const message_ = this.translate.instant("SnackBarConstants.REPORT_EXPORT_FAILED")
        this.commentsService.addSnackBar.emit(message_)
      });
    }catch(error){
      console.log(error)
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.REPORT_EXPORT_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }

    exportDataToFile(filteredData,fileExtension,reportTemplate ){
      const date = new Date()
      const fileName = `Report_${ isDateTime(date)}.${fileExtension}`
      let aoa = []

      const resultFields = reportTemplate.resultFields
      if(resultFields) {
        const firstRow = resultFields.map((field) => {
          return field.displayName
        })
        aoa.push(firstRow)

        if(filteredData) {
          const rows = filteredData.map((data: any) => {
            return resultFields.map((field) => {
              switch (field.type) {
                case "date": { // transform date
                  const date = data[field.key]
                  const a = isDate(date)
                  return a
                }
                case "time": { // transform time
                  const date = data[field.key]
                  const t = isTime(date)
                  return t
                }
                case "dateTime": { // transform dateTime
                  const date = data[field.key]
                  const dt = isDateTime (date)
                  return dt
                }
                case "media": { // transform Media
                  const m =  data[field.key]
                  if (m && Array.isArray(m)) 
                    return m.map((url: string) => encodeURI(url)).join(";")
                  else 
                    return ""
                }
                default:
                  return data[field.key]
              }
            })
          })
          rows.forEach((row: any) => {
              aoa.push(row)
          })
        }
      }

      const ws = XLSX.utils.aoa_to_sheet(aoa);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Report");

      switch(fileExtension) {
        case 'xls':
          XLSX.writeFile(wb, fileName, {bookType: "xls", type: "binary"})
          break;

        case 'csv':
          XLSX.writeFile(wb, fileName, {bookType: "csv", type: "string"})
          break;
      }

    }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  deleteReportTemplate(reportTemplateId: any):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_REPORTS)
        .doc(reportTemplateId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }


}
