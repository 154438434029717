<h2 mat-dialog-title>{{'pass.media_details' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="mediaDetailsForm">

                <mat-form-field  appearance="outline" class="w-100">
                    <!-- <mat-form-field *ngIf="image || callToAction || billboardImage || billboardVideo || text || video || imagesHorizontal || imageBlocks || splashVideo" appearance="outline" class="w-100"> -->
                    <mat-label>URL</mat-label>
                    <input formControlName="url" type="text" matInput placeholder="URL" >
                </mat-form-field>
        
                <mat-form-field *ngIf="!text && !image && !callToAction && !video && !billboardImage && !billboardVideo " appearance="outline" class="w-100">
                    <mat-label>{{'pass.title' | translate}}</mat-label>
                    <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                    <mat-error *ngIf="mediaDetailsForm.get('title').hasError('maxlength') && mediaDetailsForm.get('title').touched">
                        {{ 'pass.field_maxlength' | translate }} 40
                      </mat-error>
                      <mat-hint align="end">{{mediaDetailsForm.value?.title?.length}} / 40</mat-hint>
                </mat-form-field>
        
                <div class="d-flex flex-row" *ngIf="!image && !callToAction && !billboardImage && !billboardVideo && !video ">
                    <mat-form-field appearance="outline" class="w-100 mb-2">
                        <mat-label>{{'pass.link-URL' | translate}}</mat-label>
                        <input formControlName="linkURL" type="url" matInput placeholder="{{'pass.link-URL' | translate}}">
                        <mat-error>
                           {{'url_pattern_invalid' | translate}}  
                        </mat-error>
                        <mat-hint>
                            {{'url_pattern_detailed' | translate}}  
                        </mat-hint>
                    </mat-form-field> 
        
                    <button mat-icon-button (click)="fileInput.click()" matTooltip="{{'pick' | translate}}">
                        <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                    </button>
                    <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event)" #fileInput>
                </div>
        
                <mat-form-field *ngIf="!image && !callToAction && !billboardImage && !billboardVideo && !video && !splashVideo" appearance="outline" class="w-100">
                    <mat-label>{{'pass.content_description' | translate}}</mat-label>
                    <input formControlName="description" type="text" matInput placeholder="{{'pass.content_description' | translate}}">
                </mat-form-field>

                <mat-form-field *ngIf="!text && !image && !callToAction && !video && !billboardVideo && !billboardImage && !billboardVideo && !video " appearance="outline" class="w-100">
                    <mat-label>{{'pass.copyright' | translate}}</mat-label>
                    <input formControlName="copyright" type="text" matInput placeholder="{{'pass.copyright' | translate}}">
                </mat-form-field>
        
                <mat-form-field *ngIf="!image && !callToAction && !billboardImage && !billboardVideo && !video && !splashVideo " appearance="outline" class="w-100" >
                    <mat-label>{{'pass.visibility' | translate}}</mat-label>
                    <mat-select formControlName="visibility" class="input-group m-1">
                    <mat-option value="visible"><mat-icon>visibility</mat-icon>{{'pass.visible' | translate}}</mat-option>
                    <mat-option value="hidden"><mat-icon>visibility_off</mat-icon>{{'pass.hidden' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelChanges()">{{'cancel' | translate}}</button>
    <button
        mat-button
        class="orange-button"
        (click)="saveChanges();"
        [disabled]="mediaDetailsForm.get('title').hasError('maxlength')"
    >
        {{ 'apply' | translate }}
    </button>
</mat-dialog-actions>
