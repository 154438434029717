import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Product } from '../../../../backend/src/products.dto';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private firestore: AngularFirestore,
    private settingsService: SettingsService) { }


  updateProduct(item:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
        
      try{
        item['timeStamp']= new Date()
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_ITEMS)
        .doc(item.id)
        .update(item)
        
        resolve()
      }catch(error){
        reject(error)
      }
    }) 
    
    
  }

  async createProduct():Promise<string>{
    return new Promise(async (resolve, reject) => {
      const productsRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_ITEMS)
      .doc().ref

      const newId = productsRef.id
      
      let newProduct = { 
        creationDate: new Date(), 
        timeStamp: new Date(), 
        id: newId}

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS).doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_ITEMS)
        .doc(newId).set({...newProduct})

        resolve(newId)
        
      }catch (error) {
        reject(error)
      }
    })

  }


  deleteProduct(productId):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_ITEMS)
        .doc(productId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}
