import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { getAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent {
  shouldHideNavbar = false;
  routerUrl:any
  contextToShow:any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) { 
    this.routerUrl = this.route.url
    if(this.routerUrl.value.length>0){

      if(this.routerUrl.value[1] && this.routerUrl.value[1].path){
        this.contextToShow = this.routerUrl.value[1].path
      }
    }

  }

  ngOnInit(): void {
    if(this.contextToShow)
    this.router.navigate(['/home', this.contextToShow])
    else{
      const auth = getAuth()
      this.router.navigate(['/home'])
    }

  }
}
