export const routes = {
    contentTypes:"Content Types",
    content:"Content",
    tridys:"Tridys",
    advancedExperiences:"Advanced Experiences",
    products:"Products",
    workTasks:"Work Tasks",
    taskTemplates:"Task Templates",
    taskTemplatesGroups:"Task Templates Groups",
    finishedTasks:"Finished Tasks",
    account:"Account",
    users:"Users",
    coverImages:"Cover Images",
    reports:"Reports",
    interfaces:"Interfaces",
    cdSettings:"CD Settings"

}