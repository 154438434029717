import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../service/storage.service';
import { SettingsService } from '../service/settings.service';
import { UploadService } from '../service/upload.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-media-folder',
  templateUrl: './media-folder.component.html',
  styleUrls: ['./media-folder.component.css']
})
export class MediaFolderComponent {
sub:any
id:any
folder:any

routerUrl:any
idToShow:any
currentRoute:any
navBackLink:any
navBackTo:any
folderRoute:any
newImage:any

  constructor(private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate:TranslateService
    ){

      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
      this.currentRoute=this.routerUrl.value[0].path
   
      }

  ngOnInit():void{
    let route=""
    this.folderRoute='Contexts/' + this.settingsService.contextId$
    this.navBackLink=""
    this.routerUrl.value.forEach((element,index) => {
      if(index>2){
        route+='/'+element
        this.folderRoute+= '/'+element
      }

      if(index<this.routerUrl.value.length -1){
        this.navBackLink+= '/'+element
      }

      if(index==this.routerUrl.value.length -1){
        this.navBackTo=element
      }
    });

    try{
      this.commentService.progressSpin.emit(true)
      this.folder = this.storageService.getStorageContentOfContextId(route)
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }
  }

  getDataStorage(){
    let route=""
    this.folderRoute='Contexts/' + this.settingsService.contextId$
    this.navBackLink=""
    this.routerUrl.value.forEach((element,index) => {
      if(index>2){
        route+='/'+element
        this.folderRoute+= '/'+element
      }

      if(index<this.routerUrl.value.length -1){
        this.navBackLink+= '/'+element
      }

      if(index==this.routerUrl.value.length -1){
        this.navBackTo=element
      }
    });

    try{
      this.commentService.progressSpin.emit(true)
      this.folder = this.storageService.getStorageContentOfContextId(route)
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }
  }

  backTo(){
    this.router.navigate([this.navBackLink])
  }

  addFileOnFolder(){
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.newImage = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = async (event) => {
        if(event.target){
          const urlNew = event.target.result;
        
          if(urlNew){
            try{
              this.commentService.progressSpin.emit(true)
            
              const a = await  this.uploadService.addToStorage(this.newImage,this.folderRoute)
              setTimeout(() =>{
                const url = this.uploadService.getAddedToStorage()
                this.commentService.progressSpin.emit(false)
                const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_OK")
                this.commentService.addSnackBar.emit(message)
                this.getDataStorage()
              }, 800);

            }catch(error){
              console.log(error)
              this.commentService.progressSpin.emit(false)
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
              this.commentService.addSnackBar.emit(message)
            }
          }
        }
      }
    }
  }
}
