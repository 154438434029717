
<h2 mat-dialog-title>{{'content.value' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-2 p-1 mb-3 rounded m-auto w-100" id="section" >
                <form [formGroup]="editValueForm">
                    <mat-form-field *ngIf="typeValue=='string'" appearance="outline" class="w-100">
                        <mat-label>{{'content.value' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" formControlName="value">
                    </mat-form-field>

                    <mat-form-field *ngIf="typeValue=='number'" appearance="outline" class="w-100">
                        <mat-label>{{ 'content.value' | translate }}</mat-label>
                        <input matInput type="text" class="form-control" formControlName="value" (input)="onInput($event)">
                    </mat-form-field>
        
                    <!--<input *ngIf="typeValue=='string'" type="text" class="form-control" formControlName="value">
                    <input *ngIf="typeValue=='number'" type="number" class="form-control" formControlName="value"> -->
        
                    <!-- <mat-slide-toggle *ngIf="typeValue=='boolean'" formControlName="value"></mat-slide-toggle> -->
        
                    <div *ngIf="typeValue=='boolean'" class="form-control mb-1 d-flex justify-content-between">
                        <label for="value">{{'content.value' | translate}}</label>
                        <mat-slide-toggle class="ms-5 example-margin" formControlName="value" ></mat-slide-toggle>  
                    </div>
        
                    <div *ngIf="typeValue=='object'">
                        <!-- <input type="date" class="form-control mb-2 " formControlName="date">
                        <input type="time" class="form-control" formControlName="time"> -->
                        <mat-form-field appearance="outline">
                            <mat-label> {{'content.date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date" placeholder="{{'dd.mm.yyyy' | translate}}">
                            <mat-hint>{{'dd.mm.yyyy' | translate}}</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
    
                        <mat-form-field appearance="outline" class="mx-2">
                            <mat-label>{{'taskTemplates.time' | translate}}</mat-label>
                            <input matInput type="time" formControlName="time">
                        </mat-form-field>
                    </div>
                </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelEditSubtitleKey();">{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="saveEditSubtitleKey()" >{{'save' | translate}}</button> 
</mat-dialog-actions>