import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-work-task-template-edit-value-dialog',
  templateUrl: './work-task-template-edit-value-dialog.component.html',
  styleUrls: ['./work-task-template-edit-value-dialog.component.css']
})
export class WorkTaskTemplateEditValueDialogComponent {
  valueForm!:FormGroup
  valueData:any
  newData:any
  title:string

  constructor(
    public dialogRef: MatDialogRef<WorkTaskTemplateEditValueDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],

  ){

    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.valueData = this.data
    this.title = this.valueData.type
    this.valueForm =  this.fb.group({
      value:[]
    })

    this.valueForm.patchValue({
      value: this.valueData.value
    })

  }


  onCancel(){
    this.dialogRef.close([this.valueData.value, false])
  }

  onSave(){
    this.dialogRef.close([this.valueForm.value,true])
  }
}
