import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-tridys-create-dialog',
  templateUrl: './tridys-create-dialog.component.html',
  styleUrls: ['./tridys-create-dialog.component.css']
})
export class TridysCreateDialogComponent implements OnInit{

  tridysForm!:FormGroup
  finishedTasks:any=[]
  allContentTypes:any

  constructor(public dialogRef: MatDialogRef<TridysCreateDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private settingService: SettingsService){

      dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.tridysForm = this.fb.group({
      contentType:[],
      contextId:[this.settingService.contextId$]

    })

    this.tridysForm.valueChanges.subscribe(value=>{
    })


    this.getFinishedTasks()
    this.getContentTypes()
  }

  getFinishedTasks(){
    
    try{
      this.finishedTasks = this.settingService.observeContextsWorkTasksFirestoreWithId()
    }catch(error){
      console.log(error)
    }

    
  }

  async getContentTypes(){
    try{
      this.allContentTypes = await this.settingService.getAllContenttypes()
    }catch(error){

    }
    
  }

  onCancel(){
    this.dialogRef.close([false])
  }

  onSave(){
    this.dialogRef.close([true, this.tridysForm.value])
    
  }
}
