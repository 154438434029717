import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextEditComponent } from '../editWidgets/text-edit/text-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as MarkdownIt from 'markdown-it';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-text-phone]',
  templateUrl: './text-phone.component.html',
  styleUrls: ['./text-phone.component.css']
})
export class TextPhoneComponent implements OnInit{
  @Input('app-text-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, translations:any}>()

  textData:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  linkUrl:any
  translationsPass:any
  markdown:any
  url:any
  markdownText:any =""
  dynamic=false
  languagesPassList:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService:SettingsService
    ){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path   
  }

  ngOnInit(): void {
    this.textData=this.data[0] 
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    this.languagesPassList = this.data[4]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    // if(this.textData.imagePosition){
    //   let imgPos= this.textData.imagePosition
    //   const textAlign="text-align:"+imgPos
    //   const doc=document.getElementById("titleText");
    //   if(doc!=null)
    //     doc.style.textAlign=imgPos
    //   const docSub=document.getElementById("subtitleText");
    //   if(docSub!=null)
    //     docSub.style.textAlign=imgPos
    // }

    if (this.textData.text) { 
      //this.textData.text = checkDynamicContent(this.textData.text)
      this.markdown = new MarkdownIt({ breaks: true });
      const text = this.markdown.render(this.textData.text);
      this.markdownText = text;
    }

    if( this.textData.image && this.textData.image.url){
      try{
        const partsd = this.textData.image.url.includes("${")
        if(partsd){
          this.dynamic = true
          //this.textData.image.url = checkDynamicContent(this.textData.image.url)
        }

        this.url = this.textData.image.url
        let url = supportUrlStructure(this.textData.image.url, this.idToShow, this.settingsService.contextId$)
        if(url)
        this.url=url 
      // const imgUrl = new URL(this.textData.image.url)
      //   let hostname = environment.urlHostName
      //   if(imgUrl.protocol=="pass:" ){  
      //     const parts = this.textData.image.url.match(/pass:\/\/(.+)\/(.+)/)
      //     if(parts){
      //       const passTemplateID = parts[1]
      //       const path = parts[2]
      //       const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
      //       this.url=firebaseUrl
      //     }else{
      //       const newparts = this.textData.image.url.match(/pass:\/\/(.+)/)
      //       if(newparts){
      //         let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
      //         newparts.forEach( (part,index)=> {
      //           if(index>0){
      //             firebaseUrl += '/' + part 
      //           }
      //         })
      //         this.url=firebaseUrl
      //       }
      //     }
      //   }
      //   if(imgUrl.protocol =="tridy:"){
      //     const parts = this.textData.image.url.match(/tridy:\/\/(.+)\/(.+)/)
      //     let path =""
      //     parts.forEach((parte, index) =>{
      //       if(index>0 && index<parts.length){
      //         path += "/" + parte
      //       }
      //     })

      //     const firebaseUrl = `https://${hostname}/v1/pass${path}`
      //     this.url=firebaseUrl
      //   }
      }catch(errro){
      }
    }

    if(this.textData.image && this.textData.image.linkURL){
      //this.textData.image.linkURL = checkDynamicContent(this.textData.image.linkURL)
      this.linkUrl = this.textData.image.linkURL
      let url = supportUrlStructure(this.textData.image.linkURL, this.idToShow, this.settingsService.contextId$)
      this.linkUrl=url 
      
      // try{
      //   const imgUrl = new URL(this.textData.image.linkURL)
      //   let hostname = environment.urlHostName
      //   if(imgUrl.protocol=="pass:" ){  
      //     const parts = this.textData.image.linkURL.match(/pass:\/\/(.+)\/(.+)/)
      //     if(parts){
      //         const passTemplateID = parts[1]
      //         const path = parts[2]
      //         const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
      //         this.linkUrl=firebaseUrl
      //     }else{
      //       const newparts = this.textData.image.linkURL.match(/pass:\/\/(.+)/)
      //       if(newparts){
      //         let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
      //         newparts.forEach( (part,index)=> {
      //           if(index>0){
      //             firebaseUrl += '/' + part 
      //           }
      //         })
      //         this.linkUrl=firebaseUrl
      //       }
      //     }
      //   }
      //   if(imgUrl.protocol =="tridy:"){
      //     const parts = this.textData.image.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
      //     let path =""
      //     parts.forEach((parte, index) =>{
      //       if(index>0 && index<parts.length){
      //         path += "/" + parte
      //       }
      //     })
      //     const firebaseUrl = `https://${hostname}/v1/pass${path}`
      //     this.linkUrl=firebaseUrl
      //   }
      // }catch(erro){}
    }

  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(TextEditComponent, {
      data:{
        values: this.textData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        translations: this.translationsPass,
        url: this.url,
        languages: this.languagesPassList
        // oldtransitions: this.textData.translations
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.textData=result[0]
        if(result[0].image && result[0].image.linkURL){
          this.linkUrl = this.textData.image.linkURL
         
        }
        this.returnChanges.emit({newdata: this.textData, widget: this.widgetNumber, widgetType: this.textData.type, translations: result[3]})
      }
    })
  }

  supportURL(url){
    let transformedUrl = url
    try{
      const imgUrl = new URL(url)

      let hostname = environment.urlHostName

      if(imgUrl.protocol=="pass:" ){  
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            transformedUrl=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            transformedUrl=firebaseUrl
          }
        }
      }

      if(imgUrl.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })

        const firebaseUrl = `https://${hostname}/v1/pass${path}`
        transformedUrl=firebaseUrl
      }
    }catch(erro){}

    return transformedUrl
  }
}
