
<!-- <button *ngIf="editMode" class="edit-button-top"><span class="material-symbols-outlined" (click)="openDialogEdit();">
    edit_square
    </span>
</button> -->

<canvas #canvasPhone id="canvasPhone" style="height: 100%; width: 100%;" class="p-1" (click)="openView();"></canvas>




