import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountLanguagesDialogComponent } from './account-languages-dialog/account-languages-dialog.component';
import { PassTemplatesComponent } from './pass-templates/pass-templates.component';
import { TridysComponent } from './tridys/tridys.component';
import { MediaComponent } from './media/media.component';
import { ProductsComponent } from './products/products.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { TaskTemplatesComponent } from './task-templates/task-templates.component';
import { FinishedTasksComponent } from './finished-tasks/finished-tasks.component';
import { UsersComponent } from './users/users.component';
import { LogInComponent } from './log-in/log-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftNavbarComponent } from './left-navbar/left-navbar.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ContentTypesListComponent } from './content-types-list/content-types-list.component';
import { ContentComponent } from './content/content.component';
import { MediaFolderComponent } from './media-folder/media-folder.component';
import { FinishedTasksItemComponent } from './finished-tasks-item/finished-tasks-item.component';
import { TaskTemplateItemComponent } from './task-template-item/task-template-item.component';
import { TridysItemComponent } from './tridys-item/tridys-item.component';
import { ProductsItemComponent } from './products-item/products-item.component';
import { ContextIdSelectorComponent } from './context-id-selector/context-id-selector.component';
import { AdvancedExperiencesComponent } from './advanced-experiences/advanced-experiences.component';
import { AdvancedExperiencesItemComponent } from './advanced-experiences-item/advanced-experiences-item.component';
import { AuthGuard } from './shared/auth.guard';
import { PagesGuard } from './shared/pages.guard';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { ReportsItemTridysListComponent } from './reports-item-tridys-list/reports-item-tridys-list.component';
import { InterfacesListComponent } from './interfaces-list/interfaces-list.component';
import { TaskTemplatesGroupsComponent } from './task-templates-groups/task-templates-groups.component';
import { ContentTypesSchemaComponent } from './content-types-schema/content-types-schema.component';
import { RegisterComponent } from './register/register.component';
import { LogGuard } from './shared/log.guard';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { CdSettingsComponent } from './cd-settings/cd-settings.component';

const routes: Routes = [
  {path:'home/:contextId', component: DashboardComponent,  canActivate:[AuthGuard]},
  {path:'home/:contextId/dashboard', component: DashboardComponent},

  {path:'home/:contextId/account/:id', component: AccountLanguagesDialogComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/interfaces', component: InterfacesListComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/passTemplates/:id', component: PassTemplatesComponent},

  {path:'home/:contextId/content', component: ContentComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/content/media', component: MediaComponent, canActivate:[PagesGuard] },
  // {path:'home/:contextId/content/media/:folder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/content/media/:folder/:insideFolder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/content/media/:folder/:insideFolder/:folder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/content/media/:folder/:insideFolder/:folder/:folder1', component: MediaFolderComponent, canActivate:[PagesGuard] },

  {path:'home/:contextId/contentTypes', component: ContentTypesListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/contentTypes/:id/schema', component: ContentTypesSchemaComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/contentTypes/:id/passTemplate/:id', component: PassTemplatesComponent, canActivate:[PagesGuard], canDeactivate: [CanDeactivateGuard]},
  {path:'home/:contextId/contentTypes/:id/passTemplate/:id/coverImage/:id', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]}, 

  {path:'home/:contextId/reports', component: ReportsListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter/:value', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter/:value/tridy/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter/tridy/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/tridy/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/tridy/:tridyId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter/:value/tridy/:tridyId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:id/filter/tridy/:tridyId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/tridys', component: TridysComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/tridys/:id', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/tridys/:id/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/coverImages', component: AdvancedExperiencesComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/coverImages/:id', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/products', component: ProductsComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/products/:id', component: ProductsItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/taskTemplatesGroups', component: TaskTemplatesGroupsComponent , canActivate:[PagesGuard]},

  {path:'home/:contextId/taskTemplates', component: TaskTemplatesComponent , canActivate:[PagesGuard]},
  {path:'home/:contextId/taskTemplates/:id', component: TaskTemplateItemComponent , canActivate:[PagesGuard], canDeactivate: [CanDeactivateGuard]},

  {path:'home/:contextId/finishedTasks', component: FinishedTasksComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/finishedTasks/:id/tridy/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  
  {path:'home/:contextId/users', component: UsersComponent, canActivate:[PagesGuard]},
  
  {path:'home/:contextId/cdSettings', component: CdSettingsComponent, canActivate:[PagesGuard]},
  // ----------------------------------------------------------------------------------------------------
  {path:'home', component: DashboardComponent,  canActivate:[AuthGuard]},
 
  {path:'logIn', component: LogInComponent, canActivate:[LogGuard]},
  {path:'register', component: RegisterComponent},
  {path:'selectAccount', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},
  {path:'selectAccount/:contextId', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},
  //{path:'setPassword/:id', component: RegisterSetPasswordComponent},
  
  // { path: "", component: RedirectComponent, canActivate:[PagesGuard] },
  {path:'**', component:RedirectComponent, canActivate:[PagesGuard]}
  // {path:'**', component:PAgeNotFound}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[AuthGuard]
})
export class AppRoutingModule { }
