<div mat-dialog-title>
    <h2 class="d-inline">{{'pass.map.map_view' | translate}} {{'translationMode' | translate}}</h2>
    <button *ngIf="!showOriginalActive" mat-button style="position: relative; float: right;" class="m-3 mx-2" (click)="showOriginal();"><mat-icon>visibility</mat-icon>Show original</button>
    <button *ngIf="showOriginalActive" mat-button style="position: relative; float: right;" class="m-3 mx-2" (click)="hideOriginal();"><mat-icon>visibility_off</mat-icon>Hide original</button>
</div>

<mat-dialog-content [ngStyle]="{'width': showOriginalActive ? '80vw' : '100%'}"><!--style="width:600px;"-->
    <div class="pt-2 pb-3 d-flex" [ngStyle]="{'justify-content': showOriginalActive ? 'space-between' : 'flex-end'}">

        <section *ngIf="showOriginalActive" class="mt-1 mb-3 rounded m-auto w-100" id="section"  style="max-width: 600px;" >
            <p style="font-weight: bold;">{{languagesList[data?.languages[0]]}} (Original)</p>

            <form [formGroup]="originalLanguageForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
                
                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field  appearance="outline" class="w-100" >
                                <mat-label>{{'pass.layout' | translate}} </mat-label>
                                <mat-select formControlName="layout" class="input-group m-1">
                                    <mat-option value="singleMap">{{'pass.map.single' | translate}}</mat-option>
                                    <mat-option value="horizontal">{{'pass.map.horizontal' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field  appearance="outline" class="w-100" >
                                <mat-label>{{'pass.style' | translate}}</mat-label>
                                <mat-select formControlName="style" class="input-group m-1">
                                    <mat-option value="satellite">{{'pass.map.satellite' | translate}}</mat-option>
                                    <mat-option value="streets">{{'pass.map.streets' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <label class="m-2">{{'pass.map.ANNOTATIONS' | translate}}</label>
                <mat-tab-group>
                    <mat-tab label="{{'pass.widgets.mapEntries' | translate}}" > 
                        <!-- <google-map height="400px" width="100%" [center]="center" [zoom]="zoom" [options]="{disableDefaultUI: true}">

                            <ng-container *ngFor="let markerPosition of markerPositionsOriginal; let i=index">
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='point' && !markerPosition['dynamic']">
                                    <map-marker #marker="mapMarker" [position]="markerPosition" [options]="markerOptions" 
                                            [label]="markerPosition['badge']"  (mapClick)="openInfoWindowOriginal(marker, infoWindow, markerPosition)">
                                    </map-marker>
                                    
                                    <map-info-window #infoWindow="mapInfoWindow"> 
                                        <div class="d-flex flex-column">
                                            <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                            <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                            <a *ngIf="markerPosition['linkURL'] && linkURLOriginal[markerPosition['id']]" href="{{linkURLOriginal[markerPosition['id']]}}" target="_blank" style="color:var(--warmOrange);">
                                                <span class="material-symbols-outlined">link</span>
                                            </a>
                                            <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>

                                            <button *ngIf="markerPosition['directions']" mat-button class="secondary-gray-button w-100 mt-1" (click)="openMapDirection(markerPosition)">
                                                <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                            </button>
                                        </div>
                                    </map-info-window>
                                </div>

                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='line' && !markerPosition['dynamic']">
                                    <map-polyline [options]="actualPolygonOptions[i]"></map-polyline>
                                </div>
            
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon' && !markerPosition['dynamic']">
                                    <ng-container>
                                        <map-polygon #polygon [options]="actualPolygonOptions[i]" ></map-polygon>
                                    </ng-container>
                                </div>

                                <div *ngIf="!markerPosition['type'] && !markerPosition['dynamic']">
                                    <map-marker [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>
                                </div>
                            </ng-container>
                        </google-map> -->

            <div [app-annotated-map-view]="[widgetDefaultLanguage, true, 'map-dialog-translations-edit-original-'+widgetId, passId, allAnnotationsMapOriginal, '400px']"></div>

                    </mat-tab>
                    <mat-tab label="{{'pass.list' | translate}}" style="max-height: 200px;"> 
                        <div class="my-2">
                            <div *ngFor="let a of allAnnotationsMapOriginal;let i=index" class="p-2 border-bottom" style="overflow: hidden; "  id="annotation-list-item"><!--(click)="openAnnotationDialogOriginal(a, i);"-->
                                <div class="d-flex align-items-center">
    
                                    <div style="width: 10%;" *ngIf="!a.dynamic">
                                        <mat-icon matListItemIcon *ngIf="!a.type">place</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='point'"> place</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='line'">timeline</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='polygon'">photo_size_select_small</mat-icon>
                                    </div>

                                    <div style="width: 10%;" *ngIf="a.dynamic"><mat-icon matListItemIcon >pin_drop</mat-icon></div>

                                    <div class="d-flex flex-row justify-content-between mb-1 mt-1 align-items-center" style="width: 90%;" >
                                        <div *ngIf="!a.dynamic">
                                            <div matListItemTitle *ngIf="a.name">{{a.name}}</div>
                                            <div matListItemTitle *ngIf="a.title">{{a.title}}</div>
                                            <div matListItemTitle *ngIf="!a.name && !a.title">{{'pass.map.annotation' | translate}} {{i+1}}</div>
                                            <div matListItemLine *ngIf="a.subtitle">{{a.subtitle}}</div>
                                            <div matListItemLine *ngIf="a.lat || a.lng">{{a.lat}} , {{a.lng}}</div>
                                            <div matListItemLine *ngIf="a.coordinate && !a.lat">{{a.coordinate[0]}} , {{a.coordinate[1]}}</div>
                                        </div>

                                        <div *ngIf="a.dynamic">
                                            <div *ngIf="a.properties">
                                                <div matListItemTitle *ngIf="a.properties.name">{{a.properties.name}}</div>
                                                <div matListItemTitle *ngIf="a.properties.title">{{a.properties.title}}</div>
                                                <div matListItemTitle *ngIf="!a.properties.name && !a.properties.title">{{'pass.map.annotation_dynamic' | translate}} {{i+1}}</div>
                                                <div matListItemLine *ngIf="a.properties.subtitle">{{a.properties.subtitle}}</div>
                                                <div matListItemLine *ngIf="a.properties.lat || a.properties.lng">{{a.properties.lat}} , {{a.properties.lng}}</div>
                                                <div matListItemLine *ngIf="a.properties.coordinate && !a.properties.lat">{{a.properties.coordinate[0]}} , {{a.properties.coordinate[1]}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>

        </section>

        <section *ngIf="showOriginalActive" class="m-3" >
            <div class="vertical-separator"></div>
        </section>

        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" style="max-width: 600px;">
            <p *ngIf="showOriginalActive" style="font-weight: bold;">{{languagesList[selectedLanguage]}}</p>

            <form [formGroup]="annotatedMapTranslationEditForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
                
                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field  appearance="outline" class="w-100" >
                                <mat-label>{{'pass.layout' | translate}} </mat-label>
                                <mat-select formControlName="layout" class="input-group m-1">
                                    <mat-option value="singleMap">{{'pass.map.single' | translate}}</mat-option>
                                    <mat-option value="horizontal">{{'pass.map.horizontal' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group" >
                            <mat-form-field  appearance="outline" class="w-100" >
                                <mat-label>{{'pass.style' | translate}}</mat-label>
                                <mat-select formControlName="style" class="input-group m-1">
                                    <mat-option value="satellite">{{'pass.map.satellite' | translate}}</mat-option>
                                    <mat-option value="streets">{{'pass.map.streets' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <label class="m-2">{{'pass.map.ANNOTATIONS' | translate}}</label>
                <mat-tab-group (selectedTabChange)="onTabChange($event)">
                    <mat-tab label="{{'pass.widgets.mapEntries' | translate}}" > 
                        <!-- <google-map height="400px" width="100%" [center]="center" [zoom]="zoom" [options]="{disableDefaultUI: true}">

                            <ng-container *ngFor="let markerPosition of markerPositions; let i=index">
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='point' && !markerPosition['dynamic']">
                                    <map-marker #marker="mapMarker" [position]="markerPosition" [options]="markerOptions" 
                                            [label]="markerPosition['badge']"  (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                                    </map-marker>
                                    
                                    <map-info-window #infoWindow="mapInfoWindow"> 
                                        <div class="d-flex flex-column">
                                            <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                            <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                            <a *ngIf="markerPosition['linkURL'] && linkURL[markerPosition['id']]" href="{{linkURL[markerPosition['id']]}}" target="_blank" style="color:var(--warmOrange);">
                                                <span class="material-symbols-outlined">link</span>
                                            </a>
                                            <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>

                                            <button *ngIf="markerPosition['directions']" mat-button class="secondary-gray-button w-100 mt-1" (click)="openMapDirection(markerPosition)">
                                                <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                            </button>
                                        </div>
                                    </map-info-window>
                                </div>

                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='line' && !markerPosition['dynamic']">
                                    <map-polyline [options]="actualPolygonOptions[i]"></map-polyline>
                                </div>
            
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon' && !markerPosition['dynamic']">
                                    <ng-container>
                                        <map-polygon #polygon [options]="actualPolygonOptions[i]" ></map-polygon>
                                    </ng-container>
                                </div>

                                <div *ngIf="!markerPosition['type'] && !markerPosition['dynamic']">
                                    <map-marker [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>
                                </div>
                            </ng-container>
                        </google-map> -->

                    <div [app-annotated-map-view]="[annotatedMapTranslationEditForm.value, true, 'map-dialog-translations-edit-'+widgetId, passId, annotatedMapTranslationEditForm.value.annotations, '400px']"></div>

                    </mat-tab>
                    <mat-tab label="{{'pass.list' | translate}}" style="max-height: 200px;"> 
                        <div class="my-2">
                            <div *ngFor="let a of allAnnotationsMap;let i=index" class="p-2 border-bottom" style="overflow: hidden; " (click)="openAnnotationDialog(a, i);" id="annotation-list-item">
                                <div class="d-flex align-items-center">
    
                                    <div style="width: 10%;" *ngIf="!a.dynamic">
                                        <mat-icon matListItemIcon *ngIf="!a.type">place</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='point'"> place</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='line'">timeline</mat-icon>
                                        <mat-icon matListItemIcon *ngIf="a.type && a.type=='polygon'">photo_size_select_small</mat-icon>
                                    </div>

                                    <div style="width: 10%;" *ngIf="a.dynamic"><mat-icon matListItemIcon >pin_drop</mat-icon></div>

                                    <div class="d-flex flex-row justify-content-between mb-1 mt-1 align-items-center" style="width: 90%; cursor:pointer;" >
                                        <div *ngIf="!a.dynamic">
                                            <div matListItemTitle *ngIf="a.name">{{a.name}}</div>
                                            <div matListItemTitle *ngIf="a.title">{{a.title}}</div>
                                            <div matListItemTitle *ngIf="!a.name && !a.title">{{'pass.map.annotation' | translate}} {{i+1}}</div>
                                            <div matListItemLine *ngIf="a.subtitle">{{a.subtitle}}</div>
                                            <div matListItemLine *ngIf="a.lat || a.lng">{{a.lat}} , {{a.lng}}</div>
                                            <div matListItemLine *ngIf="a.coordinate && !a.lat">{{a.coordinate[0]}} , {{a.coordinate[1]}}</div>
                                        </div>

                                        <div *ngIf="a.dynamic">
                                            <div *ngIf="a.properties">
                                                <div matListItemTitle *ngIf="a.properties.name">{{a.properties.name}}</div>
                                                <div matListItemTitle *ngIf="a.properties.title">{{a.properties.title}}</div>
                                                <div matListItemTitle *ngIf="!a.properties.name && !a.properties.title">{{'pass.map.annotation_dynamic' | translate}} {{i+1}}</div>
                                                <div matListItemLine *ngIf="a.properties.subtitle">{{a.properties.subtitle}}</div>
                                                <div matListItemLine *ngIf="a.properties.lat || a.properties.lng">{{a.properties.lat}} , {{a.properties.lng}}</div>
                                                <div matListItemLine *ngIf="a.properties.coordinate && !a.properties.lat">{{a.properties.coordinate[0]}} , {{a.properties.coordinate[1]}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="[oldData,false]" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();">{{'apply' | translate}}</button>
</mat-dialog-actions>
