import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageEditComponent } from '../editWidgets/image-edit/image-edit.component';
import { ActivatedRoute } from '@angular/router';
import { checkDynamicContent, deepCopy, supportUrlStructure } from '../../../../../backend/utils/object';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-image-phone]',
  templateUrl: './image-phone.component.html',
  styleUrls: ['./image-phone.component.css']
})
export class ImagePhoneComponent implements OnInit {
  @Input('app-image-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetId: any, widgetType:any, images:any, translations: any}>()

  imageData:any
  imageDataSupportUrl:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  translationsPass:any
  url:any
  linkURL:any
  dynamic=false

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService:SettingsService
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }
  ngOnInit(): void {
    this.imageData=this.data[0]
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.imageDataSupportUrl = deepCopy (this.data[0])
      if(this.imageData.url){
        try{
          const partsd = this.imageData.url.includes("${")
          if(partsd){
            //this.imageData.url = checkDynamicContent(this.imageData.url)
            this.dynamic = true
          }
          let url = supportUrlStructure(this.imageData.url, this.idToShow, this.settingsService.contextId$)
          this.url=url
            
          // const imgUrl = new URL(this.imageData.url)
          // let hostname = environment.urlHostName
         
          // if(imgUrl.protocol=="pass:" ){
          //   const parts = this.imageData.url.match(/pass:\/\/(.+)\/(.+)/)
          //   if(parts){
          //     const passTemplateID = parts[1]
          //     const path = parts[2]
          //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          //     this.url=firebaseUrl
          //   }else{
          //     const newparts = this.imageData.url.match(/pass:\/\/(.+)/)
          //     if(newparts){
          //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
          //       newparts.forEach( (part,index)=> {
          //         if(index>0){
          //           firebaseUrl += '/' + part 
          //         }
          //       })
          //       this.url=firebaseUrl
          //     }
          //   }
          // }

          // if(imgUrl.protocol =="tridy:"){
          //   const parts = this.imageData.url.match(/tridy:\/\/(.+)\/(.+)/)
          //   let path =""
          //   parts.forEach((parte, index) =>{
          //     if(index>0 && index<parts.length){
          //       path += "/" + parte
          //     }
          //   })
            
          //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
          //   this.url=firebaseUrl
          // }
        }catch(error){
        }
      }

      if(this.imageData.linkURL){
        try{
          //this.imageData.linkURL = checkDynamicContent(this.imageData.linkURL)
          this.linkURL = this.imageData.linkURL
          let url = supportUrlStructure(this.imageData.linkURL, this.idToShow, this.settingsService.contextId$)
          if(url)
          this.linkURL=url
          // const imgUrl = new URL(this.imageData.linkURL)
          // let hostname = environment.urlHostName
          // if(imgUrl.protocol=="pass:" ){
          //   const parts = this.imageData.linkURL.match(/pass:\/\/(.+)\/(.+)/)
          //   if(parts){
          //     const passTemplateID = parts[1]
          //     const path = parts[2]
          //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          //     this.linkURL=firebaseUrl
          //   }else{
          //     const newparts = this.imageData.linkURL.match(/pass:\/\/(.+)/)
          //     if(newparts){
          //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
          //       newparts.forEach( (part,index)=> {
          //         if(index>0){
          //           firebaseUrl += '/' + part 
          //         }
          //       })
          //       this.linkURL=firebaseUrl
          //     }
          //   }
          // }
          // if(imgUrl.protocol =="tridy:"){
          //   const parts = this.imageData.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
          //   let path =""
          //   parts.forEach((parte, index) =>{
          //     if(index>0 && index<parts.length){
          //       path += "/" + parte
          //     }
          //   })
            
          //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
          //   this.linkURL=firebaseUrl
          // }
        }catch(error){}
      }
/*       if(this.imageData.title)
        this.imageData.title = checkDynamicContent(this.imageData.title)
  
      if(this.imageData.subtitle)
        this.imageData.subtitle = checkDynamicContent(this.imageData.subtitle) */
    
  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(ImageEditComponent, {
      data:{
        values: this.imageData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        translations: this.translationsPass,
        url :this.url
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.imageData=result[0]
        this.imageToStorage.push( result[2])
        this.returnChanges.emit({newdata: this.imageData, widget: this.widgetNumber, widgetId: result[0].id, widgetType: this.imageData.type,images: this.imageToStorage, translations: result[3]})
      }

    })
  }
}
