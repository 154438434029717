import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterTasks'
})
export class FilterTasksPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
        if(it.id){
            if(it.groupTitle){
                if(it.title){
                    return it.title.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.groupTitle.toLowerCase().includes(searchText);
                }else{
                    return it.id.toLowerCase().includes(searchText) || it.groupTitle.toLowerCase().includes(searchText);
                }
            }else{
                if(it.title){
                    return it.title.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText);
                }else{
                    return it.id.toLowerCase().includes(searchText);
                }
            }
        }else
            return ""

    });
   }
}