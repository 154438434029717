<h2 mat-dialog-title>{{'taskTemplates.section-title' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="editSectionForm" >
                <!-- <div class="input-group m-1">
                    <label class="form-control" >{{'taskTemplates.wt_title' | translate}}</label>
                    <input matInput type="text" formControlName="title" name="title" class="form-control" placeholder="{{'taskTemplates.wt_title' | translate}}">
        
                </div> -->
                <mat-form-field appearance="outline" class="pt-3 m-2 mb-0 mt-0">
                    <mat-label>{{'taskTemplates.wt_title' | translate}}</mat-label>
                    <input matInput type="text" formControlName="title" name="title" class="form-control" placeholder="{{'taskTemplates.wt_title' | translate}}">
                </mat-form-field>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelSettings();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" 
    (click)="onSaveSettings();">{{'save' | translate}}</button>
</mat-dialog-actions>
