
<section  class="topView">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelView();">
        Close
    </button>
    <canvas #canvasView id="canvasView" style="height: 80vw;
    width: 80vw;
    max-width: 700px;
    max-height: 700px;" ></canvas>
</section>

