<mat-drawer-container class="shadow-sm">
  <app-top-navbar *ngIf="!shouldHideNavbar"></app-top-navbar>
</mat-drawer-container>
<router-outlet></router-outlet>

<div *ngIf="showProgress" class="overlay">
  <div class="overlay__inner">
    <div class="overlay__content">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
</div>