import { Component, Injectable, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-advanced-experiences',
  templateUrl: './advanced-experiences.component.html',
  styleUrls: ['./advanced-experiences.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class AdvancedExperiencesComponent {
  advancedExperiencesData:any=[]
  searchText=''
  displayedColumns: string[] = [ 'imageUrl','title', "creationDate"];
  dataSource=new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingsService.paginator$

  constructor(private settingsService: SettingsService,
    private commentService:CommentsService,
    private translateService: TranslateService){
  }

  ngOnInit():void{
    this.getAdvancedExperiences()
  }

  async getAdvancedExperiences(){
    try{
      this.commentService.progressSpin.emit(true)
      this.advancedExperiencesData = this.settingsService.observeAdvancedExperiencesFirestore()
      this.dataSource = new MatTableDataSource<any>(this.advancedExperiencesData)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translateService.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.firstPage()
      this.commentService.progressSpin.emit(false)
    }, 800);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
