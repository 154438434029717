<section>
<!--     <button *ngIf="editMode" class="edit-button-top"><span class="material-symbols-outlined" (click)="openDialogEdit();">
        edit_square
        </span>
    </button> -->
    <div *ngIf="videoData.title" id="title" class="titleVideo text-break text-wrap">{{videoData.title}}</div>
    <div *ngIf="videoData.subtitle" id="subtitle" class="subtitleVideo text-break text-wrap">{{videoData.subtitle}}</div>
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px; position: absolute;color:white;filter: drop-shadow(2px 4px 6px black);z-index: 1;">
        finance_chip
    </span>

    <vg-player *ngIf="!noUrl">

        <video *ngIf="!url" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="400">
            <source *ngIf="!urlData" src="{{videoData.url}}" >
            <source *ngIf="urlData" src="{{urlData}}" >
        </video>

        <video *ngIf="url" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="400" >
            <source *ngIf="urlData" src="{{urlData}}" type="video/mp4" onerror="this.src='./../../assets/img/default.jpg'">
            <source *ngIf="!urlData" src="{{url}}" type="video/mp4" onerror="this.src='./../../assets/img/default.jpg'">
        </video>

    </vg-player>

    <div *ngIf="noUrl" class="d-flex justify-content-center align-items-center" style="height: 200px;">
        <p>{{'pass.preview_no_video_url' | translate}}</p>
    </div>
</section>
