<p>pass-templates-translations-editor-dialog works!</p>
<h2 mat-dialog-title>{{'content.translations' | translate}} {{widgetsNames[widgetType]}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
          <div>
            <!-- <div class="m-2 p-2" *ngIf="emptyLanguages"><p> {{'no_languages' | translate}}</p></div> -->
            <!-- <mat-accordion>
              <mat-expansion-panel  class="mt-1 mb-1" *ngFor=" let lang of passLanguages; let index= index;" 
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                    >
                <mat-expansion-panel-header>
                  <mat-panel-title *ngIf="lang == firstLanguageContext" style="color:var(--warmOrange);">
                      {{languagesList[lang]}}
                  </mat-panel-title>
  
                  <mat-panel-title *ngIf="lang != firstLanguageContext" >
                    {{languagesList[lang]}}
                </mat-panel-title>

                </mat-expansion-panel-header>
  
                <form [formGroup]="languageForm">
                  <div *ngFor="let trans of translationsWidget[lang]; let index = index;">
                    <div *ngIf=" trans[3] && trans[3] != 'url' && trans[3] !='linkURL' "> 
                      <mat-form-field appearance="outline" class="w-100"  >
                        <mat-label>{{trans[2]}}</mat-label>
                        <textarea matInput formControlName="{{trans[0]}}" value="{{trans[1]}}" class="form-control" placeholder="Ex." *ngIf="trans[3] =='text'"></textarea>

                        <input matInput placeholder="Ex. " value="{{trans[1]}}" formControlName="{{trans[0]}}" id="{{trans[0]}}" *ngIf="trans[3] !='text'">
                      </mat-form-field>
                    </div>
                     

                    <div *ngIf="trans[3] && trans[3] == 'url' || trans[3] =='linkURL' ">
                      <div class="w-100 d-flex flex-row mb-4 pb-3">
                        <mat-form-field appearance="outline" class="w-100"  >
                          <mat-label>{{trans[2]}}</mat-label>
                          <input matInput placeholder="Ex. " value="{{trans[1]}}" formControlName="{{trans[0]}}" id="{{trans[0]}}">
                          <mat-error >{{'url_pattern_invalid' | translate}} or pass://... </mat-error>
                          <mat-hint > {{'url_pattern_detailed' | translate}} or pass://... </mat-hint>
                        </mat-form-field>
            
                        <button mat-icon-button [matMenuTriggerFor]="pickDocument"  style="color:var(--warmOrange);">
                          <span class="material-symbols-outlined">
                              more_vert
                          </span>
                        </button>
                        
                        <mat-menu #pickDocument="matMenu">
                          <button mat-menu-item (click)="fileInput.click()">
                              <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                              {{'pick' | translate}}
                          </button>
                          <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf" (change)="readDocument($event, trans)" #fileInput>
                      </mat-menu>


                      </div>
                    </div>
                  </div>
                </form>
                
              </mat-expansion-panel>
            </mat-accordion> -->

                <form [formGroup] ="widgetForm">
                  <div *ngFor="let field of widgetFieldsForForm[widgetType]"> 
                    <mat-form-field appearance="outline" class="w-100" *ngIf="field.input && !fieldNotUrl(field.formControlName) ">
                      <mat-label>{{field.inputLabel | translate}}</mat-label>
                      <input formControlName="{{field.formControlName}}" value="widgetData[field.formControlName]" type="field.inputType" matInput placeholder="{{field.placeholder | translate}}" >
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 mb-1" *ngIf="!field.input && field.inputType =='textarea' ">
                      <mat-label>{{field.inputLabel | translate}}</mat-label>
                      <textarea matInput cdkTextareaAutosize matInput formControlName="{{field.formControlName}}" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" class="form-control" id="text" value="widgetData[field.formControlName]"></textarea>  
  
                      <mat-hint>
                          <span>{{'pass.markDown_supports' | translate}}  </span>
                          <span matTooltip="{{'pass.markDown' | translate}}" matTooltipPosition="right">
                              <mat-icon>info</mat-icon>
                          </span>
                      </mat-hint>
                    </mat-form-field>

                    <mat-expansion-panel class="mt-1 mb-1" expanded *ngIf="field.input && fieldNotUrl(field.formControlName) && field.formControlName=='image' ">
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              <mat-icon class="material-symbols-outlined">image</mat-icon> <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      
                      <div class="px-2 py-3" >
                          <div *ngIf="urlData" class="mt-2">
                              <div class="d-flex justify-content-stretch">
                                  <div *ngIf="urlData" class="custom-form-field">
                                      <img [src]="urlData"  height="150" style="object-fit: cover; border-radius:10px; width:100%;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                  </div>
                                  <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>
  
                                  <div class="form-fields">
                                      <div class="d-flex align-items-center">
                                          <mat-form-field appearance="outline" class="flex-grow-1">
                                              <mat-label>{{ field.inputLabel | translate }}</mat-label>
                                              <input type="text" formControlName="{{field.formControlName}}" value="" matInput (change)="checkNewUrl()" >
                                          </mat-form-field>
                                          <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="Delete Image" disabled>
                                              <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                          </button>
                                      </div>

  
                                    </div>
                              </div>
                          </div>
                          <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                              <mat-card class="upload-section text-center justify-content-center">
                                  <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                      <div class="py-2 mb-4">
                                          <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                          <h2>{{'pass.fileUploadMessage' | translate}}
                                              <button type="button" class="labelImage" (click)="triggerFileInput()"><strong>{{'pass.browse' | translate}}</strong></button>
                                              {{'pass.or' | translate}}
                                              <button type="button" class="labelImage" (click)="typeURL()"><strong>{{'enterURL' | translate}}</strong></button>
                                              <input #fileInput type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                          </h2>    
                                      </div>
                                      <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                  </div>
                              </mat-card>
                          </div>
                      </div>
                    </mat-expansion-panel>

                    <div class="d-flex flex-row" *ngIf="field.input && fieldNotUrl(field.formControlName) && field.formControlName=='imageLinkURL' ">
                      <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>{{field.inputLabel | translate}}</mat-label>
                          <input formControlName="field.formControlName" type="url" matInput placeholder="https://" >
                          <mat-error>{{'url_pattern_invalid' | translate}}  </mat-error>
                          <mat-hint>{{'url_pattern_detailed' | translate}}  </mat-hint>
                      </mat-form-field> 
          
                      <button mat-icon-button [matMenuTriggerFor]="pickDocument"  style="color:var(--warmOrange);" disabled>
                          <span class="material-symbols-outlined">more_vert</span>
                      </button>
                    
                      <mat-menu #pickDocument="matMenu">
                          <button mat-menu-item (click)="fileInput.click()" disabled>
                              <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                              {{'pass.pick_doc' | translate}}
                          </button>
                          <input type="file" id="myfile" name="myfile" style="display: none;"(change)="readDocument($event)" #fileInput>
                      </mat-menu>
                    </div>

                    <mat-card class="mb-4" *ngIf="field.input && fieldNotUrl(field.formControlName) && field.formControlName=='imageUrl' ">
                      <mat-card-content>
                        <div class="p-2">
                            <div *ngIf="urlData">
                                <div class="d-flex align-items-center mb-3">
                                    <mat-icon class="material-symbols-outlined" >image</mat-icon>
                                    <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                                </div>
                                <div class="d-flex justify-content-stretch flex-column">
                                    <div *ngIf="urlData" class="custom-form-field" >
                                        <img [src]="urlData" style="width:100%; border-radius:5px;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                    </div>
                                    <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>
                                    <div class="form-fields mt-3">
                                        <div class="d-flex align-items-center">
                                            <mat-form-field appearance="outline" class="flex-grow-1">
                                                <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                                <input type="text" formControlName="url" matInput (change)="checkNewUrl()"><!---->
                                            </mat-form-field>
                                            <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="Delete Image">
                                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                                <mat-card class="upload-section text-center justify-content-center">
                                    <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                        <div class="p-2 mb-4">
                                            <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                            <h2 class="px-3">{{'pass.fileUploadMessage' | translate}}
                                                <button type="button" class="labelImage" (click)="triggerFileInput()"><strong>{{'pass.browse' | translate}}</strong></button>
                                                {{'pass.or' | translate}}
                                                <button type="button" class="labelImage" (click)="typeURL()"><strong>{{'enterURL' | translate}}</strong></button>
                                                <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                            </h2>    
                                        </div>
                                        <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </form>
            
          </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!emptyLanguages" align="end">
  <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
  <button mat-button class="orange-button" (click)="onSaveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="emptyLanguages" align="end">
  <button mat-button class="orange-button" (click)="onCancelEdit();">{{'Ok' | translate}}</button>
</mat-dialog-actions>