import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { TemplatesService } from '../service/templates.service';
import { AuthService } from '../service/auth.service';
import {build, version} from './../../buildVersion';
import {project} from './../../buildVersion';
import { environment } from '../../environments/environment';
import { getAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.css']
})

export class LeftNavbarComponent implements OnInit{
  userData:any
  links=[
    "account",
    "passTemplates",
    "contentTypes",
    "tridys",
    "media",
    "products",
    "purchases",
    "taskTemplates",
    "finishedTasks",
    "users"
  ]

  linkIcon=[
    {
      name:"Account",
      route:"account",
      icon:"account_balance"
    },

    {
      name:"Pass Templates",
      route:"passTemplates",
      icon:"view_agenda"
    },
    {
      name:"Content Types",
      route:"contentTypes",
      icon:"inbox"
    },
    {
      name:"Tridys",
      route:"tridys",
      icon:"sell"
    },
    {
      name:"Media",
      route:"media",
      icon:"perm_media"
    },{
      name:"Products",
      route:"products",
      icon:"shopping_cart"
    },{
      name:"Purchases",
      route:"purchases",
      icon:"production_quantity_limits"
    },{
      name:"Task Templates",
      route:"taskTemplates",
      icon:"build"
    },{
      name:"Finished Tasks",
      route:"finishedTasks",
      icon:"handyman"
    },{
      name:"Users",
      route:"users",
      icon:"person"
    },
  ]
  accountName:any
  environmentName=environment.firebase
  version=version
  buildEnvironment:string
  buildNumber=build
  navigationSubscription: any;

  constructor(private router: Router,
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private auth: AuthService,
    private templateService: TemplatesService){

  }

  ngOnInit():void{
    setTimeout(() => {
      if(this.settingsService.userAccount$ && this.settingsService.userAccount$.displayName){
        this.accountName = this.settingsService.userAccount$.displayName
      }else{
        this.accountName=""
        this.accountName = this.settingsService.currentUser$.displayName
      }
    }, 200);
    this.userData={
      'account':'',
      'user':''
    }  
    if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
    if(this.environmentName.projectId=="narravero-eu-prod"){
      this.buildEnvironment="Narravero "
    }
    if(this.environmentName.projectId=="narravero-eu-test"){
      this.buildEnvironment="Narravero TEST"
    }
    
    if(this.settingsService.userAccount$ && this.settingsService.userAccount$.displayName){
      this.accountName = this.settingsService.userAccount$.displayName
    }else{
      this.accountName=""
      this.accountName = this.settingsService.currentUser$.displayName
    }

    if(this.settingsService.currentUser$.displayName){
      this.accountName = this.settingsService.currentUser$.displayName
    }

  }

  async selectList(x:any){
    const selected=document.getElementById(x)
    this.links.forEach( (el:any) => {
      if(el==x){
        if(selected){
          selected.style.backgroundColor="var(--warmOrange)"
          selected.style.color="white"
        }
      }else{
        const elLink=document.getElementById(el)
        if(elLink){
          elLink.style.backgroundColor="#0000"
          elLink.style.color="black"
        }
      }
    })
  }
}
