import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {Template} from './../../../../backend/src/template.dto.js';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  allPassDefinitionsSubject$ = new BehaviorSubject<Template[]|undefined>(undefined)
  ngUnsubscribe: Subject<void> = new Subject<void>();
  allDataUsers$:any
  loggedUser:any
  darkAsLight :Subject<boolean> = new Subject<boolean>();//=true
  darkAsLightValue :boolean=false

  constructor(private firestore: AngularFirestore,
    private auth: AuthService) { }


    deactiveDarkAsLight(){
      this.darkAsLightValue = false
      this.darkAsLight.next(this.darkAsLightValue)
      // this.darkAsLight.next(!this.darkAsLightValue)
    }
    activeDarkAsLight(){
      this.darkAsLightValue = true
      this.darkAsLight.next(this.darkAsLightValue)
    }

    changeDarkAsLight(){
      this.darkAsLightValue = !this.darkAsLightValue
      this.darkAsLight.next(this.darkAsLightValue)  
    }

  getDataUsersFirestore(){
    return this.firestore.collection('Users').snapshotChanges();
  }

  observeDataUsers(){
    const a= this.getDataUsersFirestore()

    a.subscribe( res => {
      this.allDataUsers$= res.map((e:any) => {
        const data = e.payload.doc.data();
        data.id = e.payload.doc.id;
        return data;
      })

    })
    return this.allDataUsers$
  }

  getData(){
    return this.allDataUsers$
  }
  getDataLoggedUser(){
  }
}



