import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

@Component({
  selector: '[app-model3d-test]',
  templateUrl: './model3d-test.component.html',
  styleUrls: ['./model3d-test.component.css']
})
export class Model3dTestComponent implements OnInit {
  @Input('app-model3d-test') data:any;
  @ViewChild('canvas')
  private canvasRef!: ElementRef;

  oldData:any
  model3dData:any
  editMode:any

  //* Cube Properties
  @Input() public rotationSpeedX: number = 0.05;
  @Input() public rotationSpeedY: number = 0.01;
  @Input() public size: number = 200;
  @Input() public texture: string = "/assets/texture.jpg";

  // @Input() public texture: string = "C:\Users\Maria\Pictures\appclipA.PNG";

  //* Stage Properties
  @Input() public cameraZ: number = 400; //camera position
  @Input() public fieldOfView: number = 1;//field of view of the camera
  @Input('nearClipping') public nearClippingPlane: number = 1;
  @Input('farClipping') public farClippingPlane: number = 1000;

  //? Helper Properties (Private Properties);
  private camera!: THREE.PerspectiveCamera;
  private get canvas(): HTMLCanvasElement {
    // if(this.canvasRef) 
    return this.canvasRef.nativeElement;
  }
  private loader = new THREE.TextureLoader();
  private geometry = new THREE.BoxGeometry(1, 1, 1);
  private material = new THREE.MeshBasicMaterial({ map: this.loader.load(this.texture) });
  private cube: THREE.Mesh = new THREE.Mesh(this.geometry, this.material);
  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;

  ngOnInit(): void {
    this.oldData=this.data[0]
    this.model3dData=this.data[0]
    this.editMode=this.data[1]

  }

/**
   *Animate the cube
 
   */
   private animateCube() {
    // this.cube.rotation.x += this.rotationSpeedX;
    this.cube.rotation.y += this.rotationSpeedY;
  }

  /**
   * Create the scene
   *
   * @private
   * @memberof CubeComponent
   */
  private createScene() {
    //* Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000)
    this.scene.add(this.cube);
    //*Camera
    let aspectRatio = this.getAspectRatio();
    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    )
    this.camera.position.z = this.cameraZ;
  }

  private getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }
   /**
 * Start the rendering loop
 *
 * @private
 * @memberof Model3dPhoneComponent
 */
   private startRenderingLoop() {
    //* Renderer
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    let component: Model3dTestComponent = this;
    (function render() {
      requestAnimationFrame(render);
      component.animateCube();
      component.renderer.render(component.scene, component.camera);
    }());

    
  }



  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();
    // this.create()
  }

}


