<h2 mat-dialog-title>{{'error' | translate }}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <p>{{message}}</p>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="activeCancel" mat-button style="color: var(--warmOrange);" (click)="onCancel();">{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSave();">OK</button>
</mat-dialog-actions>
