<section class="container">
    <div class="row mt-4">
        <div class="mt-3 mb-2">
            <h1 class="mb-1">{{'taskTemplateGropus.title' | translate}}</h1>
            <p style="font-family:'MyFont-Light';">{{'headlines.subTracerGroups' | translate}}</p>
        </div>
        <div class="col-12">
            <section class="topNavbar">
                <button mat-button style="color:var(--warmOrange);"  *ngIf="!activeEdit" (click)="activeEditTaskGroup();" id="btn-create" [disabled]="!tasktemplateUserRights_update">
                    {{'edit'  | translate}}
                </button>
                <!-- <button mat-icon-button style="color:var(--warmOrange);" (click)="addGroup();" id="btn-create" [disabled]="!tasktemplateUserRights_create">
                    <span class="material-symbols-outlined">
                        add
                    </span>
                </button> -->
            
                <button *ngIf="!activeEdit" mat-button style="background-color:white; color:var(--accentGlobal);" (click)="addGroup();" id="btn-create" [disabled]="!tasktemplateUserRights_create">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                        {{'taskTemplateGropus.add_group'  | translate}}
                    </div>
                </button>
            
                <!-- <button mat-fab extended  *ngIf="!activeDelete" style="background-color:white; color:var(--accentGlobal);" (click) = "deleteTaskGroups();" id="btn-delete" [disabled]="!tasktemplateUserRights_delete">
                    <mat-icon>delete</mat-icon>
                    {{'taskTemplateGropus.delete_group'  | translate}}
                </button> -->
            
                <button mat-raised-button *ngIf="activeDelete  || activeEdit" style="background-color:var(--accentGlobal); color:white;" (click) = "done();" id="btn-delete" [disabled]="!tasktemplateUserRights_delete">
                    {{'taskTemplateGropus.delete_done'  | translate}}
                </button>
            
            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <section class="d-flex flex-wrap align-items-start justify-content-center" id="groupsContainer">
                <div class="p-2" style="width:40%;  max-height:70vh;overflow-y: auto;" id="groupsContainerNames">
                    <span class="text-muted text-uppercase">{{'taskTemplateGropus.groups' | translate}}</span>
                    <mat-list role="list" class="list-group">
                        <mat-list-item *ngFor="let group of allGroups; let index=index;" role="listitem" style="color:var(--warmOrange);" class="list-group-item" (click)="filterByGroup(group, index);"> 
                            <div class="d-flex flex-row justify-content-between align-items-center">
                                <span *ngIf="groupSelected == group.id" class="text-wrap text-break" style="color:var(--warmOrange);"> {{group.title}}</span>
                                <span *ngIf="groupSelected != group.id" class="text-wrap text-break"> {{group.title}}</span>
                                <div>
                                    <button *ngIf="activeEdit" mat-icon-button (click)="editGroup(group, index);">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                            edit
                                        </span>
                                    </button>
            
                                    <button *ngIf="activeEdit" mat-icon-button (click)="deleteGroup(group, index);">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                            delete
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            
                <div class="d-flex flex-column " style=" width:50%;max-height:70vh; overflow-y: auto;" id="tasksList">
                    <div style="width:90%; margin:auto;" class="p-2">
                        <span class="text-muted text-uppercase">{{'taskTemplateGropus.in_group' | translate}}</span>
                        <mat-list role="list" class="list-group">
                            <mat-list-item *ngFor="let group of InGroupList; let index=index;" role="listitem" style="color:var(--warmOrange);" class="list-group-item" > 
                                <div class="d-flex flex-row justify-content-between align-items-center">
                                    <span *ngIf="groupSelected == group.id" class="text-wrap text-break" style="color:var(--warmOrange);"> {{group.title}}</span>
                                    <span *ngIf="groupSelected != group.id" class="text-wrap text-break"> {{group.title}}</span>
                                   
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
            
                    <div style="width:90%; margin:auto;" class="p-2">
                        <span class="text-muted text-uppercase">{{'taskTemplateGropus.others' | translate}}</span>
                        <mat-list role="list" class="list-group">
                            <mat-list-item *ngFor="let group of othersList; let index=index;" role="listitem" style="color:var(--warmOrange);" class="list-group-item" > 
                                <div class="d-flex flex-row justify-content-between align-items-center">
                                    <span class="text-wrap text-break"> {{group.title}}</span>
            
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>