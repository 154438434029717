import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { deepCopy, supportUrlStructure } from '../../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service'; 


@Component({
  selector: 'app-billboard-video-edit',
  templateUrl: './billboard-video-edit.component.html',
  styleUrls: ['./billboard-video-edit.component.css']
})
export class BillboardVideoEditComponent {
  billboardVideoEditData:any
  oldData:any
  changes=false
  url:any
  noUrl=false
  billboardVideoEditForm!:FormGroup
  newVideo:any=[]
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  urlData:any
  @ViewChild('media') myVideo: ElementRef;
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  languagesList = languagesList
  defaultLanguage:string
  videoConfigurations = [
    {
      translation:'pass.video.auto_play',
      controlName:"autoPlay",
      icon:"autoplay"
    },
    {
      translation:'pass.video.auto_replay',
      controlName:"autoRePlay",
      icon:"repeat"
    },
    {
      translation:'pass.video.muted',
      controlName:"muted",
      icon:"volume_off"
    },
    {
      translation:'pass.video.controls',
      controlName:"videoControls",
      icon:"tune"
    }
  ]
  dynamicUrl = false

  constructor(public dialogRef: MatDialogRef<BillboardVideoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: UploadService,
    private commentService: CommentsService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private translate:TranslateService,
    private settingsService: SettingsService){
      dialogRef.disableClose=true
    }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage
    this.oldData=this.data.values
    this.billboardVideoEditData=this.data.values
    this.url=this.billboardVideoEditData.url
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']

    if(this.data['url']){
      this.urlData = deepCopy(this.data['url'])
    }else{
      if(this.billboardVideoEditData.url ){
        // this.supportUrl(this.billboardVideoEditData.url)
        let url = supportUrlStructure(this.billboardVideoEditData.url, this.passId, this.settingsService.contextId$)
        this.urlData = url
      }
    }

    this.billboardVideoEditForm= this.fb.group({
      id:[],
      type:[],
      subtitle:[],
      url:[],
      previewPicture:[],
      copyright:[],
      autoPlay:[],
      autoRePlay:[],
      muted:[],
      videoControls:[],
      size:[],
      name: [],
      filter:[]
    })

    const {id, type, filter, subtitle, url, previewPicture, copyright, autoPlay, autoRePlay, muted, videoControls, size, name} = this.billboardVideoEditData
    this.billboardVideoEditForm.patchValue({
      id: id? id : undefined,
      type: type ? type: undefined,
      subtitle: subtitle ? subtitle: undefined,
      url: url ? url : undefined,
      previewPicture: previewPicture ? previewPicture : undefined,
      copyright: copyright ? copyright : undefined,
      autoPlay: autoPlay ? autoPlay : false,
      autoRePlay: autoRePlay ? autoRePlay : false,
      muted: muted ? muted : false,
      videoControls: videoControls ? videoControls : false,
      size: size ? size : undefined,
      name: name ? name : undefined,
      filter: filter ? filter : undefined
    })

    this.billboardVideoEditForm.get("url").valueChanges.subscribe( (newurl) => {
      if(newurl?.match(/\${(.+)\}/)){
        this.dynamicUrl = true
        this.urlData = './../../assets/img/default.jpg'
      }else
        this.dynamicUrl = false

      // if(this.addUrl && !this.dynamicUrl){
      //   this.url = newurl
      //   // this.supportUrl(newurl)
      //   let url = supportUrlStructure(newurl, this.passId, this.settingsService.contextId$)
      //   this.urlData = url
      // }

    })
  }

  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      this.newVideo = []
      var reader = new FileReader();
      this.newVideo.push(file);
      reader.readAsDataURL(file);
  
      reader.onload = async (event) => {
        if (event.target) {
          const urlNew = event.target.result as string;
          if (urlNew) {
            this.url = urlNew;
            this.changes = true;
            this.urlData = "";
            this.noUrl = false;
            this.addUrl = false
  
            try {
              const uploadURL = await this.uploadService.uploadNewBillboardVideo(this.passId, this.newVideo, this.widgetNumber);
  
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
              }
              this.billboardVideoEditForm.patchValue({
                url: this.url
              });
            } catch (error) {
              console.log(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
    if (fileToUpload.size > maxSizeInBytes) {
      const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
    this.newVideo = []
    this.newVideo.push(fileToUpload);
    const newUrl = files[0].url;
    const type = fileToUpload.type.split("/");
  
    if (type[0] === 'video') {
      if (newUrl) {
        this.drop = true;
        this.url = newUrl;
        this.changes = true;
        this.urlData = "";
        this.addUrl = false
  
        try {
          const uploadURL = await this.uploadService.uploadNewBillboardVideo(this.passId, this.newVideo, this.widgetNumber);
  
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
          }
  
          this.billboardVideoEditForm.patchValue({
            url: this.url
          });
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    } else {
      const message = this.translate.instant('SnackBarConstants.VIDEO_TYPE');
      this.commentService.addSnackBar.emit(message);
    }
  }

  async saveChanges(){
    this.commentService.progressSpin.emit(true)

    this.commentService.progressSpin.emit(false)

    if(this.url && !this.dynamicUrl){
      if(this.billboardVideoEditForm.value.size?.length==0 || !this.billboardVideoEditForm.value.size || (this.billboardVideoEditForm.value.size && this.billboardVideoEditForm.value.size[0]==0 && this.billboardVideoEditForm.value.size[1]==0)){
        await this.onVideoMetadataBillVideo()
      }
    }else{
      this.noUrl=true
    }

    
    this.dialogRef.close([this.billboardVideoEditForm.value, true, this.newVideo]);
  }

  cancelChanges(){
    this.dialogRef.close([this.oldData, false])
  }

  deleteImg(x:any){
    this.url=""
    this.billboardVideoEditForm.patchValue({url:this.url, size:undefined})
    this.urlData=""
  }

  editImg(url){
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: this.billboardVideoEditForm.value,
        type: this.billboardVideoEditForm.value.type,
        passId: this.passId

      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[0].url!=this.url){
          this.urlData=""
        }
        this.url = result[0].url
        this.billboardVideoEditForm.patchValue({url:this.url})

        // if(this.url){
          // this.supportUrl(this.url)
        // }
      }
    });
  }

  supportUrl(url){
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName
  
        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }
  
        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }
      }catch(errro){}
    }
  }

  onVideoMetadataBillVideo(event?: Event): void {
    try {
      const videoElement: HTMLVideoElement = this.myVideo.nativeElement;
      const width: number = videoElement.videoWidth;
      const height: number = videoElement.videoHeight;
      this.billboardVideoEditForm.patchValue({size : [ width, height]})    
    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  checkNewUrl(){
    if(!this.dynamicUrl){
    this.url = this.billboardVideoEditForm.value.url
    // this.supportUrl(this.billboardVideoEditForm.value.url)
    let url = supportUrlStructure(this.billboardVideoEditForm.value.url, this.passId, this.settingsService.contextId$)
    this.urlData = url
  }
  }


  returnChangesFilters($event){
    console.log($event)
    this.billboardVideoEditForm.patchValue({filter: $event.newFilters})
  }
}
