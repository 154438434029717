import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommentsService } from './service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router,RouterEvent } from '@angular/router';
import { filter } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'na-web';
  showProgress = false
  shouldHideNavbar = false;


  constructor(private snackBar: MatSnackBar,
    private comments: CommentsService, 
    private translate: TranslateService,
    private router: Router
    ){
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de/) ? browserLang : 'en');

    }

    ngOnInit(){
      this.comments.addSnackBar.subscribe((message) => { this.openSnackBar(message) })
  
      this.comments.progressSpin.subscribe((bool) => {
        setTimeout(() => {
          this.showProgress = bool
        })
      })
  
      this.router.events
  /*       .pipe(
          filter( (event: RouterEvent) => event instanceof NavigationEnd),
          // filter( (event: RouterEvent) => event instanceof NavigationEnd),
        ).subscribe( (event: RouterEvent) => {
          // this.shouldHideNavbar = event.url.startsWith('home');
          // this.shouldHideNavbar = event.url.endsWith('logIn');
          this.shouldHideNavbar = event.url.includes('logIn');
  
          if(event.url.includes('register')){
            this.shouldHideNavbar=true
          }
        }) */
  
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          this.shouldHideNavbar = event.url.includes('logIn');
    
          if (event.url.includes('register')) {
            this.shouldHideNavbar = true;
          }
        });
  
    }

  openSnackBar(message, action = "OK") {
    if(!message) {
      message = "Error"
    }
    this.comments.progressSpin.emit(false)
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass:['snackBarStyle']
    })
  }
}
