import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { deepCopy } from '../../../../../backend/utils/object';
import { injectGlobal } from '@emotion/css';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-buttons-phone]',
  templateUrl: './buttons-phone.component.html',
  styleUrls: ['./buttons-phone.component.css']
})
export class ButtonsPhoneComponent {
  @Input('app-buttons-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  idToShow:any
  routerUrl:any
  buttonsData:any
  widgetNumber:any
  editMode:any
  buttonStyle:any
  buttonUrls:any={}


  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.buttonsData = this.data[0]

    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    setTimeout(() => {
      this.styleButtons()
      this.supportButtonsUrls()
    }, 300);
  }


  ngAfterViewInit(){
    if(this.buttonsData.buttons){
      this.buttonsData.buttons.forEach((bbtn, index) => {
        const button = deepCopy(bbtn)
    
        if(button.customize){
          // if(!button.borderWidth){
          //   if(this.buttonsData.buttonsStyle.borderWidth){
          //     button.borderWidth = this.buttonsData.buttonsStyle.borderWidth
          //   }else{
          //     button.borderWidth = 0
          //   }
          // }
          if(!button.borderWidth){
            button.borderWidth = 0
          }
          
          document.getElementById(button.id).style.backgroundColor=button.backgroundColor;
          document.getElementById(button.id).style.color=button.foregroundColor;
          document.getElementById(button.id).style.border=button.borderWidth+'px solid ' + button.borderColor;

          if(button.url){
            document.getElementById(button.id).style.color=button.foregroundColor;

          }
        }else{
          const buttonstyle = deepCopy(this.buttonsData.buttonsStyle)
          if(!buttonstyle.borderWidth){
            buttonstyle.borderWidth = 0
          }
   
          document.getElementById(button.id).style.backgroundColor=buttonstyle.backgroundColor;
          document.getElementById(button.id).style.color=buttonstyle.foregroundColor;
          document.getElementById(button.id).style.border=buttonstyle.borderWidth+'px solid ' + buttonstyle.borderColor;
        }

        if(this.buttonsData.cornerRadius){
          document.getElementById(button.id).style.borderRadius=this.buttonsData.cornerRadius+'px';
        }
      });
    }
  }



  styleButtons(){
    if(this.buttonsData.buttons){
      this.buttonsData.buttons.forEach((bbtn, index) => {
        const button = deepCopy(bbtn)
    
        if(button.customize){
          // if(!button.borderWidth){
          //   if(this.buttonsData.buttonsStyle.borderWidth){
          //     button.borderWidth = this.buttonsData.buttonsStyle.borderWidth
          //   }else{
          //     button.borderWidth = 0
          //   }
          // }
          if(!button.borderWidth){
            button.borderWidth = 0
          }
          
          injectGlobal`
            #${button.id} {
              background-color: ${button.backgroundColor};
              border: ${button.borderWidth}px solid ${button.borderColor};
              color: ${button.foregroundColor};
              border-radius: ${this.buttonsData.cornerRadius}px;
              font-family: var(--plainFontName);
              font-size: 1.1em;
            }
          `
          
          document.getElementById(button.id).style.backgroundColor=button.backgroundColor;
          document.getElementById(button.id).style.color=button.foregroundColor;
          document.getElementById(button.id).style.border=button.borderWidth+'px solid ' + button.borderColor;
          document.getElementById(button.id).style.fontFamily="var(--plainFontName)"
          document.getElementById(button.id).style.fontSize = "1.1em"

          if(button.url){
            injectGlobal`
            #${button.id}>a {
              color: ${button.foregroundColor};
            }
            #${button.id}>a>span {
              color: ${button.foregroundColor};
            }
          `
          }
        }else{
          const buttonstyle = deepCopy(this.buttonsData.buttonsStyle)
          if(!buttonstyle.borderWidth){
            buttonstyle.borderWidth = 0
          }
          injectGlobal`
            #${button.id} {
              background-color: ${buttonstyle.backgroundColor};
              border: ${buttonstyle.borderWidth}px solid ${buttonstyle.borderColor};
              color: ${buttonstyle.foregroundColor};
              border-radius: ${this.buttonsData.cornerRadius}px;
              font-family: var(--plainFontName);
              font-size: 1.1em;
            }
          `

          if(button.url){
            injectGlobal`
            #${button.id}>a{
              color: ${buttonstyle.foregroundColor};
            }
            #${button.id}>a>span{
              color: ${buttonstyle.foregroundColor};
            }
          `
          }
          
          document.getElementById(button.id).style.backgroundColor=buttonstyle.backgroundColor;
          document.getElementById(button.id).style.color=buttonstyle.foregroundColor;
          document.getElementById(button.id).style.border=buttonstyle.borderWidth+'px solid ' + buttonstyle.borderColor;
          document.getElementById(button.id).style.fontFamily="var(--plainFontName)"
          document.getElementById(button.id).style.fontSize = "1.1em"
        }

        if(this.buttonsData.cornerRadius){
          document.getElementById(button.id).style.borderRadius=this.buttonsData.cornerRadius+'px';
        }
      });
    }
  }

  supportButtonsUrls(){
    if(this.buttonsData.buttons){
      this.buttonsData.buttons.forEach((bbtn, index) => {
/*         if(bbtn.title)
          bbtn.title = checkDynamicContent(bbtn.title)
        if(bbtn.url)
            bbtn.url = checkDynamicContent(bbtn.url) */
        const button = deepCopy(bbtn)
        if(button.url){
          try{
            const imgUrl = new URL(button.url)
            let hostname = environment.urlHostName
            if(imgUrl.protocol=="pass:" ){
              const parts = button.url.match(/pass:\/\/(.+)\/(.+)/)
              if(parts){
                const passTemplateID = parts[1]
                const path = parts[2]
                const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
                this.buttonUrls[button.id]=firebaseUrl
              }else{
                const newparts = button.url.match(/pass:\/\/(.+)/)
                if(newparts){
                  let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
                  newparts.forEach( (part,index)=> {
                    if(index>0){
                      firebaseUrl += '/' + part 
                    }
                  })
                  this.buttonUrls[button.id]=firebaseUrl
                }
              }
            }
    
            if(imgUrl.protocol =="tridy:"){
              const parts = button.url.match(/tridy:\/\/(.+)\/(.+)/)
              if(parts){
                  let path =""
                parts.forEach((parte, index) =>{
                  if(index>0 && index<parts.length){
                    path += "/" + parte
                  }
                })
                const firebaseUrl = `https://${hostname}/v1/pass${path}`
                this.buttonUrls[button.id]=firebaseUrl
              }else{
                const partsx = button.url.match(/tridy:\/\/(.+)/)

                let path =""
                partsx.forEach((parte, index) =>{
                  if(index>0 && index<partsx.length){
                    path += "/" + parte
                  }
                })
                const firebaseUrl = `https://${hostname}/v1/pass${path}`
                this.buttonUrls[button.id]=firebaseUrl
              }
            }

            if(imgUrl.protocol == "context:"){
              const parts = button.url.match(/context:\/\/(.+)\/(.+)/)
              let path =""
              parts.forEach((parte, index) =>{
                  if(index>0 && index<parts.length){
                      path += "/" + parte
                  }
              })
              const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
              this.buttonUrls[button.id]=firebaseUrl
            }
          }catch(erro){}
        }

      });

    }
  }


}
