export interface Iimages{
    url:string
}

export interface Iprice{
    amount:Number
    currency:string
    vat:Number
}
export interface IProduct{
    description?:string
    group?:string
    id: string
    images:Iimages[]
    manufacturer?:string
    price?:Iprice
    productId?:string
    tags?:string[]
    title?:String
    type?:string
}

export class Product{
    id: string|undefined = undefined
    description: string|undefined = undefined
    group: string|undefined = undefined
    images:Iimages[]|undefined = undefined
    manufacturer: string|undefined = undefined
    price:Iprice| undefined = undefined
    productId: string|undefined = undefined
    tags: string[]|undefined = undefined
    title: string|undefined = undefined
    type: string|undefined = undefined


    constructor(){}

    setFromAny(element:any){
        const {id, description, group, images, manufacturer, price, productId, tags, title, type} = element

        this.id = id ? id : this.id
        this.description = description ? description : this.description
        this.group = group ? group : this.group
        this.images = images ? images : this.images
        this.manufacturer = manufacturer ? manufacturer : this.manufacturer
        this.price = price ? price : this.price
        this.productId = productId ? productId : this.productId
        this.tags = tags ? tags : this.tags
        this.title = title ? title : this.title
        this.type = type ? type : this.type
        
    }

    getId(){
        if(!this.id) return undefined

        return this.id
    }

    
}