import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { checkHexColor, deepCopy, removeUndefined, removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadService } from 'src/app/service/upload.service';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { SettingsService } from 'src/app/service/settings.service';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-buttons-edit',
  templateUrl: './buttons-edit.component.html',
  styleUrls: ['./buttons-edit.component.css']
})
export class ButtonsEditComponent {
  buttonsForm!:FormGroup
  buttonsData:any
  oldData:any
  buttonsStyleData:any
  buttonsStyleForm!:FormGroup
  colorPalette:any=[]
  invalid_backgroundColor=false
  invalid_foregroundColor=false
  invalid_borderColor=false
  buttonStyleDefault:any = {
    "backgroundColor": "#FF5E38",
    "borderColor": "#FF5E38",
    "borderWidth": 0,
    "foregroundColor": "#fefefe"
  }
  newImage:any=[]
  allbuttonsForm!:FormGroup
  allButtons:any = []
  allButtonsIds:any = []
  allButtonsNumber:any = 0
  panelOpenState=false
  // urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegex = /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  passId:any
  widgetNumber:any
  url:any
  uploadDocument:any={}

  translationForm!:FormGroup
  firstLanguageContexts:any
  passLanguagesList:any
  passTranslations:any
  oldTranslations:any
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<ButtonsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    private settingsService: SettingsService,
    private uploadService: UploadService){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.initializePaletteColors()
    this.firstLanguageContexts= this.settingsService.firstLanguageContexts$

    if(this.data['translations']){
      this.passTranslations = this.data['translations']
      this.oldTranslations = deepCopy(this.data['translations'])
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }

    this.widgetNumber= this.data['widget']
    this.passLanguagesList = this.data['languages']

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })

    this.buttonsStyleForm = this.fb.group({
      backgroundColor: [],
      borderColor:[],
      foregroundColor:[],
      borderWidth:[],
    })

    this.allbuttonsForm = this.fb.group({})

    this.buttonsForm = this.fb.group({
      buttons:[],
      buttonsStyle:[],
      cornerRadius:[],
      id:[],
      type:[],
      name:[],
      filter:[]
    })

    this.buttonsData = this.data.values
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widget']


    if(this.buttonsData){
      this.oldData = deepCopy(this.buttonsData)

      const {buttons,filter, buttonsStyle, cornerRadius, id, type, name} = this.buttonsData


      this.buttonsForm.patchValue({
        buttons:buttons ? buttons : [],
        buttonsStyle: buttonsStyle ? buttonsStyle : undefined,
        cornerRadius:cornerRadius ? cornerRadius : 0,
        id:id ? id : undefined,
        type:type ? type : undefined,
        name:name ? name : undefined,
        filter: filter ? filter: undefined
      })

      if(this.buttonsData.buttons){
        this.buttonsData.buttons.forEach( (button, index) => {
          this.allButtons.push(button)
          this.allButtonsIds.push(button.id)
          this.allButtonsNumber++
          const buttonName = button.id
  
          const btn_backgroundColor = buttonName + '.' + 'backgroundColor'
          const backgroundColor_value = button.backgroundColor ? button.backgroundColor : undefined
          this.allbuttonsForm.addControl(btn_backgroundColor, new FormControl(backgroundColor_value))

          const btn_foregroundColor = buttonName + '.' + 'foregroundColor'
          this.allbuttonsForm.addControl(btn_foregroundColor, new FormControl(button.foregroundColor))
  
          const btn_borderColor = buttonName + '.' + 'borderColor'
          this.allbuttonsForm.addControl(btn_borderColor, new FormControl(button.borderColor))

          const btn_borderWidth = buttonName + '.' + 'borderWidth'
          const borderWidth_value = button.borderWidth ? button.borderWidth : 0
          this.allbuttonsForm.addControl(btn_borderWidth, new FormControl(borderWidth_value))
  
          const btn_customize = buttonName + '.' + 'customize'
          this.allbuttonsForm.addControl(btn_customize, new FormControl(button.customize))
  
          const btn_id = buttonName + '.' + 'id'
          this.allbuttonsForm.addControl(btn_id, new FormControl(button.id))

          const btn_url = buttonName + '.' + 'url'
          this.allbuttonsForm.addControl(btn_url, new FormControl(button.url, [ Validators.pattern(this.urlRegex)]))
  
          const btn_title = buttonName + '.' + 'title'
          this.allbuttonsForm.addControl(btn_title, new FormControl(button.title))
  
        })
      }
    }

    if(this.buttonsData.buttonsStyle){
      this.buttonsStyleData = this.buttonsData.buttonsStyle
      
      const { backgroundColor, foregroundColor, borderColor,borderWidth} = this.buttonsStyleData
      this.buttonsStyleForm.patchValue({
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        borderColor: borderColor ? borderColor : undefined,
        foregroundColor:foregroundColor ? foregroundColor : undefined,
        borderWidth: borderWidth ? borderWidth : 0
      })
    }else{
      this.buttonsStyleData = this.buttonStyleDefault
      
      const { backgroundColor, foregroundColor, borderColor,borderWidth} = this.buttonsStyleData
      this.buttonsStyleForm.patchValue({
        backgroundColor: backgroundColor ? backgroundColor : "#FFSE38",
        borderColor: borderColor ? borderColor : "#FFSE38",
        foregroundColor:foregroundColor ? foregroundColor : "#fefefe",
        borderWidth: borderWidth ? borderWidth : 0
      })
    }

  }

 /* drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allButtons, event.previousIndex, event.currentIndex);
    this.updateFormValue()
  }

  async updateFormValue(){
    let buttons=[]
  
    this.allButtons.forEach((w:any,ind:any) =>{
      buttons.push(w)
    })
    this.buttonsForm.patchValue(buttons)
    this.allbuttonsForm = this.fb.group({})

    this.updateButtonsForms()

  }

  updateButtonsForms(){
    if(this.allButtons){
      this.allButtons.forEach( (button, index) => {
        this.allButtons.push(button)
        this.allButtonsIds.push(button.id)
        this.allButtonsNumber++
        const buttonName = button.id

        const btn_backgroundColor = buttonName + '.' + 'backgroundColor'
        const backgroundColor_value = button.backgroundColor ? button.backgroundColor : undefined
        this.allbuttonsForm.addControl(btn_backgroundColor, new FormControl(backgroundColor_value))

        const btn_foregroundColor = buttonName + '.' + 'foregroundColor'
        this.allbuttonsForm.addControl(btn_foregroundColor, new FormControl(button.foregroundColor))

        const btn_borderColor = buttonName + '.' + 'borderColor'
        this.allbuttonsForm.addControl(btn_borderColor, new FormControl(button.borderColor))

        const btn_borderWidth = buttonName + '.' + 'borderWidth'
        const borderWidth_value = button.borderWidth ? button.borderWidth : undefined
        this.allbuttonsForm.addControl(btn_borderWidth, new FormControl(borderWidth_value))

        const btn_customize = buttonName + '.' + 'customize'
        this.allbuttonsForm.addControl(btn_customize, new FormControl(button.customize))

        const btn_id = buttonName + '.' + 'id'
        this.allbuttonsForm.addControl(btn_id, new FormControl(button.id))

        const btn_url = buttonName + '.' + 'url'
        this.allbuttonsForm.addControl(btn_url, new FormControl(button.url, [ Validators.pattern(this.urlRegex)]))

        const btn_title = buttonName + '.' + 'title'
        this.allbuttonsForm.addControl(btn_title, new FormControl(button.title))

      })
    }
  }*/
  
  initializePaletteColors(){
    var colors=[]
    const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
    const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
    const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
    const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
    colors.push(warmOrange)
    colors.push(darkblue)
    colors.push(pureWhite)
    colors.push(salbei)
    this.colorPalette=colors
  }

  updateBtnList(){
    let allbuttonsnew = []
    for (let i = 0; i < this.allButtonsNumber; i++)  {

    }
  }

  getCustomizeOptionOfButton(index,id){
    const name = id+'.customize'
    return this.allbuttonsForm.value[name]
  }

  deleteBtn(indexSelected, id){
    let indexToDelete
    let index = id
    this.allButtonsIds.forEach( (btn, index)=> {
      if(btn==id){
        indexToDelete=index
      }
    })
    this.allButtons.splice(indexToDelete,1)
    this.allButtonsIds.splice(indexToDelete,1)

    const btn_backgroundColor = index + '.' + 'backgroundColor'
    const btn_foregroundColor = index + '.' + 'foregroundColor'
    const btn_borderColor = index + '.' + 'borderColor'
    const btn_borderWidth = index + '.' + 'borderWidth'
    const btn_customize = index + '.' + 'customize'
    const btn_id = index + '.' + 'id'
    const btn_url = index + '.' + 'url'
    const btn_title = index + '.' + 'title'
    this.allbuttonsForm.removeControl(btn_backgroundColor)
    this.allbuttonsForm.removeControl(btn_foregroundColor)
    this.allbuttonsForm.removeControl(btn_borderColor)
    this.allbuttonsForm.removeControl(btn_borderWidth)
    this.allbuttonsForm.removeControl(btn_customize)
    this.allbuttonsForm.removeControl(btn_id)
    this.allbuttonsForm.removeControl(btn_url)
    this.allbuttonsForm.removeControl(btn_title)
  }

  addButton(){
    let button = {
      backgroundColor: "#FF5E38",
      borderColor: "#FF5E38",
      customize: false,
      foregroundColor: "#fefefe",
      id: "BA40BFDA-29B0-4EC7-8CA3-86B0887A01B0",
      title: "Button",
      url: ""
    }
    button['id'] = this.makeBtnId(8)


    this.allButtons.push(button)
    this.allButtonsIds.push(button['id'])
    const buttonNum = deepCopy(button.id)

    const btn_backgroundColor = buttonNum + '.' + 'backgroundColor'
    const backgroundColor_value = button.backgroundColor ? button.backgroundColor : undefined
    this.allbuttonsForm.addControl(btn_backgroundColor, new FormControl(backgroundColor_value))

    const btn_foregroundColor = buttonNum + '.' + 'foregroundColor'
    this.allbuttonsForm.addControl(btn_foregroundColor, new FormControl(button.foregroundColor))

    const btn_borderColor = buttonNum + '.' + 'borderColor'
    this.allbuttonsForm.addControl(btn_borderColor, new FormControl(button.borderColor))

    const btn_borderWidth = buttonNum + '.' + 'borderWidth'
    const borderWidth_value = button['borderWidth'] ? button['borderWidth'] : 0
    this.allbuttonsForm.addControl(btn_borderWidth, new FormControl(borderWidth_value))

    const btn_customize = buttonNum + '.' + 'customize'
    this.allbuttonsForm.addControl(btn_customize, new FormControl(button.customize))

    const btn_id = buttonNum + '.' + 'id'
    this.allbuttonsForm.addControl(btn_id, new FormControl(button.id))

    const btn_url = buttonNum + '.' + 'url'
    this.allbuttonsForm.addControl(btn_url, new FormControl(button.url))

    const btn_title = buttonNum + '.' + 'title'
    this.allbuttonsForm.addControl(btn_title, new FormControl(button.title))
    this.allButtonsNumber++
  }

  makeBtnId(length) {
    let result = '';
    const characters = '--ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  onLanguageEditorOfButton( button , index){
    let alltranslationsWidget = {}
    let translationsWidget
    
    if(this.translationForm.value.translations){
        translationsWidget = Object.keys(this.translationForm.value.translations) 
      }else{
        translationsWidget = []
      }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.buttonsForm.value.id) && ent[0].includes(this.allButtons[index].id)){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
     })
    }

    let dialogRef = this.dialog.open(LanguageEditorDialogComponent, {
      data:{
        translations: this.translationForm.value.translations,
        fields: this.buttonsData,
        columns: ['title', 'url'],
        values: this.allButtons[index],
        type: "buttons",
        valuesTranslated: alltranslationsWidget,
        languages: translationsWidget,
        widgetId: this.buttonsForm.value.id + '_' + this.allButtons[index].id,
        passId:this.passId,
        widgetNumber: this.widgetNumber
      },
    });
    let newtranslations = {}

    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }
    
    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          //------------------------widgetData on first Language translations
          // if(lang == this.firstLanguageContexts){
          //   if(this.textEditForm.value[field]){
          //     this.textEditForm.patchValue({[field]: value[1]})
          //   }
          // }
          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.oldTranslations[lang] && this.oldTranslations[lang][tranlationField])
                newtranslations[lang][tranlationField] = ''

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
    })
  }


  readDocument(event: any, button: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
      
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Optional: Validate file size
      const maxSizeInBytes = 10 * 1024 * 1024; // Example: 10 MB size limit
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Proceed with reading and uploading the file
      const reader = new FileReader();
      this.newImage = file;
      reader.readAsDataURL(file); // Read file as data URL
      
      reader.onload = async (event) => { // Called once readAsDataURL is completed
        if (event.target) {
          const urlNew = event.target.result;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
            
            if (uploadURL) {
              this.url = uploadURL;
              const name = button.id + '.url';
              this.allbuttonsForm.patchValue({
                [name]: this.url
              });
              this.uploadDocument[button.id] = true;
            }
          } catch (error) {
            console.error('Error uploading document', error);
          }
        }
      };
    }
  }
  

  onCancelEdit(){
    this.dialogRef.close([this.buttonsData, false])

  }

  onSaveEdit(){
    // const buttonsStyle = deepCopy( this.buttonsStyleForm.value)
    // removeUndefinedValuesFromObject(buttonsStyle)
    const buttonsStyle = this.getButtonsStyle(this.buttonsStyleForm.value)
    const allbuttons = Object.entries(this.allbuttonsForm.value)
    let newbuttons = []

    this.allButtonsIds.forEach((button, index) => {
      
      let field = {}

      allbuttons.forEach( (value, index)=> {
          const key = value[0].split(".")
          const vale = value[1]
        if(key[0] == button && key[1]){
          field[key[1]]  = vale
          if(key[1]=='borderWidth' && !vale)
            field[key[1]]  = 0
        }
      })
      newbuttons.push(field)
    });

    const buttons = newbuttons

    const data = {
      buttons: buttons,
      buttonsStyle: buttonsStyle,
      cornerRadius: this.buttonsForm.value.cornerRadius,
      id: this.buttonsForm.value.id,
      type:"buttons",
      name: this.buttonsForm.value.name,
      filter: this.buttonsForm.value.filter
    }

    if(!data.cornerRadius){
      data.cornerRadius = 0
    } 

    if(!data.buttonsStyle.borderWidth){
      data.buttonsStyle.borderWidth = 0
    } 

    const clean = removeUndefinedValuesFromObject(data)
    this.dialogRef.close([clean, true, , this.translationForm.value.translations])
  }
  

  returnChangesFilters($event){
    console.log($event)
    this.buttonsForm.patchValue({filter: $event.newFilters})
  }

  getButtonsStyle(data){
    let { backgroundColor, foregroundColor, borderColor,borderWidth} = data

    backgroundColor = checkHexColor(backgroundColor)
    foregroundColor = checkHexColor(foregroundColor)
    borderColor = checkHexColor(borderColor)

    return {
      backgroundColor: backgroundColor ? backgroundColor : "#FF5E38",
      borderColor: borderColor ? borderColor : "#FF5E38",
      foregroundColor:foregroundColor ? foregroundColor : "#fefefe",
      borderWidth: borderWidth ? borderWidth : 0
    }
  
  }
}
