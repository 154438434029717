import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillboardImageEditComponent } from '../editWidgets/billboard-image-edit/billboard-image-edit.component';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, deepCopy, supportUrlStructure } from '../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-billboard-image-phone]',
  templateUrl: './billboard-image-phone.component.html',
  styleUrls: ['./billboard-image-phone.component.css']
})
export class BillboardImagePhoneComponent implements OnInit {
  @Input('app-billboard-image-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  billboardImgData:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  idToShow:any
  routerUrl:any
  url:any
  linkURL:any
  dynamic = false

  constructor(public dialog: MatDialog,
    private settingsService: SettingsService,
    private router: ActivatedRoute){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit(): void{
    this.billboardImgData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
        this.widgetNumber = this.data[2]
    }

    if(this.billboardImgData.url){
      try{
        const partsd = this.billboardImgData.url.includes("${")
        if(partsd){
          this.dynamic = true
          //this.billboardImgData.url = checkDynamicContent(this.billboardImgData.url)
        }
        let url = supportUrlStructure(this.billboardImgData.url, this.idToShow, this.settingsService.contextId$)
        if(url)
         this.url = url
        // const imgUrl = new URL(this.billboardImgData.url)
        // let hostname = environment.urlHostName
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.billboardImgData.url.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.url=firebaseUrl
        //   }else{
        //     const newparts = this.billboardImgData.url.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.url=firebaseUrl
        //     }
        //   }
        // }

        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.billboardImgData.url.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.url=firebaseUrl
        // }
      }catch(erro){}
    }

    if(this.billboardImgData.linkURL){
      try{
        this.linkURL = this.billboardImgData.linkURL
        let linkUrl = supportUrlStructure(this.billboardImgData.linkURL, this.idToShow, this.settingsService.contextId$)
        if(linkUrl)
        this.linkURL = linkUrl
        // const imgUrl = new URL(this.billboardImgData.linkURL)
        // let hostname = environment.urlHostName
        
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.billboardImgData.linkURL.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.linkURL=firebaseUrl
        //   }else{
        //     const newparts = this.billboardImgData.linkURL.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.linkURL=firebaseUrl
        //     }
        //   }
        // }
        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.billboardImgData.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.linkURL=firebaseUrl
        // }
      }catch(error){}
    }
  }


  openDialogEdit():void{
    const billboardData= this.billboardImgData
    let dialogRef= this.dialog.open(BillboardImageEditComponent, {
      data: { 
        values: this.billboardImgData,
        widget: this.widgetNumber,
        passId : this.idToShow,
        url: this.url
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.billboardImgData = result[0];
        this.returnChanges.emit({newdata: this.billboardImgData, widget: this.widgetNumber, widgetType: billboardData.type})

      }
    });
  }
}
