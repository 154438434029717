import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { removeUndefined } from '../../../../backend/utils/object';

@Component({
  selector: 'app-work-task-template-dialog-icoming-outgoing',
  templateUrl: './work-task-template-dialog-icoming-outgoing.component.html',
  styleUrls: ['./work-task-template-dialog-icoming-outgoing.component.css']
})
export class WorkTaskTemplateDialogIcomingOutgoingComponent {
  type:any
  tridyTypeData:any = {}
  tridyTypeForm!:FormGroup
  tridyAction:boolean
  tasktemplateUserRights_update:any
  contentTypesList:any = []
  tridySelection = [
    {
      translation:'workTask.select_list',
      value:'scan',
    },
    {
      translation:'workTask.select_scan',
      value:'select'
    },
    {
      translation:'workTask.select_opticalScan',
      value:'opticalScan'
    }
  ]

  multiplicityList = [
    {//"0-*":
      translation:"workTask.multiplicity.endless",
      value:'0-*',
    },
    {//"1-*":
      translation:"workTask.multiplicity.atLEast_once",
      value:'1-*'
    },
    {//"0-1":
      translation:"workTask.multiplicity.maxOne",
      value:"0-1"
    },
    {//"1-1":
      translation:"workTask.multiplicity.one",
      value:"1-1"
    },
    {//"":
      translation:"workTask.multiplicity.custom",
      value:"custom"
    }
  ]
  
  constructor(
    public dialogRef: MatDialogRef<WorkTaskTemplateDialogIcomingOutgoingComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private settingService: SettingsService

  ){
    dialogRef.disableClose = true;
  }


  ngOnInit():void{
    this.tasktemplateUserRights_update = this.settingService.userRolesRights$['workTaskTemplates']['U']

    if(this.data['type']){
      this.type = this.data['type']
    }
    this.tridyTypeForm = this.fb.group({
      actions: [],
      displayTitle:[],
      contentTypeId: [, Validators.required],
      multiplicity:[],
      source:[],
      min:[],
      max:[],
      amount:[],
      amountInteger:[],
      amountUnit:[],
      sources:[],
      tridyAction: [''],
    })

    if(this.data['contentTypes']) this.contentTypesList = this.data['contentTypes']

    // if(this.data.values){
      this.tridyTypeData = this.data.values
      let { actions, displayTitle, contentTypeId, multiplicity, source, amount, amountInteger, amountUnit, sources, tridyAction} = this.tridyTypeData
      let min, max
      if(multiplicity){
        const values = multiplicity.split('-')
        min = values[0]
        max = values[1]
        if(multiplicity != "0-*" && multiplicity != "1-*" && multiplicity != "0-1" && multiplicity != "1-1" ){
          multiplicity = "custom"
        }
      }

      this.tridyTypeForm.patchValue({
        actions: actions ? actions : [],
        displayTitle: displayTitle ? displayTitle : undefined,
        contentTypeId: contentTypeId ? contentTypeId : undefined,
        multiplicity:multiplicity ? multiplicity : "0-*",
        source:source ? source : undefined,
        sources:sources ? sources : undefined,
        min: min ? min : undefined,
        max: max ? max : undefined,
        amount: amount ? amount: 'none',
        amountInteger: amountInteger ? amountInteger : false,
        amountUnit:amountUnit ? amountUnit : undefined,
        tridyAction: tridyAction || '',
      })

       // Set `tridyAction` based on `actions` array, if it exists
      if (actions && actions.length > 0) {
        this.tridyTypeForm.patchValue({
          tridyAction: actions[0],
        });
        this.tridyAction = true;
      } else {
        this.tridyTypeForm.patchValue({ tridyAction: '' });
        this.tridyAction = false;
      }

      if(this.type!='Outgoing'){
        this.tridyTypeForm.get('sources').setValidators([Validators.required])
        this.tridyTypeForm.patchValue({sources:sources ? sources : ['select']})
        this.tridyTypeForm.patchValue({ tridyAction: tridyAction ? tridyAction : '' });

        if(source){
          switch(source){
            case 'select':{
              const sourcesAdd = sources
              if(!sourcesAdd.includes('select'))
              this.tridyTypeForm.patchValue({sources:['select']})
              break;
            }
            case 'scan':{
              let sourcesAdd
              if(sourcesAdd && sourcesAdd.length !=0){
                sourcesAdd = sources
              }else{
                sourcesAdd = []
              }

              if(!sourcesAdd.includes('scanNFC')  )
              sourcesAdd.push('scanNFC')

              if(!sourcesAdd.includes('scanQR'))
              sourcesAdd.push('scanNFC')

              this.tridyTypeForm.patchValue({sources:sources})

              // this.tridyTypeForm.patchValue({sources:['scanNFC','scanQR']})
              break;
            }
            default:{
              this.tridyTypeForm.patchValue({sources:sources})
              // this.tridyTypeForm.patchValue({sources:['select']})
              break;
            }
          }
        }
      }

      if(actions && actions.length > 0){
        this.tridyAction = true
      }
    // }
  }


  changeActionValue() {
    if(this.tridyAction)
    this.tridyAction = false
    else
    this.tridyAction = true
  }
  onCancel(){
    this.dialogRef.close(['', false])
  }

  onDone(){
    let data = {}
    switch(this.type){
      case 'Incoming':{
        if(this.tridyTypeForm.value.multiplicity == 'custom'){
          let min, max 
          min = this.tridyTypeForm.value.min
          max = this.tridyTypeForm.value.max
          let multiplicity

          if(min || min ==0 || max || max ==0 ){
            multiplicity = this.tridyTypeForm.value.min +'-' + this.tridyTypeForm.value.max
          }else{
            multiplicity = "0-*"
          }
          this.tridyTypeForm.patchValue( {multiplicity: multiplicity})

        }

        if(this.tridyTypeForm.value.source && !this.tridyTypeForm.value.sources ){
          let valueSources = []
          switch(this.tridyTypeForm.value.source){
            case 'select':{
              this.tridyTypeForm.patchValue({sources:['select']})
              break;
            }
    
            case 'scan':{
              this.tridyTypeForm.patchValue({sources:['scanNFC','scanQR']})
              break;
              
            }
            default:{
              this.tridyTypeForm.patchValue({sources:['select']})
              break;
            }
          }
        }

          if(this.tridyTypeForm.value.sources && this.tridyTypeForm.value.sources[0]){
            const sources = this.tridyTypeForm.value.sources[0]
            switch(sources){
              case 'select':{
                this.tridyTypeForm.patchValue({source:'select'})
                break;
              }
      
              case 'scanQR':{
                this.tridyTypeForm.patchValue({source:'scan'})
                break;
                
              }

              case 'scanNFC':{
                this.tridyTypeForm.patchValue({source:'scan'})
                break;
                
              }
              default:{
                this.tridyTypeForm.patchValue({sources:'select'})
                break;
              }
            }
          }
        break;
      }
        case 'Outgoing':
        const tridyActionValue = this.tridyTypeForm.get('tridyAction')?.value;
        if (tridyActionValue) {
          const selectedAction = this.tridyTypeForm.get('tridyAction')?.value;
          this.tridyTypeForm.patchValue({ actions: selectedAction ? [selectedAction] : [] });
        } else {
          this.tridyTypeForm.patchValue({ actions: [] });
        }
        break;
    }
    /* if(this.tridyTypeForm.value.amountInteger){
      const amount = Math.trunc(this.tridyTypeForm.value.amount)
      this.tridyTypeForm.patchValue({amount: amount})
    } */
    data['tridyAction'] = this.tridyTypeForm.get('tridyAction')?.value;
    data = {
      ...removeUndefined(this.tridyTypeForm.value),
      tridyAction: this.tridyAction
    };
    if(data['amount']=='none'){
      delete data['amount']
    }
    this.dialogRef.close([data, true])
  }
  onTridyActionChange(value: string): void {
    this.tridyAction = value !== '';
    this.tridyTypeForm.patchValue({ tridyAction: value });
  }
  
}
