import { Component, Injectable, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { CommentsService } from '../service/comments.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { InterfacesDataDialogComponent } from '../interfaces-data-dialog/interfaces-data-dialog.component';
import { NgDialogAnimationService } from "ng-dialog-animation";

  @Injectable()
  export class MyCustomPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();
    constructor(
      private translate: TranslateService
    ){}

    firstPageLabel = this.translate.instant("first_page");
    itemsPerPageLabel = this.translate.instant("items_page");
    lastPageLabel =this.translate.instant("last_page")
    nextPageLabel = this.translate.instant("next_page");
    previousPageLabel = this.translate.instant("previous_page");

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return this.translate.instant("page1_1");
      }
      const amountPages = Math.ceil(length / pageSize);
      
      return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
    }
  }

  @Component({
    selector: 'app-interfaces-list',
    templateUrl: './interfaces-list.component.html',
    styleUrls: ['./interfaces-list.component.css']
  })
  export class InterfacesListComponent {
    allInterfaces:any
    emptyList=false
    deleteMode=false
    list:any
    searchText = ''; 
    displayedColumns: string[] = ['displayName', 'name', 'info'];
    listdata= new MatTableDataSource()
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    paginatorSize = this.settingsSevice.paginator$
    interfacesUserRights_create:any
    interfacesUserRights_update:any

    constructor(private settingsSevice: SettingsService,
      public dialog: NgDialogAnimationService,
      private commentService:CommentsService,
      private router: Router){

      }

      ngOnInit(): void {
        this.interfacesUserRights_create = this.settingsSevice.userRolesRights$['interfaces']['C']
        this.interfacesUserRights_update = this.settingsSevice.userRolesRights$['interfaces']['U']
        this.getInterfaces()
        this.commentService.progressSpin.emit(true)
      }

      ngAfterViewInit(): void {
        this.listdata.paginator = this.paginator;
        this.listdata.sort = this.sort;
        setTimeout(() => {
          this.listdata.filter = " ";
          this.commentService.progressSpin.emit(false)
        }, 500);
      }

      getInterfaces(){
        try{
          this.allInterfaces = this.settingsSevice.getAllInterfaces()
          this.listdata.data = this.allInterfaces
          this.listdata = new MatTableDataSource<any>(this.settingsSevice.settingsInterfaces)
          this.updateTable()
          if(this.allInterfaces.length==0){
            this.emptyList=true
          }
    
        }catch(error){
          console.log(error)
          this.commentService.progressSpin.emit(false)
        }
      }

      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.listdata.filter = filterValue.trim().toLowerCase();
    
        if (this.listdata.paginator) {
          this.listdata.paginator.firstPage();
        }
      }

      updateTable(){
        this.listdata.paginator = this.paginator;
        this.listdata.sort = this.sort;
    
        setTimeout(() => {
          if(this.allInterfaces.length!=0){
            this.listdata.paginator = this.paginator;
            this.listdata.paginator.firstPage()
            this.commentService.progressSpin.emit(false)
          }else{
            this.commentService.progressSpin.emit(false)
          }
        }, 600);
      }

      openEditInterface(row){
        let dialogRef = this.dialog.open(InterfacesDataDialogComponent, {
          data: { 
            info:row,
            type: 'edit',
          },
          panelClass: 'custom-dialog-container',
          position: { right: '0'},
          animation: {
            to: "left",
            incomingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            },
            outgoingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            }
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result[1]){
            this.getInterfaces()
          }
        });
      }

      openDialogNewInterface(){
        let dialogRef = this.dialog.open(InterfacesDataDialogComponent, {
          data: { 
            info:'',
            type: 'create',
          },
          panelClass: 'custom-dialog-container',
          position: { right: '0'},
          animation: {
            to: "left",
            incomingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            },
            outgoingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            }
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result[1]){
            this.getInterfaces()
          }
        });
      }
  }
