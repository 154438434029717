
<section class="m-1">

    <!-- <google-map *ngIf="editMode" height="400px"
                width="360px"
                [center]="center"
                [zoom]="zoom"
                (mapClick)="addMarker($event)">
    <map-marker *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition"
                [options]="markerOptions"></map-marker>
    </google-map>

    <google-map *ngIf="!editMode" height="400px"
                width="365px"
                [center]="center"
                [zoom]="zoom"
                >
    <map-marker *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition"
                [options]="markerOptions"></map-marker>
    </google-map> -->


    <google-map height="400px"
                width="100%"
                [center]="center"
                [zoom]="zoom"
                (mapClick)="addMarker($event)">
    <map-marker *ngFor="let markerPosition of markerPositions"
                [position]="markerPosition"
                [options]="markerOptions"></map-marker>
    </google-map>
    
</section>
    