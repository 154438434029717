<section #carousel class="carousel-container">
    <!-- <button *ngIf="editMode" class="edit-button-top" (click)="openDialogEdit()">
        <span class="material-symbols-outlined">
        edit_square
        </span>
    </button> -->
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 25px; z-index:100; position: absolute; filter: drop-shadow(2px 4px 6px black); color:white;">
        finance_chip
    </span>
    <!-- if there is only 1 image -->
    <div *ngIf="imagesHorizontalData.images && imagesHorizontalData.images.length==1">
        <div class="m-1" *ngFor="let im of imagesHorizontalData.images; let index= index;" class="d-flex align-center justify-content-around flex-column" style="height: 30%;" >
            <div *ngIf="im.title" class="rounded" style="position:relative; display:inline-block; text-align: center;">
                <a *ngIf="linkURL[im.linkURL]" href="{{linkURL[im.linkURL]}}" target="_blank">
                    <img *ngIf="url[im.url]" src={{url[im.url]}} alt="" class="w-100 align-center " style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                    <img *ngIf="!url[im.url]" src={{im.url}} alt="" class="w-100 align-center " style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>
    
                <img *ngIf="url[im.url] && !linkURL[im.linkURL]" src={{url[im.url]}} alt="" class="w-100 align-center " style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                <img *ngIf="!url[im.url] && !linkURL[im.linkURL]" src={{im.url}} alt="" class="w-100 align-center " style="border-radius:var(--cornerRadius);"  onerror="this.src='./../../assets/img/default.jpg'">

                <div *ngIf="im.title" class="text-container">
                    <span id="title" class="image-title-text">{{im.title}}</span>
                </div>  
            </div>

            <div *ngIf="!im.title" class="rounded">
                <a *ngIf="linkURL[im.linkURL]" href="{{linkURL[im.linkURL]}}" target="_blank">
                    <img *ngIf="url[im.url]" src={{url[im.url]}} alt="" class="w-100 align-center" style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                    <img *ngIf="!url[im.url]" src={{im.url}} alt="" class="w-100 align-center" style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>
    
                <img *ngIf="url[im.url] && !linkURL[im.linkURL]" src={{url[im.url]}} alt="" class="w-100 align-center" style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
                <img *ngIf="!url[im.url] && !linkURL[im.linkURL]" src={{im.url}} alt="" class="w-100 align-center " style="border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
            </div>
        </div>
    </div>
    
<div class="carousel-inner rounded-3">
    <!-- if there is more than 1 image -->
    <mdb-carousel #carousel [controls]="true" [indicators]="true" style="margin:5px; margin-bottom:15px;" *ngIf="imagesHorizontalData.images && imagesHorizontalData.images.length>1">
        <mdb-carousel-item *ngFor="let im of imagesHorizontalData.images; let index=index;">
            <a *ngIf="linkURL[im.linkURL]" href="{{linkURL[im.linkURL]}}" target="_blank">
                <img  *ngIf="!url[im.url]" src="{{im.url}}" class="d-block " alt="..." style="width: 100%; margin:auto;  object-fit: cover; border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'"/>
                <img  *ngIf="url[im.url]" src="{{url[im.url]}}" class="d-block " alt="..." style="width: 200px;  width: 100%; margin:auto;  object-fit: cover; border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>

            <img  *ngIf="!url[im.url] && !linkURL[im.linkURL]" src="{{im.url}}" class="d-block " alt="..." style="width: 100%; margin:auto;  object-fit: cover; border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img  *ngIf="url[im.url] && !linkURL[im.linkURL]" src="{{url[im.url]}}" class="d-block " alt="..." style="width: 200px;  width: 100%; margin:auto;  object-fit: cover; border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'"/>
            
            <div *ngIf="im.title" class="text-container">
                <span id="title" class="image-title-text">{{im.title}}</span>
            </div>  

        </mdb-carousel-item>
    </mdb-carousel>

        <!-- if there is 0 images -->
        <!-- visualize an emty pic exmple of the carrousel -->
    <mdb-carousel #carousel [controls]="true" [indicators]="true" style="/*width: 370px;*/ height: 300px; margin:5px;" *ngIf="imagesHorizontalData.images && imagesHorizontalData.images.length==0">
        <mdb-carousel-item >
            <img src="./../../assets/img/default.jpg" class="d-block w-100 rounded" alt="..." style="width: 200px; height: 300px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />

            <div class="carousel-caption d-none d-md-block">
            <!-- <h5>{{im.title}}</h5>
            <p>{{im.subtitle}}</p> -->
            </div>
        </mdb-carousel-item>

        <mdb-carousel-item >
            <img src="./../../assets/img/default.jpg" class="d-block w-100 rounded" alt="..." style="width: 200px; height: 300px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />

            <div class="carousel-caption d-none d-md-block">
            <!-- <h5>{{im.title}}</h5>
            <p>{{im.subtitle}}</p> -->
            </div>
        </mdb-carousel-item>

    </mdb-carousel>
</div>
    
</section>