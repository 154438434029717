export const defaultModulesPublisher = {
    // DPP Designer
    contentTypes:{ //'DPP Designer'*
        R:true,
    },
    // Elements
    content:{ //'Elements'*
        R:true
    },
    media:{
        R:false
    },
    // Home / Languages
    accountLanguages:{
        R:true
    },
    // Reports > All Reports
    tridys:{ //'Reports'*
        R:true
    },
    reports:{ //'Reports'*
        R:true
    },

    ///--------tracer > templates
    //                > groups
    workTaskTemplates:{ //*
        R:false
    },
    //--------Reports > finishedTasks 
    workTask:{ //*
        R:false
    },
    context:{ //'CRUD'
        R:true
    },
    users:{ //*
        R:false
    },
    interfaces:{ //'CRUD'*
        R:false
    },
    passTemplates:{ //DPP Designer
        R:true
    },
    account:{
        R:true
    },
    products:{//*
        R:false
    }
}

export const defaultModulesTracer = {
    contentTypes:{ //*
        R:false,
    },
    content:{ //
        R:false
    },
    media:{
        R:false
    }, 
    accountLanguages:{
        R:true
    },
    // Reports > All Reports
    tridys:{ //'Reports'*
        R:true
    },
    reports:{ //'Reports'*
        R:true
    },
    ///--------tracer > templates
    //                > groups
    workTaskTemplates:{ //*
        R:true
    },
    //--------Reports > finishedTasks 
    workTask:{ //*
        R:true
    },
    context:{ //'CRUD'
        R:false
    },
    users:{ //*
        R:false
    },
    interfaces:{ //
        R:false
    },
    passTemplates:{ //
        R:false
    },
    account:{
        R:false
    },
    products:{//*
        R:false
    }
}

export const defaultModulesAll = {
    // DPP Designer
    contentTypes:{ //'DPP Designer'*
        R:true,
    },
    // Elements
    content:{ //'Elements'*
        R:true
    },
    media:{
        R:false
    },
    // Home / Languages
    accountLanguages:{
        R:true
    },
    // Reports > All Reports
    tridys:{ //'Reports'*
        R:true
    },
    reports:{ //'Reports > All Reports'*
        R:true
    },
    ///--------tracer > templates
    //                > groups
    workTaskTemplates:{ //*
        R:true
    },
    //--------Reports > finishedTasks 
    workTask:{ //*
        R:true
    },
    context:{ //'CRUD'
        R:true
    },
    users:{ //*
        R:true
    },
    interfaces:{ //'CRUD'*
        R:true
    },
    passTemplates:{ //DPP Designer
        R:true
    },
    account:{
        R:true
    },
    products:{//*
        R:false
    }
}

export const defaultModules = {
    publisher: {
        // DPP Designer
        contentTypes:{ //'DPP Designer'*
            R:true,
        },
        // Elements
        content:{ //'Elements'*
            R:true
        },
        media:{
            R:false
        },
        // Home / Languages
        accountLanguages:{
            R:true
        },
        // Reports > All Reports
        tridys:{ //'Reports'*
            R:true
        },
        reports:{ //'Reports'*
            R:true
        },
        ///--------tracer > templates
        //                > groups
        workTaskTemplates:{ //*
            R:false
        },
        //--------Reports > finishedTasks 
        workTask:{ //*
            R:false
        },
        context:{ //'CRUD'
            R:true
        },
        users:{ //*
            R:false
        },
        interfaces:{ //'CRUD'*
            R:false
        },

        passTemplates:{ //DPP Designer
            R:true
        },
        account:{
            R:false
        },
        products:{//*
            R:false
        }
    },
    
    tracer:{
        contentTypes:{ //*
            R:false,
        },
        content:{ //
            R:false
        },
        media:{
            R:false
        }, 
        accountLanguages:{
            R:false
        },
        // Reports > All Reports
        tridys:{ //'Reports'*
            R:true
        },
        reports:{ //'Reports'*
            R:true
        },
        ///--------tracer > templates
        //                > groups
        workTaskTemplates:{ //*
            R:true
        },
        //--------Reports > finishedTasks 
        workTask:{ //*
            R:true
        },
        context:{ //'CRUD'
            R:false
        },
        users:{ //*
            R:false
        },
        interfaces:{ //
            R:false
        },
        passTemplates:{ //
            R:false
        },
        account:{
            R:false
        },
        products:{//*
            R:false
        }    
    }
}
