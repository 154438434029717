<h2 mat-dialog-title>{{'coverImages.title' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="advancedForm">

                <mat-form-field appearance="outline" class="w-100 mb-1">
                    <mat-label>{{'coverImages.ci_title' | translate}}</mat-label>
                    <input formControlName="title" type="text" matInput placeholder="{{'coverImages.ci_title' | translate}}" required>
                    <mat-error *ngIf=" !advancedForm.hasError('title')">
                        {{'required' | translate}}
                    </mat-error>
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100 mb-1">
                    <mat-label>{{'coverImages.ci_subtitle' | translate}}</mat-label>
                    <input formControlName="subtitle" type="text" matInput placeholder="{{'coverImages.ci_subtitle' | translate}}" required>
                    <mat-error *ngIf=" !advancedForm.hasError('subtitle')">
                        {{'required' | translate}}
                    </mat-error>
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100 mb-4">
                    <mat-label>{{'coverImages.ci-prefix' | translate}}</mat-label>
                    <input formControlName="urlPrefix" type="text" matInput placeholder="{{'coverImages.ci-prefix' | translate}}" required>
                    <mat-error *ngIf="advancedForm.get('urlPrefix')?.hasError('required') && advancedForm.get('urlPrefix')?.dirty">
                        {{ 'required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="advancedForm.get('urlPrefix')?.hasError('invalidUrlPrefix') && advancedForm.get('urlPrefix')?.dirty">
                        {{'coverImages.invalid-prefix' | translate}}
                    </mat-error>
                    <mat-hint *ngIf="alreadyPrefix" color="warn">{{'coverImages.prefix-exists' | translate}}</mat-hint>
                </mat-form-field>
        
                <div *ngIf="advancedForm.value.urlPrefix" appDragAndDrop (files)="filesDropped($event)">
                    <p style="color:var(--accentGlobal);"> {{'coverImages.drop_file' | translate}}</p>
                    <p style="color:var(--accentGlobal);"> {{'coverImages.image_size' | translate}}</p>
                    <div class="insertImgContainer"  >
                       
                        <label for="myfile" class="labelFile"> 
                            +  <input type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg, .png" (change)="readURL($event);"> 
                        </label>
            
                        <input matInput type="text" formControlName="url" value="{{url}}" style="display:none">
                        
                        <div class="d-flex flex-row flex-nowrap justify-content-start">
                            
                            <img *ngIf="url" id="billboardImg" [src]="url" height="200" style="margin-left:10px; border-radius:10px;" onerror="this.src='./../../assets/img/default.jpg'">
                            
                            <div class="d-flex flex-column justify-content-between" >
                                <button *ngIf="url" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); right:50px;" (click)="deleteImg(url);" >
                                    <span class="material-symbols-outlined"> delete  </span>
                                </button>
                                <!-- <button *ngIf="url" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); right:50px; " (click)="editImg(url);" >
                                    <span class="material-symbols-outlined"> edit </span>
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="!advancedForm.value.urlPrefix" appDragAndDrop >
                    <p style="color:var(--accentGlobal);"> {{'coverImages.drop_file' | translate}}</p>
                    <p style="color:var(--accentGlobal);"> {{'coverImages.image_size' | translate}}</p>
        
                    <div class="insertImgContainer"  >               
                        <label for="myfile" class="labelFileDisabled"> 
                            +  <input type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg"  (change)="readURL($event);" disabled> 
                        </label>
            
                        <input matInput type="text" formControlName="url" value="{{url}}" style="display:none">
                        
                        <div class="d-flex flex-row flex-nowrap justify-content-start">
                            <img *ngIf="url" id="billboardImg" [src]="url" height="200" style="margin-left:10px; border-radius:10px;" onerror="this.src='./../../assets/img/default.jpg'">
                            
                            <div class="d-flex flex-column justify-content-between" >
                                <button *ngIf="url" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); right:50px;" (click)="deleteImg(url);" >
                                    <span class="material-symbols-outlined"> delete  </span>
                                </button>
                                <!-- <button *ngIf="url" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); right:50px; " (click)="editImg(url);" >
                                    <span class="material-symbols-outlined"> edit </span>
                                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();"> {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" [disabled]="advancedForm.invalid || !isUrlPrefixValid || alreadyPrefix"  (click)="dialogSave();"> {{ 'save' | translate }}</button>

</mat-dialog-actions>
