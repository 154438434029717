import { Injectable } from '@angular/core';
import { Subject,BehaviorSubject } from 'rxjs';
import { deepCopy, removeUndefined } from '../../../../backend/utils/object';
import { deleteField } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TranslationsDPPService {
  passTemplateFormDataById$: Subject<any> = new Subject<any>()
  passTemplateTranslatedValues$: Subject<any> = new Subject<any>()
  OLDpassTemplateTranslatedValues$: Subject<any> = new Subject<any>()
  passLanguagesList$:Subject<string[]> = new Subject<string[]>()
  passLanguagesSelected$:Subject<string> = new Subject<string>()
  firstLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined)
  passAllInfo:Subject<any> = new Subject<any>()
  passDefaultLanguage$:Subject<string> = new Subject<string>()
  constructor() { }

  initializeFormDataForTranslations(data){
    this.passTemplateFormDataById$.next(data)
  }

  setDefaultLanguagePass(lang){
    this.passDefaultLanguage$.next(lang)
  }
  
  initializePassTranslatedValues(translations){
    this.passTemplateTranslatedValues$.next(translations)
    this.OLDpassTemplateTranslatedValues$.next(translations)
  }

  initializePassLanguages(languages){
    this.passLanguagesList$.next(languages)
  }

  initializePassFirstLanguage(language){
    this.firstLanguage$.next(language)
  }

  updateFormDataForTranslations(data){
    this.passTemplateFormDataById$.next(data)
  }

  cleanFormDataForTranslations(data?){
    this.passTemplateFormDataById$.next({})
  }

  updatepassLanguagesList(languages){
    if(languages){
      this.passLanguagesList$.next(languages)
      if(languages.length!=0)
        this.firstLanguage$.next(languages[0])

    }
  }

  setPassFirstLanguage(passId, firstLanguage){
    let info = {}

    if(this.passAllInfo[passId]){
      this.passAllInfo[passId]['firstLanguage'] = firstLanguage
    }else{
      info={
        [passId]: {
          'firstLanguage':firstLanguage
        }
      }
    }
    this.passAllInfo.next(info)
  }

  addTranslations(widget, language, values){

  }


  viewPassLanguageForm(lang, formData){
    const copiaForm = deepCopy(formData)
    // this.languageSelected = lang//coment to prevent select to chnage language select 12.07
    // let firstLanguage
    // let languagesList
    // if(this.passLanguagesList$.value.length!=0){
    //   languagesList=this.passLanguages
    //   firstLanguage=this.passFirstLanguage
    // }else{
    //   languagesList=this.contextLanguages
    //   firstLanguage=this.contextFirstLanguage
    // }

    // let dialogRef = this.dialog.open(LanguageVisualisationDialogComponent, {
    //   panelClass: 'custom-dialog-container',
    //   position: { left: '0'},
    //   animation: {
    //     to: "right",
    //     incomingOptions: {
    //       keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
    //     },
    //     outgoingOptions: {
    //       keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
    //     }
    //   },
    //   data:{
    //     values:copiaForm,
    //     firstLanguageContexts: firstLanguage,
    //     contextLanguages: languagesList,
    //     languageSelected: lang,
    //     idToShow:this.idToShow
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if(this.passLanguages.length!=0){
    //     this.languageSelected = this.passFirstLanguage   
    //   }else{
    //     this.languageSelected = this.contextFirstLanguage   
    //   }
    // })
  }

  //delete translations of deleted widget
  //----every time a widget is deleted, it checks if there were translations of that widget
  //----YES --- delete translations 
  //----NO ---> next
  removeDeletedWidgetsTranslationsBeforeSaving(deletedWidget, translations){
    const alltransaltions = Object.entries(translations)

    alltransaltions.forEach( valuesTranslated => {
      const values = Object.keys(valuesTranslated)
      values.forEach( (key, index) => {
        const id = key.split('.')
        if(id == deletedWidget.id){
          delete values[index]
        }
      })


    })
  }

  clearPassInfoTranslations(){
    this.passTemplateFormDataById$.next({})
    this.passTemplateTranslatedValues$.next({})
    this.OLDpassTemplateTranslatedValues$.next({})
    this.passLanguagesList$.next([])
    this.passLanguagesSelected$.next(undefined)
    this.firstLanguage$.next(undefined)
    this.passAllInfo.next({})
  }


  addMoreTranslationsToThisWidgetType(result, type){
    console.log('____________add new translations to translations', result)
    let newtranslations = {}
  
    if(this.passTemplateTranslatedValues$){
      newtranslations = this.passTemplateTranslatedValues$
    }
      console.log(result)
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          //------------------------widgetData on first Language translations
          // if(lang == this.firstLanguageContexts){
          //   if(this.textEditForm.value[field]){
          //     this.textEditForm.patchValue({[field]: value[1]})
          //   }
          // }
          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.OLDpassTemplateTranslatedValues$[lang] && this.OLDpassTemplateTranslatedValues$[lang][tranlationField])
                newtranslations[lang][tranlationField] = deleteField()

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.passTemplateTranslatedValues$.next(newtranslations)
        console.log('newtranslations',newtranslations)
      }
  }


}
