import { Injectable ,EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor() { }

  addSnackBar: EventEmitter<string> = new EventEmitter()
  progressSpin: EventEmitter<boolean> = new EventEmitter()

}
