<section class="px-3">
<!--     <button *ngIf="editMode" class="edit-button"><span class="material-symbols-outlined" (click)="openDialogEdit();">
        edit_square
        </span>
    </button> -->

    <div *ngIf="textData.image" class="d-flex flex-row" style="padding-top: 5px;">

        <div class="d-flex flex-column">
            <div *ngIf="textData.title" id="titleText" class="titleText text-break text-wrap" style=" word-break: break-word;" [style.textAlign]="textData.textAlignment ? textData.textAlignment : 'left'" ><span id="title">{{textData.title}}</span></div>
            <div *ngIf="textData.subtitle" id="subtitleText" class="subtitleText text-break text-wrap" style=" word-break: break-word;" [style.textAlign]="textData.textAlignment ? textData.textAlignment : 'left'"  ><span id="subtitle">{{textData.subtitle}}</span></div><!--[ngStyle]="{'text-align': textData.textAlignment ? textData.textAlignment : 'left'}" -->
            <p class="textPrimary" id="textPrimary" *ngIf="!url" style=" word-break: break-word;" >
                <!-- img left -->
                <img *ngIf="textData.imagePosition=='left' && !linkUrl && textData.image.url" src="{{textData.image.url}}" 
                style="float:left;width: 40%;object-fit: cover; margin-right:8px; 
                        margin-bottom:10px; border-radius:  calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">

                <a *ngIf="linkUrl" href="{{linkUrl}}" target="_blank">
                    <img *ngIf="textData.imagePosition=='left' && textData.image.url" src="{{textData.image.url}}" 
                        style="float:left;width: 40%;object-fit: cover; margin-right:8px; 
                        margin-bottom:10px; border-radius:  calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>

                <!-- img right -->
                <img *ngIf="textData.imagePosition=='right' && !linkUrl && textData.image.url" src="{{textData.image.url}}" 
                    style="float:right;
                    width: 40%; object-fit: cover; margin-left:8px; 
                    margin-bottom:10px; border-radius: calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">

                <a *ngIf="linkUrl" href="{{linkUrl}}" target="_blank">
                    <img *ngIf="textData.imagePosition=='right' && textData.image.url" src="{{textData.image.url}}" 
                    style="float:right;
                    width: 40%;
                            object-fit: cover; margin-left:8px; 
                    margin-bottom:10px; border-radius:calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>
                <span *ngIf="dynamic && textData.imagePosition=='left'" class="material-symbols-outlined" style="left: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <span *ngIf="dynamic && textData.imagePosition=='right'" class="material-symbols-outlined" style="right: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <!-- {{textData.text}} -->
                <span id="{{textData.id}}" *ngIf="!textData.textAlignment"></span>
                <span id="{{textData.id}}" *ngIf="textData.textAlignment && textData.textAlignment=='center'"></span>
                <span id="{{textData.id}}" *ngIf="textData.textAlignment && textData.textAlignment=='right'"></span>
                <span id="{{textData.id}}" *ngIf="textData.textAlignment && textData.textAlignment=='left'"></span>
            </p>

            <p class="textPrimary" id="textPrimary" *ngIf="!url && !textData.image.url" style=" word-break: break-word;" >

                <span *ngIf="dynamic && textData.imagePosition=='left'" class="material-symbols-outlined" style="left: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <span *ngIf="dynamic && textData.imagePosition=='right'" class="material-symbols-outlined" style="right: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <!-- {{textData.text}} -->
                <span id="{{textData.id}}" *ngIf="markdownText && !textData.textAlignment" [innerHTML]="markdownText"></span>
                <span id="{{textData.id}}" *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='center'" [innerHTML]="markdownText" style=" word-break: break-word; text-align: center;"></span>
                <span id="{{textData.id}}" *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='right'" [innerHTML]="markdownText" style=" word-break: break-word; text-align: right;"></span>
                <span id="{{textData.id}}" *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='left'" [innerHTML]="markdownText" style=" word-break: break-word; text-align: left;"></span>
            </p>
            <p class="textPrimary" id="textPrimary" *ngIf="url" style=" word-break: break-word;" >
                <!-- img left -->
                <img *ngIf="textData.imagePosition=='left' && !linkUrl" src="{{url}}" 
                style="float:left;width: 40%;object-fit: cover; margin-right:8px; 
                        margin-bottom:10px; border-radius: calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">

                <a *ngIf="linkUrl" href="{{linkUrl}}" target="_blank">
                    <img *ngIf="textData.imagePosition=='left'" src="{{url}}" 
                        style="float:left;width: 40%;object-fit: cover; margin-right:8px; 
                        margin-bottom:10px; border-radius: calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>

                <!-- img right -->
                <img *ngIf="textData.imagePosition=='right' && !linkUrl" src="{{url}}" 
                    style="float:right;width: 40%;object-fit: cover; margin-left:8px; 
                    margin-bottom:10px; border-radius: calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">

                <a *ngIf="linkUrl" href="{{linkUrl}}" target="_blank">
                    <img *ngIf="textData.imagePosition=='right'" src="{{url}}" 
                    style="float:right;width: 40%;object-fit: cover; margin-left:8px; 
                    margin-bottom:10px; border-radius:calc(var(--cornerRadius)*0.8);" onerror="this.src='./../../assets/img/default.jpg'">
                </a>
                <span *ngIf="dynamic && textData.imagePosition=='left'" class="material-symbols-outlined" style="left: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <span *ngIf="dynamic && textData.imagePosition=='right'" class="material-symbols-outlined" style="right: 25px; position: absolute;filter: drop-shadow(2px 4px 6px black);">
                    finance_chip
                </span>

                <!-- {{textData.text}} -->
                <!-- <span id ="{{textData.id}}"></span> -->
                <span *ngIf="markdownText && !textData.textAlignment" [innerHTML]="markdownText"></span>
                <span *ngIf="markdownText && textData.textAlignment && textData.textAlignment == 'center'" [innerHTML]="markdownText" style="text-align: center;"></span>
                <span *ngIf="markdownText && textData.textAlignment && textData.textAlignment == 'left'" [innerHTML]="markdownText"  style="text-align: left;"></span>
                <span *ngIf="markdownText && textData.textAlignment && textData.textAlignment == 'right'" [innerHTML]="markdownText"  style="text-align: right;"></span>
            </p>
        </div>
    </div>
    
    <div *ngIf="!textData.image">
        <div *ngIf="textData.title" id="title" class="titleText text-break text-wrap" [style.textAlign]="textData.textAlignment ? textData.textAlignment : 'left'">{{textData.title}}</div>
        <div *ngIf="textData.subtitle" id="subtitle" class="subtitleText text-break text-wrap" [style.textAlign]="textData.textAlignment ? textData.textAlignment : 'left'">{{textData.subtitle}}</div>
        <!-- <pre class="textPrimaryPre">{{textData.text}}</pre> -->       
        <!-- <div id="{{textData.id}}" class="textPrimaryPre">  </div> -->
        <div *ngIf="markdownText && !textData.textAlignment" id="{{textData.id}}" class="textPrimaryPre" style=" word-break: break-word;" [innerHTML]="markdownText">  </div>
        <div *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='center'" id="{{textData.id}}" class="textPrimaryPre" style=" word-break: break-word; text-align: center;" [innerHTML]="markdownText">  </div>
        <div *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='left'" id="{{textData.id}}" class="textPrimaryPre" style=" word-break: break-word; text-align: left;" [innerHTML]="markdownText">  </div>
        <div *ngIf="markdownText && textData.textAlignment && textData.textAlignment=='right'" id="{{textData.id}}" class="textPrimaryPre" style=" word-break: break-word; text-align: right;" [innerHTML]="markdownText">  </div>
    </div>
    
</section>