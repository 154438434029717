import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-googlemap]',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.css']
})
export class GooglemapComponent implements OnInit {
  @Input('app-googlemap') data:any;
  annotatedMap:any
  annotationsdata:any
  editMode:any

  constructor() { }

  ngOnInit(): void {
    this.annotatedMap=this.data[0]
    this.annotationsdata=this.annotatedMap.annotations
    this.editMode=this.data[1]
  }
  display : any;
  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 4;

  moveMap(event: google.maps.MapMouseEvent) {
    if(event.latLng!= null)
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if(event.latLng != null)
    this.display = event.latLng.toJSON();
  }
}