import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterExperiences'
})
export class FilterExperiencesPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
        return it.title.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.subtitle.toLowerCase().includes(searchText) || it.urlPrefix.toLowerCase().includes(searchText) || it.appStoreImageURL.toLowerCase().includes(searchText);

    });
   }
}