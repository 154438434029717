import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterContentsPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
        if(it.displayName){
            if(it.id){
                if(it.titleKey){
                    if(it.passTemplateId){
                        return it.displayName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.displayName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText);
                    }
                    

                }else{

                    if(it.passTemplateId){
                        return it.displayName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.displayName.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) ;
                    }
                    
    
                }
            }else{
                if(it.titleKey){
                    if(it.passTemplateId){
                        return it.displayName.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.displayName.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText);
                    }
                }else{
                    if(it.passTemplateId){
                        return it.displayName.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.displayName.toLowerCase().includes(searchText);
                    }

                    
                }
            }
        }else{
            if(it.id){
                if(it.titleKey){
                    if(it.passTemplateId){
                        return it.id.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.id.toLowerCase().includes(searchText) || it.titleKey.toLowerCase().includes(searchText);
                    }
                    

                }else{
                    if(it.passTemplateId){
                        return it.id.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return it.id.toLowerCase().includes(searchText);
                    }
                
    
                }
            }else{
                if(it.titleKey){
                    if(it.passTemplateId){
                        return it.titleKey.toLowerCase().includes(searchText) || it.passTemplateId.toLowerCase().includes(searchText);
                    }else{
                        return  it.titleKey.toLowerCase().includes(searchText);
                    }
                }else{
                    if(it.passTemplateId){
                        return it.passTemplateId.toLowerCase().includes(searchText);
                    }
                }
            }
        }

    });
   }
}