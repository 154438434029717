
<h2 mat-dialog-title>{{'pass.widgets.list' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->
<mat-dialog-content>
    <div>
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="listEdiForm">
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="mt-1 mb-2">
                    <span class="text-uppercase text-muted mb-2">{{'pass.lists.list_type' | translate}}</span>
                </div>
                <mat-form-field appearance="outline" class="w-100" >
                    <mat-label>{{'pass.style' | translate}} </mat-label>
                    <mat-select formControlName="style" class="input-group m-1">
                      <mat-option value="definition">{{'pass.lists.definition' | translate}} </mat-option>
                      <mat-option value="list">{{'pass.list' | translate}} </mat-option>
                      <mat-option value="table">{{'pass.lists.table' | translate}} </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="mt-1 mb-2">
                    <mat-accordion class="mt-1 mb-1">
                        <mat-expansion-panel class="mt-1 mb-1" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                            
                                <mat-panel-title  >
                                        
                                    <span class="d-flex " style="color:var(--warmOrange);">
                                        <mat-icon>add</mat-icon> 
                                        {{'pass.lists.add_item' | translate}}
                                    </span>

                                </mat-panel-title>

                            </mat-expansion-panel-header>
            
                            <form [formGroup]="newItemForm"> 
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'pass.title' | translate}}</mat-label>
                                    <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                                </mat-form-field>
    
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'value' | translate}}</mat-label>
                                    <input formControlName="value" type="text" matInput placeholder="{{'value' | translate}}">
                                </mat-form-field>
    
                                <button mat-button class="w-100" style="color:var(--warmOrange);" (click)="addItem();" > {{'pass.lists.add_item' | translate}} </button>
    
                            </form>
    
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div class="mt-4 mb-2">
                    <span class="text-uppercase text-muted mb-2">{{'pass.lists.content' | translate}}</span>
                </div>
                <div style = "max-height: 530px; overflow-y: auto;">
                    <div class="list-group">
                        <div class="list-group-item" *ngFor="let item of listEdiForm.value.items; let index = index">
                            <div class="d-flex flex-row justify-content-between align-items-center ">
                                <div class="d-flex flex-column">
                                    <span style="font-size: 1.1em;">{{item.title}}</span>
                                    <span style="font-size: 0.8em;" class="text-muted">{{item.value}}</span>
                                </div>

                                <button mat-icon-button style="color:var(--warmOrange);" (click)="deleteItem(item, index);" > 
                                    <span class="material-symbols-outlined"> 
                                        delete  
                                    </span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "listData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="dialogSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>