<p>googlemap works!</p>

<google-map height="400px"
            width="370px"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="moveMap($event)"
            (mapMousemove)="move($event)" style="border-radius: 10px;">


            <!-- <map-marker *ngFor="let markerPosition of annotationsData"
              [position]="markerPosition"
              [options]="markerOptions"></map-marker> -->
              
              
              <map-marker *ngFor="let markerPosition of annotationsdata"
              [position]="markerPosition.coordinate "
              [options]="{draggable:false}"></map-marker>

              
</google-map>

<div>Latitude: {{display?.lat}}</div>
<div>Longitude: {{display?.lng}}</div>