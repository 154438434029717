<div *ngIf="imagesData.title" id="titleImages" class="title ps-3 pb-2" style="word-break: break-word; font-size: 18pt;">
    <span id="title">{{imagesData.title}}</span>
</div>

<div *ngIf="imagesData.subtitle" id="subtitleImages" class="subtitle ps-3 pb-2" style="word-break: break-word; font-size: 13pt;">
    <span id="subtitle">{{imagesData.subtitle}}</span>
</div>


<div *ngIf="imagesData.type=='imageBlocks'" [app-image-blocks-phone]="[imagesData,editMode, widgetNumber]" (returnChanges)=returnChangesActive($event)></div>
<div *ngIf="imagesData.type=='images' && imagesData.layout!='horizontal'" [app-image-blocks-phone]="[imagesData,editMode, widgetNumber]" (returnChanges)=returnChangesActive($event)></div>

<div *ngIf="imagesData.type=='imagesHorizontal'" [app-images-horizontal-phone]="[imagesData,editMode, widgetNumber]" (returnChanges)=returnChangesActive($event)></div>
<div *ngIf="imagesData.type=='images' && imagesData.layout=='horizontal'" [app-images-horizontal-phone]="[imagesData,editMode, widgetNumber]" (returnChanges)=returnChangesActive($event)></div>
