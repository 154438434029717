export interface IPayload{
    array?:string[]
    description?: string
    map?:Object
    name?:string
    tagline?:string
}

export interface ITridys{
    id:string
    contextId:string
    contentType:string
    creationDate:Date
    payload?:IPayload
    timeStamp:Date
    
}

export class Tridys{
    public static className = "tridys"
    public className = "tridys"

    id: string|undefined = undefined
    contextId:string|undefined = undefined
    contentType:string|undefined = undefined
    payload?:IPayload|undefined = undefined

    constructor(){}
    
    setFromAny(element:any){
        const {id, contextId, contentType, payload} = element

        this.id = id ? id : this.id
        this.contextId = contextId ? contextId : this.contextId
        this.contentType = contentType ? contentType : this.contentType
        this.payload = payload ? payload : payload
    }

    getId(){
        if(!this.id) return undefined

        return this.id
    }

    getPayload(){
        if(!this.payload) return undefined

        return this.payload
    }

    
}