<h2 mat-dialog-title>{{title | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <div class="p-3 delete-infos">
                <p>{{message | translate}} <i>{{tridyCount}}</i> Tridys</p> 
                <hr>
                <p><strong style="color:var(--warmOrange);" >{{confrmQuestion | translate}}</strong></p> 
             </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSave();" >{{'save' | translate}}</button>
</mat-dialog-actions>
