import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Directive, HostBinding, HostListener, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';

export interface FileHandle {
  file: File,
  url: SafeUrl
}

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding('style.background') private background = '#eeeeee17';

  constructor(private sanitizer: DomSanitizer, private renderer: Renderer2, private el: ElementRef) { }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'var(--imgSelector)';
    this.renderer.addClass(this.el.nativeElement, 'drag-over');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eeeeee17';
    this.renderer.removeClass(this.el.nativeElement, 'drag-over');
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eeeeee17';
    this.renderer.removeClass(this.el.nativeElement, 'drag-over');

    let files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}