<section class="addNavbar" >
    <h2>{{'coverImages.title' | translate}}</h2>
    <!-- <div class="ps-3 d-flex flex-row flex-nowrap align-items-center ">
        <input class="w-100  form-control" type="text" placeholder="{{'search' | translate}}" id="searchBar" [(ngModel)]="searchText" #searchbar>
       
    </div> -->

    <mat-form-field appearance="outline" >
        <mat-label>{{'filter' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
    </mat-form-field>

</section>



<div *ngIf="advancedExperiencesData.length === 0" class="p-2 empty-list" >{{'coverImages.empty_title' | translate}}</div>


<div class="shadow-sm mat-elevation-z8 mb-4" id="table-container">
    <table mat-table [dataSource]="dataSource" matSort>
        
      <ng-container matColumnDef="imageUrl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'coverImages.title' | translate}} </th>
        <td mat-cell *matCellDef="let element" > 
            <img width="60" src="{{element.imageUrl}}"  onerror="this.src='./../../assets/img/default.jpg'">
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'coverImages.ci_title' | translate}} / {{'coverImages.ci_subtitle' | translate}} </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.title" class="text-wrap text-break">{{element.title}}</span> <br>
            <span *ngIf="element.subtitle" class="text-wrap text-break text-muted">{{element.subtitle}}</span> 
        </td>
      </ng-container>
  
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date}} </span>        
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="paginatorSize"
                   showFirstLastButtons
                   aria-label="Select page of cover Image" class="pb-2">
    </mat-paginator>
  </div>