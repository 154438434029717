import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { removeUndefinedValuesFromObject } from '../../../../../backend/utils/object';

@Component({
  selector: '[app-images-phone]',
  templateUrl: './images-phone.component.html',
  styleUrls: ['./images-phone.component.css']
})
export class ImagesPhoneComponent {
  @Input('app-images-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()
  imagesData:any
  routerUrl:any
  idToShow:any
  editMode:any
  widgetNumber:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    ){  
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }

  ngOnInit(): void {
    this.imagesData = this.data[0]
    this.editMode = this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
/*     if(this.imagesData.title)
      this.imagesData.title = checkDynamicContent(this.imagesData.title)

    if(this.imagesData.subtitle)
      this.imagesData.subtitle = checkDynamicContent(this.imagesData.subtitle) */
  }

  returnChangesActive(event: any){
    const data = event.newdata
    removeUndefinedValuesFromObject(data)
    this.imagesData = data

    this.returnChanges.emit({newdata: data, widget: this.widgetNumber, widgetType: data.type,images: event.images})

  }
}
