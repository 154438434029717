import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-location-picker]',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.css']
})
export class LocationPickerComponent {
  @Input('app-location-picker') data:any;


  ngOnInit(){
    console.log(this.data)
    this.initMap();

  }

   async initMap() {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const map = new Map(document.getElementById('map') as HTMLElement, {
        center: { lat: 37.4239163, lng: -122.0947209 },
        zoom: 14,
        mapId: '4504f8b37365c3d0',
        disableDefaultUI: true,
    });

    const pinBackground = new PinElement({
      background: '#FBBC04',
  });

    const marker = new AdvancedMarkerElement({
        map,
        position: { lat: 37.4239163, lng: -122.0947209 },
        content: pinBackground.element,
        gmpClickable: true,
    });


    // A marker with a with a URL pointing to a PNG.
    const beachFlagImg = document.createElement('img');
    // beachFlagImg.src = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    beachFlagImg.src = '../../assets/img/narravero-pin.svg'

    const beachFlagMarkerView = new AdvancedMarkerElement({
        map,
        position: { lat: 37.434, lng: -122.082 },
        content: beachFlagImg,
        title: 'A marker using a custom PNG Image',
        gmpClickable: true,
    });


    let contentString = "this is an example"
    let index = 11 +''
    contentString ='<div class="d-flex flex-column">'
    contentString +='<span>...</span>'    
    contentString +=' </div> '   

    const infoWindow = new google.maps.InfoWindow({
      content: contentString,
      ariaLabel: index,
    });

    // Add a click listener for each marker, and set up the info window.
    marker.addListener('click', ({ domEvent, latLng }) => {
      const { target } = domEvent;
      infoWindow.close();
      infoWindow.setContent(marker.title);
      infoWindow.open(marker.map, marker);
    });


    const infoWindowFlag = new google.maps.InfoWindow({
      content: contentString,
      ariaLabel: index,
    });
    beachFlagMarkerView.addListener('click', ({ domEvent, latLng }) => {
      const { target } = domEvent;
      infoWindowFlag.close();
      infoWindowFlag.setContent(beachFlagMarkerView.title);
      infoWindowFlag.open(beachFlagMarkerView.map, beachFlagMarkerView);
    });
  }
}
