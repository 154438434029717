import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { deepCopy, openMapDirection, removeUndefined, supportUrlStructure } from '../../../../../backend/utils/object';
import { AnnotatedMapAnnotationTranslationDialogComponent } from '../annotated-map-annotation-translation-dialog/annotated-map-annotation-translation-dialog.component';
import { SettingsService } from 'src/app/service/settings.service';
import { languagesList } from '../../../../../backend/src/languagesList.dto';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-annotated-map-translation-edit',
  templateUrl: './annotated-map-translation-edit.component.html',
  styleUrls: ['./annotated-map-translation-edit.component.css']
})
export class AnnotatedMapTranslationEditComponent {
  annotatedMapTranslationEditData:any
  oldData:any
  addList:any
  annotatedMapTranslationEditForm!:FormGroup
  newAnnotations!:FormGroup
  allAnnotationsMap:any = []
  allAnnotationsMapOriginal:any = []
  allAnnotationsDynamicMap:any = []
  allAnnotationsDynamicMapOriginal:any = []
  changeAnnotations=false
  translationForm!:FormGroup
  passTranslations:any
  passLanguagesList:any

  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  actualPolygonOptionsOriginal:any={}
  pairsPath:any ={}
  polylineOptions = {
    path: [],
    strokeColor: '#adff2f',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  };
  a: google.maps.Polyline

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#ff0000",
    fillOpacity: 0.3,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }
  oldTranslations:any
  passId:any
  widgetNumber:any
  linkURL:any = {}
  linkURLOriginal:any = {}
  prev_infowindow:any
  prev_infowindowOriginal:any
  selectedLanguage:string
  widgetId:string
  allpassTranslations
  widgetDefaultLanguage:any
  showOriginalActive = false
  originalLanguageForm!:FormGroup
  markerPositionsOriginal: google.maps.LatLngLiteral[] = [];
  languagesList = languagesList

  constructor(public dialogRef: MatDialogRef<AnnotatedMapTranslationEditComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private commentService: CommentsService,
              private settingsService: SettingsService){

      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.annotatedMapTranslationEditData=this.data.values
    this.oldData=this.data.values
    this.widgetId = this.data.values.id
    this.selectedLanguage = this.data.selectedLanguage

    if(this.data.translations){
      this.allpassTranslations = this.data.translations
      this.oldTranslations = deepCopy(this.data.translations)
    }

    this.widgetDefaultLanguage = this.data.defaultLanguageWidgetData
    this.passId = this.data.passId
    this.widgetNumber= this.data.widget
    this.passLanguagesList = this.data.languages

    if(this.data.valuesTranslated){
      this.passTranslations = this.data.valuesTranslated
      this.oldTranslations = deepCopy(this.data.valuesTranslated)
    }

    this.annotatedMapTranslationEditForm = this.fb.group({
      id:[{value:undefined, disabled:true}],
      type:[{value:undefined, disabled:true}],
      style:[{value:undefined, disabled:true}],
      layout:[{value:undefined, disabled:true}],
      title:[],
      subtitle:[],
      annotationGroup:[{value:undefined, disabled:true}],
      annotations:[{value:undefined, disabled:false}],
      name:[{value:undefined, disabled:true}]
    })
    const {id,  type, style, layout, annotationGroup, annotations,name} = this.annotatedMapTranslationEditData
    const title = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.title']
    const subtitle = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.subtitle']

    this.annotatedMapTranslationEditForm.patchValue({
      id:id ? id : undefined,
      type:type ? type : undefined,
      style: style ? style : undefined,
      layout:layout ? layout : undefined,
      title:title ? title :undefined,
      subtitle:subtitle ? subtitle : undefined,
      annotationGroup:annotationGroup ? annotationGroup : undefined,
      annotations:annotations ? annotations : undefined,
      name:name ? name : undefined
    })

    if(this.annotatedMapTranslationEditData.annotations){
      this.annotatedMapTranslationEditData.annotations.forEach((e:any)=>{
        this.allAnnotationsMap.push(e)
        if(e.linkURL)
          this.supportLinkUrl(e.linkURL, e.id)
      })
    }else{
      if(this.annotatedMapTranslationEditData[0].annotations){
        this.annotatedMapTranslationEditData[0].annotations.forEach((e:any)=>{
          this.allAnnotationsMap.push(e)
        })
      }
    }

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.allpassTranslations ? this.allpassTranslations : undefined,
      valuesTranslated: this.passTranslations ? this.passTranslations : undefined
    })
    this.loadMarkers()

    this.loadOriginalLanguageData(this.data?.defaultLanguageWidgetData)
  }

  loadOriginalLanguageData(defaultLanguageWidgetData){
    const {id,  type, style, layout, annotationGroup, annotations,name, title, subtitle} = defaultLanguageWidgetData

    if(annotations){
      annotations.forEach((e:any)=>{
        this.allAnnotationsMapOriginal.push(e)
        if(e.linkURL)
          this.supportLinkUrlOriginal(e.linkURL, e.id)
      })
    }else{
      // if(this.annotatedMapTranslationEditData[0].annotations){
      //   this.annotatedMapTranslationEditData[0].annotations.forEach((e:any)=>{
      //     this.allAnnotationsMap.push(e)
      //   })
      // }
    }

    this.originalLanguageForm = this.fb.group({
      id:[{value:id ? id : undefined, disabled:true}],
      type:[{value:type ? type : undefined, disabled:true}],
      style:[{value:style ? style : undefined, disabled:true}],
      layout:[{value:layout ? layout : undefined, disabled:true}],
      title:[{value:title ? title : undefined, disabled:true}],
      subtitle:[{value: subtitle ? subtitle : undefined, disabled:true}],
      annotationGroup:[{value:annotationGroup ? annotationGroup : undefined, disabled:true}],
      annotations:[{value:annotations ? annotations : undefined, disabled:false}],
      name:[{value:name ? name : undefined, disabled:true}]
    })

    this.loadMarkersOriginal()
  }

  onSaveEdit(){
    try{
      this.commentService.progressSpin.emit(true)
      let data = this.getWidgetFormatedData(this.annotatedMapTranslationEditForm.value)
      this.dialogRef.close([data, true])
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
    }
  }

  openAnnotationDialog(annotation, index?){
    if(!annotation.dynamic){
      let alltranslationsWidget = {}
      let translationsWidget = []

      if(this.translationForm.value.translations)
        translationsWidget = Object.keys(this.translationForm.value.translations) 

      if(this.passLanguagesList)
        translationsWidget=this.passLanguagesList

      if(translationsWidget.length!=0){
        translationsWidget.forEach(lang => {
          if(this.translationForm.value.translations){
            if(this.translationForm.value.translations[lang]){
              let entries = Object.entries(this.translationForm.value.translations[lang]);
              entries.forEach( ent => {
                if(ent[0].includes(this.widgetId+'_'+annotation.id) ){
                  const key = lang+ "." + ent[0]
                  alltranslationsWidget[key] = ent[1]
                }
              })
            }
          }
        })
      }

      let fieldsToTranslate = ['title', 'subtitle', 'linkURL'] 
      let columns = []

      if(annotation.id){
        fieldsToTranslate.forEach( field => {
          columns.push('_' + annotation.id + '.' + field)
        })
      }

      let dialogRef = this.dialog.open(AnnotatedMapAnnotationTranslationDialogComponent, {
        data:{
          translations: this.translationForm.value.translations,
          encodedContent: this.actualPolygonOptions[index],
            fields: fieldsToTranslate,
            columns: columns,//['title', 'subtitle','text'],
            values: annotation,
            type: annotation.type,
            valuesTranslated: alltranslationsWidget,
            languages: translationsWidget,
            selectedLanguage:this.selectedLanguage,
          annotationMapData:this.annotatedMapTranslationEditForm.value,
            oldTemplateData:this.annotatedMapTranslationEditData,
            widgetId:this.widgetId,
            passId: this.passId,
          index: index,
          widgetNumber:this.widgetNumber,
          defaultLanguageWidgetData:this.widgetDefaultLanguage
        }
      });
      

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.allAnnotationsMap[index]=result[2]
          this.markerPositions[index]=result[2]
          this.getPolygon(this.markerPositions[index], index)
          this.annotatedMapTranslationEditForm.patchValue({annotations:this.allAnnotationsMap})
          this.supportLinkUrl(this.allAnnotationsMap[index].linkURL, this.allAnnotationsMap[index].id)
          this.addMoreTranslationsToThisWidgetType(result, index)
        }
      })
    }else{
      this.openDynamicAnnotationDialog(annotation, index)
    }
  }

  addMoreTranslationsToThisWidgetType(result, index){
    let newtranslations = {}
  
    if(this.translationForm.value.translations)
      newtranslations = this.translationForm.value.translations
    
    if(result[0]){
      const data = result[1]
      const trans = Object.entries(data)
      trans.forEach( value => {
        const key = value[0]
        const keyName = key.split(".")
        const lang = keyName[0]
        const id = keyName[1]
        const field = keyName[2]
        const tranlationField = id +"."+field

        if(value[1]){
          if(newtranslations[lang]){
            newtranslations[lang][tranlationField] = value[1]
          }else{
            newtranslations[lang]= {}
            newtranslations[lang][tranlationField] = value[1]
          }
        }else{
          if(newtranslations[lang]){
            newtranslations[lang][tranlationField] = undefined
          }else{
            newtranslations[lang] = {}
            newtranslations[lang][tranlationField] = undefined
          }
        }
      })
      this.translationForm.patchValue({translations: newtranslations})
    }
  }

  openDynamicAnnotationDialog(annotation, index?){
    // let alltranslationsWidget = {}
    // let translationsWidget = []
    // let columns = []

    // let dialogRef = this.dialog.open(AnnotatedMapAnnotationDynamicDialogComponent, {
    //   data:{
    //     values: annotation,
    //     index: index,
    //     encodedContent: this.actualPolygonOptions[index],
    //     translations: this.translationForm.value.translations,
    //     fields: this.annotatedMapTranslationEditData.annotations[index],
    //     columns: columns,
    //     valuesTranslated: alltranslationsWidget,
    //     languages: this.passLanguagesList,
    //     annotationMapData:this.annotatedMapTranslationEditForm.value,
    //     passId : this.passId,
    //     widgetNumber: this.widgetNumber,
    //   },
    // });
    

    // dialogRef.afterClosed().subscribe(result => {
    //   if(result[0]){
    //     this.allAnnotationsMap[index]=result[1]
    //     this.allAnnotationsDynamicMap[index]=result[1]
    //     this.markerPositions[index]=result[1]
    //     this.annotatedMapTranslationEditForm.patchValue({
    //       annotations:this.allAnnotationsMap
    //     })
    //   }
    // })
  }

  loadMarkers(){
    if(this.annotatedMapTranslationEditData.annotations){
      this.annotatedMapTranslationEditData.annotations.forEach((element:any, index) => {
        if(!element.dynamic){
          if(element.coordinate){
            const lats = element
            lats['lat'] = parseFloat(element.coordinate[0])
            lats['lng'] = parseFloat(element.coordinate[1])
  
            if(element.name)
              lats['title']=element.name
            
            this.markerPositions.push(lats);
            this.center=lats
          }else{
            const lats = element
            const lat = parseFloat(element.lat)
            const lng = parseFloat(element.lng)
            lats['coordinate'] = [lat, lng]
              
            if(element.name)
              lats['title']=element.name
            
            this.markerPositions.push(lats);
            this.center=lats
          }
  
          if(element.linkURL)
            this.supportLinkUrl(element.linkURL, element.id)
  
          if(element.encodedContent)
            this.getPolygon(element, index)
          
        }else{
          this.markerPositions.push(element);
          this.allAnnotationsDynamicMap.push(element)
        }
      });
    }
  }

  getPolygon(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
        pairs = encodedContent.reduce(function(result, value, index, array) {
        if (index % 2 === 0){
          const splited = array.slice(index, index + 2)
          var lat: number = +splited[0];
          var lng: number = +splited[1];
          const value = { lat: lat, lng: lng }
          result.push(value);
        }
          
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptions[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptions[index].path = pairs


        if(markerPosition.strokeColor)
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor

        if(markerPosition.lineWidth)
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth


        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].path && this.actualPolygonOptions[index].path[0]){
          this.center=this.actualPolygonOptions[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptions[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptions[index].paths = pairs

        if(markerPosition.strokeColor)
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor

        if(markerPosition.fillColor)
          this.actualPolygonOptions[index].fillColor = markerPosition.fillColor

        if(markerPosition.lineWidth)
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].paths && this.actualPolygonOptions[index].paths[0]){
          this.center=this.actualPolygonOptions[index].paths[0]
          this.zoom = 9
        }
      }      
    }
    return pairs
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
      this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  supportLinkUrl(linkurl, id){
    this.linkURL[id] = linkurl
    
      // try{
      // const imgUrl = new URL(linkurl)
      // let hostname = environment.urlHostName
      
      // if(imgUrl.protocol=="pass:" ){
      //   const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
      //   if(parts){
      //     const passTemplateID = parts[1]
      //     const path = parts[2]
      //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
      //     this.linkURL[id]=firebaseUrl
      //   }else{
      //     const newparts = linkurl.match(/pass:\/\/(.+)/)
      //     if(newparts){
      //       let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
      //       newparts.forEach( (part,index)=> {
      //         if(index>0){
      //           firebaseUrl += '/' + part 
      //         }
      //       })
      //       this.linkURL[id]=firebaseUrl
      //     }
      //   }
      // } 

      // if(imgUrl.protocol =="tridy:"){
      //   const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
      //   let path =""
      //   parts.forEach((parte, index) =>{
      //     if(index>0 && index<parts.length){
      //       path += "/" + parte
      //     }
      //   })
      //   const firebaseUrl = `https://${hostname}/v1/pass${path}`

      //   this.linkURL[id]=firebaseUrl
      // }
      // }catch(erro){
      // }

      let link = supportUrlStructure(linkurl, this.passId,this.settingsService.contextId$)
      if(link){
      this.linkURL[id]=link
    }
  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  getWidgetFormatedData(data){
    let {title, subtitle} = data
    
    return {
      [this.selectedLanguage+'.'+this.widgetId+'.title']: title ? title : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.subtitle']: subtitle ? subtitle : undefined,
    }
    
  }

  onTabChange(event: MatTabChangeEvent): void {
    if(event.index == 0){
      this.annotatedMapTranslationEditForm.patchValue({annotations : this.annotatedMapTranslationEditForm.value.annotations})
    }
  }
  // --------------------------------
  loadMarkersOriginal(){
    if(this.widgetDefaultLanguage.annotations){
      this.widgetDefaultLanguage.annotations.forEach((element:any, index) => {
        if(!element.dynamic){
          if(element.coordinate){
            const lats = element
            lats['lat'] = parseFloat(element.coordinate[0])
            lats['lng'] = parseFloat(element.coordinate[1])
  
            if(element.name)
              lats['title']=element.name
            
            this.markerPositionsOriginal.push(lats);
            this.center=lats
          }else{
            const lats = element
            const lat = parseFloat(element.lat)
            const lng = parseFloat(element.lng)
            lats['coordinate'] = [lat, lng]
              
            if(element.name)
              lats['title']=element.name
            
            this.markerPositionsOriginal.push(lats);
            this.center=lats
          }
  
          if(element.linkURL)
            this.supportLinkUrl(element.linkURL, element.id)
  
          if(element.encodedContent)
            this.getPolygonOriginal(element, index)
          
        }else{
          this.markerPositionsOriginal.push(element);
          this.allAnnotationsDynamicMapOriginal.push(element)
        }
      });
    }
  }

  getPolygonOriginal(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
        pairs = encodedContent.reduce(function(result, value, index, array) {
        if (index % 2 === 0){
          const splited = array.slice(index, index + 2)
          var lat: number = +splited[0];
          var lng: number = +splited[1];
          const value = { lat: lat, lng: lng }
          result.push(value);
        }
          
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptionsOriginal[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptionsOriginal[index].path = pairs

        if(markerPosition.strokeColor)
          this.actualPolygonOptionsOriginal[index].strokeColor = markerPosition.strokeColor

        if(markerPosition.lineWidth)
          this.actualPolygonOptionsOriginal[index].strokeWeight = markerPosition.lineWidth

        if(this.actualPolygonOptionsOriginal[index] && this.actualPolygonOptionsOriginal[index].path && this.actualPolygonOptionsOriginal[index].path[0]){
          this.center=this.actualPolygonOptionsOriginal[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptionsOriginal[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptionsOriginal[index].paths = pairs

        if(markerPosition.strokeColor)
          this.actualPolygonOptionsOriginal[index].strokeColor = markerPosition.strokeColor

        if(markerPosition.fillColor)
          this.actualPolygonOptionsOriginal[index].fillColor = markerPosition.fillColor

        if(markerPosition.lineWidth)
          this.actualPolygonOptionsOriginal[index].strokeWeight = markerPosition.lineWidth

        if(this.actualPolygonOptionsOriginal[index] && this.actualPolygonOptionsOriginal[index].paths && this.actualPolygonOptionsOriginal[index].paths[0]){
          this.center=this.actualPolygonOptionsOriginal[index].paths[0]
          this.zoom = 9
        }
      }      
    }
    return pairs
  }

  supportLinkUrlOriginal(linkurl, id){
    this.linkURLOriginal[id] = linkurl
    let link = supportUrlStructure(linkurl, this.passId,this.settingsService.contextId$)
    if(link){
      this.linkURLOriginal[id]=link
    }
  }

  public openInfoWindowOriginal(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindowOriginal)
      this.prev_infowindowOriginal.close()

    this.prev_infowindowOriginal = infoWindow
    infoWindow.open(marker);
  }

  showOriginal(){
    this.showOriginalActive = true
  }

  hideOriginal(){
    this.showOriginalActive = false
  }

}
