<section class="topNavbar">
    <button mat-button style="color:var(--warmOrange);" (click)="backTo();">
        <span class="material-symbols-outlined" style="font-size: 10px;">
            arrow_back_ios
        </span>
        <span>{{backToTitle}}</span>
        <!-- <span>{{'coverImages.title' | translate}}</span> -->
    </button>
    
</section>

<div class="d-flex justify-content-end p-2" style="width: 60%; margin:auto; max-width: 800px;">
    <button *ngIf="coverImageUserRights_delete" mat-fab extended style="background-color:white; color:var(--accentGlobal);" id="button-tridy" (click) = "onDelete();">
        <mat-icon>delete</mat-icon>
        {{'delete' | translate}}
    </button>
</div>

<section class="container p-2">
    
    <div class="mb-4" >
        <img  src="{{imageUrl}}"  class="rounded" style="width:100%; height:auto; max-height:450px;" onerror="this.src='./../../assets/img/default.jpg'">
    </div>

    <div class="d-flex flex-column w-100" id="info-cover">
        <div class="form-control mb-3">
            <div class="d-flex flex-row flex-wrap justify-content-between " >
                <span>{{'coverImages.ci_title' | translate}}</span>
                <div *ngIf="title">
                    <span class="text-muted">{{title}}</span>
                </div>
            </div>
        </div>


        <div class="form-control mb-3">
            <div class="d-flex flex-row flex-wrap justify-content-between " >
                <span>{{'coverImages.ci_subtitle' | translate}}</span>
                <div *ngIf="subtitle">
                    <span class="text-muted">{{subtitle}}</span>
                </div>
            </div>
        </div>

        <div class="form-control mb-3">
            <div class="d-flex flex-row flex-wrap justify-content-between " >
                <span>{{'coverImages.ci-prefix' | translate}}</span>
                <div *ngIf="urlPrefix">
                    <span class="text-muted">{{urlPrefix}}</span>
                </div>
            </div>
        </div>
    </div>
    
</section>



