<div mat-dialog-title>
    <h2 class="d-inline">{{'location' | translate}} - {{'translationMode' | translate}}</h2>
    <button *ngIf="!showOriginalActive" mat-button style="position: relative; float: right;" class="m-3 mx-2" (click)="showOriginal();"><mat-icon>visibility</mat-icon>Show original</button>
    <button *ngIf="showOriginalActive" mat-button style="position: relative; float: right;" class="m-3 mx-2" (click)="hideOriginal();"><mat-icon>visibility_off</mat-icon>Hide original</button>
</div>

<mat-dialog-content ><!--style="width:600px;"-->
    <div class="pt-2 pb-3 d-flex" [ngStyle]="{'justify-content': showOriginalActive ? 'space-between' : 'flex-end'}">

    <section *ngIf="showOriginalActive" class="mt-1 mb-3 rounded m-auto w-100" id="section"  style="max-width: 600px;">
        <p style="font-weight: bold;">{{languagesList[data?.languages[0]]}} (Original)</p>
        <div>
            <!-- <google-map height="250px" width="100%" [center]="center" [zoom]="zoom" [options]="{disableDefaultUI: true}">
                <map-marker *ngFor="let markerPosition of markerPositionsOriginal" [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>

                <ng-container *ngFor="let markerPosition of markerPositionsOriginal; let i=index">
                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='point'">
                        <map-marker #marker="mapMarker" [position]="markerPosition" 
                                [options]="markerOptions"  [label]="markerPosition['badge']" 
                                (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                        </map-marker>
                        
                        <map-info-window #infoWindow="mapInfoWindow"> 
                            <div class="d-flex flex-column">
                                <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                <a *ngIf="markerPosition['linkURL'] && linkURL" href="{{linkURL}}" target="_blank" style="color:var(--warmOrange);">
                                    <span class="material-symbols-outlined">link</span>
                                </a>
                                <a *ngIf="markerPosition['directions']" href="{{openMapDirection(markerPosition)}}" target="_blank" class="mt-1">
                                    <button mat-button class="secondary-gray-button w-100">
                                        <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                    </button>
                                </a>
                                <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>
                            </div>
                        </map-info-window>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='line'">
                        <map-polyline [options]="actualPolygonOptions"></map-polyline>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon'">
                        <ng-container>
                            <map-polygon #polygon [options]="actualPolygonOptions" ></map-polygon>
                        </ng-container>
                    </div>

                    <div *ngIf="!markerPosition['type']">
                        <map-marker [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>
                    </div>
                </ng-container>
            </google-map> -->
    
            <div [app-annotated-map-view]="[originalLanguageForm.value, true, 'map-dialog-translations-annotation-original-'+widgetId, passId, [annotationDataOriginal], '250px']"></div>
        </div>
        <div class="pt-2 pb-3">
            <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
                <form [formGroup]="originalLanguageForm">
    
                    <div *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='point'">
                        <div class="text-muted pb-3">{{'pass.map.coordinate' | translate}}</div>
                        <div class="d-flex flex-row">
                            <div class="pe-2 flex-grow-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'pass.map.latitude' | translate}}</mat-label>
                                    <input matInput type="number" id="newLat" formControlName="lat" placeholder="{{'pass.map.latitude' | translate}}" >
                                </mat-form-field>
                            </div>
                            <div class="ps-2 flex-grow-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'pass.map.longitude' | translate}}</mat-label>
                                    <input matInput type="number" id="newLng" formControlName="lng"  placeholder="{{'pass.map.longitude' | translate}}">
                                </mat-form-field> 
                            </div>
                        </div>
    
                        <div class="form-control mb-3 d-flex justify-content-between w-100 p-3">
                            <label for="value">{{'pass.map.show_directions' | translate}}</label>
                            <mat-slide-toggle class="ms-5 example-margin" formControlName="directions" ></mat-slide-toggle>  
                        </div>
                    </div>
    
                    <div *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='polygon'" class="pb-4">
                        <span class="text-muted text-uppercase">{{'polygon' | translate}}</span>
                        <div class="list-group">
                            <div class="list-group-item  p-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span >{{'points' | translate}}</span>
                                    <span class="text-muted" >{{actualPolygonOptions.paths.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='line'" class="pb-4">
                        <span class="text-muted text-uppercase">{{'line' | translate}}</span>
                        <div class="list-group">
                            <div class="list-group-item  p-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span >{{'points' | translate}}</span>
                                    <span class="text-muted" >{{actualPolygonOptions.path.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'pass.map.title' | translate}}</mat-label>
                        <input matInput type="text" id="titleMap" formControlName="title" placeholder="{{'pass.title' | translate}}">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.map.subtitle' | translate}}</mat-label>
                        <input matInput type="text" id="subtitleMap" formControlName="subtitle" placeholder="{{'pass.subtitle' | translate}}">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.map.badge' | translate}}</mat-label>
                        <input matInput type="text" id="bagdeMap" formControlName="badge" placeholder="{{'pass.map.badge' | translate}}">
                    </mat-form-field>
    
                    <div class="d-flex flex-row" >
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.linkURL' | translate}}</mat-label>
                            <input matInput type="url" id="linkURLMap" formControlName="linkURL" placeholder="{{'pass.linkURL' | translate}}">
                            <mat-error>{{'url_pattern_invalid' | translate}}  </mat-error>
                            <mat-hint>{{'url_pattern_detailed' | translate}}  </mat-hint>
                        </mat-form-field>
                     
                        <button mat-icon-button disabled matTooltip="{{'pick' | translate}}">
                            <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                        </button>
                        <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event)" #fileInput>
                    </div>
    
                    <div>
                        <div class="text-muted text-uppercase py-3">{{'style' | translate}}</div>
    
                        <div class="list-group" *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='point'">
                            <div class="list-group-item p-2 ps-3 pe-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span class="w-50 bg-white text-muted" >{{'pinColor' | translate}}</span>
                                    <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="pinColor" style="margin-left:10px;"></ngx-colors>
                                        <span *ngIf="invalid_pinColor" id="invalid_pinColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='polygon'">
                            <div class="list-group pb-3" >
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'lineColor' | translate}}</span>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
    
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'fillColor' | translate}}</span>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="fillColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_fillColor" id="invalid_fillColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'lineWidth' | translate}}</mat-label>
                                <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                            </mat-form-field> 
                        </div>
    
                        <div *ngIf="annotationDataOriginal.type && annotationDataOriginal.type=='line'">
                            <div class="list-group pb-3" >
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'lineColor' | translate}}</span>                          
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'lineWidth' | translate}}</mat-label>
                                <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                            </mat-form-field> 
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </section>

    <section *ngIf="showOriginalActive" class="m-3" >
        <div class="vertical-separator"></div>
    </section>

    <section class="mt-1 mb-3 rounded m-auto w-100" id="section" style="max-width: 600px;">
        <p *ngIf="showOriginalActive" style="font-weight: bold;">{{languagesList[selectedLanguage]}}</p>
        <div>
            <!-- <google-map height="250px" width="100%"
                            [center]="center" [zoom]="zoom" [options]="{disableDefaultUI: true}">
                <map-marker *ngFor="let markerPosition of markerPositions"
                            [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>

                <ng-container *ngFor="let markerPosition of markerPositions; let i=index">
                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='point'">
                        <map-marker #marker="mapMarker" [position]="markerPosition" 
                                [options]="markerOptions"  [label]="markerPosition['badge']" 
                                (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                        </map-marker>
                        
                        <map-info-window #infoWindow="mapInfoWindow"> 
                            <div class="d-flex flex-column">
                                <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                <a *ngIf="markerPosition['linkURL'] && linkURL" href="{{linkURL}}" target="_blank" style="color:var(--warmOrange);">
                                    <span class="material-symbols-outlined">link</span>
                                </a>
                                <a *ngIf="markerPosition['directions']" href="{{openMapDirection(markerPosition)}}" target="_blank" class="mt-1">
                                    <button mat-button class="secondary-gray-button w-100">
                                        <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                    </button>
                                </a>
                                <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>
                            </div>
                        </map-info-window>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='line'">
                        <map-polyline [options]="actualPolygonOptions"></map-polyline>
                    </div>

                    <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon'">
                        <ng-container>
                            <map-polygon #polygon [options]="actualPolygonOptions" ></map-polygon>
                        </ng-container>
                    </div>

                    <div *ngIf="!markerPosition['type']">
                        <map-marker [position]="markerPosition" [options]="markerOptions" [label]="markerPosition['badge']"></map-marker>
                    </div>
                </ng-container>
            </google-map> -->

            <div [app-annotated-map-view]="[newAnnotationtranslationsForm.value, true, 'map-dialog-translations-annotation-'+widgetId, passId, [annotationAllValues], '250px']"></div>

        </div>
        <div class="pt-2 pb-3">
            <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
                <form [formGroup]="newAnnotationtranslationsForm">
    
                    <div *ngIf="annotationData.type && annotationData.type=='point'">
                        <div class="text-muted pb-3">{{'pass.map.coordinate' | translate}}</div>
                        <div class="d-flex flex-row">
                            <div class="pe-2 flex-grow-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'pass.map.latitude' | translate}}</mat-label>
                                    <input matInput type="number" id="newLat" formControlName="lat" placeholder="{{'pass.map.latitude' | translate}}" >
                                </mat-form-field>
                            </div>
                            <div class="ps-2 flex-grow-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'pass.map.longitude' | translate}}</mat-label>
                                    <input matInput type="number" id="newLng" formControlName="lng"  placeholder="{{'pass.map.longitude' | translate}}">
                                </mat-form-field> 
                            </div>
                        </div>
    
                        <div class="form-control mb-3 d-flex justify-content-between w-100 p-3">
                            <label for="value">{{'pass.map.show_directions' | translate}}</label>
                            <mat-slide-toggle class="ms-5 example-margin" formControlName="directions" ></mat-slide-toggle>  
                        </div>
                    </div>
    
                    <div *ngIf="annotationData.type && annotationData.type=='polygon'" class="pb-4">
                        <span class="text-muted text-uppercase">{{'polygon' | translate}}</span>
                        <div class="list-group">
                            <div class="list-group-item  p-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span >{{'points' | translate}}</span>
                                    <span class="text-muted" >{{actualPolygonOptions.paths.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="annotationData.type && annotationData.type=='line'" class="pb-4">
                        <span class="text-muted text-uppercase">{{'line' | translate}}</span>
                        <div class="list-group">
                            <div class="list-group-item  p-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span >{{'points' | translate}}</span>
                                    <span class="text-muted" >{{actualPolygonOptions.path.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'pass.map.title' | translate}}</mat-label>
                        <input matInput type="text" id="titleMap" formControlName="title" placeholder="{{'pass.title' | translate}}">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.map.subtitle' | translate}}</mat-label>
                        <input matInput type="text" id="subtitleMap" formControlName="subtitle" placeholder="{{'pass.subtitle' | translate}}">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.map.badge' | translate}}</mat-label>
                        <input matInput type="text" id="bagdeMap" formControlName="badge" placeholder="{{'pass.map.badge' | translate}}">
                    </mat-form-field>
    
                    <div class="d-flex flex-row" >
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.linkURL' | translate}}</mat-label>
                            <input matInput type="url" id="linkURLMap" formControlName="linkURL" placeholder="{{'pass.linkURL' | translate}}">
                            <mat-error>{{'url_pattern_invalid' | translate}}  </mat-error>
                            <mat-hint>{{'url_pattern_detailed' | translate}}  </mat-hint>
                        </mat-form-field>
            
                        <button mat-icon-button (click)="fileInput.click()" matTooltip="{{'pick' | translate}}">
                            <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                        </button>
                        <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event)" #fileInput>
                    </div>
    
                    <div>
                        <div class="text-muted text-uppercase py-3">{{'style' | translate}}</div>
    
                        <div class="list-group" *ngIf="annotationData.type && annotationData.type=='point'">
                            <div class="list-group-item p-2 ps-3 pe-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span class="w-50 bg-white text-muted" >{{'pinColor' | translate}}</span>
                                    <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="pinColor" style="margin-left:10px;"></ngx-colors>
                                        <span *ngIf="invalid_pinColor" id="invalid_pinColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div *ngIf="annotationData.type && annotationData.type=='polygon'">
                            <div class="list-group pb-3" >
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'lineColor' | translate}}</span>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
    
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'fillColor' | translate}}</span>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="fillColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_fillColor" id="invalid_fillColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'lineWidth' | translate}}</mat-label>
                                <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                            </mat-form-field> 
                        </div>
    
                        <div *ngIf="annotationData.type && annotationData.type=='line'">
                            <div class="list-group pb-3" >
                                <div class="list-group-item p-2 ps-3 pe-3">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class=" w-50 bg-white text-muted" >{{'lineColor' | translate}}</span>                          
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'lineWidth' | translate}}</mat-label>
                                <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                            </mat-form-field> 
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="[false]" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();"  >{{'save' | translate}}</button>
</mat-dialog-actions>