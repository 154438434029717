import { Component, Injectable, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { TridysCreateDialogComponent } from '../tridys-create-dialog/tridys-create-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TridysService } from '../service/tridys.service';
import { CommentsService } from '../service/comments.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}
@Component({
  selector: 'app-tridys',
  templateUrl: './tridys.component.html',
  styleUrls: ['./tridys.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})

export class TridysComponent {
  tridysData: any = []
  alltridys:any
  displayedColumns: string[] = [ 'contentName', 'timeStamp', "creationDate"];
  dataSource=new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor( private  settingsService: SettingsService,
    public dialog: MatDialog,
    private tridyService: TridysService,
    private commentService: CommentsService,
    private route: Router,
    private translate:TranslateService
    ){
    
  }
  breakpoint: number;
  paginatorSize = this.settingsService.paginator$
  searchText = ''; 

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
     try{
      this.commentService.progressSpin.emit(true)
      this.tridysData = this.settingsService.observeTridysFirestore()
      this.dataSource = new MatTableDataSource<any>(this.tridysData);
     }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
     }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      if(this.tridysData.length!=0){
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.firstPage()
        this.commentService.progressSpin.emit(false)
      }else{
        this.commentService.progressSpin.emit(false)
      }

    }, 600);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  createTridy(){
    let dialogRef= this.dialog.open(TridysCreateDialogComponent, {
      data: { 
         values: ""
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        try{
          this.commentService.progressSpin.emit(true)
          const newId=this.tridyService.createTridy(result[1])
          const message = this.translate.instant("SnackBarConstants.CREATE_OK")
          this.commentService.addSnackBar.emit(message)
          this.route.navigate(['home', this.settingsService.contextId$,'tridys',result[1].id] )
          this.commentService.progressSpin.emit(false)
        }catch(error){
          console.log(error)
          const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
          this.commentService.addSnackBar.emit(message)
        }
      }
    });
  }


}


