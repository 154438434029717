
<h2 mat-dialog-title>{{'pass.widgets.filter.comparisson' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style="position:absolute;top:25px;right:10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div>
 -->
<mat-dialog-content style="width: 600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="filterForm">        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.widgets.filter.field' | translate}}</mat-label>
                    <input formControlName="field" type="text" matInput placeholder="{{'pass.widgets.filter.field' | translate}}">
                </mat-form-field>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'pass.widgets.filter.operator' | translate}}</mat-label>
                <mat-select formControlName="operator">
                    <!-- <mat-option value=""> - </mat-option> -->
                    <mat-option *ngFor="let operator of filterOperatorTypes" [value]="operator.type">{{ operator.translate | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.widgets.filter.value' | translate}}</mat-label>
                    <input formControlName="value" type="text" matInput placeholder="{{'pass.widgets.filter.value' | translate}}">
                </mat-form-field>
                
            </form>
        </section>
    </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="onSave();" [disabled]="filterForm.invalid">{{'apply' | translate}}</button>
</mat-dialog-actions>
