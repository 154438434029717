
<h2 mat-dialog-title>{{'pass.widgets.form' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->
<mat-dialog-content style="width:650px;">
    <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
        <form [formGroup]="formEditForm">
    
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'pass.name' | translate}}</mat-label>
                <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
            </mat-form-field>

            <div class="d-flex flex-row">
                <div class="pe-2 flex-grow-1">
                    <div class="input-group">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.title' | translate}}</mat-label>
                            <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
                <div class="ps-2 flex-grow-1">
                    <div class="input-group">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                            <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row">
                <div class="pe-2 flex-grow-1">
                    <div class="input-group">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.submitTitle' | translate}}</mat-label>
                            <input formControlName="submitTitle" type="text" matInput placeholder="{{'pass.submitTitle' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
                <div class="ps-2 flex-grow-1">
                    <div class="input-group">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.submitAgainTitle' | translate}}</mat-label>
                            <input formControlName="submitAgainTitle" type="text" matInput placeholder="{{'pass.submitAgainTitle' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'pass.successMessage' | translate}}</mat-label>
                <input formControlName="successMessage" type="text" matInput placeholder="{{'pass.successMessage' | translate}}">
                <mat-hint>{{'pass.successMessageHint' | translate}}</mat-hint>
            </mat-form-field>

            <mat-tab-group class="mt-3">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="material-symbols-outlined me-2">view_stream</mat-icon>  {{'pass.form.manage_sections' | translate}}
                    </ng-template>
                    <div class="p-2 mt-3">
                        <button mat-button 
                                class="secondary-gray-button my-2"
                                (click)="addSection();"
                        >
                            <mat-icon>add_circle</mat-icon> {{'taskTemplates.wt_section'  | translate}}
                        </button>

                        <div class="my-3">
                            <mat-accordion class="form-field-section" multi>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let section of sectionsEdit; let sectionIndex=index">
                                    <mat-expansion-panel-header>
                                    
                                        <mat-panel-title >
                                            <span *ngIf="section.title" style="color:var(--warmOrange);">{{section.title ? section.title : 'pass.newSection' | translate}} </span>
                                            <span *ngIf="!section.title" class="text-muted">{{'pass.newSection' | translate}} </span>
                                        </mat-panel-title>

                                        <mat-panel-description style="flex-grow: 0;">
                                            <button mat-icon-button [matMenuTriggerFor]="editDelete" style="color:var(--warmOrange);" (click)="$event.stopPropagation()">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #editDelete="matMenu">
                                                <button mat-menu-item (click)="editTitleSection(section.id,section.title, sectionIndex)">
                                                    <mat-icon>edit</mat-icon> {{"taskTemplates.editSectionTitle" | translate}}
                                                </button>
                                                <!-- <button mat-menu-item (click)="duplicateSection(section, sectionIndex);" ><mat-icon>content_copy</mat-icon> {{"taskTemplates.duplicateSection" | translate}}</button> -->
                                                <button mat-menu-item (click)="deleteSection(section.title, sectionIndex);" style="margin-left: 0" class="delete-button">
                                                    <mat-icon class="material-symbols-outlined">delete</mat-icon> {{"taskTemplates.deleteSection" | translate}}
                                                </button>
                                            </mat-menu>
                                        </mat-panel-description>

                                    </mat-expansion-panel-header>
                    
                                    <mat-action-row>
                                        <button mat-button 
                                                class="secondary-gray-button my-2"
                                                [matMenuTriggerFor]="fieldTypes"
                                        >
                                            <mat-icon>add_circle</mat-icon> {{'workTask.addNewElement' | translate}}
                                        </button>
                                        <mat-menu #fieldTypes="matMenu">
                                            <button mat-menu-item (click)="addFieldToSection('text', sectionIndex);" >{{'taskTemplates.add_text' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('longText', sectionIndex);" >{{'taskTemplates.add_longText' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('number', sectionIndex);" >{{'taskTemplates.add_number' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('decimal', sectionIndex);" >{{'taskTemplates.add_decimal' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('date', sectionIndex);" >{{'taskTemplates.add_date' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('time', sectionIndex);" >{{'taskTemplates.add_time' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('dateTime', sectionIndex);" >{{'taskTemplates.add_dateTime' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('boolean', sectionIndex);" >{{'taskTemplates.add_switch' | translate}} </button>
                                            <button mat-menu-item (click)="addFieldToSection('options', sectionIndex);" >{{'taskTemplates.add_list' | translate}} </button>
                                        </mat-menu>
                                    </mat-action-row>
                                
                                    <div style="max-height: 530px; overflow-y: auto;">
                                        <div class="list-group" *ngIf="section.fields" cdkDropList (cdkDropListDropped)="drop($event,sectionIndex)">
                                            <div class="list-group-item" style="padding: 2px 0;" *ngFor="let field of section.fields; let fieldIndex = index" cdkDrag>
                                                <div class="d-flex flex-row justify-content-between align-items-center">
                                                    <div class="d-flex flex-row flex-wrap" (click)="openFieldDialog(field, fieldIndex, sectionIndex)" >
                                                        <span *ngIf="field.type=='text'" class="material-symbols-outlined p-2" style="color:var(--warmOrange);">text_format</span>
                                                        <span *ngIf="field.type=='longText'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">subject</span>
                                                        <span *ngIf="field.type=='number'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">pin</span>
                                                        <span *ngIf="field.type=='decimal'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">numbers</span>
                                                        <span *ngIf="field.type=='date'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">today</span>
                                                        <span *ngIf="field.type=='time'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">schedule</span>
                                                        <span *ngIf="field.type=='dateTime'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">calendar_month</span>
                                                        <span *ngIf="field.type=='boolean'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">toggle_on</span>
                                                        <span *ngIf="field.type=='options'" class="material-symbols-outlined p-2 text-orange" style="color:var(--warmOrange);">list</span>

                                                        <div class="ps-1 d-flex flex-column justify-content-center">
                                                            <span>{{field.title}} <span *ngIf="field.mandatory">*</span></span>
                                                            <span class="text-muted" style="font-size: 11px;">{{field.key}}</span>
                                                        </div>
                                                    </div>
                    
                                                    <button mat-icon-button style="color:var(--warmOrange);" (click)="deleteField(field, fieldIndex, sectionIndex);" > 
                                                        <span class="material-symbols-outlined"> 
                                                            delete  
                                                        </span>
                                                    </button>
                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="material-symbols-outlined me-2">palette</mat-icon>  {{'pass.form.btn_form_style' | translate}}
                    </ng-template>
                    <div class="p-2 mt-3">
                        <form [formGroup]="submitButtonStyleForm">

                            <div class="d-flex flex-row">
                                <div class="pe-2 flex-grow-1">
                                    <div class="input-group">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'pass.buttons.borderWidth' | translate}}</mat-label>
                                            <input formControlName="borderWidth" type="number" step="0.1" matInput min="0" placeholder="{{'pass.buttons.borderWidth' | translate}}">
                                            <span matSuffix class="p-3"> px</span>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="ps-2 flex-grow-1">
                                    <div class="input-group">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'pass.buttons.cornerRadius' | translate}}</mat-label>
                                            <input formControlName="cornerRadius" type="number" step="0.1" min="0" matInput placeholder="{{'pass.buttons.cornerRadius' | translate}}">
                                            <span matSuffix class="p-3"> px</span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="list-group ">
                                <div class="list-group-item">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class="w-50 bg-white" >{{'pass.buttons.backgroundColor' | translate}}</span>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="backgroundColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_backgroundColor" id="invalid_backgroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </div>
        
                                <div class="list-group-item">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class="w-50 bg-white" >{{'pass.buttons.borderColor' | translate}}</span>
                                    
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="borderColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_borderColor" id="invalid_borderColor" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                        </label>
                                    </div>
                                </div>
        
                                <div class="list-group-item">
                                    <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                        <span class="w-50 bg-white" >{{'pass.buttons.foregroundColor' | translate}}</span>
                                        
                                        <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="foregroundColor" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_foregroundColor" id="invalid_foregroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> 
                </mat-tab>
            </mat-tab-group>

        </form>
    </section>

    <div [app-widget-edit-filters] = "formData" (returnChanges)="returnChangesFilters($event)"></div>
    
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="dialogSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>