
<!-- <section class="topNavbar"  >

</section> -->

<section class="container">
    <div class="row mt-4">
        <div class="col-12 mb-4">
            <button mat-fab extended style="background-color:var(--accentGlobal); color:white;" class="mb-2" id="btn-create" (click)="triggerFileInput()">
                <mat-icon>add_circle</mat-icon>
                {{'upload' | translate}}
                <label class="file-button">
                    <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);">
                </label>
            </button>
        </div>
        <div class="col-12">

            <div class="shadow-sm containerFolders" >
                <p *ngIf="folder.length==0">{{'empty' | translate}}</p>
                <div *ngFor="let value of folder" class="m-3 "> 
        
                    <!-- <button *ngIf="value.folder" mat-button style="color:var(--warmOrange);" routerLink="{{value.folder}}">
                        <span class="material-symbols-outlined" >
                            folder
                        </span>
                        <span>{{value.folder}}</span>
                    </button> -->
        
                    <button *ngIf="value.folder" mat-fab extended id="folder-btn" style="color:var(--warmOrange); background-color:var(--pureWhite);" routerLink="{{value.folder}}" >
                        <mat-icon>folder</mat-icon>
                        {{value.folder}}
                    </button>
        
                    <div *ngIf="value.item" style="    background-position: 0px 0px, 10px 10px;
                                                background-size: 20px 20px;
                                    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);">
                    <!-- <div *ngIf="value.item" style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); width: 200px; margin:5px;"> -->
                        <img  src="{{value.url}}" alt="{{value.item}}" width="200" style="border-radius:10px;">
        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

