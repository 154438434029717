import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';
import { SettingsService } from './settings.service';
import { newTaskTemplate } from '../../../../backend/src/defaultFieldsTaskTemplates.dto';

@Injectable({
  providedIn: 'root'
})
export class WorkTaskTemplatesService {

  constructor(private firestore: AngularFirestore,
    private settingsService: SettingsService) { }



  async createWorkTaskTemplate(data):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const workTTRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES).doc().ref

      const newId = workTTRef.id
      let newWorktt = newTaskTemplate
      newWorktt['creationDate'] = new Date()
      newWorktt['timeStamp'] = new Date()
      newWorktt['id'] = newId
      newWorktt['taskType'] = 'create'
      if(data['groupId'])
        newWorktt['groupId'] = data['groupId']
      if(data['title'])
        newWorktt['title'] = data['title']

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES).doc(newId).set({...newWorktt})

        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })

  }

  updateWorkTaskTemplate(workTaskTemplate:any, id:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
       if(!workTaskTemplate['id']){
          workTaskTemplate['id'] = id
       }
      try{
        workTaskTemplate['timeStamp']= new Date()
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES)
        .doc(workTaskTemplate.id)
        .update(workTaskTemplate)
        
        resolve()
      }catch(error){
        reject(error)
      }
    }) 
  }

  deleteworkTaskTemplate(workTaskTemplateId: any):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES)
        .doc(workTaskTemplateId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

}
