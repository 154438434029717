import { Component, Input } from '@angular/core';
import { deepCopy, openMapDirection } from '../../../../backend/utils/object';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: '[app-map-marker-horizontal]',
  templateUrl: './map-marker-horizontal.component.html',
  styleUrls: ['./map-marker-horizontal.component.css']
})
export class MapMarkerHorizontalComponent {
  @Input('app-map-marker-horizontal') data:any;
  mapData:any
  annotationsData:any
  editMode:any
  firstMap:any
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 3;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  pairsPath:any ={}
  //advMarker: google.maps.marker.PinView
  mapTypeId: google.maps.MapTypeId

  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }

  index:any
  idToShow:any
  linkURL:any = {}
  routerUrl:any
  
  constructor(
    private router: ActivatedRoute,
    private settingsService: SettingsService
  ) { 
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit(): void {
    this.mapData=this.data[0]
    this.annotationsData=this.data[1]
    this.index = this.data[2]

    if(this.mapData.annotationGroup){
      this.annotationsData=this.mapData.annotationGroup
    }
    this.mapTypeId= google.maps.MapTypeId.ROADMAP

    if(this.mapData.style=='satellite')
      this.mapTypeId= google.maps.MapTypeId.SATELLITE
    
    this.loadMarkers()
  }
  
  ngOnChanges():void{
    this.annotationsData=this.data[1]
  }

  updateMarkers(){
   this.markerPositions=this.annotationsData 
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    infoWindow.open(marker);
  }

  loadMarkers(){
    if(this.annotationsData && !this.annotationsData.dynamic){
      const element = this.annotationsData
      const index = this.index
      if(element.coordinate){
        const lats = element
        lats['lat'] = parseFloat(element.coordinate[0])
        lats['lng'] = parseFloat(element.coordinate[1])
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        if(element.name){
          lats['title']=element.name
        }
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        this.markerPositions.push(lats);
        this.center=lats
      }else{
        const lats = element
        lats['lat'] = parseFloat(element.lat)
        lats['lng'] = parseFloat(element.lng)
        if(element.name){
          lats['title']=element.name
        }
        if(!element.badge){
          lats['badge']=(index +1).toString()
        }
        this.markerPositions.push(lats);
        this.center=lats
      }

      if(element.linkURL)
          this.supportLinkUrl(element.linkURL, element.id)

      if(element.encodedContent){
        this.getPolygon(element, index)
      }
    }
  }



  showArrays(event: any) {
    // Since this polygon has only one path, we can call getPath() to return the
    // MVCArray of LatLngs.
    // @ts-ignore
    // const polygon = this as google.maps.Polygon;
    // const vertices = polygon.getPath();
  
    // let contentString =
    //   "<b>Bermuda Triangle polygon</b><br>" +
    //   "Clicked location: <br>" +
    //   event.latLng.lat() +
    //   "," +
    //   event.latLng.lng() +
    //   "<br>";
  
    // // Iterate over the vertices.
    // for (let i = 0; i < vertices.getLength(); i++) {
    //   const xy = vertices.getAt(i);
  
    //   contentString +=
    //     "<br>" + "Coordinate " + i + ":<br>" + xy.lat() + "," + xy.lng();
    // }

    // this.infoWindow = new google.maps.InfoWindow();
    // // Replace the info window's content and position.
    // this.infoWindow.setContent(contentString);
    // this.infoWindow.setPosition(event.latLng);
    // this.infoWindow.open(this.googlemaps);
  }

  getPolygon(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
      pairs = encodedContent.reduce(function(result, value, index, array) {
      if (index % 2 === 0){
        const splited = array.slice(index, index + 2)
        var lat: number = +splited[0];
        var lng: number = +splited[1];
        const value = { lat: lat, lng: lng }
        result.push(value);
      }
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptions[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptions[index].path = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.fillColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.strokeWeight
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].path && this.actualPolygonOptions[index].path[0]){
          this.center=this.actualPolygonOptions[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptions[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptions[index].paths = pairs

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].fillColor = markerPosition.fillColor
        }

        if(markerPosition.strokeColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].paths && this.actualPolygonOptions[index].paths[0]){
          this.center=this.actualPolygonOptions[index].paths[0]
          this.zoom = 9
        }
      }
    }
    return pairs
  }


  supportLinkUrl(linkurl, id){
    this.linkURL[id] = linkurl
    if(linkurl){
      try{
      const imgUrl = new URL(linkurl)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.linkURL[id]=firebaseUrl
        }else{
          const newparts = linkurl.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.linkURL[id]=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.linkURL[id]=firebaseUrl
      }

      if(imgUrl.protocol == "context:"){
        const parts = linkurl.match(/context:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
                path += "/" + parte
            }
        })
        const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
        this.linkURL[id]=firebaseUrl
      }
      }catch(erro){
      }
    }
  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }
}
