import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  constructor(private firestore: AngularFirestore,
    private settingsService: SettingsService) { }


    updateContextDataAccount(newValues){
      return new Promise<void>(async (resolve, reject) => {

        try{ 
          await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .update(newValues)
          
          resolve()
        }catch(error){
          reject(error)
        }
      }) 
    }

}
