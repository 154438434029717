import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ImagesHorizontal} from "../../../../../../backend/src/imagesHorizontal.dto"
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadService } from 'src/app/service/upload.service';
import { CommentsService } from 'src/app/service/comments.service';


@Component({
  selector: 'app-images-horizontal-edit',
  templateUrl: './images-horizontal-edit.component.html',
  styleUrls: ['./images-horizontal-edit.component.css']
})

export class ImagesHorizontalEditComponent implements OnInit {
  editImgHData:any
  allImages:any
  urls:any
  oldImagesLength:any
  imagesHorizontalEditForm!: FormGroup
  oldData=this.data[0]
  changes=false
  carouselImg=this.data[1]
  newImage:any=[]
  widgetNumber:any
  passId:any
  imgFromImgNew:any=[]
  allImagesAfterDelete:any=[]

  constructor(
    public dialogRef: MatDialogRef<ImagesHorizontalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private uploadService: UploadService,
    private commentService: CommentsService,
  ){
    dialogRef.disableClose = true;
  }
 
  ngOnInit():void{
    this.oldImagesLength=this.data.values[1]
    this.editImgHData=this.data.values
    if(this.editImgHData.images.length!=0){
      this.allImages=this.editImgHData.images
    }else{
      this.allImages=[]
    }
    
    this.urls=this.editImgHData.images
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']

    this.imagesHorizontalEditForm = this.fb.group({
      id:[],
      type:[],
      title:[],
      subtitle:[],
      images:[]
    })
    const {id, type, title, subtitle, images } = this.editImgHData
    this.imagesHorizontalEditForm.patchValue({
      id: id ? id : undefined,
      type: type ? type : undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle : undefined,
      images: this.allImages ? this.allImages : undefined
    })

    this.imagesHorizontalEditForm.valueChanges.subscribe( (value:any) => {
    })
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      this.newImage.push(event.target.files[0])
      reader.readAsDataURL(event.target.files[0]);
      
      reader.onload = (event) => { 
        if(event.target){
          const url = event.target.result;
          if(url){
            var newimg=
            {
              "url": url
            }
            const a=this.imagesHorizontalEditForm.value.images
            this.imagesHorizontalEditForm.patchValue({
              images: [...a, newimg]
            })
            this.imgFromImgNew.push({form: a.length, new : this.newImage.length -1})
            this.changes=true
            this.carouselImg= this.imagesHorizontalEditForm.value.images.length
          }
          
        }
 
      }
    }

  }

  async onSubmitEdit(){
  
    await this.uploadService.uploadImageHorizontal(this.passId, this.newImage, this.widgetNumber)

    this.commentService.progressSpin.emit(true)
    setTimeout(() =>{
      const newUrl = this.uploadService.getImagesHorizontal()
      const a=this.imagesHorizontalEditForm.value.images

      if(this.allImages.length==0){
        this.imagesHorizontalEditForm.patchValue({images:newUrl})
      }else{
        
        if(this.allImagesAfterDelete.length==0){
          newUrl.forEach(u =>{
            this.allImages.push(u)
          })
          this.imagesHorizontalEditForm.patchValue({
            images: this.allImages
          })

        }else{
          newUrl.forEach(u =>{
            this.allImagesAfterDelete.push(u)
          })
          this.imagesHorizontalEditForm.patchValue({
            images: this.allImagesAfterDelete
          })
        }
        
      }
      this.commentService.progressSpin.emit(false)
      this.dialogRef.close([this.imagesHorizontalEditForm.value, true, this.newImage]);

    }, 2500);
  }

  onCancelEdit(){
    this.dialogRef.close([this.oldData, false])

  }

  deleteImg(x:any, index:any){
    if(this.allImages.length ==0){
      this.newImage.splice(index,1)

      var a:any=[]
      this.imagesHorizontalEditForm.value.images.forEach((element:any, index:any) => {
        if(element.url!=x){
          a.push(element)
        }
      });
      this.imagesHorizontalEditForm.patchValue({
        images: a
      })
      
    }else{
      var a:any=[]
      this.imagesHorizontalEditForm.value.images.forEach((element:any, index:any) => {
        if(element.url!=x){
          a.push(element)
        }
      });
      
      this.imagesHorizontalEditForm.patchValue({
        images: a
      })

      this.allImages.forEach( (img, ind) =>{
        if(img['url']!=x){
          this.allImagesAfterDelete.push(img)
        }
      })

      this.imgFromImgNew.forEach( (img, inde)=>{
        if(img['form']==index){
          this.newImage.splice(img['new'], 1)
        }
      })

      this.carouselImg= this.imagesHorizontalEditForm.value.images.length

    }

  }
}
