import { Component, Inject } from '@angular/core';
import { widgetTypes } from '../widgetTypes';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../../../../backend/src/template.dto';
import { deepCopy } from '../../../../backend/utils/object';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language-visualisation-dialog',
  templateUrl: './language-visualisation-dialog.component.html',
  styleUrls: ['./language-visualisation-dialog.component.css']
})
export class LanguageVisualisationDialogComponent {
  templateData:any
  templateDataDefaultLanguage:any
  editTemp=false
  widgetsNames = widgetTypes
  translations:any
  contextLanguages:any
  contextFirstLanguage:any
  languageSelected=false
  langaugeSwitcher=false
  passTemplateLanguages:any = []
  passTemplateLanguagesO:any = {}
  languagesList = languagesList
  languageView:any
  passId:any
  templateName:any

  constructor(
    public dialog: MatDialog,
    private fb : FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    public dialogRef: MatDialogRef<LanguageVisualisationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
  ){
      dialogRef.disableClose = true;
  }

  ngOnInit(){

    this.templateData = new Template();
    this.templateData.setFromAny(this.data.values);


    if (this.templateData.name) {
      this.templateName = this.templateData.name;
    }


    this.templateDataDefaultLanguage = deepCopy(this.templateData);


    this.passId = this.data['idToShow'];
    this.passTemplateLanguagesO['default'] = this.templateDataDefaultLanguage;
    this.contextFirstLanguage = this.data['firstLanguageContexts'];
    this.contextLanguages = this.data['contextLanguages'];
    this.languageView = this.data['languageSelected'];


    if (this.languageView) {
      this.languageSelected = this.languageView;
      this.changePassLanguage(this.languageSelected);
    } else if (this.contextFirstLanguage) {
      this.languageSelected = this.contextFirstLanguage;
      this.changePassLanguage(this.languageSelected);
    } else if (this.contextLanguages && this.contextLanguages.length > 0) {

      this.languageSelected = this.contextLanguages[0];
      this.changePassLanguage(this.languageSelected);
    }


    if (this.templateData.translations) {
      this.translations = this.templateData.translations;
    } else {
      this.translations = undefined;
    }
  }

  closeDialog(){
    this.dialogRef.close()
  }

  showTranslatedPass(lang){
    const copy = deepCopy(this.templateData)
    const template = new Template()
    template.setFromAny(copy)

    let teform = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations} = template
    teform.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : undefined,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined
    })
    
    this.languageSelected = lang
    if(this.editTemp==false){
      if(this.templateData.translations && this.templateData.translations[lang] ){
        const entries = Object.entries(this.templateData.translations[lang])
        
        entries.forEach( translation => {
          const idField = translation[0].split(".")
          const id = idField[0]
          const field = idField[1]
          const value = translation[1]

          //TODO: missing support for buttons and annotations
          if(teform.value.widgets){
            const widgets = deepCopy(teform.value.widgets)
            widgets.forEach( (widget, index) => {
              if(value){
                if(id == widget['id']){
                  widget[field] = value
                }

                if(id.includes(widget['id']+'_')){
                  if(widget['type']=="annotatedMap"){
                    if(widget['annotations']){
                      const annotations = widget['annotations']
                      annotations.forEach((annotation, index) => {
                        if(id == widget['id']+'_'+annotation.id){
                          annotation[field] = value
                        }
                        if(annotation['linkURL']){
                          // annotation['linkURL'] = this.supportLinkUrl(annotation['linkURL'])
                          widget['annotations'][index]['linkURL'] = this.supportLinkUrl(annotation['linkURL'])
                        }
                      });
                    }
                  }

                  if(widget['type'] == 'buttons'){
                    const buttons = widget['buttons']
                    if(buttons){
                      buttons.forEach((btn, indexBTN) => {
                        if(id == widget['id']+'_'+btn.id){
                          if(btn[field])
                            btn[field] = value
                        }
                        
                          if(btn['url']){
                            widget['buttons'][indexBTN]['url'] = this.supportLinkUrl(btn['url'])
                          }
                      });
                    }
                  }
                }
              }

              switch(widget['type']){
                case 'billboardImage':{
                  if(widget['url']){
                    widget['url'] = this.supportLinkUrl(widget['url'])
                  }
                  if(widget['linkURL']){
                    widget['linkURL'] = this.supportLinkUrl(widget['linkURL'])
                  }
                  break;
                }
                case 'billboardVideo':{
                  if(widget['url']){
                    widget['url'] = this.supportLinkUrl(widget['url'])
                  }
                  break;
                }
                case 'text':{
                  if(widget['image']){
                    if(widget['image']['url'])
                      widget['image']['url'] = this.supportLinkUrl(widget['image']['url'])
                    
                    if(widget['image']['linkURL'])
                      widget['image']['linkURL'] = this.supportLinkUrl(widget['image']['linkURL'])
                  }
                  break;
                }
                case 'image':{
                  if(widget['url']){
                    widget['url'] = this.supportLinkUrl(widget['url'])
                  }

                  if(widget['linkURL']){
                    widget['linkURL'] = this.supportLinkUrl(widget['linkURL'])
                  }
                  break;
                }
                case 'images':{
                  const images = widget['images']
                  if(images){
                    images.forEach((image, indexImgs) => {
                      if(image['url'])
                        widget['images'][indexImgs]['url'] = this.supportLinkUrl(image['url'])
                      // image['url'] = this.supportLinkUrl(image['url'])

                      if(image['linkURL'])
                        widget['images'][indexImgs]['linkURL'] = deepCopy(this.supportLinkUrl(image['linkURL']))
                    });
                  }

                  break;
                }
                case 'annotatedMap':{
                  if(widget['annotations']){
                    const annotations = widget['annotations']
                    annotations.forEach((annotation, index) => {
                      if(annotation['linkURL']){
                        widget['annotations'][index]['linkURL'] = this.supportLinkUrl(annotation['linkURL'])
                      }                      
                    });
                  }
                  break;
                }
                case 'callToAction':{
                  if(widget['imageUrl']){
                    widget['imageUrl'] = this.supportLinkUrl(widget['imageUrl'])
                  }
                  break;
                }
                case 'video':{
                  if(widget['url']){
                    widget['url'] = this.supportLinkUrl(widget['url'])
                  }
                  break;
                }
                case 'splashVideo':{
                  if(widget['url']){
                    widget['url'] = this.supportLinkUrl(widget['url'])
                  }
                  break;
                }

                case 'form':{
                  break;
                }

                case 'buttons':{
                  const buttons = widget['buttons']
                  if(buttons){
                    buttons.forEach((btn, indexBTN) => {
                    if(btn['url'] ){
                      widget['buttons'][indexBTN]['url'] = this.supportLinkUrl(btn['url'])
                      // widget['url'] = this.supportLinkUrl(btn['url'])
                    }
                    });
                  }
                  break;
                }

                case 'spacer':{
                  break;
                }

                case 'list':{
                  break;
                }
              }
            })
            teform.patchValue({widgets: widgets})
          }
        })
        
        if(!this.passTemplateLanguagesO[lang])
          this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }else{

        if(teform.value.widgets){
          const widgets = deepCopy(teform.value.widgets)
          widgets.forEach( (widget, index) => {
            switch(widget['type']){
              case 'billboardImage':{
                if(widget['url']){
                  widget['url'] = this.supportLinkUrl(widget['url'])
                }
                if(widget['linkURL']){
                  widget['linkURL'] = this.supportLinkUrl(widget['linkURL'])
                }
                break;
              }
              case 'billboardVideo':{
                if(widget['url']){
                  widget['url'] = this.supportLinkUrl(widget['url'])
                }
                break;
              }
              case 'text':{
                if(widget['image']){
                  if(widget['image']['url'])
                    widget['image']['url'] = this.supportLinkUrl(widget['image']['url'])

                  if(widget['image']['linkURL'])
                    widget['image']['linkURL'] = this.supportLinkUrl(widget['image']['linkURL'])
                }
                break;
              }
              case 'image':{
                if(widget['url']){
                  widget['url'] = this.supportLinkUrl(widget['url'])
                }

                if(widget['linkURL']){
                  widget['linkURL'] = this.supportLinkUrl(widget['linkURL'])
                }
                break;
              }
              case 'images':{
                const images = widget['images']
                if(images){
                  images.forEach((image, indexImgs) => {
                    if(image['url'])
                      widget['images'][indexImgs]['url'] = this.supportLinkUrl(image['url'])
                    // image['url'] = this.supportLinkUrl(image['url'])

                    if(image['linkURL'])
                      widget['images'][indexImgs]['linkURL'] = deepCopy(this.supportLinkUrl(image['linkURL']))
                  });
                }

                break;
              }
              case 'annotatedMap':{
                if(widget['annotations']){
                  const annotations = widget['annotations']
                  annotations.forEach((annotation, index) => {
                    if(annotation['linkURL']){
                      widget['annotations'][index]['linkURL'] = this.supportLinkUrl(annotation['linkURL'])
                    }                      
                  });
                }
                break;
              }
              case 'callToAction':{
                if(widget['imageUrl']){
                  widget['imageUrl'] = this.supportLinkUrl(widget['imageUrl'])
                }
                break;
              }
              case 'video':{
                if(widget['url']){
                  widget['url'] = this.supportLinkUrl(widget['url'])
                }
                break;
              }
              case 'splashVideo':{
                if(widget['url']){
                  widget['url'] = this.supportLinkUrl(widget['url'])
                }
                break;
              }

              case 'form':{
                break;
              }

              case 'buttons':{
                const buttons = widget['buttons']
                if(buttons){
                  buttons.forEach((btn, indexBTN) => {
                  if(btn['url'] ){
                    widget['buttons'][indexBTN]['url'] = this.supportLinkUrl(btn['url'])
                    // widget['url'] = this.supportLinkUrl(btn['url'])
                  }
                  });
                }
                break;
              }

              case 'spacer':{
                break;
              }

              case 'list':{
                break;
              }
            }
          })
          teform.patchValue({widgets: widgets})
        }
        this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }
    }

    if(lang == this.contextFirstLanguage){
      // console.log(this.passTemplateLanguagesO[lang])
      // console.log(this.templateData)
    }
  }

  changePassLanguage(lang){
    if(this.editTemp){
      this.languageSelected=lang
    }else{
      this.templateData = new Template()
      this.templateData.setFromAny(this.templateDataDefaultLanguage)
      this.showTranslatedPass(lang)
      if(!this.passTemplateLanguagesO[lang]){
        this.templateData = new Template()
        this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
      }else{
        this.languageSelected = lang
        this.templateData = new Template()
        this.templateData.setFromAny(this.passTemplateLanguagesO[lang])
      }
    }
  }

  supportLinkUrl(linkurl){
    let transform_linkURL
    if(linkurl){
      try{
        transform_linkURL = linkurl
        const imgUrl = new URL(linkurl)
        let hostname = environment.urlHostName
        
        if(imgUrl.protocol=="pass:" ){
          const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            transform_linkURL=firebaseUrl
          }else{
            const newparts = linkurl.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              transform_linkURL=firebaseUrl
            }
          }
        } 

        if(imgUrl.protocol =="tridy:"){
          const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`

          transform_linkURL=firebaseUrl
        }
      }catch(erro){
      }
    }
    return transform_linkURL
  }
}
