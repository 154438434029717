import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent {

  message:any
  activeCancel = false

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private translate : TranslateService
    ){
      dialogRef.disableClose = true;
    }

  ngOnInit():void{
    this.message = this.translate.instant(this.data['message'])

    if(this.data['cancelBtn']){
      this.activeCancel = true
    }
  }

  onSave(){
    this.dialogRef.close([true])
  }

  onCancel(){
    this.dialogRef.close([false])
  }
}
