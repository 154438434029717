<!-- <section style="max-height: 80vh; position:static;"> -->


<div mat-dialog-title>
    <span>{{'translationPreview' | translate}}</span>

    <button mat-icon-button style="color:var(--warmOrange); float:right; " class="m-2 me-0" (click)="closeDialog();">
        <span class="material-symbols-outlined">
            close
        </span>
    </button>
</div>
<!-- <div>
    <div style="width: 15vh; margin:auto;">
        <div class="position-absolute d-flex flex-wrap align-items-center m-auto" style="z-index:1;">
            <mat-chip-listbox *ngFor="let lang of contextLanguages; let index=index" aria-label="Langauge selection">
                <mat-chip-option class="m-1" *ngIf="lang == languageSelected "  selected disabled style="background-color:var(--warmOrange);">{{languagesList[lang]}}</mat-chip-option>
                <mat-chip-option class="m-1" *ngIf="lang != languageSelected" (click)="changePassLanguage(lang);" > {{languagesList[lang]}} </mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>

    <button mat-icon-button style="color:var(--warmOrange);" (click)="closeDialog();">
        <span class="material-symbols-outlined">
            close
        </span>
    </button>
</div> -->

<!-- <main class="d-flex flex-row flex-wrap justify-content-around" >
        <section  class="mobile">        
                <div *ngFor="let elem of templateData.widgets">
                
                <div *ngIf="elem.type=='annotatedMap'" >
                    <div [app-annotated-map-phone]="[elem,editTemp,'',translations]"></div>
                </div>
    
                <div *ngIf="elem.type=='billboardImage'" >
                    <div [app-billboard-image-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='billboardVideo'" >
                    <div [app-billboard-video-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='callToAction'" >
                    <div [app-call-to-action-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='image'" >
                    <div [app-image-phone]="[elem,editTemp,'', translations]"></div>
                </div>
    
                <div *ngIf="elem.type=='instagram'" >
                    <div [app-instagram-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='imageBlocks'" >
                    <div [app-images-phone]="[elem,editTemp]" ></div>
                </div>
    
                <div *ngIf="elem.type=='imagesHorizontal'" >
                    <div [app-images-phone]="[elem,editTemp]" ></div>
    
                </div>
    
                <div *ngIf="elem.type=='products'" >
                    <div [app-products-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='shopify'">
                    <div [app-shopify-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='splashVideo'" >
                    <div [app-splash-video-phone]="[elem,editTemp]" ></div>
                </div>
    
                <div *ngIf="elem.type=='text'" >
                    <div [app-text-phone]="[elem,editTemp,'',translations]"></div>
                </div>
    
                <div *ngIf="elem.type=='video'" >
                    <div [app-video-phone]="[elem,editTemp]"></div>
                </div>
    
                <div *ngIf="elem.type=='3dmodel'" >
                    <div [app-model3d-phone]="[elem,editTemp]"></div>
                </div>
    
            </div>
            <p *ngIf="templateData.widgets.length==0"> Empty</p>
        </section> 
    </main> -->
<mat-dialog-content>

<section>

    <div class="container p-4 m-4 mt-0">

        <div class="text-center d-flex align-items-center">
            <div class="ms-5 me-3">{{'pass.selectLanguage' | translate}}</div>

            <button 
              mat-button 
              [matMenuTriggerFor]="languageMenu" 
              class="language-button"
            >
                <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
              <span class="text-uppercase ml-2">{{languageSelected}}</span> 
              
            </button>
          
            <mat-menu #languageMenu="matMenu">
              <button 
                mat-menu-item 
                *ngFor="let lang of contextLanguages" 
                (click)="changePassLanguage(lang)" 
                [disabled]="lang === languageSelected"
              >
                <span [ngStyle]="{'color': lang === languageSelected ? 'var(--warmOrange)' : 'inherit'}">
                  {{languagesList[lang]}}
                </span>
              </button>
            </mat-menu>
        </div>

        <div class=" mt-3">
            <div class="">
                <div class="mobile-wrapper " [class.full-view]="false">
                    <section class="mobile pt-1">
                        <h2 class="text-center"></h2><!--{{'content.preview' | translate}}-->
                        <div class="mobile-frame-container" ></div>
                        <div class="mobile-frame">
                            <div  >
                                <div    *ngFor="let elem of templateData.widgets; let isFirst = first; let index = index;" 
                                        [ngClass]="{'first-widget': isFirst}"
                                        style="padding-top: var(--widgetSpacing);" 
                                        [attr.id]="'widget-' + elem.id"
                                >
                            
                                    <div *ngIf="elem.type=='annotatedMap'">
                                        <div [app-annotated-map-phone]="[elem,editTemp,index,translations,contextLanguages]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='buttons'">
                                        <div [app-buttons-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='billboardImage'">
                                        <div [app-billboard-image-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                        <div *ngIf="elem.type=='billboardVideo'">
                                        <div [app-billboard-video-phone]="[elem,editTemp, index]" ></div>
                                    </div>
                            
                                    <div *ngIf="elem.type=='callToAction'">
                                        <div [app-call-to-action-phone]="[elem,editTemp,index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='form'">
                                        <div [app-form-phone]="[elem,editTemp, index,translations,contextLanguages]" ></div>
                                    </div> 
    
                                    <div *ngIf="elem.type=='image'">
                                        <div [app-image-phone]="[elem,editTemp, index,translations,contextLanguages]" ></div>
                                    </div>    
    
                                    <div *ngIf="elem.type=='images'" >
                                        <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='imageBlocks'">
                                        <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                    </div> 
                                    <div *ngIf="elem.type=='imagesHorizontal'">
                                        <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='instagram'">
                                        <div [app-instagram-phone]="[elem,editTemp]"></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='list'">
                                        <div [app-list-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='products'">
                                        <div [app-products-phone]="[elem,editTemp,index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='shopify'">
                                        <div [app-shopify-phone]="[elem,editTemp]"></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='spacer'">
                                        <div [app-spacer-phone]="[elem,editTemp, index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='splashVideo'">
                                        <div [app-splash-video-phone]="[elem,editTemp,index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='text'">
                                        <div [app-text-phone]="[elem,editTemp,index,translations,contextLanguages]" ></div>
                                    </div> 
                                    <div *ngIf="elem.type=='video'">
                                        <div [app-video-phone]="[elem,editTemp,index]" ></div>
                                    </div>
    
                                    <div *ngIf="elem.type=='3dmodel'">
                                        <div [app-model3d-phone]="[elem,editTemp, index]" ></div>
                                    </div>

                                    <div *ngIf="elem.type=='productRegistration'">
                                        <div [app-product-registration-phone]="[elem,editTemp,index]"></div>
                                    </div>
                                </div>
                                <p *ngIf="templateData.widgets.length==0" class="p-2">{{'empty' | translate}}</p>
                            </div>
                    
                        </div>
                    </section> 
                </div>
            </div>
           
        </div>
    </div>
    </section>
</mat-dialog-content>