<section style="min-width: 40vw;  padding:10px; overflow: hidden;" >
    <div style="display: flex; align-items: center;justify-content: space-between;">
        
        <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit()">
            {{'cancel' | translate}}
        </button>

        <button mat-button class="orange-button" (click)="onSubmitEdit()" >{{'apply' | translate}}</button>
    </div>

    <form [formGroup]="imagesHorizontalEditForm">
        <h1>{{'pas.widgets.img_horizontal' | translate}}</h1> 
       
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'pass.title' | translate}}</mat-label>
            <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'pass.subtitle' | translate}}</mat-label>
            <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subitle' | translate}}">
        </mat-form-field>

        <div class="insertImgContainer">
            <!-- <div style="width:100%; margin-top:15px; margin-bottom:20px; border-radius:5px;background-color: blueviolet; display: flex; flex-direction: row; flex-wrap: wrap; overflow: hidden; padding:10px;"> -->
            <label class="labelFile"> 
                + 
                <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);"> 
            </label>
            

            <div *ngIf="!changes" class="d-flex flex-row flex-wrap" style="max-width: 500px;">
                <div *ngFor="let im of allImages; let index=index;" style="width:70px; margin-left:20px; margin-bottom:10px;" >
                    <button *ngIf="carouselImg==1" mat-icon-button style="/*position: relative;*/  background-color: var(--warmOrange); color:var(--pureWhite); left:30px; top:20px;" (click)="deleteImg(im.url,index)" disabled>
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>

                    <button *ngIf="carouselImg>1" mat-icon-button style="/*position: relative;*/  background-color: var(--warmOrange);color:var(--pureWhite); left:30px; top:20px;" (click)="deleteImg(im.url, index)" >
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>
                    <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">

                    <img  src="{{im.url}}" width="70" height="70" style="max-width: 70px; object-fit: cover; border-radius:10px;"  onerror="this.src='./../../assets/img/default.jpg'">

                    <!-- <button>
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </button> -->

                    

                </div>
            </div>

            <div *ngIf="changes" class="d-flex flex-row flex-wrap" style="max-width: 500px;">

                <div *ngFor="let im of imagesHorizontalEditForm.value.images; let index=index" style="width:70px; margin-left:20px; margin-bottom:10px;" >

                    <button *ngIf="carouselImg==1" mat-icon-button style="position: relative;  background-color: var(--warmOrange);color:var(--pureWhite); left:30px; top:20px;" (click)="deleteImg(im.url, index)" disabled>
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>

                    <button *ngIf="carouselImg>1" mat-icon-button style="position: relative;  background-color: var(--warmOrange);color:var(--pureWhite); left:30px; top:20px;" (click)="deleteImg(im.url, index)" >
                        <span class="material-symbols-outlined">
                        delete
                        </span>
                    </button>
                    <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">

                    <img  src="{{im.url}}" height="70" width="70" style="max-width: 70px; object-fit: cover; border-radius:10px;"  onerror="this.src='./../../assets/img/default.jpg'">

                    <!-- <button>
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </button> -->


                </div>
            </div>
        </div>

    </form>
</section>