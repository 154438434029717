export interface IFields{
    id:string
    key:string
    mandatory: boolean
    title:string
    type:string
    payloadKey?:string
    contentType?:string
    value?:string
    defaultValue?:string
    extendable?:boolean
    searchable?:boolean
}

export interface Ititles{
    de: string
}

export interface IformSections{
    fields?: IFields[]
    title?:string
    titles?:Ititles
    id?:string
}

export interface IOutgoing{
    actions?:string[]
    contentTypeId?:string
    displayTitle?:string
    multiplicity?:string
    source?:string
    amount?:string
    amountInteger?:boolean
    amountUnit?:string
}

export interface IIncoming{
    actions?:string[]
    contentTypeId?:string
    displayTitle?:string
    multiplicity?:string
    source?:string
    sources?:string
    amount?:string
    amountInteger?:boolean
    amountUnit?:string
}

export interface ITaskTemplate{
    actions: string[]
    contentType?:string
    formSections: Object[]
    id?: string
    sortIndex:number
    title: string
    tridySource?: string
    visibleInPass?:boolean
    predicates?:string[]
    groupTitle?:string
    groupId?:string
    titleKeys?:string[]
    incomingTridys?:IIncoming[]
    outgoingTridys?:IOutgoing[]
    taskType?:string
    showImages?:boolean
    showComment?:boolean
    hidden?:boolean
    gpsMandatory?:boolean
}

export class TaskTemplate{
    actions: string[]|undefined = undefined
    contentType:string|undefined = undefined
    formSections: Object[]|undefined = undefined
    id: string|undefined = undefined
    sortIndex:number|undefined = undefined
    title: string|undefined = undefined
    tridySource: string|undefined = undefined
    visibleInPass:boolean|undefined = undefined
    predicates:string[]|undefined = undefined
    groupTitle:string|undefined = undefined
    groupId:string|undefined = undefined
    titleKeys:string[]|undefined = undefined
    incomingTridys?:IIncoming[]|undefined = undefined
    outgoingTridys?:IOutgoing[]|undefined = undefined
    taskType:string|undefined = undefined
    showImages?:boolean|undefined = true
    showComment?:boolean|undefined = true 
    hidden?:boolean|undefined = undefined 
    gpsMandatory?:boolean|undefined = undefined 
    amount?:number|undefined = undefined
    amountInteger?:boolean|undefined = false
    amountUnit?: string|undefined = undefined
    
    constructor(){}

    setFromAny(element:any){
        const { actions,groupTitle, groupId,contentType,formSections,id,sortIndex, titleKeys,title,
            tridySource,visibleInPass,predicates, incomingTridys, outgoingTridys, taskType,showImages, 
            showComment, hidden, gpsMandatory, amount, amountInteger, amountUnit} = element
        this.actions = actions ? actions : this.actions
        this.groupTitle = groupTitle ? groupTitle : this.groupTitle
        this.groupId = groupId ? groupId : this.groupId
        this.contentType = contentType ? contentType : this.contentType
        this.formSections = formSections ? formSections : this.formSections
        this.id = id ? id : this.id 
        this.sortIndex = sortIndex ? sortIndex : this.sortIndex
        this.title = title ? title : this.title  
        this.tridySource = tridySource ? tridySource : this.tridySource
        this.visibleInPass = visibleInPass ? visibleInPass : this.visibleInPass
        this.predicates = predicates ? predicates : this.predicates
        this.titleKeys = titleKeys ? titleKeys : this.titleKeys
        this.incomingTridys = incomingTridys ? incomingTridys : this.incomingTridys
        this.outgoingTridys = outgoingTridys ? outgoingTridys : this.outgoingTridys
        this.taskType = taskType ? taskType : this.taskType
        this.showImages = showImages //? showImages : this.showImages,
        this.showComment =  showComment //? showComment : this.showComment
        this.hidden = hidden ? hidden : this.hidden
        this.gpsMandatory = gpsMandatory ? gpsMandatory : this.gpsMandatory
        this.amount = amount ? amount : this.amount
        this.amountInteger = amountInteger ? amountInteger : this.amountInteger
        this.amountUnit = amountUnit ? amountUnit : this.amountUnit
    }
}