import { Component, Injectable, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../service/local-storage-service.service';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-finished-tasks',
  templateUrl: './finished-tasks.component.html',
  styleUrls: ['./finished-tasks.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],

})

export class FinishedTasksComponent implements OnInit{
finishedTasks: any[] = [];
workTasks:any
searchText=""
paginatorSize = this.settingService.paginator$
displayedColumns: string[] = ['title', 'creationDate', 'timeStamp'];
listdata= new MatTableDataSource()
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

constructor(private settingService: SettingsService,
  private commentsService: CommentsService,
  private translate: TranslateService,
  private localStorageService:LocalStorageService,
  private changeDetectorRef: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    this.getFinishedTasks()
  }

  loadTablePreferences(): void {
    const preferences = this.localStorageService.get('finishedTasksTablePreferences');
    if (preferences) {
      this.paginator.pageSize = preferences.pageSize;
      this.sort.active = preferences.sortActive;
      this.sort.direction = preferences.sortDirection;
      this.paginator.pageIndex = preferences.pageIndex;
    } else {
      this.sort.active = 'timeStamp';
      this.sort.direction = 'desc';
    }
    this.changeDetectorRef.detectChanges();
  }

  storeTablePreferences(): void {
    const preferences = {
      pageSize: this.paginator.pageSize,
      sortActive: this.sort.active,
      sortDirection: this.sort.direction,
      pageIndex: this.paginator.pageIndex
    };
    this.localStorageService.set('finishedTasksTablePreferences', preferences);
  }

  ngAfterViewInit(): void {
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;

    this.paginator.page.subscribe(() => this.storeTablePreferences());
    this.sort.sortChange.subscribe(() => this.storeTablePreferences());

    this.loadTablePreferences();

    setTimeout(() => {
      if (this.finishedTasks.length != 0) {
        this.listdata.paginator = this.paginator;
        const preferences = this.localStorageService.get('finishedTasksTablePreferences');
        if (preferences) {
          this.paginator.pageIndex = preferences.pageIndex;
          this.paginator._changePageSize(this.paginator.pageSize);
        } else {
          this.listdata.paginator.firstPage();
        }
        this.commentsService.progressSpin.emit(false);
      } else {
        this.commentsService.progressSpin.emit(false);
      }
      this.listdata.filter = " ";
      this.commentsService.progressSpin.emit(false)
    }, 500);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listdata.filter = filterValue.trim().toLowerCase();

    if (this.listdata.paginator) {
      this.listdata.paginator.firstPage();

    }
  }

    getFinishedTasks() {

      /*     
        try{
          this.commentsService.progressSpin.emit(true)
          console.log("not yet loaded")
          this.finishedTasks = this.settingService.observeContextsWorkTasksFirestoreWithId()
          console.log("loaded")
          this.listdata = new MatTableDataSource<any>(this.finishedTasks)
          console.log(this.listdata)
          // this.commentsService.progressSpin.emit(false)

        }catch(error){
          console.log(error)
          this.commentsService.progressSpin.emit(false)
          const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
          this.commentsService.addSnackBar.emit(message)
      } */

      this.commentsService.progressSpin.emit(true);
    
      this.settingService.observeContextsWorkTasksFirestoreWithId()
        .subscribe({
          next: (tasks) => {
            this.finishedTasks = tasks || [];
            this.listdata.data = this.finishedTasks;
            this.commentsService.progressSpin.emit(false);
          },
          error: (error) => {
            console.error("Error loading finished tasks:", error);
            this.commentsService.progressSpin.emit(false);
            const message = this.translate.instant("SnackBarConstants.LOAD_FAILED");
            this.commentsService.addSnackBar.emit(message);
          }
        });
    }

}
