import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { SettingsService } from 'src/app/service/settings.service';
import { TranslationsDPPService } from 'src/app/service/translations-dpp.service';
import { deepCopy, openMapDirection, supportUrlStructure } from '../../../../../backend/utils/object';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: '[app-annotated-map-view]',
  templateUrl: './annotated-map-view.component.html',
  styleUrls: ['./annotated-map-view.component.css']
})
export class AnnotatedMapViewComponent {
  @Input('app-annotated-map-view') data:any;
  mapData:any
  mapIdName:string
  addMarkersDisabled = false
  prev_infowindow:any
  map:google.maps.Map
  mapId:any
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  pairsPath:any ={}
  polylineOptions = {
    path: [],
    strokeColor: '#adff2f',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  };
  a: google.maps.Polyline
  passId:any
  allAnnotationsMap:any
  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#ff0000",
    fillOpacity: 0.3,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }
  mapHeight:string = '300px'
  constructor(
    private commentService: CommentsService,
    private translate: TranslateService, 
    private settingsService: SettingsService,
    private dpptranslations: TranslationsDPPService,
    private cdr: ChangeDetectorRef){
  }

  ngOnInit() :void {
    this.mapData = this.data[0]
    this.addMarkersDisabled = this.data[1]
    this.mapIdName = this.data[2]
    this.passId = this.data[3]
    this.allAnnotationsMap = this.data[4]
    if(this.data[5])
      this.mapHeight = this.data[5]
    setTimeout(() => {
      this.loadMap(this.mapIdName)
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.mapData = this.data[0]
      this.addMarkersDisabled = this.data[1]
      this.mapIdName = this.data[2]
      this.passId = this.data[3]
      this.allAnnotationsMap = this.data[4]
        this.initMap(this.mapIdName)
    }
  }

  loadMap(i){
    try{
      var mapProp= {
        center:new google.maps.LatLng(51.508742,-0.120850),
        zoom:5,
      };
      const id = this.mapIdName//"map-edit-dialog-"+i
      this.map = new google.maps.Map(document.getElementById(id));
      this.mapId = id
      this.initMap(i)
    }catch(e){
      console.log(e)
    }
  }

  async initMap(i?:any) {
    const id = this.mapIdName//'map-edit-dialog-'+i
    this.mapId = id

    // // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let mapWidgetId = this.mapData.id

    let mapTypeId="roadmap" 
    // if(this.mapData && this.mapData.style && this.mapData.style=='satellite'){
    //     mapTypeId = this.mapData.style   
    // }
    if(document.getElementById(id)){
      this.map = new Map(document.getElementById(id), {
        zoom: 4,
        center: position,
        mapId: "dev",
        mapTypeId: mapTypeId,
        disableDefaultUI: true
    });

    this.map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      // infoWindow.close();
      // if(this.addMarkersDisabled)
      // this.addMarker(mapsMouseEvent)
    });

    const directionTranslation = this.translate.instant('pass.map.open_direction')
    const annotations = deepCopy(this.allAnnotationsMap)
    if(annotations){//(this.mapData && this.mapData.annotations){
      for(let t=0; t<annotations.length; t++){
        const annotation = annotations[t]
        const annotationId = annotation.id
        const index = t

        if(annotation.type){
          if(annotation.type=="point"){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
          
            if(pos.name)
              pos['title']=pos.name
            
            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" target="_blank" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;" ><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
            contentString +=' </div> '
          
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg

            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
            imgPin.addListener("click", () => {
              if(this.prev_infowindow)
                this.prev_infowindow.close()

              this.prev_infowindow = infowindow

              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
            // const marker = new AdvancedMarkerElement({
            //   map: this.map,
            //   position: pos,
            //   title:"annotation-"+index,
            //   content:pin.element
            // });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }

          if(annotation.type=="polygon"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
              this.actualPolygonOptions[mapWidgetId][index].paths = pairs

              if(annotation.fillColor)
                this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                this.zoom = 9
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }
              const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
              polygon.setMap(this.map);
            }
          }

          if(annotation.type=="line"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
              this.actualPolygonOptions[mapWidgetId][index].path = pairs

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth
                
              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                this.zoom = 11
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }

              const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
              line.setMap(this.map);
            }
          }

        }else{
            if(annotation.lat || annotation.lng || annotation.coordinate){
              let pos = annotation
          if(annotation.coordinate){
            pos['lat']=annotation.coordinate[0]
            pos['lng']=annotation.coordinate[1]
          }

          if(pos['lat'] || pos['lng']){
            if(!annotation.badge)
            pos['badge']=(index +1).toString()
          
          if(pos.name)
            pos['title']=pos.name
          
          let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
          let contentString = "this is an example"
          contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                  contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'
            
              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
            contentString +=' </div> '    
            
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg
            let pinData = {glyph: pos.badge}
          
            pinData['background']=pinColor
            pinData['borderColor'] = annotation.pinForegroundColor
            pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
            }
          }
        } 
      } 
    }
    }
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
      this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  async loadNewMarkersOnMap(){
    const directionTranslation = this.translate.instant('pass.map.open_direction')
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let mapWidgetId = this.mapData.id
    let mapTypeId="roadmap" 
    this.map = this.map.data.getMap()
    const div = this.map.getDiv().innerHTML=''

    this.map = new Map(this.map.getDiv(), {
      zoom: 4,
      center: position,
      mapId: "dev",
      mapTypeId: mapTypeId,
      disableDefaultUI: true
    });

    this.map.addListener("click", (mapsMouseEvent) => {
      // this.addMarker(mapsMouseEvent)
    });
  
    const annotations = deepCopy(this.allAnnotationsMap)  
    if(annotations){//(this.annotatedMapEditData && this.annotatedMapEditData.annotations){
      for(let t=0; t<annotations.length; t++){
        const annotation = annotations[t]
        const annotationId = annotation.id
        const index = t

        if(annotation.type){
          if(annotation.type=="point"){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name

            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'

              }
            contentString +=' </div> ' 
          
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg

            let pinData = {glyph: pos.badge}
          
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });

            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }

          if(annotation.type=="polygon"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
              this.actualPolygonOptions[mapWidgetId][index].paths = pairs

              if(annotation.fillColor)
                this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                this.zoom = 9
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }
              const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
              polygon.setMap(this.map);
            }
          }

          if(annotation.type=="line"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")
              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
              this.actualPolygonOptions[mapWidgetId][index].path = pairs

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth
              
              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                this.zoom = 11
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }

              const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
              line.setMap(this.map);
            }
          }

        }else{
          if(annotation.lat || annotation.lng){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }
  
            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name
  
            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
              const directions = this.openMapDirection(annotation)
              const directionTranslation = this.translate.instant('pass.map.open_direction')
              contentString += '<a href="'+ directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
  
            contentString +=' </div> ' 
            
            let iconImg = document.createElement('div');  
            let pinColor = '#FF5E38'
  
            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor
  
            let textColor = 'black'
  
            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor
  
            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`
  
            iconImg.innerHTML = svg
  
            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor
  
            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
  
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
  
            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }
        } 
      } 
    }
  }
}
