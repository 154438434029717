<section class="container">
    <div class="row mt-4">
        <div class="col-12">
            <div class="topNavbar mb-1"> 
                <button mat-button class="m-1" style="color:var(--warmOrange);" (click)="backTo();"> 
                    <mat-icon>arrow_back_ios_new</mat-icon> {{'goBack' | translate}}
                </button>
                <span class="fs-5">{{'finished.table_title' | translate}}: {{titleTask}}</span>
                <div>
<!--                     <button mat-button class="m-1 secondary-gray-button" (click)="QRoptions()">
                        <mat-icon>qr_code_2_add</mat-icon> Sample QR
                    </button> -->
<!--                     <button mat-button class="m-1 secondary-gray-button" (click)="onDelete();" id="btn-delete" [disabled]="!workTasksUserRights_delete"> 
                        <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                    </button> -->
                    <!-- <button mat-fab extended style="background-color:white; color:var(--accentGlobal);" id="button-tridy" (click) = "deleteTridy();">
                        <mat-icon>delete</mat-icon>
                        {{'tridys.delete' | translate}}
                    </button> -->
                </div>
            </div>

            <div *ngIf="tridyId">
                <div class="mt-4 mb-2 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">qr_code</mat-icon>{{'tridys.tridy' | translate}}
                </div>
                
                <mat-card class="mb-2 shadow-sm">
                    <mat-card-content class="my-2">
                        <div >
                            <div class="item-content my-1" (click)="redirectTo(finishedTask);">
                                <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                    <div class="d-flex flex-column flex-nowrap">
                                        <span style="color:var(--warmOrange);">{{'tridys.tridy' | translate}}: </span><span>{{tridyId}}</span>
                                    </div>
                                    <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                        keyboard_arrow_right
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="sections && sections.length > 0">
                <div class="mt-4 mb-2 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">checklist</mat-icon> Task Field Results:
                </div>
                <mat-accordion class="w-100 mb-2" *ngFor="let section of sections;">
                    <mat-expansion-panel expanded class="mb-1">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{section.title}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-card class="mb-1 shadow-sm" *ngFor="let field of section.fields" >
                            <mat-card-content class="py-2 px-3">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="field-title">
                                        <mat-icon *ngIf="field.type=='barcode'" class="material-symbols-outlined">qr_code_scanner</mat-icon>
                                        <mat-icon *ngIf="field.type=='signature'" class="material-symbols-outlined">signature</mat-icon>
                                        <mat-icon *ngIf="field.type=='boolean'" class="material-symbols-outlined">toggle_on</mat-icon>
                                        <mat-icon *ngIf="field.type=='currency'" class="material-symbols-outlined">euro</mat-icon>
                                        <mat-icon *ngIf="field.type=='dateTime'" class="material-symbols-outlined">calendar_month</mat-icon>
                                        <mat-icon *ngIf="field.type=='decimal'" class="material-symbols-outlined">numbers</mat-icon>
                                        <mat-icon *ngIf="field.type=='display'" class="material-symbols-outlined">visibility</mat-icon>
                                        <mat-icon *ngIf="field.type=='document'" class="material-symbols-outlined">attach_file</mat-icon>
                                        <mat-icon *ngIf="field.type=='hidden'" class="material-symbols-outlined">visibility_off</mat-icon>
                                        <mat-icon *ngIf="field.type=='longText'" class="material-symbols-outlined">subject</mat-icon>
                                        <mat-icon *ngIf="field.type=='number'" class="material-symbols-outlined">pin</mat-icon>
                                        <mat-icon *ngIf="field.type=='text'" class="material-symbols-outlined">text_format</mat-icon>
                                        <mat-icon *ngIf="field.type=='tridy'" class="material-symbols-outlined">contactless</mat-icon>
                                        <mat-icon *ngIf="field.type=='options'" class="material-symbols-outlined">list</mat-icon>
                                        <mat-icon *ngIf="field.type=='weight'" class="material-symbols-outlined">weight</mat-icon>
                                        <mat-icon *ngIf="field.type=='nfc'" class="material-symbols-outlined">nfc</mat-icon>
                                        <mat-icon *ngIf="field.type=='showImage'" class="material-symbols-outlined">edit_note</mat-icon>
                                        <mat-icon *ngIf="field.type=='showText'" class="material-symbols-outlined">image</mat-icon>
                                        <span class="font-weight-bold">{{field.title}}</span>
                                    </div>
                                    <div>
                                        <div *ngIf="field.payloadKey && !field.key" class="text-end">
                                            <span class="text-muted text-end" *ngIf="field.type!='document' && field.type!='dateTime' && field.type!='weight' && field.type!='boolean'"> {{finishedTask.payload[field.payloadKey]}}</span>
                                            <div *ngIf="field.type=='boolean'" class="ps-1 d-flex">
                                                <span class="text-muted">{{finishedTask.payload[field.payloadKey]}}</span>
                                                <span>{{finishedTask.payload[field.payloadKey]}}</span>
                                            </div>
                                            <div *ngIf=" finishedTask.payload[field.payloadKey] && field.type=='weight'"  class="ps-1 d-flex">
                                                <span class="text-muted" >{{finishedTask.payload[field.payloadKey]}}</span>
                                            </div>
                                            <div *ngIf=" finishedTask.payload[field.payloadKey] && field.type=='dateTime'" class="d-flex">
                                                <div *ngIf="field.payloadKey" class="text-muted">
                                                    {{convertDateTime(finishedTask.payload[field.payloadKey], field.style)}}
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="field.key" class="d-flex">
                                            <span class="text-muted text-break text-wrap text-end" *ngIf=" field.type!='document' && field.type!='dateTime' && field.type!='weight' && field.type!='boolean' && field.type!='signature'"> {{finishedTask.payload[field.key]}}</span>
                                            <div *ngIf=" field.type=='boolean'" class="ps-1 d-flex text-end">
                                                <span class="text-muted">{{finishedTask.payload[field.key]}}</span>
                                            </div>
                                            <div *ngIf=" field.type=='weight'"  class="ps-1 d-flex">
                                                <span class="text-muted" *ngIf="finishedTask.payload[field.key] && finishedTask.payload[field.key]['value']">{{finishedTask.payload[field.key]['value']}}</span>
                                            </div>
                                            <div *ngIf=" field.type=='dateTime'" class="d-flex">
                                                <div *ngIf="field.key" class="text-muted">
                                                    {{convertDateTime(finishedTask.payload[field.key], field.style)}}
                                                </div>
                                            </div>
                                            <div *ngIf=" field.type=='document' || field.type =='signature'"  class="ps-1 d-flex align-items-center">
                                                <!-- <a href="{{docs[field.key]}}" target="_blank"> -->
                                                    <!-- <img [src]="docs[field.key]" style="height:150px;width:auto;"> -->
                                                <!-- </a> -->
                                                <div *ngIf="docs[field.key]">
                                                    <a *ngIf="!fileIsPDF(field.key)" href="{{docs[field.key]}}" target="_blank" style="cursor:zoom-in;">
                                                        <img [src]="docs[field.key]" style="height:150px;width:auto;" ><!--onerror="this.src='./../../assets/img/default.jpg'" class="image-hover rounded" style="object-fit: cover;"-->
                                                    </a>

                                                    <a *ngIf="fileIsPDF(field.key)" href="{{docs[field.key]}}" class="pdf-title" target="_blank">
                                                        <div><mat-icon class="material-symbols-outlined">open_in_new</mat-icon>PDF</div>
                                                    </a>
                                                </div>
                                                <span *ngIf="!docs[field.key]" class="material-symbols-outlined text-muted">
                                                    attach_file
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>
        </div>


            <div *ngIf="finishedTask && finishedTask.incomingTridys && finishedTask.incomingTridys.length!=0" class="mb-2">
                <div class="mt-4 mb-2 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">input</mat-icon>{{'workTask.incoming_tridys' | translate}}
                </div>
    
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                        <div *ngIf="finishedTask.incomingTridys">
                            <div *ngFor="let incoming of finishedTask.incomingTridys; let index" class="item-content my-1" (click)="redirectTo(incoming)">
                                <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                    <div class="d-flex flex-column flex-nowrap">
                                        <span style="color:var(--warmOrange);">{{getContentTypeIdData(incoming.contentTypeId, 'displayName')}}</span>
                                        <!-- <div>{{incoming.tridyId}}</div> -->
                                        <div matTooltip="Created: {{isDateTime(getTRIDYIdData(incoming.tridyId, 'creationDate')) }}, Updated: {{isDateTime(getTRIDYIdData(incoming.tridyId, 'timeStamp')) }}">
                                            <mat-icon class="time-icon">schedule</mat-icon><span class="text-muted"> {{isDateTime(getTRIDYIdData(incoming.tridyId, 'timeStamp')) }}</span>
                                        </div>
                                    </div>
                                    <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                        keyboard_arrow_right
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-3" *ngIf="finishedTask.incomingTridys.length==0">
                            <span class="text-muted">{{'workTask.empty' | translate}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="finishedTask && finishedTask.outgoingTridys &&  finishedTask.outgoingTridys.length!=0" class="mb-2">
                <div class="mt-4 mb-2 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">output</mat-icon>{{'workTask.outgoing_tridys' | translate}}
                </div>
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                        <div *ngIf="finishedTask.outgoingTridys">
                            <div *ngFor="let outgoing of finishedTask.outgoingTridys; let index" class="item-content my-1" (click)="redirectTo(outgoing)">
                                <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                    <div class="d-flex flex-column flex-nowrap">
                                        <span style="color:var(--warmOrange);">{{getContentTypeIdData(outgoing.contentTypeId, 'displayName')}}</span>
                                        <!-- <div>{{outgoing.tridyId}}</div> -->
                                        <div matTooltip="Created: {{isDateTime(getContentTypeIdData(outgoing.contentTypeId, 'creationDate')) }}, Updated: {{isDateTime(getContentTypeIdData(outgoing.contentTypeId, 'timeStamp')) }}">
                                            <mat-icon class="time-icon">schedule</mat-icon><span class="text-muted"> {{isDateTime(getContentTypeIdData(outgoing.contentTypeId, 'timeStamp')) }}</span>
                                        </div>
                                    </div>
                                    <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                        keyboard_arrow_right
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-3" *ngIf="finishedTask.outgoingTridys.length==0">
                            <span class="text-muted">{{'workTask.empty' | translate}}</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="!sections && !locationCoordinates" class="container">
                <p>{{'finished.no_sections' | translate}}</p>
            </div>

            <div *ngIf="locationCoordinates">
                <div class="mt-4 mb-2 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">location_on</mat-icon> {{'location' | translate}}
                </div>
                <mat-card class="mb-5">
                    <mat-card-content class="m-0 p-0">
                        <div class="d-flex align-items-center px-4 py-3">
                            <span class="text-muted">{{'workTask.coordinates' | translate}}: {{locationCoordinates.latitude}}, {{locationCoordinates.longitude}} </span>
                            <mat-icon matTooltip="{{'copyToClipboard' | translate}}" cdkCopyToClipboard="{{locationCoordinates.latitude}}, {{locationCoordinates.longitude}}" class="copy_coordinates">content_copy</mat-icon>
                        </div>
                        <div class="container-map shadow-sm">
                            <div [app-finished-tasks-map]="locationCoordinates"></div>
                        </div> 
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>