export const defaultFields = {
    display:{
        "id": "3pqcfj",
        "type": "display",
        "mandatory": false,
        "key": "newField",
        "title": "New Field"
    },
    hidden:{
        "id": "jfJUy",
        "mandatory": false,
        "value": null,
        "type": "hidden",
        "title": "New Field",
        "key": "newField"
    },
    text:{
        "key": "newField",
        "type": "text",
        "id": "3IWd4N",
        "title": "New Field",
        "mandatory": false
    },
    longText:{
        "key": "newField",
        "title": "New Field",
        "mandatory": false,
        "id": "4iJBdT",
        "type": "longText"
    },
    number:{
        "key": "newField",
        "type": "number",
        "title": "New Field",
        "mandatory": false,
        "id": "3vLhEu"
    },
    decimal:{
        "id": "4sttKg",
        "mandatory": false,
        "key": "newField",
        "title": "New Field",
        "type": "decimal"
    },
    currency:{
        "type": "currency",
        "id": "2CoIcP",
        "mandatory": false,
        "key": "newField",
        "title": "New Field"
    },
    dateTime:{
        "mandatory": false,
        "key": "newField",
        "style": "dateTime",
        "title": "New Field",
        "id": "2CH5LI",
        "type": "dateTime"
    },
    
    boolean:{
        "key": "newField",
        "id": "9sLfi",
        "mandatory": false,
        "type": "boolean",
        "title": "New Field"
    },
    options:{
        "mandatory": false,
        "title": "New Field",
        "type": "options",
        "id": "44Z9I5",
        "key": "newField"
    },
    weight:{
        "id": "53y7v8",
        "title": "New Field",
        "key": "newField",
        "type": "weight",
        "mandatory": false
    },
    tridy:{
        "type": "tridy",
        "mandatory": false,
        "id": "bvn3Y",
        "title": "New Field",
        "key": "newField",
        "searchIdentifiers":false,
        "listSelection":false
    },
    barcode:{
        "id": "4YalTk",
        "barcodeType": "barcode",
        "title": "New Field",
        "type": "barcode",
        "key": "newField",
        "mandatory": false
    },
    document:{
        "id": "2l3tBJ",
        "mandatory": false,
        "title": "New Field",
        "type": "document",
        "key": "newField",
        "contentType": "document",
        sources:["documents","library","camera","scan"]
    },
    nfc:{
        "id": "123",
        "mandatory": false,
        "title": "New Field",
        "type": "nfc",
        "key": "newField",
    },
    signature:{
        "id": "23874",
        "mandatory": false,
        "title": "New Field",
        "type": "signature",
        "key": "newField",
    },
    showText:{
        "id": "123",
        "mandatory": false,
        "title": "New Field",
        "type": "showText",
        "key": "newField",
    },
    showImage:{
        "id": "123",
        "mandatory": false,
        "title": "New Field",
        "type": "showImage",
        "key": "newField",
    }
    
}

export const newSection = {
    fields:[],
    title: "New Section",
    titles: {
        de: "NewSection"
    }
}

export const defaultFieldsTypes = [
    "Display",
    "Hidden",
    "Text",
    "Long Text",
    "Number",
    "Decimal Number",
    "Currency",
    "Date/Time",
    "Switch",
    "List of options",
    "Weight",
    "Tridy",
    "Barcode / QR",
    "Document / Image",
    "Signature",
]

export const newTaskTemplate = {
    actions:[],
    formSections:[],
    sortIndex: 999,
    tridySource:"select",
    visibleInPass:true,
    showImages:false,
    showComment:false


}