import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { ContentType } from './../../../../backend/src/contentType.dto';
import { prepareDataToFirestoreUpdates, removeUndefinedValuesFromObject } from './../../../../backend/utils/object';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';
import { PassDefinitionsService } from './pass-definitions.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeService {
  contentTypes_schema:any={}
  accountLanguages:any=[]
  constructor(private settingsService: SettingsService,
    private firestore: AngularFirestore,
    private passService: PassDefinitionsService) { 

  }

  async updateContentTypeTimestamp(contentTypeId: string): Promise<void> {
    try {
      const timestamp = new Date();
      await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
        .doc(contentTypeId)
        .update({ 'timeStamp': timestamp });
    } catch (error) {
      console.error('Error updating timestamp:', error);
      throw error;
    }
  }

  updateContentType(contentType: ContentType, oldContentTypeData: ContentType): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const cleanedData = removeUndefinedValuesFromObject(contentType); 
        const newdata = prepareDataToFirestoreUpdates(cleanedData);
        
        if (!contentType.titleKey || contentType.titleKey.trim() === "") {
          newdata['titleKey'] = firebase.firestore.FieldValue.delete();
        }

        newdata['timeStamp'] = new Date();
        
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
          .doc(contentType.id)
          .update(newdata);

        if (contentType.displayName !== oldContentTypeData.displayName) {
          this.passService.updatePassDefinitionAfterUpdateContentType(contentType.passTemplateId, contentType.displayName);
        }
  
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }  

  deleteContentType(contentTypeId:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_CONTENT_TYPES)
        .doc(contentTypeId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

  async createContentType(newContent:any):Promise<any>{
    return new Promise(async (resolve, reject) => {
    try{

      const contentRefdoc = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES).doc().ref

      const newId = contentRefdoc.id

      const passid = await this.passService.createPassDefinition(newContent.displayName, newId, newContent.language)
      newContent['id']= newId
      newContent['creationDate'] = new Date()
      newContent['timeStamp'] = new Date()
      newContent['passTemplateId'] = passid
      newContent['state']="active"

      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES).doc(newId)
      .set({...newContent})

      const data = {
        contentTypeId: newId,
        passTemplateId: passid
      }
      resolve(data)
    }catch(error){
      console.log(error)
    }
  }) 

  }


  setSchemaOfContentTypeID(contentTypeId,schema){
    this.contentTypes_schema[contentTypeId]=schema
  }

  resetSchemaOfContentTypeID(contentTypeId){
    if(this.contentTypes_schema[contentTypeId])
    delete this.contentTypes_schema[contentTypeId]
  }

  getSchemaOfContentTypeID(contentTypeId){
    return this.contentTypes_schema[contentTypeId]
  }


  async importContentype(newContent:any,  newPass):Promise<any>{
    return new Promise(async (resolve, reject) => {
    try{

      const contentRefdoc = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES).doc().ref

      const newId = contentRefdoc.id

      const passid = await this.passService.importPassDefinition(newPass, newId)
      newContent['id']= newId
      newContent['creationDate'] = new Date()
      newContent['timeStamp'] = new Date()
      newContent['passTemplateId'] = passid
      newContent['state']="active"
      newContent['displayName'] += " import_"

      this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(this.settingsService.contextId$)
      .collection(NetworkConstants.COLLECTION_CONTENT_TYPES).doc(newId)
      .set({...newContent})

      const data = {
        contentTypeId: newId,
        passTemplateId: passid
      }
      resolve(data)
    }catch(error){
      console.log(error)
    }
  }) 

  }

}
