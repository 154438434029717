<section style="min-width: 40%; max-width: 700px; padding:10px; overflow: hidden;" >
    <div style="display: flex; align-items: center;justify-content: space-between;">
        
        <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="oldData">
            {{'cancel' | translate}}
        </button>

        <button mat-button class="orange-button" (click)="saveEdit();">{{'apply' | translate}}</button>
        <!-- <button mat-button class="orange-button" [mat-dialog-close]="imageBlocksEditForm.value">Save</button> -->
    </div>

    <form [formGroup]="imageBlocksEditForm">
        <h1>{{'pass.widgets.img_blocks' | translate}}</h1> 

        <div class="input-group m-1" >
          <mat-form-field  appearance="outline" class="w-100" >
            <mat-label>{{'pass.layout'}}</mat-label>
            <mat-select formControlName="layout" class="input-group m-1">
              <mat-option *ngFor="let lay of layoutTypes" [value]="lay.value">
                {{lay.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p *ngIf="layoutImages>allImages.length" style="color:var(--accentGlobal);">{{'pass.layout_error' | translate}}</p>
        </div>

        <!-- <div class="form-group m-1">
            <label for="title" >Title</label>
            <input matInput formControlName="title" class="form-control" placeholder="Title" id="title" type="text" value="{{imageBlocksEditData.title}}">
        </div>


        <div class="form-group m-1">
            <label for="subtitle">Subtitle</label>
            <input matInput formControlName="subtitle" class="form-control" placeholder="Subitle" id="subtitle" type="text" value="{{imageBlocksEditData.subtitle}}">
        </div> -->

        <mat-form-field appearance="outline" class="w-100 p-1">
            <mat-label>{{'pass.title' | translate}}</mat-label>
            <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'pass.subtitle' | translate}}</mat-label>
            <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subitle' | translate}}">
        </mat-form-field>

        <div class="insertImgContainer">
            <label class="labelFile"> 
                + 
                <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);"> 
            </label>
            
            <div *ngFor="let im of imageBlocksEditForm.value.images; let index=index" style="width:70px; margin-left:20px; margin-bottom:10px;" >
                <!-- <div *ngFor="let im of allImages; let index=index" style="width:70px; margin-left:20px; margin-bottom:10px;" > -->
                <button *ngIf="allImages.length==1" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); position: absolute; margin-left: 40px;" (click)="deleteImg(im.url, index)" disabled>
                    <span class="material-symbols-outlined">
                    delete
                    </span>
                </button>

                <button *ngIf="allImages.length>1" mat-icon-button style="background-color: var(--warmOrange); color:var(--pureWhite); position: absolute; margin-left: 40px;" (click)="deleteImg(im.url, index)" >
                    <span class="material-symbols-outlined">
                    delete
                    </span>
                </button>
                <input matInput type="text" formControlName="images" value="{{im.url}}" style="display:none">
                <img  src="{{im.url}}" width="70" height="70" style="    margin-top: 30px;width: 70px; max-height: 100px; object-fit: cover; border-radius:10px;" onerror="this.src='./../../assets/img/default.jpg'">
                <!-- <button mat-button style="background-color: var(--warmOrange);" (click)="editImg(im.url)" >
                    <span class="material-symbols-outlined">
                    edit
                    </span>
                </button> -->
            </div>
        </div>

    </form>
</section>