import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-list-phone]',
  templateUrl: './list-phone.component.html',
  styleUrls: ['./list-phone.component.css']
})
export class ListPhoneComponent {
  @Input('app-list-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  listData:any
  widgetNumber:any
  idToShow:any
  routerUrl:any
  editMode = false
  style ="definition"

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.listData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
        this.widgetNumber = this.data[2]
    }
    if(this.listData.style){
      this.style = this.listData.style
    }
  }
}
