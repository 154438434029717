<h2 mat-dialog-title>{{'content.translations' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
          <div>
            <div class="m-2 p-2" *ngIf="emptyLanguages">
              <p> {{'no_languages' | translate}}</p>
            </div>
            <mat-accordion>
          
              <!-- <mat-expansion-panel  class="mt-1 mb-1" *ngFor=" let lang of accountLanguages; let index= index;" (opened)="panelOpenState = true" -->
              <mat-expansion-panel  class="mt-1 mb-1" *ngFor=" let lang of passLanguages.slice(1); let index=index;"
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                    >
                <mat-expansion-panel-header>
                  <mat-panel-title *ngIf="lang == firstLanguageContext" style="color:var(--warmOrange);">
                      {{languagesList[lang]}}
                  </mat-panel-title>
  
                  <mat-panel-title *ngIf="lang != firstLanguageContext" >
                    {{languagesList[lang]}}
                </mat-panel-title>
                  <!-- <mat-panel-description>
                    Open to see the translations
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
  
                <form [formGroup]="languageForm">
                  <div *ngFor="let trans of translationsWidget[lang]; let index = index;">
                    <div *ngIf=" trans[3] && trans[3] != 'url' && trans[3] !='linkURL' "> 
                      <mat-form-field appearance="outline" class="w-100"  >
                        <mat-label>{{trans[2]}}</mat-label>
                        <textarea matInput formControlName="{{trans[0]}}" value="{{trans[1]}}" class="form-control" placeholder="Ex." *ngIf="trans[3] =='text'"></textarea>

                        <input matInput placeholder="Ex. " value="{{trans[1]}}" formControlName="{{trans[0]}}" id="{{trans[0]}}" *ngIf="trans[3] !='text'">
                      </mat-form-field>
                    </div>
                     

                    <div *ngIf="trans[3] && trans[3] == 'url' || trans[3] =='linkURL' ">
                      <div class="w-100 d-flex flex-row mb-4 pb-3">
                        <mat-form-field appearance="outline" class="w-100"  >
                          <mat-label>{{trans[2]}}</mat-label>
                          <input matInput placeholder="Ex. " value="{{trans[1]}}" formControlName="{{trans[0]}}" id="{{trans[0]}}">
                          <mat-error >{{'url_pattern_invalid' | translate}} or pass://... </mat-error>
                          <mat-hint > {{'url_pattern_detailed' | translate}} or pass://... </mat-hint>
                        </mat-form-field>
            
                        <button mat-icon-button [matMenuTriggerFor]="pickDocument"  style="color:var(--warmOrange);">
                          <span class="material-symbols-outlined">
                              more_vert
                          </span>
                        </button>
                        
                        <mat-menu #pickDocument="matMenu">
                          <button mat-menu-item (click)="fileInput.click()">
                              <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                              {{'pick' | translate}}
                          </button>
                          <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf" (change)="readDocument($event, trans)" #fileInput>
                      </mat-menu>


                      </div>
                    </div>
                  </div>
                </form>
                
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!emptyLanguages" align="end">
  <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
  <button mat-button class="orange-button" (click)="onSaveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="emptyLanguages" align="end">
  <button mat-button class="orange-button" (click)="onCancelEdit();">{{'Ok' | translate}}</button>
</mat-dialog-actions>