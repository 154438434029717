import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { TridysService } from '../service/tridys.service';
import { CommentsService } from '../service/comments.service';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.css']
})
export class QrCodeDialogComponent {
  myAngularxQrCode: string = null
  QRdata:string =""
  QRId:string
  nfcURLPrefix: any;

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private tridyService: TridysService,
    private commentsService: CommentsService
    ){
      this.myAngularxQrCode = environment.tagHostName
      dialogRef.disableClose = true;
    }

  ngOnInit(){
    this.QRId = this.data['id']
    this.nfcURLPrefix = this.data['nfcPrefix']
    const prefix = this.nfcURLPrefix ? this.nfcURLPrefix + '/' : '';
    this.QRdata = this.myAngularxQrCode + prefix + this.QRId;

  }

  closeQRDialog(){
    this.dialogRef.close()
  }
}
