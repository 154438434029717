import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CallToActionEditComponent } from '../editWidgets/call-to-action-edit/call-to-action-edit.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-call-to-action-phone]',
  templateUrl: './call-to-action-phone.component.html',
  styleUrls: ['./call-to-action-phone.component.css']
})
export class CallToActionPhoneComponent implements OnInit {
  @Input('app-call-to-action-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()
  callToActionData:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  url:any
  dynamic = false

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }

  ngOnInit():void{
    this.callToActionData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    const section=document.getElementById("callToAction")

    if(this.callToActionData.imageUrl){
      try{
        const partsd = this.callToActionData.imageUrl.includes("${")
        if(partsd){
          //this.callToActionData.imageUrl = checkDynamicContent(this.callToActionData.imageUrl)
          this.dynamic = true
        }
        let url = supportUrlStructure(this.callToActionData.imageUrl, this.idToShow, this.settingsService.contextId$)
        if(url)
        this.url = url
        // const imgUrl = new URL(this.callToActionData.imageUrl)
        // let hostname = environment.urlHostName
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.callToActionData.imageUrl.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.url=firebaseUrl
        //   }else{
        //     const newparts = this.callToActionData.imageUrl.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.url=firebaseUrl
        //     }
        //   }
        // }
        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.callToActionData.imageUrl.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.url=firebaseUrl
        // }

      }catch(error){
      }
    }
  }

  openDialogEdit():void{
    let dialogRef= this.dialog.open(CallToActionEditComponent, {
      data: { 
        values: this.callToActionData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        url:this.url
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.callToActionData=result[0]
        if(result[2].length!=0){
          this.imageToStorage.push(result[2])
        }
        this.returnChanges.emit({newdata: this.callToActionData, widget: this.widgetNumber, widgetType: this.callToActionData.type,images: this.imageToStorage})
      }
    });
  }
}
