import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})
export class FinishedTasksService {

 
  constructor(private settingsService: SettingsService,
    private firestore: AngularFirestore,) { }

    deleteFinishedTasks(finishedId):Promise<void>{
      return new Promise(async (resolve, reject) => {
        try {
          const d = this.firestore
          .collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_WORK_TASKS)
          .doc(finishedId)
          .delete()
          resolve(d)
        } catch (error) {
          reject(error)  
          console.log(error)      
        } 
      })
    }
}
