import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy, removeUndefined } from '../../../../../../backend/utils/object';
import { TaskTemplateEditSectionDialogComponent } from 'src/app/task-template-edit-section-dialog/task-template-edit-section-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormEditFieldDialogComponent } from '../form-edit-field-dialog/form-edit-field-dialog.component';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { defaultFields } from '../../../../../../backend/src/deafultFieldsFormWidget.dto';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css']
})
export class FormEditComponent {
  formEditForm!:FormGroup
  formData:any
  oldData:any
  widgetNumber:any
  passId:any
  sectionsEdit:any =[]
  panelOpenState = false

  allIdsSections:any =[]
  allKeysSection:any=[]
  sectionsForm!:FormGroup
  submitButtonStyleForm!:FormGroup

  addedFields:any = 0
  duplicateKeys = false
  duplicatedKeyNumber=0

  invalid_foregroundColor = false
  invalid_borderColor = false
  invalid_backgroundColor = false

  colorPalette:any=[]

  buttonStyleDefault:any = {
    "backgroundColor": "#ff5d38",
    "borderColor": "#ff5d38",
    "borderWidth": 0,
    "foregroundColor": "#fefefe",
    "cornerRadius": 0
  }
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<FormEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage
    this.initializePaletteColors()

    this.formData = this.data.values
    this.oldData = deepCopy(this.formData)
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widget']

    this.sectionsForm = this.fb.group({})

    this.formEditForm = this.fb.group({
      type:[],
      sections:[],
      id:[],
      title:[],
      subtitle:[],
      name:[],
      submitTitle:[],
      submitAgainTitle:[],
      successMessage:[],
      submitButtonStyle:[],
      filter:[]
    })

    const { type, filter,sections, id, title, subtitle,name, submitTitle, submitAgainTitle, successMessage, submitButtonStyle} = this.formData

    this.formEditForm.patchValue({
      name: name ? name : undefined,
      subtitle: subtitle ? subtitle : undefined,
      type:type ? type : "form",
      sections: sections ? sections : undefined,
      id: id ? id : undefined,
      title:title ? title : undefined,
      submitTitle: submitTitle ? submitTitle : undefined,
      submitAgainTitle: submitAgainTitle ? submitAgainTitle : undefined,
      successMessage: successMessage ? successMessage : undefined,
      submitButtonStyle: submitButtonStyle ? submitButtonStyle : undefined,
      filter: filter? filter: undefined
    })

    this.submitButtonStyleForm = this.fb.group({
      backgroundColor: [],
      foregroundColor: [],
      borderColor: [],
      borderWidth: [],
      cornerRadius: []
    })

    if( submitButtonStyle){
      const {backgroundColor, foregroundColor, borderColor, borderWidth, cornerRadius} = submitButtonStyle

      this.submitButtonStyleForm.patchValue( {
        backgroundColor: submitButtonStyle.backgroundColor ? submitButtonStyle.backgroundColor : this.buttonStyleDefault.backgroundColor,
        foregroundColor: submitButtonStyle.foregroundColor ? submitButtonStyle.foregroundColor : this.buttonStyleDefault.foregroundColor,
        borderColor: submitButtonStyle.borderColor ? submitButtonStyle.borderColor : this.buttonStyleDefault.borderColor,
        borderWidth: submitButtonStyle.borderWidth ? submitButtonStyle.borderWidth : this.buttonStyleDefault.borderWidth,
        cornerRadius: submitButtonStyle.cornerRadius ? submitButtonStyle.cornerRadius : this.buttonStyleDefault.cornerRadius
      })
    }else{
      this.submitButtonStyleForm.patchValue( {
        backgroundColor: this.buttonStyleDefault.backgroundColor,
        foregroundColor: this.buttonStyleDefault.foregroundColor,
        borderColor: this.buttonStyleDefault.borderColor,
        borderWidth: this.buttonStyleDefault.borderWidth,
        cornerRadius: this.buttonStyleDefault.cornerRadius
      })
    }

    this.sectionsEdit = deepCopy(sections)
    if(this.sectionsEdit){
      this.sectionsEdit.forEach((section, sectionIndex) => {
        this.sectionsForm.addControl(section.id+'.fields', new FormControl(section.fields))
        this.sectionsForm.addControl(section.id+'.id', new FormControl(section.id))
        this.sectionsForm.addControl(section.id+'.title', new FormControl(section.title))

      });
    }


    this.formEditForm.valueChanges.subscribe(value => {
      this.checkDuplicatesKeys()
    })

  }

  drop(event: CdkDragDrop<string[]>, index:number) {
    moveItemInArray(this.sectionsEdit[index].fields, event.previousIndex, event.currentIndex);
    this.formEditForm.patchValue({sections: this.sectionsEdit})
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    if(this.allIdsSections.includes(result)){
      result = this.makeid(6)
    }
    return result;
  }

  addSection(){
    const defaultTitle = this.translate.instant("workTask.newSection");
    const newSection = {
      fields:[],
      title: '',
      id: this.makeid(6)
    }

    this.sectionsEdit.push(newSection)
    this.formEditForm.patchValue({sections: this.sectionsEdit})
    
  }

  editTitleSection(sectionId, sectionTitle, sectionIndex){
    let dialogRef= this.dialog.open(TaskTemplateEditSectionDialogComponent, {
      data: { 
         values: sectionTitle
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.sectionsEdit[sectionIndex].title = result[0].title
        const control = sectionId + '.title'
        this.sectionsForm.patchValue({[control]: result[0].title})
        this.formEditForm.patchValue({sections: this.sectionsEdit})
      }
    });
  }

  openFieldDialog(field, fieldNumber,sectionIndex){
    let dialogRef = this.dialog.open(FormEditFieldDialogComponent , {
      data: { 
         values: field,
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        
        this.sectionsEdit[sectionIndex].fields[fieldNumber]=result[0]
        this.formEditForm.patchValue({sections: this.sectionsEdit})
        
        this.sectionsEdit.forEach((section, ind) => {
          if(ind==sectionIndex){
          }
        });
  
      }
    });
  }


  duplicateSection(section, index){
    const newSection = deepCopy(section)

    if(newSection.fields){
      newSection.fields.forEach((field, index) => {
        field.id = this.makeid(6)
        this.allIdsSections = field.id
        field.key += '_copy'
        this.allKeysSection.push(field.key)
      });
    }
    newSection.title += '_copy'
    this.sectionsEdit.push(newSection)
    this.formEditForm.patchValue({sections: this.sectionsEdit})
  }

  deleteSection(sectionTitle, sectionIndex){
    this.sectionsEdit.splice(sectionIndex,1)
    this.formEditForm.patchValue({sections: this.sectionsEdit})
  }

  addFieldToSection(type, sectionIndex){
    const id= this.makeid(6)
    const _defaultFields = defaultFields
    if(!this.allIdsSections.includes(id)){
      this.allIdsSections.push(id)
    }
    const newField = deepCopy(_defaultFields[type])
    newField['id']=id
    newField['title']+=''+this.addedFields
    newField['key']+=''+this.addedFields
    if(this.allKeysSection.includes(newField['key'])){
      const random = this.makeid(4)
      newField['key']+=''+ random
      newField['title']+=''+random
    }
    this.addedFields++
    this.sectionsEdit[sectionIndex].fields.push(newField)
    this.allKeysSection.push(newField.key)
    this.formEditForm.patchValue({sections: this.sectionsEdit})
  }

  deleteField(field, FieldIndex, sectionIndex){
    this.sectionsEdit[sectionIndex].fields.splice(FieldIndex,1)
    this.formEditForm.patchValue({sections: this.sectionsEdit})
  }

  dialogCancel(){
    this.dialogRef.close([this.formData, false])
  }
  dialogSave(){
    const submitButtonStyleForm = this.submitButtonStyleForm.value
    this.formEditForm.patchValue({
      submitButtonStyle: submitButtonStyleForm
    })
    const data = this.formEditForm.value
    removeUndefined(data)
    this.dialogRef.close([ data, true])
  }

  checkDuplicatesKeys(){
    let allFieldsNumber = 0
    this.allIdsSections=[]
    this.allKeysSection=[]
    let changes = false
    this.duplicateKeys = false

    this.sectionsEdit.forEach((section, index) => {
      if(section.fields ){
        section.fields.forEach( (field, number) => {
          if(field.id){
            if(this.allIdsSections.includes(field.id)){
              field.id = this.makeid(6)
              changes = true
              this.allIdsSections.push(field.id)
            }else{
              this.allIdsSections.push(field.id)
            }
          }
           
          if(field.key){
            allFieldsNumber++
            this.addedFields = allFieldsNumber
            if(this.allKeysSection.includes(field.key)){
              this.duplicateKeys = true
              field.key+=this.duplicatedKeyNumber
              this.duplicatedKeyNumber++
              this.allKeysSection.push(field.key)
            }else{
              this.allKeysSection.push(field.key)
            }
          }else{
            if(field.key==""){
              if(this.allKeysSection.includes(field.key)){
                this.duplicateKeys = true
              field.key+=this.duplicatedKeyNumber
              this.duplicatedKeyNumber++
                this.allKeysSection.push("")
              }else{
                this.allKeysSection.push("")
              }
            }
          }
        })
      }

      
    });

    if(changes){
      this.formEditForm.patchValue({sections : this.sectionsEdit})
    }
  }

  initializePaletteColors(){
    var colors=[]
    const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
    const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
    const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
    const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
    colors.push(warmOrange)
    colors.push(darkblue)
    colors.push(pureWhite)
    colors.push(salbei)
    this.colorPalette=colors
  }

  returnChangesFilters($event){
    console.log($event)
    this.formEditForm.patchValue({filter: $event.newFilters})
  }
}
