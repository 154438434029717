import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';

@Component({
  selector: 'app-interfaces-data-mapping-dialog',
  templateUrl: './interfaces-data-mapping-dialog.component.html',
  styleUrls: ['./interfaces-data-mapping-dialog.component.css']
})
export class InterfacesDataMappingDialogComponent {
  mappingForm!:FormGroup
  mappingData:any
  type:any

  constructor(
    public dialogRef: MatDialogRef<InterfacesDataMappingDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any[],
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(){
    this.type = this.data['type']
    
    this.mappingForm = this.fb.group({
      key:[],
      path:[],
      type:[]
    })

    if(this.data['info']){
      this.mappingData = this.data['info']
      const {key, path, type} = this.mappingData
      this.mappingForm.patchValue({
        key: key ? key : undefined,
        path: path ? path : undefined,
        type: type ? type : undefined
      })
    }else{
      this.mappingForm.patchValue({
        key:  undefined,
        path: undefined,
        type: undefined
      })
    }
  }

  dialogCancel(){
    this.dialogRef.close([false, this.mappingData])

  }
  dialogSave(){
    const data = removeUndefinedValuesFromObject(this.mappingForm.value)
    this.dialogRef.close([true, data])
  }
}