<h2 mat-dialog-title>{{dialogData.key}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto custom-width w-100" id="section" >
            <form [formGroup]="dialogForm" class="m-1">

                <div class="input-group" >
                    <mat-form-field appearance="outline" class="w-100" >
                      <mat-label>{{'content.type' | translate}} </mat-label>
                      <mat-select formControlName="type" class="input-group m-1">
                        <mat-option value="string">{{'content.text' | translate}} </mat-option>
                        <mat-option value="number">{{'content.number' | translate}} </mat-option>
                        <mat-option value="boolean">{{'content.bool' | translate}} </mat-option>
                        <mat-option value="array">{{'content.list' | translate}} </mat-option>
                        <mat-option value="object">{{'content.keyValue' | translate}} </mat-option>
                        <mat-option value="date">{{'content.date' | translate}} </mat-option>
        
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'content.key' | translate}}</mat-label>
                    <input formControlName="key" type="text" matInput placeholder="Key">
                </mat-form-field>
        
                <mat-form-field *ngIf="type=='string'" appearance="outline" class="w-100">
                    <mat-label>{{'content.value' | translate}}</mat-label>
                    <input matInput formControlName="value" class="form-control" placeholder="{{'content.value' | translate}}" id="value" type="text" >
                </mat-form-field>
        
                <mat-form-field *ngIf="type=='number'" appearance="outline" class="w-100">
                    <mat-label>{{'content.value' | translate}}</mat-label>
                    <input matInput formControlName="value" class="form-control" placeholder="{{'content.value' | translate}}" id="value" type="number" >
                </mat-form-field>
        
                <div *ngIf="type=='boolean'" class="form-control mb-1 d-flex justify-content-between">
                    <label for="value">{{'content.value' | translate}}</label>
                    <mat-slide-toggle class="example-margin" formControlName="value" ></mat-slide-toggle>  
                </div>
        
                <div *ngIf="type=='array'" class="form-group m-1">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <label for="value">{{'content.LIST' | translate}}</label>
        
                        <button *ngIf="contentUserRights_update" mat-icon-button [matMenuTriggerFor]="newContentValue">
                            <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                add_circle
                            </span>
                        </button>
        
                        <button *ngIf="!contentUserRights_update" mat-icon-button [matMenuTriggerFor]="newContentValue" disabled>
                            <span class="material-symbols-outlined">
                                add_circle
                            </span>
                        </button>
        
                        <mat-menu #newContentValue="matMenu">
                            <button mat-menu-item (click)="addNewValue('text');" [disabled]="currentType && currentType !== 'text'">
                              <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange); padding-right: 5px;">
                                  font_download
                                </span>
                                <span>{{'content.text' | translate}}</span>
                              </div>
                            </button>
                            <button mat-menu-item (click)="addNewValue('number');" [disabled]="currentType && currentType !== 'number'">
                              <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                  tag
                                </span>
                                <span>{{'content.number'  | translate}}</span>
                              </div>
                            </button>
                      
                            <button mat-menu-item (click)="addNewValue('boolean');" [disabled]="currentType && currentType !== 'boolean'">
                              <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                  toggle_on
                                </span>
                                <span>{{'content.bool' | translate}}</span>
                              </div>
                            </button>
                      
                            <button mat-menu-item (click)="addNewValue('date');" [disabled]="currentType && currentType !== 'date'">
                              <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                  calendar_month
                                </span>
                                <span>{{'content.date' | translate}}</span>
                              </div>
                            </button>
                          </mat-menu>
                    </div>
                    
                    <div class="list-group">
                        <div *ngFor="let value of dialogForm.value.value; let index=index;" class="d-flex flex-row justify-content-between align-items-center border-bottom list-group-item" id="item-list">
        
                            <div *ngIf='isNumber(value)' class="flex-grow-1">
                                <span class="text-break">{{value}}</span>
                            </div>
        
                            <div *ngIf='isString(value)' class="flex-grow-1">
                                <span class="text-break">{{value}}</span>
                            </div>
        
                            <div *ngIf='isBoolean(value)' class="flex-grow-1">
                                <mat-slide-toggle class="example-margin" [checked]="value"  class=" mb-1" disabled> </mat-slide-toggle> 
                            </div>
        
                            
                            <div *ngIf='isObject(value)' class="flex-grow-1">
                                <div *ngIf="value.nanoseconds || value.seconds">
                                    <span class="text-break">{{isDateTime(value)}}</span>
                                </div>
        
                                <div *ngIf="!value.nanoseconds && !value.seconds">
                                    <div *ngIf="value.key">____
                                        <span class="text-break">{{value.key}}</span>
        
                                    </div>
                                    <span *ngIf="!value.key" class="text-break">{{value}}</span>
                                </div>
                            </div>
        
                            <div class="ps-1 border-start d-flex flex-row flex-nowrap">
                                <button *ngIf="contentUserRights_update" mat-icon-button (click)="editListValue(value, index);">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                        edit
                                    </span>
                                </button>
        
                                <button *ngIf="!contentUserRights_update" mat-icon-button (click)="editListValue(value, index);" disabled>
                                    <span class="material-symbols-outlined" >
                                        edit
                                    </span>
                                </button>
                                <button *ngIf="contentUserRights_update" mat-icon-button (click)="deleteListValue(value, index);">
                                    <span class="material-symbols-outlined" style="color: red;">
                                        delete
                                    </span>
                                </button>
        
                                <button *ngIf="!contentUserRights_update" mat-icon-button (click)="deleteListValue(value, index);" disabled>
                                    <span class="material-symbols-outlined" >
                                        delete
                                    </span>
                                </button>
                            </div>
        
                        </div>
                    </div>
                    
                </div>
        
                <div *ngIf="type=='object'" class="form-group m-1">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <label for="value">{{'content.keyValue' | translate}}</label>
        
                        <button *ngIf="contentUserRights_update" mat-icon-button [matMenuTriggerFor]="newContentValue">
                            <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                add_circle
                            </span>
                        </button>
                        <button *ngIf="!contentUserRights_update" mat-icon-button [matMenuTriggerFor]="newContentValue" disabled>
                            <span class="material-symbols-outlined">
                                add_circle
                            </span>
                        </button>
                        <mat-menu #newContentValue="matMenu">
                            <button mat-menu-item (click)="addNewValue('text');">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange); padding-right: 5px;">
                                        font_download
                                    </span>
                                    <span>{{'content.text' | translate}}</span>
                                </div>
                            </button>
                            <button mat-menu-item (click)="addNewValue('number');">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                        tag
                                    </span>
                                    <span>{{'content.number' | translate}}</span>
                                </div>
                            </button>
                
                            <button mat-menu-item (click)="addNewValue('boolean');">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                        toggle_on
                                    </span>
                                    <span>{{'content.bool' | translate}}</span>
                                </div>
                            </button>
        
                            <button mat-menu-item (click)="addNewValue('date');">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                        calendar_month
                                    </span>
                                    <span>{{'content.date' | translate}}</span>
                                </div>
                            </button>
                        </mat-menu>
                    </div>
                    
                    <div class="list-group">
                        <div *ngFor="let value of dataValues; let index=index;" class="d-flex flex-row justify-content-between align-items-center border-bottom list-group-item" id="item-list">
                            <div *ngIf='isNumber(value)' class="flex-grow-1">
                                <span class="text-break">{{value}}</span>
                            </div>
        
                            <div *ngIf='isString(value)' class="flex-grow-1">
                                <span class="text-break">{{value}}</span>
                            </div>
        
                            <div *ngIf='isBoolean(value)' class="flex-grow-1">
                                <mat-slide-toggle class="example-margin" [checked]="value"  class="mb-1" disabled> </mat-slide-toggle> 
                            </div>
        
                            <div *ngIf='isObject(value)' class="flex-grow-1 pe-2">
                                <!-- <div *ngIf="value.nanoseconds || value.seconds">
                                    <span class="text-break">{{value?.toDate() | date}}</span>
                                </div>
        
                                <div *ngIf="!value.nanoseconds && !value.seconds">
                                    <span class="text-break">{{value}}</span>
                                </div> -->
                                <div>
                                    <span class="text-break text-wrap">{{isDateTime(value)}}</span>
                                </div>
                            </div>
                            
                            <div class="ps-1 border-start d-flex flex-row flex-nowrap">
                                <button *ngIf="contentUserRights_update" mat-icon-button (click)="editListValue(value, index);">
                                    <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                        edit
                                    </span>
                                </button>
        
                                <button *ngIf="!contentUserRights_update" mat-icon-button (click)="editListValue(value, index);" disabled>
                                    <span class="material-symbols-outlined" >
                                        edit
                                    </span>
                                </button>
                                <button *ngIf="contentUserRights_update" mat-icon-button (click)="deleteListValue(value, index);">
                                    <span class="material-symbols-outlined" style="color: red;">
                                        delete
                                    </span>
                                </button>
                                <button *ngIf="!contentUserRights_update" mat-icon-button (click)="deleteListValue(value, index);" disabled >
                                    <span class="material-symbols-outlined" >
                                        delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- <div *ngIf="type=='date'" class="form-group m-1">
                    <label for="value">{{'content.value' | translate}}</label>
                    <input matInput  class="form-control" placeholder="Value" id="value" type="date" formControlName="date">
                    <input matInput  class="form-control mt-1" placeholder="Value" id="value" type="time" formControlName="time">
                </div> -->
                <div *ngIf="type=='date'" class="pt-2"> 
                    <mat-form-field appearance="outline"> 
                        <mat-label> {{'content.date' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="date" placeholder="{{'dd.mm.yyyy' | translate}}">
                        <mat-hint>{{'dd.mm.yyyy' | translate}}</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label> {{'taskTemplates.time' | translate}}</mat-label>
                        <input matInput type="time" formControlName="time">
                    </mat-form-field>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();">{{'cancel' | translate}}</button>
    <button *ngIf="contentUserRights_update" mat-button class="orange-button" (click)="onSaveEdit();" >{{'save' | translate}}</button>
</mat-dialog-actions>
