import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommentsService } from '../service/comments.service';
import { AccountService } from '../service/account.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { validColorValidator } from 'ngx-colors';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { StorageService } from '../service/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from 'src/app/service/upload.service';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-cd-settings',
  templateUrl: './cd-settings.component.html',
  styleUrls: ['./cd-settings.component.css']
})
export class CdSettingsComponent implements OnInit {
  darkMode:boolean = false
  emptyFonts:boolean = false
  formLoaded:boolean = false
  showSpinner:boolean = true
  drop:boolean = false
  files:any
  cdSettingsForm:FormGroup
  contextId:any
  contextData:any
  oldData:any
  contextCdEditData:any
  lightColorsData:any
  darkColorsData:any
  isResetColorsDisabled:boolean
  fontsData:any
  colorPalette: string[] = [];
  initialLightColorsData: any;
  initialDarkColorsData: any;
  isResetDisabled: boolean = true;
  saveDisabled: boolean = true;
  fontType: string
  uploadedFonts: { name: string, url: string }[] = [];

  defaultColorsValuesHex:any={
    'accent':'#FF5E38',
    'background':'#ffffff',
    'title':'#000000',
    'subtitle':'#3C3C4399',
    'primary':'#000000',
/*     'secondary':'#3C3C4399' */
  }

  defaultDarkColorsValuesHex:any={
    'accent':'#FF5E38',//'rgb(255, 94, 56)',
    'background':'#000000',//rgb(0, 0, 0//'rgb(255, 255, 255)',
    'title':'#ffffff',//'rgb(255,255,255)',//'rgb(0, 0, 0)'
    'subtitle':'#818181',//'rgb(129,129,129)',//'rgb(60, 60, 67, 0.60)'
    'primary':'#ffffff',//'rgb(255,255,255)',//'rgb(0, 0, 0)',
/*     'secondary':'#818181'//'rgb(129,129,129)'//'rgb(60, 60, 67, 0.60)' */
  }

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private commentService: CommentsService,
    private uploadService: UploadService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private imageCompressionService: ImageCompressionService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private accountService: AccountService,
    private fb: FormBuilder
  ) {
    this.contextData = this.settingsService.contextAccount$
    this.contextId = this.settingsService.contextId$

    this.cdSettingsForm = this.fb.group({
      imageUrl: [],
      titleFont: [],
      subtitleFont: [],
      plainFont: [],
      boldFont: [],
      italicFont: [],
      lightBackground: [],
      lightTitle: [],
      lightSubtitle: [],
      lightPrimary: [],
      // lightSecondary: [],
      lightAccent: [],
      darkBackground: [],
      darkTitle: [],
      darkSubtitle: [],
      darkPrimary: [],
      // darkSecondary: [],
      darkAccent: [],
      cornerRadius: [],
      widgetSpacing: [],
      darkMode: [false]
    });
    
  }

  async ngOnInit(): Promise<void> {

    await this.loadFonts();
    this.initializePaletteColors()

    this.contextData = this.settingsService.contextAccount$
    this.contextId = this.settingsService.contextId$

    if (this.contextData.design) {
      this.contextCdEditData = this.contextData.design
      this.lightColorsData = this.contextCdEditData.theme.lightColors || this.defaultColorsValuesHex
      this.darkColorsData = this.contextCdEditData.theme.darkColors || this.defaultDarkColorsValuesHex
      this.fontsData = this.contextCdEditData.theme.fonts

      // Store initial color data
      this.initialLightColorsData = { ...this.lightColorsData };
      this.initialDarkColorsData = { ...this.darkColorsData };
      // Initialize form after fonts are loaded
 
      this.initializeForm();
      console.log("initalized fb data")
    } else {
      this.initializeFormWithDefaults();
      console.log("initiazlized defaults")
    }

  // Set initial font-face rules based on the form values
  this.setFontFace(this.cdSettingsForm.value.titleFont, 'titleFont');
  this.setFontFace(this.cdSettingsForm.value.subtitleFont, 'subtitleFont');
  this.setFontFace(this.cdSettingsForm.value.plainFont, 'plainFont');
  this.setFontFace(this.cdSettingsForm.value.boldFont, 'boldFont');
  this.setFontFace(this.cdSettingsForm.value.italicFont, 'italicFont');

  // Update font-face rules when form values change
  this.cdSettingsForm.get('titleFont').valueChanges.subscribe(value => {
    this.setFontFace(value, 'titleFont');
  });
  this.cdSettingsForm.get('subtitleFont').valueChanges.subscribe(value => {
    this.setFontFace(value, 'subtitleFont');
  });
  this.cdSettingsForm.get('plainFont').valueChanges.subscribe(value => {
    this.setFontFace(value, 'plainFont');
  });
  this.cdSettingsForm.get('boldFont').valueChanges.subscribe(value => {
    this.setFontFace(value, 'boldFont');
  });
  this.cdSettingsForm.get('italicFont').valueChanges.subscribe(value => {
    this.setFontFace(value, 'italicFont');
  });
}

getImageUrl(imageUrl: any): string | null {
  if (typeof imageUrl !== 'string' || !imageUrl.startsWith('context://images/')) {
    return null;
  }
  const imageName = imageUrl.replace('context://images/', '');
  const hostname = environment.urlHostName;
  return `https://${hostname}/v1/context/${this.contextId}/images/${imageName}`;
}

extractFilenameFromUrl(url: string): string {
  const urlObj = new URL(url, `https://${environment.urlHostName}`);
  const pathname = urlObj.pathname;
  return pathname.substring(pathname.lastIndexOf('/') + 1);
}

setFontFace(font: { name?: string, url?: string }, fontControlName: string): void {
  if (!font || (!font.name && !font.url)) return;

  const fontName = this.extractFilenameFromUrl(font.url)

  if (!fontName) return;

  let hostname = environment.urlHostName;
  const fontUrl = `https://${hostname}/v1/context/${this.contextId}/fonts/${fontName}`;

  const styleElement = this.renderer.createElement('style');
  styleElement.innerHTML = `
    @font-face {
      font-family: '${fontControlName}';
      src: url('${fontUrl}');
    }
  `;
  this.renderer.appendChild(document.head, styleElement);
}

initializeForm() {
  this.commentService.progressSpin.emit(true);

    const findFontByName = (name: string) => {
      return this.uploadedFonts.find(font => font.name === name) || undefined;
    };

  this.cdSettingsForm = this.fb.group({
    imageUrl: [{value:this.contextCdEditData.logo.url ? this.contextCdEditData.logo.url : undefined, disabled:false}],
    imageDescription: [{value:this.contextCdEditData.logo.description ? this.contextCdEditData.logo.description : undefined, disabled:false}],
    lightMode: [!this.darkMode],
    darkMode: [this.darkMode],
    lightAccent: [{value: this.lightColorsData.accent ? this.lightColorsData.accent : undefined, disabled:false}, validColorValidator()],
    lightBackground: [{value:this.lightColorsData.background ? this.lightColorsData.background : undefined, disabled:false}, validColorValidator()],
    lightPrimary: [{value:this.lightColorsData.primary ? this.lightColorsData.primary : undefined, disabled:false}, validColorValidator()],
    lightSubtitle: [{value:this.lightColorsData.subtitle ? this.lightColorsData.subtitle : undefined, disabled:false}, validColorValidator()],
    lightTitle: [{value:this.lightColorsData.title ? this.lightColorsData.title : undefined, disabled:false}, validColorValidator()],
    darkAccent: [{value:this.darkColorsData.accent ? this.darkColorsData.accent : undefined, disabled:false}, validColorValidator()],
    darkBackground: [{value: this.darkColorsData.background ? this.darkColorsData.background : undefined, disabled:false}, validColorValidator()],
    darkPrimary: [{value:this.darkColorsData.primary ? this.darkColorsData.primary : undefined, disabled:false}, validColorValidator()],
    darkSubtitle: [{value:this.darkColorsData.subtitle ? this.darkColorsData.subtitle : undefined, disabled:false}, validColorValidator()],
    darkTitle: [{value:this.darkColorsData.title ? this.darkColorsData.title : undefined, disabled:false}, validColorValidator()],
    cornerRadius: [this.contextCdEditData.theme.cornerRadius ?? 10],
    widgetSpacing: [this.contextCdEditData.theme.widgetSpacing ?? 20],
    titleFont: [findFontByName(this.fontsData.title?.name || undefined)],
    subtitleFont: [findFontByName(this.fontsData.subtitle?.name || undefined)],
    plainFont: [findFontByName(this.fontsData.plain?.name || undefined)],
    boldFont: [findFontByName(this.fontsData.bold?.name || undefined)],
    italicFont: [findFontByName(this.fontsData.italic?.name || undefined)]
  });

  this.formLoaded = true;
  this.showSpinner = false;
  this.commentService.progressSpin.emit(false);

  // Check if colors have changed on form value changes
  this.cdSettingsForm.valueChanges.subscribe((value) => {
    this.checkIfColorsChanged();
  // improvement needed
    this.saveDisabled = false
  });
  // Initially check if colors are different from defaults
  this.checkIfColorsChanged();

}


initializeFormWithDefaults() {
  this.commentService.progressSpin.emit(true);
  this.cdSettingsForm = this.fb.group({
    imageUrl: [{value:undefined, disabled:false}],
    imageDescription: [{value:undefined, disabled:false}],
    lightMode: [!this.darkMode],
    darkMode: [this.darkMode],
    lightBackground: [{value:this.defaultColorsValuesHex.background, disabled:false}, validColorValidator()],
    lightTitle: [{value:this.defaultColorsValuesHex.title, disabled:false}, validColorValidator()],
    lightSubtitle: [{value:this.defaultColorsValuesHex.subtitle, disabled:false}, validColorValidator()],
    lightPrimary: [{value:this.defaultColorsValuesHex.primary, disabled:false}, validColorValidator()],
    lightAccent: [{value:this.defaultColorsValuesHex.accent, disabled:false}, validColorValidator()],
    darkBackground: [{value:this.defaultDarkColorsValuesHex.background, disabled:false}, validColorValidator()],
    darkTitle: [{value:this.defaultDarkColorsValuesHex.title, disabled:false}, validColorValidator()],
    darkSubtitle: [{value:this.defaultDarkColorsValuesHex.subtitle, disabled:false}, validColorValidator()],
    darkPrimary: [{value:this.defaultDarkColorsValuesHex.primary, disabled:false}, validColorValidator()],
    darkAccent: [{value:this.defaultDarkColorsValuesHex.accent, disabled:false}, validColorValidator()],
    cornerRadius: [10],
    widgetSpacing: [20],
    titleFont: [{value:undefined, disabled:false}],
    subtitleFont: [{value:undefined, disabled:false}],
    plainFont: [{value:undefined, disabled:false}],
    boldFont: [{value:undefined, disabled:false}],
    italicFont: [{value:undefined, disabled:false}]
  });
  this.formLoaded = true;
  this.showSpinner = false
  this.commentService.progressSpin.emit(false);
  this.cdSettingsForm.valueChanges.subscribe(() => {
    this.saveDisabled = false
  });
}

initializePaletteColors() {
  const colors = [
    getComputedStyle(document.documentElement).getPropertyValue('--warmOrange'),
    getComputedStyle(document.documentElement).getPropertyValue('--darkBlue'),
    getComputedStyle(document.documentElement).getPropertyValue('--pureWhite'),
    getComputedStyle(document.documentElement).getPropertyValue('--salbei')
  ];
  this.colorPalette = colors;
}

  changeTheme(value:boolean){
    this.darkMode=value
  }

  async loadFonts() {
    try {
      const fonts = await this.storageService.getCdFonts(this.contextId);
      this.uploadedFonts = fonts.map(font => ({
        name: font.name,
        url: `context://fonts/${font.filename}`
      }));

      this.emptyFonts = this.uploadedFonts.length === 0;
    } catch (error) {
      console.error('Error loading CD fonts', error);
    }
  }
  
  resetCdSettings(){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:this,
        type:"CdSettings"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.resetToDefaultSettings();
        const message = this.translateService.instant("SnackBarConstants.RESET_OK")
      }else{
        const message = this.translateService.instant("SnackBarConstants.RESET_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }

  resetToDefaultSettings() {
    this.cdSettingsForm.reset({
      imageUrl: undefined,
      imageDescription: undefined,
      lightMode: !this.darkMode,
      darkMode: this.darkMode,
      lightAccent: this.defaultColorsValuesHex.accent,
      lightBackground: this.defaultColorsValuesHex.background,
      lightPrimary: this.defaultColorsValuesHex.primary,
      // lightSecondary: this.defaultColorsValuesHex.secondary,
      lightSubtitle: this.defaultColorsValuesHex.subtitle,
      lightTitle: this.defaultColorsValuesHex.title,
      darkAccent: this.defaultDarkColorsValuesHex.accent,
      darkBackground: this.defaultDarkColorsValuesHex.background,
      darkPrimary: this.defaultDarkColorsValuesHex.primary,
      // darkSecondary: this.defaultDarkColorsValuesHex.secondary,
      darkSubtitle: this.defaultDarkColorsValuesHex.subtitle,
      darkTitle: this.defaultDarkColorsValuesHex.title,
      cornerRadius: 10,
      widgetSpacing: 20,
      titleFont: undefined,
      subtitleFont: undefined,
      plainFont: undefined,
      boldFont: undefined,
      italicFont: undefined
    });
    this.isResetDisabled = true;
  }

  async readImageURL(event: any) {
  
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
      if (!allowedTypes.includes(file.type)) {
        const message = this.translateService.instant("SnackBarConstants.INVALID_IMAGE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit
  
      let imageToUpload = file;
  
      if (file.size > maxSizeInBytes) {
        try {
          // Compress the image
          const compressedImage = await this.imageCompressionService.compressImage(file, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translateService.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          imageToUpload = compressedImage;
        } catch (error) {
          console.log(error);
          const message = this.translateService.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      }
  
      try {
        this.commentService.progressSpin.emit(true);
  
        const newImage = await this.uploadService.uploadCdLogo(imageToUpload, /* type */ '', this.contextId);
  
        this.cdSettingsForm.patchValue({
          imageUrl: newImage,
          imageDescription: ""
        });
  
        this.commentService.progressSpin.emit(false);
      } catch (error) {
        this.commentService.progressSpin.emit(false);
        const message = this.translateService.instant("SnackBarConstants.UPLOAD_IMAGE_FAILED");
        console.error('Error uploading image', error);
      }
    }
  }

  copyLightToDark() {
    const lightValues = {
      darkAccent: this.cdSettingsForm.get('lightAccent')?.value,
      darkBackground: this.cdSettingsForm.get('lightBackground')?.value,
      darkPrimary: this.cdSettingsForm.get('lightPrimary')?.value,
      darkSubtitle: this.cdSettingsForm.get('lightSubtitle')?.value,
      darkTitle: this.cdSettingsForm.get('lightTitle')?.value,
    };
    this.cdSettingsForm.patchValue(lightValues);
  }  
  
  checkIfColorsChanged() {
    const hasLightColorsChanged = 
      this.cdSettingsForm.get('lightAccent')?.value !== this.initialLightColorsData.accent ||
      this.cdSettingsForm.get('lightBackground')?.value !== this.initialLightColorsData.background ||
      this.cdSettingsForm.get('lightPrimary')?.value !== this.initialLightColorsData.primary ||
      this.cdSettingsForm.get('lightSubtitle')?.value !== this.initialLightColorsData.subtitle ||
      this.cdSettingsForm.get('lightTitle')?.value !== this.initialLightColorsData.title;
  
    const hasDarkColorsChanged = 
      this.cdSettingsForm.get('darkAccent')?.value !== this.initialDarkColorsData.accent ||
      this.cdSettingsForm.get('darkBackground')?.value !== this.initialDarkColorsData.background ||
      this.cdSettingsForm.get('darkPrimary')?.value !== this.initialDarkColorsData.primary ||
      this.cdSettingsForm.get('darkSubtitle')?.value !== this.initialDarkColorsData.subtitle ||
      this.cdSettingsForm.get('darkTitle')?.value !== this.initialDarkColorsData.title;
  
    this.isResetColorsDisabled = !(hasLightColorsChanged || hasDarkColorsChanged);
  }  

   resetColors() {
    if (this.darkMode) {
      // Reload darkColorsData with initial dark colors data
      this.darkColorsData = { ...this.initialDarkColorsData };
      
      this.cdSettingsForm.patchValue({
        darkAccent: this.darkColorsData.accent,
        darkBackground: this.darkColorsData.background,
        darkTitle: this.darkColorsData.title,
        darkSubtitle: this.darkColorsData.subtitle,
        darkPrimary: this.darkColorsData.primary,
        // darkSecondary: this.darkColorsData.secondary
      });
    } else {
      // Reload lightColorsData with initial light colors data
      this.lightColorsData = { ...this.initialLightColorsData };
      
      this.cdSettingsForm.patchValue({
        lightAccent: this.lightColorsData.accent,
        lightBackground: this.lightColorsData.background,
        lightTitle: this.lightColorsData.title,
        lightSubtitle: this.lightColorsData.subtitle,
        lightPrimary: this.lightColorsData.primary,
        // lightSecondary: this.lightColorsData.secondary
      });
    }
  
    const message = this.translateService.instant('SnackBarConstants.RESET_COLORS_OK');
    this.commentService.addSnackBar.emit(message);
  }

async imageDropped(files: FileHandle[], type: string): Promise<void> {
  this.files = files;
  const fileToUpload = files[0].file;

  if (fileToUpload) {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
    if (!allowedTypes.includes(fileToUpload.type)) {
      const message = this.translateService.instant("SnackBarConstants.INVALID_IMAGE_FORMAT");
      this.commentService.addSnackBar.emit(message);
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit

    let imageToUpload = fileToUpload;

    if (fileToUpload.size > maxSizeInBytes) {
      try {
        // Compress the image
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translateService.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        imageToUpload = compressedImage;
      } catch (error) {
        console.log(error);
        const message = this.translateService.instant("SnackBarConstants.IMAGE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    }

    this.drop = true;

    try {
      this.commentService.progressSpin.emit(true);

      const newImage = await this.uploadService.uploadCdLogo(imageToUpload, type, this.contextId);
      this.cdSettingsForm.patchValue({
        imageUrl: newImage,
        imageDescription: ""
      });

      this.commentService.progressSpin.emit(false);
    } catch (error) {
      this.commentService.progressSpin.emit(false);
      const message = this.translateService.instant("SnackBarConstants.UPLOAD_IMAGE_FAILED");
      console.error('Image upload failed', error);
    }
  }
}

  async fontsDropped(files: FileHandle[], type: string): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
  
    if (fileToUpload) {
      this.drop = true;
  
      try {
        this.commentService.progressSpin.emit(true);
        const newfont = await this.uploadService.uploadCdFont(fileToUpload, this.contextId);
        
        const opentype = require('opentype.js');
        const buffer = await fileToUpload.arrayBuffer();
        const font = opentype.parse(buffer);
        const fullName = font.names.fullName.en;
  
        const newdata = {
          name: fullName,
          url: newfont.url
        };
  
        this.loadFonts();
        this.commentService.progressSpin.emit(false);
      } catch (error) {
        this.commentService.progressSpin.emit(false);
        const message = this.translateService.instant("SnackBarConstants.UPLOAD_FONT_FAILED");
        console.error('Font upload failed', error);
      }
    }
  }
  

  readFontURL(event: any) {

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileType = file.type;

      try {
        this.commentService.progressSpin.emit(true);
        this.uploadService.uploadCdFont(file, this.contextId).then(async (newfont) => {
          const opentype = require('opentype.js');
          const buffer = await file.arrayBuffer();
          const font = opentype.parse(buffer);
          const fullName = font.names.fullName.en;

          const newdata = {
            name: fullName,
            url: newfont.url
          };

          await this.loadFonts();

          this.patchFormAfterFontLoad();
          this.commentService.progressSpin.emit(false);
        }).catch((error) => {
          this.commentService.progressSpin.emit(false);
          const message = this.translateService.instant("SnackBarConstants.UPLOAD_FONT_FAILED");
          console.error('Font upload failed', error);
        });
      } catch (error) {
        this.commentService.progressSpin.emit(false);
        const message = this.translateService.instant("SnackBarConstants.UPLOAD_FONT_FAILED");
        console.error('Error reading font file', error);
      }
    }
  }

  triggerFontFileInput() {
    const fileInput = document.getElementById('myfile-pick-font') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  triggerImageFileInput() {
    const fileInput = document.getElementById('myfile-pick-image') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  async deleteFont(font: { name: string, url: string }) {
    const filePath = font.url.replace('context://', `Contexts/${this.contextId}/`);
    try {
      this.commentService.progressSpin.emit(true);
      await this.storageService.deleteFont(filePath); // Delete
      await this.loadFonts(); // Refresh font list
  
      this.patchFormAfterFontLoad();
  
    } catch (error) {
      console.error('Error deleting font', error);
      const message = this.translateService.instant("SnackBarConstants.DELETE_FONT_FAILED");
      this.commentService.addSnackBar.emit(message);
    } finally {
      this.commentService.progressSpin.emit(false);
    }
  }
  
  patchFormAfterFontLoad() {
    const findFontByName = (name: string) => {
      return this.uploadedFonts.find(font => font.name === name) || undefined;
    };
  
    this.cdSettingsForm.patchValue({
      titleFont: findFontByName(this.fontsData.title?.name || undefined),
      subtitleFont: findFontByName(this.fontsData.subtitle?.name || undefined),
      plainFont: findFontByName(this.fontsData.plain?.name || undefined),
      boldFont: findFontByName(this.fontsData.bold?.name || undefined),
      italicFont: findFontByName(this.fontsData.italic?.name || undefined)
    });
  }


  async deleteImage(imageUrl: string) {
    const filePath = imageUrl.replace('context://', `Contexts/${this.contextId}/`);
    try {
      this.commentService.progressSpin.emit(true);
      await this.storageService.deleteImage(filePath);
      this.cdSettingsForm.patchValue({
        imageUrl: null,
        imageDescription: null
      });
    } catch (error) {
      console.error('Error deleting image', error);
      const message = this.translateService.instant("SnackBarConstants.DELETE_IMAGE_FAILED");
      this.commentService.addSnackBar.emit(message);
    } finally {
      this.commentService.progressSpin.emit(false);
    }
  }
  setEmptyToDefaultColors(){

    // light
    if(this.lightColorsData){
      if(!this.lightColorsData.accent)
        this.lightColorsData.accent=this.defaultColorsValuesHex.accent
      
      if(!this.lightColorsData.background)
        this.lightColorsData.background=this.defaultColorsValuesHex.background

      if(!this.lightColorsData.title)
        this.lightColorsData.title=this.defaultColorsValuesHex.title
      
      if(!this.lightColorsData.subtitle)
        this.lightColorsData.subtitle=this.defaultColorsValuesHex.subtitle

      if(!this.lightColorsData.primary)
        this.lightColorsData.primary=this.defaultColorsValuesHex.primary
      
/*       if(!this.lightColorsData.secondary)
        this.lightColorsData.secondary=this.defaultColorsValuesHex.secondary */
      
    }
    // dark

    if(this.darkColorsData){
      if(!this.darkColorsData.accent)
        this.darkColorsData.accent=this.defaultDarkColorsValuesHex.accent
      
      if(!this.darkColorsData.background)
        this.darkColorsData.background=this.defaultDarkColorsValuesHex.background

      if(!this.darkColorsData.title)
        this.darkColorsData.title=this.defaultDarkColorsValuesHex.title
      
      if(!this.darkColorsData.subtitle)
        this.darkColorsData.subtitle=this.defaultDarkColorsValuesHex.subtitle

      if(!this.darkColorsData.primary)
        this.darkColorsData.primary=this.defaultDarkColorsValuesHex.primary
      
/*       if(!this.darkColorsData.secondary)
        this.darkColorsData.secondary=this.defaultDarkColorsValuesHex.secondary */
      
    }
  }
  
  async saveCdSettings() {
    const value = this.cdSettingsForm.value;

    const getFontUrl = (fontName: string) => {
      const font = this.uploadedFonts.find(f => f.name === fontName);
      return font ? `context://fonts/${font.name}` : undefined;
    };
  
    // remove empty values
    const removeEmptyValues = (obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null && v !== '')
      );
    };
    
    // Transform data
    const designData = {
      design: {
        theme: {
          fonts: removeEmptyValues({
            title: value.titleFont,
            subtitle: value.subtitleFont,
            plain: value.plainFont,
            bold: value.boldFont,
            italic: value.italicFont
          }),
          darkColors: removeEmptyValues({
            accent: value.darkAccent,
            background: value.darkBackground,
            primary: value.darkPrimary,
            // secondary: value.darkSecondary,
            subtitle: value.darkSubtitle,
            title: value.darkTitle
          }),
          lightColors: removeEmptyValues({
            accent: value.lightAccent,
            background: value.lightBackground,
            primary: value.lightPrimary,
            // secondary: value.lightSecondary,
            subtitle: value.lightSubtitle,
            title: value.lightTitle
          }),
          widgetSpacing: value.widgetSpacing,
          cornerRadius: value.cornerRadius
        },
        logo: removeEmptyValues({
          url: value.imageUrl,
          description: value.imageDescription
        })
      }
    };

    try {
      this.commentService.progressSpin.emit(true);
  
      // Save the data
      await this.accountService.updateContextDataAccount(designData);
  
      // refresh data
      await this.settingsService.observeContextsIdLoggedFirestore(this.settingsService.contextId$);
  
      const message = this.translateService.instant('SnackBarConstants.UPDATE_OK');
      this.commentService.addSnackBar.emit(message);
  
      // Update initial data
      this.initialLightColorsData = {
        accent: value.lightAccent,
        background: value.lightBackground,
        primary: value.lightPrimary,
        // secondary: value.lightSecondary,
        subtitle: value.lightSubtitle,
        title: value.lightTitle
      };

      this.initialDarkColorsData = {
        accent: value.darkAccent,
        background: value.darkBackground,
        primary: value.darkPrimary,
        // secondary: value.darkSecondary,
        subtitle: value.darkSubtitle,
        title: value.darkTitle
      };

    } catch (error) {
      console.error('Save Error:', error);
      const message = this.translateService.instant('SnackBarConstants.SAVE_FAILED');
      this.commentService.addSnackBar.emit(message);
    } finally {
      this.commentService.progressSpin.emit(false);
    }

  }


}