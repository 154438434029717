<h2 mat-dialog-title>{{'account_languages' | translate}}</h2>

<mat-dialog-content style="width:500px;">
    <div class="pb-3">
        <section class="mt-1 mb-3 w-100" id="section">
            <div class="hint-text mb-3">
                {{'account_languages_hint' | translate}}
            </div>

            <mat-divider class="mb-3"></mat-divider>

            <button mat-button 
                    class="secondary-gray-button my-3"
                    id="btn-add"
                    [disabled]="!accountUserRights_update"
                    [matMenuTriggerFor]="listLanguages"
            >
                <mat-icon>add_circle</mat-icon> {{'add' | translate}}
            </button>

            <mat-menu #listLanguages="matMenu">
                <button mat-menu-item *ngFor="let lang of languages; let index = index;" (click)="addLanguageToContexts(lang[0]);"> {{lang[1]}} </button>
            </mat-menu>
            
            <div *ngIf="accountUserRights_update" cdkDropList (cdkDropListDropped)="drop($event)">
                <div *ngFor="let lang of accountForm.value.languages; let index= index;">
                    <mat-card cdkDrag class="my-2">
                        <mat-card-content class="py-0">
                            <div class="d-flex align-items-center" style="justify-content: space-between;">
                                <div>
                                    <span *ngIf="index==0" class="fw-bold">{{languagesList[lang]}}</span>
                                    <span *ngIf="index!=0">{{languagesList[lang]}}</span>
                                </div>
                                <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteLanguage(index);">
                                    <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div *ngIf="!accountUserRights_update">
                <div *ngFor="let lang of accountForm.value.languages; let index= index;">
                    <mat-card class="mb-1">
                        <mat-card-content class="py-0">
                            <div class="d-flex align-items-center" style="justify-content: space-between;">
                                <div>
                                    <span *ngIf="index==0" class="fw-bold">{{languagesList[lang]}}</span>
                                    <span *ngIf="index!=0">{{languagesList[lang]}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" [disabled]="!accountUserRights_update" (click)="saveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>
