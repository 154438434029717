
<!-- <button *ngIf="editMode" class="edit-button"><span class="material-symbols-outlined" (click)="openDialogEdit();">
    edit_square
    </span>
</button> -->

<section class="d-flex flex-column align-items-center">
    <p>The list of products will be shown here!</p>
    <p> Not available for the moment </p>
</section>


<section class="mb-2">
    <div class="ps-3">
        <p id="title" *ngIf="productsData.title" style="color:var(--title); font-size:18pt; font-family:var(--titleFontName);">{{productsData.title}}</p>
        <p id="subtitle" *ngIf="productsData.subtitle" style="color:var(--subtitle); font-family:var(--subtitleFontName);">{{productsData.subtitle}}</p>
    </div>
    <mdb-carousel [controls]="true" [indicators]="true" style="height: 300px; margin:5px;" *ngIf="productsData.layout=='horizontal'">
        <mdb-carousel-item >
            <img
            src='./../../assets/img/default.jpg'
            class="d-block w-100"
            alt="..."
            style="width: 200px;
            height: 300px;
            object-fit: scale-down;"
            onerror="this.src='./../../assets/img/default.jpg'"
            />

            <div class="carousel-caption d-none d-md-block">
                <h5 id="title" style="color:var(--title);">{{productsData.title}}</h5>
                <p id="subtitle" style="color:var(--subtitle);">{{productsData.subtitle}}</p>
                <p style="color:var(--primary);">123,13 €</p>
            </div>
        </mdb-carousel-item>

        <mdb-carousel-item >
            <img
            src='./../../assets/img/default.jpg'
            class="d-block w-100"
            alt="..."
            style="width: 200px;
            height: 300px;
            object-fit: scale-down;"
            onerror="this.src='./../../assets/img/default.jpg'"
            />

            <div class="carousel-caption d-none d-md-block">
                <h5 id="title" style="color:var(--title);">{{productsData.title}}</h5>
                <p id="subtitle" style="color:var(--subtitle);">{{productsData.subtitle}}</p>
                <p style="color:var(--primary);">123,13 €</p>
            </div>
        </mdb-carousel-item>
       
    </mdb-carousel>


    <section  *ngIf="productsData.layout=='vertical'" class="d-flex flex-column flex-nowrap w-100 p-2">
        <div class="mt-2  d-flex flex-row justify-content-around align-items-center ps-2 pe-2 pb-2">
            <img src="./../../assets/img/default.jpg" alt="product Image" width="100" style="border-radius: 10px;" onerror="this.src='./../../assets/img/default.jpg'"> 
            
           
            <div class="d-flex flex-column">
                <span id="title" style="color:var(--title);">{{productsData.title}}</span>
                <span id="subtitle" style="color:var(--subtitle);"><b>{{productsData.subtitle}}</b></span>
                <span style="color:var(--primary);">13,45 €</span>
            </div>

            <button mat-button style="background-color: var(--warmOrange); color:white;"> 
                <span class="material-symbols-outlined">
                    add_shopping_cart
                </span>
            </button>
       
        </div>

        <mat-divider></mat-divider>

        <div class="mt-2 d-flex flex-row justify-content-around align-items-center ps-2 pe-2">
            <img src="./../../assets/img/default.jpg" alt="product Image" width="100" style="border-radius: 10px;" onerror="this.src='./../../assets/img/default.jpg'"> 
            
            <div class="d-flex flex-column">
                <span id="title" style="color:var(--title);">{{productsData.title}}</span>
                <span id="subtitle" style="color:var(--subtitle);"><b>{{productsData.subtitle}}</b></span>
                <span style="color:var(--primary);">13,45 €</span>
            </div>

            <button mat-button style="background-color: var(--warmOrange); color:white;"> 
                <span class="material-symbols-outlined">
                    add_shopping_cart
                </span>
            </button>
       
        </div>

    </section>
</section>