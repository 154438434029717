import { Component, OnInit, Injectable, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { Router, RouterLink } from '@angular/router';
import { ReportCreateDialogComponent } from '../report-create-dialog/report-create-dialog.component';
import { ReportService } from '../service/report.service';
import { deepCopy, removeUndefined } from '../../../../backend/utils/object';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DuplicateReportTemplateDialogComponent } from '../duplicate-report-template-dialog/duplicate-report-template-dialog.component';
import { LocalStorageService } from '../service/local-storage-service.service';
import * as JSZip from 'jszip';
import { saveAs } from "file-saver";

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}
  editMode=false
  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent implements OnInit, AfterViewInit{
  reportExport!:FormGroup
  reportsData: any = []
  allreports:any
  displayedColumns: string[] = [ 'displayName', 'timeStamp', "creationDate", "export"];
  dataSource=new MatTableDataSource();
  editMode = false
  reportsUserRights_create:any
  reportsUserRights_update:any
  emptyList:any

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private  settingsService: SettingsService,
    public dialog: NgDialogAnimationService,
    private commentService: CommentsService,
    private route: Router,
    private fb: FormBuilder,
    private reportService:ReportService,
    private translate:TranslateService,
    private localStorageService:LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef
    ){
    
  }

  breakpoint: number;
  paginatorSize = this.settingsService.paginator$
  searchText = ''; 

  ngOnInit(): void {
    this.reportsUserRights_create = this.settingsService.userRolesRights$['reports']['C']
    this.reportsUserRights_update = this.settingsService.userRolesRights$['reports']['U']

    this.reportExport = this.fb.group({
      export:[]
    })

    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    this.loadReports()
  }

  loadTablePreferences(): void {
    const preferences = this.localStorageService.get('reportTablePreferences');
    if (preferences) {
      this.paginator.pageSize = preferences.pageSize;
      this.sort.active = preferences.sortActive;
      this.sort.direction = preferences.sortDirection;
      this.paginator.pageIndex = preferences.pageIndex;
    } else {
      this.sort.active = 'timeStamp';
      this.sort.direction = 'desc';
    }
    this.changeDetectorRef.detectChanges();
  }

  storeTablePreferences(): void {
    const preferences = {
      pageSize: this.paginator.pageSize,
      sortActive: this.sort.active,
      sortDirection: this.sort.direction,
      pageIndex: this.paginator.pageIndex
    };
    this.localStorageService.set('reportTablePreferences', preferences);
  }

  loadReports(){
    try{
      this.commentService.progressSpin.emit(true)
      this.reportsData = this.settingsService.observeReportsFirestore()
      this.dataSource = new MatTableDataSource<any>(this.reportsData);
      this.updateTable()

      setTimeout(() => {
        this.emptyList = this.reportsData.length === 0;
      }, 650);      

     }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
     }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(() => this.storeTablePreferences());
    this.sort.sortChange.subscribe(() => this.storeTablePreferences());

    this.loadTablePreferences();

    setTimeout(() => {
      if (this.reportsData.length != 0) {
        this.dataSource.paginator = this.paginator;
        const preferences = this.localStorageService.get('reportTablePreferences');
        if (preferences) {
          this.paginator.pageIndex = preferences.pageIndex;
          this.paginator._changePageSize(this.paginator.pageSize);
        } else {
          this.dataSource.paginator.firstPage();
        }
        this.commentService.progressSpin.emit(false);
      } else {
        this.commentService.progressSpin.emit(false);
      }

    }, 600);
  }

  updateTable(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      if(this.reportsData.length!=0){
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.firstPage()
        this.commentService.progressSpin.emit(false)
      }else{
        this.commentService.progressSpin.emit(false)
      }

    }, 600);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editReport(element){
    let dialogRef = this.dialog.open(ReportCreateDialogComponent, {
      data: { 
        values:deepCopy(element),
        type: 'edit',
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.loadReports()
      }
    });
  }

  addReport(){
    let dialogRef = this.dialog.open(ReportCreateDialogComponent, {
      data: { 
        values:{},
        type: 'create',
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1])
      this.loadReports()
    });
  }

  deleteThisReport(report) {
    console.log(report)
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:report,
        type:"Report"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.loadReports()
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }


  openDuplicateReportDialog(element){

    let dialogRef = this.dialog.open(DuplicateReportTemplateDialogComponent, {
      data: {
        reportTemplateId: element.id,
        oldTitle: element.displayName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.loadReports()
      }
    });

}

  selectReport(element){

    if(this.editMode){
      this.editReport(element)
    }else{
      if(element.contentTypeId && element.resultFields && element.resultFields.length!=0){
        let firstFilters
        
        if(element.filters)
        firstFilters=deepCopy(element.filters)
        else
        firstFilters=[]

        let newFiltersTemplate = []
        firstFilters.forEach((filter, index) => {
          const key = filter.key ? filter.key : undefined
          const value = filter.value ?  filter.value :  undefined
          const newFilter = {
            key, 
            value
          }

          removeUndefined(newFilter)
          if(newFilter.value)
            newFiltersTemplate.push(newFilter)
        })

        let data = { 
          contentTypeId: element.contentTypeId,
          displayName:element.displayName,
          id: element.id,
          filters: newFiltersTemplate,
          resultFields: element.resultFields,
          fixedFilters: undefined
        }

        if(element.fixedFilters && element.fixedFilters.length!=0){
          data['fixedFilters']= element.fixedFilters
        }

        try{
          const list = this.reportService.reports_createReport(this.settingsService.contextId$, data)
        }catch(error){
          console.log(error)
          const message = this.translate.instant("SnackBarConstants.REPORT_LOAD_FAILED")
          this.commentService.addSnackBar.emit(message)
        }
      }else{
        const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
        this.commentService.addSnackBar.emit(message)
      }
      
      
    }
  }

  exportThisReportTo(element, extension){
    this.reportService.reportExportFile(this.settingsService.contextId$, element,extension )
  }

  exportThisReport(report){
    try{
      this.commentService.progressSpin.emit(true)      
      var zip = new JSZip;
      let nameZIP = 'report_export'
      if(report.displayName){
        nameZIP = report.displayName.replaceAll(" ", '_')
      }
      this.commentService.progressSpin.emit(true)
      removeUndefined(report)
      zip.file("reportData.json", JSON.stringify(report));
      zip.generateAsync({ type: "blob" })
        .then(blob => {
                saveAs(blob, nameZIP+'(v1.1).nvpt')
                this.commentService.progressSpin.emit(false)
              });
    }catch(e){
      const message = this.translate.instant('SnackBarConstants.ZIP_EXPORT_FAILED')
      this.commentService.addSnackBar.emit(message)
      this.commentService.progressSpin.emit(false)
    }
  }

  readURL(event){
    if (event.target.files && event.target.files[0]) {
      this.commentService.progressSpin.emit(true)
      var reader = new FileReader();      
      reader.readAsDataURL(event.target.files[0]);

      JSZip.loadAsync(event.target.files[0]).then((zip) => { // open zip and read files names
        let newReportData
        
        if(zip.files['reportData.json']){
          zip.files['reportData.json'].async('string').then((fileData) => { // reportData.json for report doc data
            newReportData = JSON.parse(fileData)
            this.importReportData(newReportData)
          });
        }else{
          const message = this.translate.instant('SnackBarConstants.ZIP_REPORTS_MISSING_FIELDS')
          this.commentService.addSnackBar.emit(message)
          this.commentService.progressSpin.emit(false)
        }
      });
    }
  }

  async importReportData(newReportData){
    if(newReportData){
      try{
        newReportData['displayName'] += " import_"
        this.commentService.progressSpin.emit(true)
        const newContent = await this.reportService.createReport(newReportData, this.settingsService.contextId$)
        if(newContent)
          this.loadReports()

        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.IMPORT_OK")
        this.commentService.addSnackBar.emit(message)
      } catch(error){
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.IMPORT_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }else{
      const message = this.translate.instant('SnackBarConstants.ZIP_REPORTS_MISSING_FIELDS')
      this.commentService.addSnackBar.emit(message)
      this.commentService.progressSpin.emit(false)
    }
  }
}
