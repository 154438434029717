import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-finished-tasks-map]',
  templateUrl: './finished-tasks-map.component.html',
  styleUrls: ['./finished-tasks-map.component.css']
})
export class FinishedTasksMapComponent implements OnInit {
  
  @Input('app-finished-tasks-map') data:any;
  coordinates:any

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 15;
  mapTypeId: google.maps.MapTypeId.SATELLITE
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];

  ngOnInit(): void {
   if(this.data.longitude && this.data.latitude){
    this.coordinates = {
      lat: this.data.latitude,
      lng: this.data.longitude
     }
   }

   if(this.data._lat && this.data._lng){
    this.coordinates = {
      lat: this.data._lat,
      lng: this.data._long
     }
   }
   this.markerPositions.push(this.coordinates)
   this.center = this.coordinates
  }
}
