import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})
export class WorkTaskTemplateGroupsService {

  constructor(private firestore: AngularFirestore) { }


  async createWorkTaskTemplate(data, contextId,sortIndex):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const workTTRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
      .doc(contextId)
      .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS).doc().ref

      const newId = workTTRef.id

      let group = data
      group['creationDate'] = new Date()
      group['timeStamp'] = new Date()
      group['id'] = newId
      group['sortIndex']=sortIndex

      try{
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(contextId)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS).doc(newId).set({...group})

        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })
  }

  deleteworkTaskTemplateGroup(workTaskTemplateGroupId: any, contextId):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(contextId)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS)
        .doc(workTaskTemplateGroupId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

  updateWorkTaskTemplate(value,groupId,contextId){
    return new Promise(async (resolve, reject) => {
      try {
        value['timeStamp'] = new Date()
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(contextId)
        .collection(NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS)
        .doc(groupId).update(value)

        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}
