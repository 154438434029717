import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UploadService } from '../service/upload.service';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { httpsCallable } from '@angular/fire/functions';
import { FileHandle } from '../directive/drag-and-drop.directive';
import { ActivatedRoute } from '@angular/router';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../backend/utils/object'; 
import { Functions } from "@angular/fire/functions";
import { environment } from 'src/environments/environment';
import { AdvancedExperiencesService } from '../service/advanced-experiences.service';
import { TranslateService } from '@ngx-translate/core';


export function urlPrefixValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const validUrlPattern = /^[a-zA-Z0-9-_]+$/;
      const valid = validUrlPattern.test(control.value);
      return valid ? null : { invalidUrlPrefix: true };
  };
}

@Component({
  selector: 'app-advanced-experiences-dialog',
  templateUrl: './advanced-experiences-dialog.component.html',
  styleUrls: ['./advanced-experiences-dialog.component.css']
})

export class AdvancedExperiencesDialogComponent {
  advancedForm!:FormGroup
  files: FileHandle[] = []
  drop=false
  url:any
  allImages:any=[]
  newImage:any=[]
  changes=false
  advancedData:any
  routerUrl:any
  idToShow:any
  prefix = false
  existingUrlPrefixes:any=[]
  public isUrlPrefixValid = true;
  existingCoverImages:any
  alreadyPrefix=false

  constructor(public dialogRef: MatDialogRef<AdvancedExperiencesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private cdRef: ChangeDetectorRef,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private router: ActivatedRoute,
    private functions: Functions,
    private advancedService:AdvancedExperiencesService,
    private translateService:TranslateService
   
  ){
    dialogRef.disableClose = true;
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path 
  }


  ngOnInit():void{
    this.advancedData = this.data.values

    this.advancedForm = this.fb.group({
      contextId:[ , Validators.required],
      urlPrefix: ['', [Validators.required, urlPrefixValidator()]],
      title:[,Validators.required],
      subtitle:[, Validators.required],
      appStoreId:[],
      appStoreImageURL:[],
      url:[,Validators.required],
      contentTypeId: [, Validators.required]
    })

    const {contextId, contentTypeId} = this.advancedData

    this.advancedForm.patchValue({
      contextId :  contextId ? contextId : this.settingsService.contextId$,
      contentTypeId: contentTypeId ? contentTypeId : this.idToShow
    })

    this.advancedForm.get('urlPrefix')?.valueChanges.subscribe(value => {
      this.isUrlPrefixValid = this.validateUrlPrefix(value);
  
      if (value) {
        const allprefix = environment.tagHostName + value;
        // Check for existing prefixes
        if (this.existingCoverImages) {
          this.alreadyPrefix = this.existingUrlPrefixes.includes(allprefix);
        } else {
          this.getServiceUrlPrefixData();
        }
      } else {
        // Reset the alreadyPrefix check if input is empty
        this.alreadyPrefix = false;
      }
  
      this.cdRef.detectChanges();
    });

    this.advancedForm.get('urlPrefix').valueChanges.subscribe( value => {
      if(value){
        this.prefix = true
        const allprefix = environment.tagHostName + value
        if(this.existingCoverImages){
          if(this.existingUrlPrefixes.includes(allprefix)){
            this.alreadyPrefix=true
            this.commentService.addSnackBar.emit('this urlPrefix already exists')
          }else
            this.alreadyPrefix=false
        }else
          this.getServiceUrlPrefixData()
      }
    })

    if(this.advancedForm.value.urlPrefix){
      this.prefix = true
    }

    if(this.alreadyPrefix){
      this.commentService.addSnackBar.emit('this urlPrefix already exists')
    }

    if(this.advancedService.existingCoverImages){
      if(this.advancedService.existingCoverImages.length!=0){
        this.existingCoverImages = this.advancedService.existingCoverImages

        this.existingCoverImages.forEach(element => {
          this.existingUrlPrefixes.push(element.link)
        });
      }
        
    }else{
      this.advancedService.getActualUrlPrefixes()
      this.existingCoverImages = this.advancedService.existingCoverImages
    }

  }

  validateUrlPrefix(value: string): boolean {
    const pattern = /^[a-zA-Z0-9-_]+$/;
    return pattern.test(value);
  }

  getServiceUrlPrefixData(){
    this.existingCoverImages = this.advancedService.existingCoverImages
    this.existingCoverImages.forEach(element => {
      this.existingUrlPrefixes.push(element.link)
    });
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.newImage = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]); 

      const type = this.newImage.type
     
      if(type=="image/jpeg" || type=="image/png"){
        reader.onload = async (event) => {
          if(event.target){
            const urlNew = event.target.result;
            
            if(urlNew){
              this.drop=false
              this.url=urlNew
              this.changes=true
              this.advancedForm.patchValue({
                url: this.url
              })
              const urlprefix = deepCopy(this.advancedForm.value.urlPrefix)
              this.advancedForm.controls['urlPrefix'].disable()

              try{
                const size = this.uploadService.calculateImageSizeCoverImage(this.newImage, urlprefix, this.url)
              }catch(error){
                console.log(error)
                const message = this.translateService.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
                this.commentService.addSnackBar.emit(message)
              }
            }  
          }
        }
      }else{
        const message = this.translateService.instant("SnackBarConstants.IMG_TYPE")
        this.commentService.addSnackBar.emit(message)
      }
    }
  }

  deleteImg(url){
    this.changes=true
    this.url=""
    this.advancedForm.patchValue({url: this.url})
    this.advancedForm.controls['urlPrefix'].enable()
  }


  loadExistingCoverImages(){
    if(this.advancedService.existingCoverImages){
      if(this.advancedService.existingCoverImages.length!=0){
        this.existingCoverImages = this.advancedService.existingCoverImages

        this.existingCoverImages.forEach(element => {
          this.existingUrlPrefixes.push(element.link)
        });
      }
        
    }else{
      this.advancedService.getActualUrlPrefixes()
      this.existingCoverImages = this.advancedService.existingCoverImages
    }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file

    this.newImage = fileToUpload

    const type = this.newImage.type

    if(type=="image/jpeg" || type=="image/png"){
      const newUrl = files[0].url
      if(newUrl){
        this.drop=true
        this.url = newUrl
        this.changes=true

        this.advancedForm.patchValue({url: this.url})
        const urlprefix = deepCopy(this.advancedForm.value.urlPrefix)
        this.advancedForm.controls['urlPrefix'].disable()

        try{
          const size = this.uploadService.calculateImageSizeCoverImage(this.newImage,urlprefix, this.url, true)
        }catch(error){
          console.log(error)
          const message = this.translateService.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
          this.commentService.addSnackBar.emit(message)
        }
      }
    }else{
      const message = this.translateService.instant("SnackBarConstants.IMG_TYPE")
      this.commentService.addSnackBar.emit(message)
    }
    
  }


  dialogCancel(){
    const images = this.uploadService.getImagesUploaded();

    this.dialogRef.close([false]);
  }

  async dialogSave(){

   const invalidUrlPrefix = !this.isUrlPrefixValid;

   if (this.advancedForm.invalid || invalidUrlPrefix || this.alreadyPrefix) {
       const message = this.translateService.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY");
       this.commentService.addSnackBar.emit(message);
       return;
   }

   else {
      const size = this.uploadService.getCoverImageSize()
      const url = this.uploadService.getCoverImage()
      this.advancedForm.patchValue(url)
      const fileName  = this.uploadService.getCoverImageFileName()

      const widthImgs = size[0]
      const heightImgs = size[1]
      this.advancedForm.controls['urlPrefix'].enable()

      if(this.advancedForm.value.title && this.advancedForm.value.subtitle && this.advancedForm.value.urlPrefix ){
        if(fileName){
          if(this.alreadyPrefix){
            const message = this.translateService.instant("SnackBarConstants.PREFIX_EXISTS")
            this.commentService.addSnackBar.emit(message)
          }else{
            if( this.prefix ){
              this.commentService.progressSpin.emit(true)
      
              const { urlPrefix, title, subtitle, contextId, contentTypeId} = this.advancedForm.value
              const data = {
                urlPrefix: urlPrefix,
                fileName: fileName,
                title: title,
                subtitle: subtitle ? subtitle : undefined,
                contextId: contextId,
                contentTypeId: contentTypeId
              }
              
              removeUndefinedValuesFromObject(data)

              const createExperience = httpsCallable(this.functions, 'appStoreConnect-createExperience');
      
              createExperience(data ).then((result) => {
                  this.advancedService.getActualUrlPrefixes()

                  const data = result.data;
                  const sanitizedMessage = data;
                  this.commentService.progressSpin.emit(false)
                  const id = result.data['id']
                  const advancedExperiencesData = this.settingsService.observeAdvancedExperiencesFirestore()
                  const message = this.translateService.instant("SnackBarConstants.CREATE_OK")
                  this.commentService.addSnackBar.emit(message)
                  this.dialogRef.close([true,id, urlPrefix]);
                })
                .catch((error) => {
      
                  this.commentService.progressSpin.emit(false)
                  console.log(error)
                  const message = this.translateService.instant("SnackBarConstants.CREATE_FAILED")
                  this.commentService.addSnackBar.emit(message)
                  this.commentService.addSnackBar.emit(error)
                });
            }else{
              const message = this.translateService.instant("SnackBarConstants.PREFIX_REQUIRED")
              this.commentService.addSnackBar.emit(message)
            }
          }
        }else{
          const message = this.translateService.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
        this.commentService.addSnackBar.emit(message)
        }
      }else{
        const message = this.translateService.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
        this.commentService.addSnackBar.emit(message)
      }
      
    }

  }


  getActualUrlPrefixes(){
    this.commentService.progressSpin.emit(true)
    const createExperience = httpsCallable(this.functions, 'appStoreConnect-requestExperiences');
        createExperience( ).then((result) => {
          
            this.existingCoverImages = result.data['data']
            const data = result.data;
            const sanitizedMessage = data;

            result.data['data'].forEach(element => {
              this.existingUrlPrefixes.push(element.link)
            });

            this.commentService.progressSpin.emit(false)
            
          })
          .catch((error) => {

            this.commentService.progressSpin.emit(false)
            console.log(error)
            const message = this.translateService.instant("SnackBarConstants.CREATE_FAILED")
            this.commentService.addSnackBar.emit(message)
            this.commentService.addSnackBar.emit(error)
          });
  }
}
