import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { MapMarkerEditComponent } from 'src/app/map-marker-edit/map-marker-edit.component';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy, openMapDirection, removeUndefined, supportUrlStructure } from '../../../../../../backend/utils/object';
import { AnnotatedMapAnnotationDialogComponent } from '../../annotated-map-annotation-dialog/annotated-map-annotation-dialog.component';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { AnnotatedMapAnnotationDynamicDialogComponent } from '../../annotated-map-annotation-dynamic-dialog/annotated-map-annotation-dynamic-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { TranslationsDPPService } from 'src/app/service/translations-dpp.service';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: 'app-annotated-map-edit',
  templateUrl: './annotated-map-edit.component.html',
  styleUrls: ['./annotated-map-edit.component.css']
})

export class AnnotatedMapEditComponent implements OnInit{
  annotatedMapEditData:any
  oldData:any
  addList:any
  annotatedMapEditForm!:FormGroup
  newAnnotations!:FormGroup
  allAnnotationsMap:any = []
  allAnnotationsDynamicMap:any = []
  changeAnnotations=false
  translationForm!:FormGroup
  passTranslations:any
  passLanguagesList:any

  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any={}
  pairsPath:any ={}
  polylineOptions = {
    path: [],
    strokeColor: '#adff2f',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  };
  a: google.maps.Polyline

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#ff0000",
    fillOpacity: 0.3,
    clickable: true
    // draggable: true,
    // geodesic: true,
  }
  oldTranslations:any
  passId:any
  widgetNumber:any
  linkURL:any = {}
  map:google.maps.Map
  mapId:any
  prev_infowindow:any
  languagesList = languagesList
  defaultLanguage:string

  constructor(public dialogRef: MatDialogRef<AnnotatedMapEditComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private commentService: CommentsService,
              private translate: TranslateService, 
              private settingsService: SettingsService,
              private dpptranslations: TranslationsDPPService){
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.defaultLanguage = this.data.defaultLanguage
    this.annotatedMapEditData=this.data.values
    this.oldData=this.data.values
    if(this.data['translations']){
      this.passTranslations = this.data['translations']
      this.oldTranslations = deepCopy(this.data['translations'])
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }

    this.passId = this.data['passId']
    this.widgetNumber= this.data['widget']
    this.passLanguagesList = this.data['languages']

    this.annotatedMapEditForm = this.fb.group({
      id:[],
      type:[],
      style:[],
      layout:[],
      title:[],
      subtitle:[],
      annotationGroup:[],
      annotations:[],
      name:[],
      filter:[]
    })
    const {id, filter, type, style, layout, title, subtitle, annotationGroup, annotations,name} = this.annotatedMapEditData

    this.annotatedMapEditForm.patchValue({
      id:id ? id : undefined,
      type:type ? type : undefined,
      style: style ? style : undefined,
      layout:layout ? layout : undefined,
      title:title ? title :undefined,
      subtitle:subtitle ? subtitle : undefined,
      annotationGroup:annotationGroup ? annotationGroup : undefined,
      annotations:annotations ? annotations : undefined,
      name:name ? name : undefined,
      filter: filter ? filter : undefined
    })

    if(this.annotatedMapEditData.annotations){
      this.annotatedMapEditData.annotations.forEach((e:any)=>{
        this.allAnnotationsMap.push(e)
        if(e.linkURL)
          this.supportLinkUrl(e.linkURL, e.id)
      })
    }else{
      if(this.annotatedMapEditData[0].annotations){
        this.annotatedMapEditData[0].annotations.forEach((e:any)=>{
          this.allAnnotationsMap.push(e)
        })
      }
    }
    
    this.newAnnotations = this.fb.group({
      name:[],
      lat:[],
      lng:[]
    })

    this.annotatedMapEditForm.valueChanges.subscribe( (value:any) => {
    })
    this.annotatedMapEditForm.get('annotations').valueChanges.subscribe( (value:any) => {
      // this.loadMap(this.annotatedMapEditData.id)
      this.loadNewMarkersOnMap()
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })
    this.loadMarkers()

    setTimeout(() => {
      this.loadMap(id)
    }, 500);
  }

  loadMap(i){
    try{
      var mapProp= {
        center:new google.maps.LatLng(51.508742,-0.120850),
        zoom:5,
      };
      const id = "map-edit-dialog-"+i
      this.map = new google.maps.Map(document.getElementById(id));
      this.mapId = id
      this.initMap(i)
    }catch(e){
      console.log(e)
    }
  }

  async initMap(i) {
    const id = 'map-edit-dialog-'+i
    this.mapId = id
    // // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let mapWidgetId = this.annotatedMapEditData.id

    let mapTypeId="roadmap" 
    // if(this.annotatedMapEditData && this.annotatedMapEditData.style && this.annotatedMapEditData.style=='satellite'){
    //     mapTypeId = this.annotatedMapEditData.style   
    // } 
    this.map = new Map(document.getElementById(id), {
        zoom: 4,
        center: position,
        mapId: "dev",
        mapTypeId: mapTypeId,
        disableDefaultUI: true
    });

    this.map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      // infoWindow.close();
      // console.log(mapsMouseEvent.latLng)
      this.addMarker(mapsMouseEvent)
    });

    const directionTranslation = this.translate.instant('pass.map.open_direction')

    const annotations = deepCopy(this.annotatedMapEditForm.value.annotations)
    if(annotations){//(this.annotatedMapEditData && this.annotatedMapEditData.annotations){
      for(let t=0; t<annotations.length; t++){
        const annotation = annotations[t]
        const annotationId = annotation.id
        const index = t

        if(annotation.type){
          if(annotation.type=="point"){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
          
            if(pos.name)
              pos['title']=pos.name
            
            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
            contentString +=' </div> '
          
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg

            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
            // const marker = new AdvancedMarkerElement({
            //   map: this.map,
            //   position: pos,
            //   title:"annotation-"+index,
            //   content:pin.element
            // });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }

          if(annotation.type=="polygon"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
              this.actualPolygonOptions[mapWidgetId][index].paths = pairs

              if(annotation.fillColor)
                this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                this.zoom = 9
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }
              const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
              polygon.setMap(this.map);
            }
          }

          if(annotation.type=="line"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
              this.actualPolygonOptions[mapWidgetId][index].path = pairs

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth
                
              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                this.zoom = 11
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }

              const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
              line.setMap(this.map);
            }
          }

        }else{
            if(annotation.lat || annotation.lng || annotation.coordinate){
              let pos = annotation
          if(annotation.coordinate){
            pos['lat']=annotation.coordinate[0]
            pos['lng']=annotation.coordinate[1]
          }

          if(pos['lat'] || pos['lng']){
            if(!annotation.badge)
            pos['badge']=(index +1).toString()
          
          if(pos.name)
            pos['title']=pos.name
          
          let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
          let contentString = "this is an example"
          contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                  contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'
            
              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
            contentString +=' </div> '    
            
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg
            let pinData = {glyph: pos.badge}
          
            pinData['background']=pinColor
            pinData['borderColor'] = annotation.pinForegroundColor
            pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
            }
          }
        } 
      } 
    }
  }

  async loadNewMarkersOnMap(){
    const directionTranslation = this.translate.instant('pass.map.open_direction')
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let mapWidgetId = this.annotatedMapEditData.id
    let mapTypeId="roadmap" 
    this.map = this.map.data.getMap()
    const div = this.map.getDiv().innerHTML=''

    this.map = new Map(this.map.getDiv(), {
      zoom: 4,
      center: position,
      mapId: "dev",
      mapTypeId: mapTypeId,
      disableDefaultUI: true
    });

    this.map.addListener("click", (mapsMouseEvent) => {
      this.addMarker(mapsMouseEvent)
    });
  
    const annotations = deepCopy(this.annotatedMapEditForm.value.annotations)  
    if(annotations){//(this.annotatedMapEditData && this.annotatedMapEditData.annotations){
      for(let t=0; t<annotations.length; t++){
        const annotation = annotations[t]
        const annotationId = annotation.id
        const index = t

        if(annotation.type){
          if(annotation.type=="point"){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }

            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name

            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
                const directions = this.openMapDirection(annotation)
                const directionTranslation = this.translate.instant('pass.map.open_direction')
                contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'

              }
            contentString +=' </div> ' 
          
            let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg

            let pinData = {glyph: pos.badge}
          
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });

            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });

            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }

          if(annotation.type=="polygon"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")

              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
              this.actualPolygonOptions[mapWidgetId][index].paths = pairs

              if(annotation.fillColor)
                this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
                this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
                this.zoom = 9
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }
              const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
              polygon.setMap(this.map);
            }
          }

          if(annotation.type=="line"){
            if(annotation.encodedContent){
              const encodedContent = annotation['encodedContent'].split(",")
              let pairs
                  pairs = encodedContent.reduce(function(result, value, indexV, array) {
                  if (indexV % 2 === 0){
                    const splited = array.slice(indexV, indexV + 2)
                    var lat: number = +splited[0];
                    var lng: number = +splited[1];
                    const value = { lat: lat, lng: lng }
                    result.push(value);
                  }
                  return result;
                }, []);

              this.pairsPath[annotationId] = pairs
              this.actualPolygonOptions[mapWidgetId]={}
              this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
              this.actualPolygonOptions[mapWidgetId][index].path = pairs

              if(annotation.strokeColor)
                this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

              if(annotation.lineWidth)
                this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth
              
              if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
                this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
                this.zoom = 11
                const newcenter = new google.maps.LatLng(this.center)
                this.map.setCenter(newcenter);
                this.map.setZoom(this.zoom)
              }

              const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
              line.setMap(this.map);
            }
          }

        }else{
          if(annotation.lat || annotation.lng){
            let pos = annotation
            if(annotation.coordinate){
              pos['lat']=annotation.coordinate[0]
              pos['lng']=annotation.coordinate[1]
            }
  
            if(!annotation.badge)
              pos['badge']=(index +1).toString()
            
            if(pos.name)
              pos['title']=pos.name
  
            let linkURL = supportUrlStructure(pos.linkURL, this.passId,this.settingsService.contextId$ )
            let contentString = "this is an example"
            contentString ='<div class="d-flex flex-column">'
            if(pos.title)
              contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
            if(pos.subtitle)
              contentString +='<span>'+pos.subtitle+' </span>'
            if(pos.linkURL){
              contentString +='<a href="'+linkURL+'" target="_blank" style="color:var(--warmOrange);">'
                contentString +='<span class="material-symbols-outlined">link</span>'
              contentString +='</a>'
            }
            if(!pos.title && !pos.linkURL && !pos.subtitle)
              contentString +='<span>...</span>'

              if(pos['directions']){
              const directions = this.openMapDirection(annotation)
              const directionTranslation = this.translate.instant('pass.map.open_direction')
              contentString += '<a href="'+ directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
              }
  
            contentString +=' </div> ' 
            
            let iconImg = document.createElement('div');  
            let pinColor = '#FF5E38'
  
            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor
  
            let textColor = 'black'
  
            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor
  
            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`
  
            iconImg.innerHTML = svg
  
            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor
  
            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: index+'',
            });
  
            imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
  
            this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
          }
        } 
      } 
    }
  }

  addListAnnotation(){
    this.addList=true
  }

  onSaveEdit(){
    try{
      this.commentService.progressSpin.emit(true)
      this.dialogRef.close([this.annotatedMapEditForm.value,true, ,this.translationForm.value.translations]);
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
    }
  }

  addAnnotation(){
    const badge = this.annotatedMapEditForm.value.annotations.length + 1
    const lat: number = +this.newAnnotations.value.lat
    const lng: number = +this.newAnnotations.value.lng
    const a={
      name:this.newAnnotations.value.name,
      lat:lat,
      lng:lng,
      coordinate: [lat, lng],
      badge: badge,
      pinColor:"#ff0000",
      type:"point"
    }
    this.allAnnotationsMap.push(a)
    this.annotatedMapEditForm.patchValue({
      annotations:this.allAnnotationsMap
    })
    this.addList=false
    this.loadMap(this.annotatedMapEditForm.value.id)
  }

  deleteAnnotation(x:any){
    this.allAnnotationsMap.splice(x,1)
    this.annotatedMapEditForm.patchValue({
      annotations:this.allAnnotationsMap
    })
    this.markerPositions.splice(x,1)
    this.changeAnnotations=true
  }
  onLanguageEditor(){
    let alltranslationsWidget = {}
    let translationsWidget

    if(this.translationForm.value.translations){
      translationsWidget = Object.keys(this.translationForm.value.translations) 

    }else{
      translationsWidget = []
    }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    // if(this.translationForm.value.translations){
      // translationsWidget = Object.keys(this.translationForm.value.translations) 
    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.annotatedMapEditForm.value.id+'.title') || ent[0].includes(this.annotatedMapEditForm.value.id+'.subtitle')){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
      })
    }
    // else{
    //   translationsWidget = []
    // }

    let columns = ['title', 'subtitle']
    let dialogRef = this.dialog.open(LanguageEditorDialogComponent, {
      data:{
        translations: this.translationForm.value.translations,
        fields: this.annotatedMapEditData,
        columns: columns,
        values: this.annotatedMapEditForm.value,
        type: "annotatedMap",
        valuesTranslated: alltranslationsWidget,
        languages: translationsWidget
      },
    });
    let newtranslations 

    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }else{
      newtranslations = {}
    }

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.oldTranslations[lang] && this.oldTranslations[lang][tranlationField])
                newtranslations[lang][tranlationField] = ''

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
    })
  }

  openAnnotationDialog(annotation, index?){
    if(!annotation.dynamic){
      let alltranslationsWidget = {}
    let translationsWidget = []

    if(this.translationForm.value.translations){
      translationsWidget = Object.keys(this.translationForm.value.translations) 
    }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    // if(this.translationForm.value.translations){
      // translationsWidget = Object.keys(this.translationForm.value.translations) 
    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.annotatedMapEditForm.value.id+'_'+annotation.id) ){
                // if(ent[0].includes(this.annotatedMapEditForm.value.id) && ent[0].includes(annotation.id)){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
      })
    }
    // else{
    //   translationsWidget = []
    // }

    let fieldsToTranslate = ['title', 'subtitle', 'linkURL'] 

    let columns = []

    if(annotation.id){
      fieldsToTranslate.forEach( field => {
        columns.push('_' + annotation.id + '.' + field)
      })
    }

    let dialogRef = this.dialog.open(AnnotatedMapAnnotationDialogComponent, {
      data:{
        values: annotation,
        index: index,
        encodedContent: this.actualPolygonOptions[index],
        translations: this.translationForm.value.translations,
        fields: this.annotatedMapEditData.annotations[index],
        columns: columns,
        valuesTranslated: alltranslationsWidget,
        languages: this.passLanguagesList,
        annotationMapData:this.annotatedMapEditForm.value,
        passId : this.passId,
        widgetNumber: this.widgetNumber,
      },
    });
    

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.allAnnotationsMap[index]=result[1]
        this.markerPositions[index]=result[1]
        this.getPolygon(this.markerPositions[index], index)
        this.annotatedMapEditForm.patchValue({
          annotations:this.allAnnotationsMap
        })
        this.supportLinkUrl(this.allAnnotationsMap[index].linkURL, this.allAnnotationsMap[index].id)
      }
    })
    }else{
      this.openDynamicAnnotationDialog(annotation, index)
    }
  }

  openDynamicAnnotationDialog(annotation, index?){
    let alltranslationsWidget = {}
    let translationsWidget = []
    let columns = []

    let dialogRef = this.dialog.open(AnnotatedMapAnnotationDynamicDialogComponent, {
      data:{
        values: annotation,
        index: index,
        encodedContent: this.actualPolygonOptions[index],
        translations: this.translationForm.value.translations,
        fields: this.annotatedMapEditData.annotations[index],
        columns: columns,
        valuesTranslated: alltranslationsWidget,
        languages: this.passLanguagesList,
        annotationMapData:this.annotatedMapEditForm.value,
        passId : this.passId,
        widgetNumber: this.widgetNumber,
      },
    });
    

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.allAnnotationsMap[index]=result[1]
        this.allAnnotationsDynamicMap[index]=result[1]
        this.markerPositions[index]=result[1]
        this.annotatedMapEditForm.patchValue({
          annotations:this.allAnnotationsMap
        })
      }
    })
  }

  addAnnotationDialog(){
    let alltranslationsWidget = {}
    let translationsWidget = []

    if(this.translationForm.value.translations){
      translationsWidget = Object.keys(this.translationForm.value.translations) 
    }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    let fieldsToTranslate = ['title', 'subtitle', 'linkURL'] 

    let columns = []
    const annotation = {
      id: this.makeid(6)
    }

    if(annotation.id){
      fieldsToTranslate.forEach( field => {
        columns.push('_' + annotation.id + '.' + field)
      })
    }

    const index: number = this.markerPositions.length
    let dialogRef = this.dialog.open(AnnotatedMapAnnotationDialogComponent, {
      data:{
        id: annotation.id,
        type:'static',
        values:'',
        index: (this.markerPositions.length+1).toString(),
        annotationMapData:this.annotatedMapEditForm.value,
        translations: this.translationForm.value.translations,
        fields: this.annotatedMapEditData.annotations[index],
        columns: columns,
        valuesTranslated: alltranslationsWidget,
        languages: this.passLanguagesList,
      },
    });
    

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        if(!result[1].id)
        result[1]['id']=this.makeid(6)

        this.allAnnotationsMap.push(result[1])
        this.markerPositions.push(result[1])
        this.annotatedMapEditForm.patchValue({
          annotations:this.allAnnotationsMap
        })
        this.supportLinkUrl(result[1].linkURL, result[1].id)
       
      }
    })
  }

  addDynamicAnnotation(){
    const index: number = this.markerPositions.length
    let dialogRef = this.dialog.open(AnnotatedMapAnnotationDynamicDialogComponent, {
      data:{
        type:'dynamic',
        values:'',
        index: (this.markerPositions.length+1).toString(),
        annotationMapData:this.annotatedMapEditForm.value 
      },
    });
    

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        if(!result[1].id)
        result[1]['id']=this.makeid(6)

        this.allAnnotationsDynamicMap.push(result[1])
        this.allAnnotationsMap.push(result[1])
        this.markerPositions.push(result[1])
        this.annotatedMapEditForm.patchValue({
          annotations:this.allAnnotationsMap
        })
        // this.supportLinkUrl(result[1].linkURL, result[1].id)
       
      }
    })
  }

  updateAllMarkersList(newone){
    this.allAnnotationsMap.push(newone)
    this.annotatedMapEditForm.patchValue({
      annotations:this.allAnnotationsMap
    })
  }

  addMarker(event: google.maps.MapMouseEvent) {
    const badge = this.annotatedMapEditForm.value.annotations.length + 1
    const newones = event.latLng.toJSON()
    const coordinates = []
    coordinates.push(newones.lat)
    coordinates.push(newones.lng)
    this.center=newones

    newones['coordinate'] = coordinates
    newones['badge']= badge.toString()
    newones['pinBackgroundColor']="#FF5E38"
    newones['pinForegroundColor']="#000000"
    newones['type']="point"
    newones['id']=this.makeid(6)

    if(event.latLng != null){
        this.markerPositions.push(newones);
    }
    this.updateAllMarkersList(newones)
  }

  loadMarkers(){
    if(this.annotatedMapEditForm.value.annotations){
      this.annotatedMapEditForm.value.annotations.forEach((element:any, index) => {
        if(!element.dynamic){
          if(element.coordinate){
            const lats = element
            lats['lat'] = parseFloat(element.coordinate[0])
            lats['lng'] = parseFloat(element.coordinate[1])
  
            if(element.name){
              lats['title']=element.name
            }
            this.markerPositions.push(lats);
            this.center=lats
          }else{
            const lats = element
            const lat = parseFloat(element.lat)
            const lng = parseFloat(element.lng)
            lats['coordinate'] = [lat, lng]
              
            if(element.name){
              lats['title']=element.name
            }
            this.markerPositions.push(lats);
            this.center=lats
          }
  
          if(element.linkURL)
            this.supportLinkUrl(element.linkURL, element.id)
  
          if(element.encodedContent){
            this.getPolygon(element, index)
          }
        }else{
          this.markerPositions.push(element);
          this.allAnnotationsDynamicMap.push(element)
        }
      });

    }
  }

  readURL(event:any){
    // if (event.target.files && event.target.files[0]) {
    //   var reader = new FileReader();
    //   const imported = event.target.files[0]
    //   reader.readAsText(imported, "UTF-8");

    //   reader.onload = async (event) => { // called once readAsDataURL is completed
    //     if(event.target){
    //       const url = event.target.result;
    //       if(url){
    //         const jsonObj=(JSON.parse(reader.result.toString()));
    //         const geojson = (jsonObj as GeoJsonObject)
    //         if(geojson.type){
    //           if(geojson.type=="Feature"){
    //             const coordinates =  (jsonObj as GeoJSON.Feature<GeoJSON.Polygon>).geometry
    //           }

    //           if(geojson.type=="FeatureCollection"){
    //             (geojson as GeoJSON.FeatureCollection<GeoJSON.Polygon>).features.forEach(
    //               feature => {
    //                 // area += this.getArea(feature.geometry);
    //               }
    //             );
    //           }
    //         }
    //       } 
    //     }
    //   }
    // }
  }

  getPolygon(markerPosition, index){
    let pairs
    if(markerPosition['encodedContent']){
      const encodedContent = markerPosition['encodedContent'].split(",")
        pairs = encodedContent.reduce(function(result, value, index, array) {
        if (index % 2 === 0){
          const splited = array.slice(index, index + 2)
          var lat: number = +splited[0];
          var lng: number = +splited[1];
          const value = { lat: lat, lng: lng }
          result.push(value);
        }
          
        return result;
      }, []);

      this.pairsPath[index] = pairs
      if(markerPosition.type && markerPosition.type == "line"){
        this.actualPolygonOptions[index] = deepCopy(this.polylineOptions)
        this.actualPolygonOptions[index].path = pairs


        if(markerPosition.strokeColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor
        }

        // if(markerPosition.fillColor){
        //   this.actualPolygonOptions[index].strokeColor = markerPosition.fillColor
        // }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].path && this.actualPolygonOptions[index].path[0]){
          this.center=this.actualPolygonOptions[index].path[0]
          this.zoom = 9
        }
      }

      if(markerPosition.type && markerPosition.type == "polygon"){
        this.actualPolygonOptions[index] = deepCopy(this.polygonOptions)
        this.actualPolygonOptions[index].paths = pairs

        if(markerPosition.strokeColor){
          this.actualPolygonOptions[index].strokeColor = markerPosition.strokeColor
        }

        if(markerPosition.fillColor){
          this.actualPolygonOptions[index].fillColor = markerPosition.fillColor
        }

        if(markerPosition.lineWidth){
          this.actualPolygonOptions[index].strokeWeight = markerPosition.lineWidth
        }

        if(this.actualPolygonOptions[index] && this.actualPolygonOptions[index].paths && this.actualPolygonOptions[index].paths[0]){
          this.center=this.actualPolygonOptions[index].paths[0]
          this.zoom = 9
        }
      }      
    }
    return pairs
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
      this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  supportLinkUrl(linkurl, id){
    this.linkURL[id] = linkurl
    if(linkurl){
      try{
      const imgUrl = new URL(linkurl)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.linkURL[id]=firebaseUrl
        }else{
          const newparts = linkurl.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.linkURL[id]=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.linkURL[id]=firebaseUrl
      }

      if(imgUrl.protocol == "context:"){
        const parts = linkurl.match(/context:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
                path += "/" + parte
            }
        })
        const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
        this.linkURL[id]=firebaseUrl
      }
      }catch(erro){
      }
    }
  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }


  returnChangesFilters($event){
    console.log($event)
    this.annotatedMapEditForm.patchValue({filter: $event.newFilters})
  }
}
