import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from '../../../../backend/utils/object';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css']
})
export class SettingsDialogComponent implements OnInit {
  @Input('app-settings-dialog') data:any
  settingsData={
    contextIds:[],
    contextIdsNames:[],
    account:"",
    user:""
  }
  settingsForm!:FormGroup
  selected:any
  newIdUser:string
  oldAccount:any
  actualRoute:string
  title:string


  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any[],
    private authService: AuthService,
    private router: Router,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private translate:TranslateService
    ){

      dialogRef.disableClose = true;
    }

  async ngOnInit(): Promise<void> {
    this.commentService.progressSpin.emit(true)
    if(this.dialogData['title']){
      this.title = this.dialogData['title']
    }else{
      this.title = this.translate.instant('settings')
    }
    this.actualRoute = this.router.url

   if(this.settingsService.contextAccount$){
    this.selected=this.settingsService.contextAccount$.displayName

   }else{
    if(this.settingsService.userAccount$ && this.settingsService.userAccount$.displayName)
      this.selected=this.settingsService.userAccount$.displayName

   }
    this.oldAccount=this.settingsService.contextAccount$
    this.newIdUser=this.settingsService.currentUser$.uid

    this.settingsData.account= this.selected
    if(this.settingsService.currentUser$ && this.settingsService.currentUser$.displayName)
    this.settingsData.user= this.settingsService.currentUser$.displayName

    this.settingsForm = this.fb.group({
      id:[this.selected]
    })

    this.settingsData.contextIds=this.settingsService.contextIds$
    if(this.settingsData.contextIds && this.settingsData.contextIds.length>1){
        
      try{
        const allc = await this.settingsService.observeContextDataOfContexts(this.settingsData.contextIds)
        this.settingsData.contextIdsNames=this.settingsService.contextIdFields$.sort()

        setTimeout(() => {
          this.settingsData.contextIdsNames=this.settingsService.contextIdFields$.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
          this.commentService.progressSpin.emit(false)
        }, 300);

      }catch(error){}
    }else{

      try{
        const all = await this.settingsService.observeContextIdFields(this.settingsService.contextId$)
        this.settingsData.contextIdsNames=this.settingsService.contextIdFields$.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        this.commentService.progressSpin.emit(false)
      }catch(error){}
    }
  }

/*   async signOutUser(){
    try{
      this.dialogRef.close()
      this.settingsService.resetLogout()
      await this.authService.signOut()
      this.settingsData.contextIds=[],
      this.settingsData.contextIdsNames=[],
      this.settingsData.account="",
      this.settingsData.user=""    
      setTimeout(async () => {
        this.router.navigate(['/logIn'])
      }, 300)
    }catch(error){
      console.log(error)
    }

  } */

  onSaveSettings(){
    this.commentService.progressSpin.emit(true)
    this.newIdUser = this.settingsService.SortedcontextIdFields$[this.settingsForm.value.id]
    const a= [this.newIdUser,this.settingsForm.value]

    this.dialogRef.close([a,true])
    if(this.dialogData['title'] == 'Select Account'){
      const route = '/home/' + this.newIdUser
      this.router.navigateByUrl(route)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.ACCOUNT_CHANGE")
      this.commentService.addSnackBar.emit(message)

    }else{
      setTimeout(() => {
      const newRoute = '/home/' + this.newIdUser
      this.router.navigate([newRoute])
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.ACCOUNT_CHANGE")
      this.commentService.addSnackBar.emit(message)
      }, 500);
    }
  }

  onCancelSettings(){
    this.newIdUser = this.settingsService.contextId$
    const a= [this.newIdUser,this.settingsData.account]
    this.dialogRef.close([a,false])
  }
}
