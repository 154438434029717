<section class="container">
    <div class="row mt-4">
        <div class="col-12">
            <section class="addNavbar mb-1">
                <button mat-button class="m-1" style="color:var(--warmOrange);" (click)="backTo();"> 
                    <mat-icon>arrow_back_ios_new</mat-icon> {{'goBack' | translate}}
                </button>
                <span class="fs-5">{{displayName}}</span>
                <div class="example-sidenav-content">
                    <button mat-button class="m-1 secondary-gray-button" (click)="drawer.toggle()" [disabled]="reportTemplate.filters.length == 0"> 
                        <mat-icon>tune</mat-icon>{{'filters.filter_settings' | translate}}
                    </button>

                    <button mat-button class="m-1 secondary-gray-button"  [matMenuTriggerFor]="exportReport">
                        <mat-icon>download</mat-icon>
                        {{'reports.export_report' | translate}}
                    </button>

                    <mat-menu  #exportReport="matMenu">
                        <button mat-menu-item (click)="exportThisReportTo('csv');">{{'reports.csv' | translate}}</button>
                        <button mat-menu-item (click)="exportThisReportTo('xls');">{{'reports.xls' | translate}}</button>
                    </mat-menu>
                </div>  
            </section>
            <app-reports-item-tridys-list-filters *ngIf="reportTemplate.filters.length > 0"></app-reports-item-tridys-list-filters>
            <div *ngIf="reportsList.length === 0">   
                <div class="container">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-around align-items-center">
                            <p class="text-center mt-5">{{'tridys.empty_title' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="reportsList.length > 0" class="my-3 shadow-sm mat-elevation-z8 " id="table-container">
                <div style="box-sizing: border-box;    overflow-x: auto;">
                    <table mat-table [dataSource]="dataSource" matSort>
                    
                        <ng-container *ngFor="let field of resultFields; let index = index" matColumnDef="{{field.key}}">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--accentGlobal);"> {{field.displayName}} </th>
                            <td mat-cell *matCellDef="let element" (click)="openRowView(field, element)">
                                <span *ngIf="field.type!='media' && field.type!='time' && field.type!='dateTime' && field.type!='date'" class="text-wrap text-break" >{{showElementFieldKey(element, field)}}</span>
                                <!-- <span *ngIf="field.type!='media' && field.type!='time' && field.type!='dateTime' && field.type!='date'" class="text-wrap text-break" >{{showElementFieldKey(element, field)}}</span> -->
                                <span *ngIf="field.type=='date'" class="text-wrap text-break" >{{showElementFieldKey(element, field) | date: 'dd.MM.yyyy' }}</span> 
                                <span *ngIf="field.type=='dateTime'" class="text-wrap text-break" >{{showElementFieldKey(element, field) | date: 'dd.MM.yyyy, HH:mm'}}</span> 
                                <span *ngIf="field.type=='time'" class="text-wrap text-break" > {{showElementFieldKey(element, field) | date: 'HH:mm'}}</span> 
                                <span *ngIf="field.type=='media'" class="text-wrap text-break" style="cursor:pointer;">
                                    <!-- <div *ngFor="let url of showElementFieldKey(element,field); let index = index" class="p-2">
                                        <a href="{{url}}" *ngIf="index==0" target="_blank" style="cursor:zoom-in;" class="dropbtn">
                                            <img *ngIf="!url.endsWith('.pdf')" src="{{url}}" width="150" onerror="this.src='./../../assets/img/default.jpg'" class="rounded">
                                            <span *ngIf="url.endsWith('.pdf')">PDF</span>
                                        </a>
                                    </div> -->
                                    <div *ngIf="checkTypeOfArray(element,field)">
                                        <ng-container *ngFor="let url of showElementFieldKey(element,field); let index = index" class="p-2">
                                            <div mat-button [matMenuTriggerFor]="menu" *ngIf="index==0" style="cursor:pointer;" class="m-2" [matBadge]="showElementFieldKey(element, field).length > 1 ? '+' + (showElementFieldKey(element, field).length - 1) : null" style="width: 50px; height: 50px;" matBadgeSize="large">
                                                <img *ngIf="!url.endsWith('.pdf')" src="{{url}}" width="50" height="50" onerror="this.src='./../../assets/img/default.jpg'" class="rounded imgBTN" style="object-fit: cover;">
                                                <button *ngIf="url.endsWith('.pdf')" mat-button class="secondary-gray-button" style="width:75px"><mat-icon>attach_file</mat-icon> PDF</button>
                                            </div>
                                        </ng-container>
                                        <mat-menu #menu="matMenu">
                                            <div class="d-flex p-1">
                                                <div *ngFor="let url of showElementFieldKey(element,field); let index = index" class="p-2">

                                                    <a *ngIf="!url.endsWith('.pdf')" href="{{url}}" target="_blank" style="cursor:zoom-in;">
                                                        <img src="{{url}}" width="100" height="100" onerror="this.src='./../../assets/img/default.jpg'" class="image-hover rounded" style="object-fit: cover;">
                                                    </a>

                                                    <a *ngIf="url.endsWith('.pdf')" href="{{url}}" class="pdf-title" target="_blank">
                                                        <div><mat-icon class="material-symbols-outlined">open_in_new</mat-icon>{{extractNameFromUrl(url)}}</div>
                                                    </a>
                                                </div>
                                            </div>
                                        </mat-menu>
                                    </div>
                                </span> 
                            </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" ></tr><!--routerLink="tridy/{{row.id}}"-->
                    </table>
                <!-- <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-2" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td> -->
                </div>
                <mat-paginator [pageSizeOptions]="paginatorSize"
                                showFirstLastButtons
                                aria-label="Select page of reports" class="pb-2">
                </mat-paginator>
            </div>
        </div>
    </div>
</section>

<mat-drawer #drawer class="p-3" mode="over" position="end" scrollable style="max-width: 450px;position: fixed;">
<div class="filter-drawer" style="overflow-y: auto;">
    <div class="mb-5">
        <div class="p-3 d-flex flex-row justify-content-between align-items-center">
            <span class="fs-5">{{'filters.filter_settings' | translate}}</span>
        </div>
        <form [formGroup]="filterForm" style="overflow-y: auto;">
            <mat-accordion class=" mb-1 mt-1">
                <mat-expansion-panel *ngFor="let filter of reportTemplate.filters; let index=index" (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false" class="m-2">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{filter.displayName}}
                            <!-- {{filter.name}} -->
                        </mat-panel-title>
                        <mat-panel-description>
                            {{filterForm.value[filter.key+'_description']}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    
                    <div *ngIf="filter.key">
                        <div>
                            <div class="form-control d-flex justify-content-between rounded mt-1 mb-3 w-100 p-3">
                                <span class="text-wrap" style="background-color: var(--pureWhite);">{{'reports.key' | translate}}    </span>
                                <label class=" text-muted" style="border-left: none;text-align: end;">{{filter.key}}</label>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>{{'displayName' | translate}}</mat-label>
                                <input matInput type="text" class="form-control" placeholder="{{'displayName' | translate}}" formControlName="{{filter.key}}_displayName" required>
                            </mat-form-field>
    
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'reports.type' | translate}}</mat-label>
                                <mat-select formControlName="{{filter.key}}_type" required>
                                    <mat-option *ngFor="let type of typeOptions; let index=index" value="{{type.type}}" >{{type.translation | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <!-- <mat-form-field appearance="outline" class="w-100 " disabled>
                                <mat-label>{{'reports.key' | translate}}</mat-label>
                                <input matInput type="text" class="form-control" placeholder=" {{'reports.key' | translate}}" formControlName="{{filter.key}}_key" required readonly>
                            </mat-form-field> -->
    
                            <!-- 
    
                            <div class=" form-control d-flex justify-content-between rounded mt-1 mb-3 w-100 p-3">
                                <span class="text-wrap" style="background-color: var(--pureWhite);">{{'reports.type' | translate}}    </span>
                                <label class=" text-muted" style="border-left: none;text-align: end;">{{filter.type}}</label>
                            </div>
    
                            <div class="form-control d-flex justify-content-between rounded mt-1 mb-3 w-100 p-3">
                                <span class="text-wrap" style="background-color: var(--pureWhite);">{{'description' | translate}}    </span>
                                <label class=" text-muted" style="border-left: none;text-align: end;">{{filter.description}}</label>
                            </div>
    
                            <mat-form-field appearance="outline" class="w-100" *ngIf="filter.type!='boolean' ">
                                <mat-label>{{filter.displayName}} </mat-label>
                                <input matInput *ngIf="filter.type=='string' " type="text" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='text' " type="text" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='media' " type="text" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='number' " type="number" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='decimal' " type="number" formControlName="{{filter.key}}" step="0.1">
                                <input matInput *ngIf="filter.type=='date' " type="date" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='time' " type="time" formControlName="{{filter.key}}">
                                <input matInput *ngIf="filter.type=='dateTime' " type="datetime-local" formControlName="{{filter.key}}" >
                            </mat-form-field>
    
                            <div  class="form-control p-3 mb-3  d-flex justify-content-between" *ngIf="filter.type=='boolean' ">
                                <label for="{{filter.key}}" >{{filter.displayName}}</label>
                                <mat-slide-toggle  class="ms-5 example-margin" formControlName="{{filter.key}}" ></mat-slide-toggle>  
                            </div> -->
    
                            <mat-form-field appearance="outline" class="w-100 ">
                                <mat-label>{{'contentTypes.description' | translate}}</mat-label>
                                <input matInput type="text" class="form-control" placeholder="{{'contentTypes.description' | translate}}" formControlName="{{filter.key}}_description">
                            </mat-form-field>
    
                        </div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'reports.operator' | translate}}</mat-label>
                            <mat-select formControlName="{{filter.key}}_operator" required>
                                <mat-option *ngFor="let operator of operatorOptions; let index=index" value="{{operator.value}}"  disabled="{{checkFilterTypeOperatorRange(filter.type, operator)}}" >{{operator.translation | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                </mat-expansion-panel>
            </mat-accordion>
        </form>
    </div>

    <div class="drawer-action-section pt-2">
        <!-- <button mat-button (click)="clearFilters();" style="color:var(--accentGlobal);"> {{'reports.clear_filter'| translate}}</button>
        <button mat-button class="ms-1" (click)="createNewFilters();" style="color:var(--pureWhite); background-color: var(--accentGlobal);"> {{'reports.update_filter' | translate}}</button> -->
        <button mat-button (click)="drawer.toggle();" style="color:var(--accentGlobal);margin-right:10px"> {{'cancel'| translate}}</button>
        <button mat-button class="m-1" (click)="updateFilters(drawer);" class="orange-button"> {{'save' | translate}}</button>
        <!-- <button mat-button class="ms-1" (click)="createNewFilters();" style="color:var(--pureWhite); background-color: var(--accentGlobal);" disabled> {{'save' | translate}}</button> -->
    </div>
</div>
</mat-drawer>  