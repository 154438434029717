import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-composed-content-phone]',
  templateUrl: './composed-content-phone.component.html',
  styleUrls: ['./composed-content-phone.component.css']
})
export class ComposedContentPhoneComponent {
  contentData:any
  @Input('app-composed-content-phone') data:any
  nodeType:string
  hasChildren = false
  fontfamily:string = 'var(--plainFontName)'
  verticalAlignment:string = 'center'
  horizontalAlignment:string = 'center'
  constructor(){}

  ngOnInit(): any{
    this.contentData = this.data[0]
    console.log(this.contentData)
    if(this.contentData?.attributes?.font){
      this.fontfamily = 'var(--' + this.contentData?.attributes?.font + 'FontName)'
    }

    if(this.contentData?.attributes?.verticalAlignment){
      switch(this.contentData?.attributes?.verticalAlignment){
        case 'top':{
          this.verticalAlignment = 'flex-start'
          break;
        }

        case 'center':{
          this.verticalAlignment = 'center'
          break
        }

        case 'top':{
          this.verticalAlignment = 'flex-end'
          break
        }
      }
    }

    if(this.contentData?.attributes?.horizontalAlignment){
      switch(this.contentData?.attributes?.horizontalAlignment){
        case 'leading':{
          this.horizontalAlignment = 'flex-start'
          break;
        }

        case 'center':{
          this.horizontalAlignment = 'center'
          break
        }

        case 'trailing':{
          this.horizontalAlignment = 'stretch'
          break
        }
      }
    }
  }
}
