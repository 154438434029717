<h2 mat-dialog-title>{{'users.invite' | translate}}</h2>

<mat-dialog-content style="width: 380px;">
    <div class="pt-2">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="createUserForm" class="m-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'users.displayName' | translate}}</mat-label>
                    <input formControlName="displayName" type="text" matInput placeholder="{{'users.displayName' | translate}}" >
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'users.email' | translate}}</mat-label>
                    <input formControlName="email" type="email" matInput placeholder="{{'users.email' | translate}}" >
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'users.roles' | translate}}</mat-label>
                    <mat-select formControlName="roles" multiple>
                        <mat-option *ngFor="let role of availableRoles" [value]="role.value">{{ role.display }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="allInvitesUsers.length>1" class="w-100 mb-2">
                    <span class="text-muted text-uppercase">{{'users.users_list' | translate}}</span>
        
                    <div class="list-group mt-1">
                        <div *ngFor="let user of allInvitesUsers" class="list-group-item d-flex flex-column">
                            <span class="font-weight-bold">{{user.displayName}}</span>
                            <span >{{user.email}}</span>
                        </div>
                    </div>
                </div>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelNewUSer();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="addUserToList()" [disabled]="!createUserForm.value.email || !createUserForm.value.displayName">{{'users.send_invitation' | translate}}</button>
</mat-dialog-actions>
