<h2 mat-dialog-title>{{'taskTemplateGropus.title' | translate}}</h2>

<mat-dialog-content class="custom-width">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="newGroupForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'taskTemplateGropus.new_title' | translate}}</mat-label>
                    <input matInput type="text" formControlName="title" placeholder="{{'taskTemplateGropus.new_title' | translate}}" required >
                </mat-form-field>
            </form> 
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button *ngIf="option=='create'" mat-button class="orange-button"
    (click)="onSave();">{{'taskTemplateGropus.create' | translate}}</button>

    <button *ngIf="option=='edit'" mat-button class="orange-button"
    (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>