<section class="container">

    <ng-container *ngIf="emptyList">
        <div class="row justify-content-center my-5">
            <div class="col-8 text-center my-5 py-5">
                <h2 class="mb-1">{{'topNavbar.content' | translate}}</h2>
                <p class="lead pb-3" style="font-family:'MyFont-Light';">{{'empty_elements' | translate}}</p>
                <button mat-button class="mb-4 orange-button" id="btn-create" 
                    [matTooltip]="!contentUserRights_create ? ('home.tooltip' | translate) : null"
                    [matMenuTriggerFor]="newContentData" 
                    [disabled]="!contentUserRights_create" >
                    <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                </button>
            <mat-menu #newContentData="matMenu">
                <button mat-menu-item (click)="addNewData('text');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange); padding-right: 5px;">
                            font_download
                        </span>
                        <span>{{'content.text' | translate}}</span>
                    </div>
                </button>
                <button mat-menu-item (click)="addNewData('number');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                            tag
                        </span>
                        <span>{{'content.number' | translate}}</span>
                    </div>
                </button>
    
                <button mat-menu-item (click)="addNewData('boolean');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                            toggle_on
                        </span>
                        <span>{{'content.bool' | translate}}</span>
                    </div>
                </button>
    
                <button mat-menu-item (click)="addNewData('list');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                            list
                        </span>
                        <span>{{'content.list' | translate}}</span>
                    </div>
                </button>
    
                <button mat-menu-item (click)="addNewData('object');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                            calendar_view_month
                        </span>
                        <span>{{'content.keyValue' | translate}}</span>
                    </div>
                </button>
    
                <button mat-menu-item (click)="addNewData('date');">
                    <div class="d-flex">
                        <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                            calendar_month
                        </span>
                        <span>{{'content.date' | translate}}</span>
                    </div>
                </button>
            
            </mat-menu>
            </div>
          </div>
    </ng-container>

    <ng-container *ngIf="!emptyList">
        <div class="row mt-4">

            <div class="mt-3">
                <h1 class="mb-1">{{'topNavbar.content' | translate}}</h1>
                <p style="font-family:'MyFont-Light';">{{'headlines.subContent' | translate}}</p>
            </div>
    
            <div class="col-12">

                <div class="d-flex mt-2 align-items-center justify-content-between">
                    <button mat-button class=" orange-button" id="btn-create" 
                        [matTooltip]="!contentUserRights_create ? ('home.tooltip' | translate) : null"
                        [matMenuTriggerFor]="newContentData" 
                        [disabled]="!contentUserRights_create">
                        <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                    </button>
                    <mat-menu #newContentData="matMenu">
                        <button mat-menu-item (click)="addNewData('text');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange); padding-right: 5px;">
                                    font_download
                                </span>
                                <span>{{'content.text' | translate}}</span>
                            </div>
                        </button>
                        <button mat-menu-item (click)="addNewData('number');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                    tag
                                </span>
                                <span>{{'content.number' | translate}}</span>
                            </div>
                        </button>
            
                        <button mat-menu-item (click)="addNewData('boolean');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                    toggle_on
                                </span>
                                <span>{{'content.bool' | translate}}</span>
                            </div>
                        </button>
            
                        <button mat-menu-item (click)="addNewData('list');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                    list
                                </span>
                                <span>{{'content.list' | translate}}</span>
                            </div>
                        </button>
            
                        <button mat-menu-item (click)="addNewData('object');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                    calendar_view_month
                                </span>
                                <span>{{'content.keyValue' | translate}}</span>
                            </div>
                        </button>
            
                        <button mat-menu-item (click)="addNewData('date');">
                            <div class="d-flex">
                                <span class="material-symbols-outlined" style="color: var(--warmOrange);padding-right: 5px;">
                                    calendar_month
                                </span>
                                <span>{{'content.date' | translate}}</span>
                            </div>
                        </button>
                    
                    </mat-menu>

                    <mat-form-field class="pt-3 mb-0 mt-0 white-input-bg">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12 mb-4">
                <div class="mat-elevation-z8 rounded shadow-sm">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <!-- key Column -->
                        <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{'content.key' | translate}} </th>
                        <td mat-cell *matCellDef="let data"> 
                            <div *ngIf="data.key" >
                                <div class="d-flex flex-row flex-nowrap align-items-center  pe-1 px-2"  style="cursor:pointer;" (click)="clickContentList(data);">
                                    <span *ngIf="data.type=='string'" class="material-symbols-outlined" style="color: var(--warmOrange);">font_download</span>                    
                                    <span *ngIf="data.type=='object'"  class="material-symbols-outlined" style="color: var(--warmOrange);">calendar_view_month</span>
                                    <span *ngIf="data.type=='array'" class="material-symbols-outlined" style="color: var(--warmOrange);">list</span>
                                    <span *ngIf="data.type=='number'" class="material-symbols-outlined" style="color: var(--warmOrange);">tag</span>
                                    <span *ngIf="data.type=='boolean'" class="material-symbols-outlined" style="color: var(--warmOrange);">toggle_on</span>
                                    <span *ngIf="data.type=='date'" class="material-symbols-outlined" style="color: var(--warmOrange);">calendar_month</span>
                                    <span class="ps-3 text-wrap text-break"> {{data.key}}</span>
                                </div>
                            </div>
                        </td>
                        </ng-container>
                    
                        <!-- value Column -->
                        <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{'content.value' | translate}} </th>
                        <td mat-cell *matCellDef="let data"> 
                            <div  class="pe-1 px-2"  style="cursor:pointer;" (click)="clickContentList(data);">
                                <span *ngIf="data.type=='string'" class="text-muted ms-auto">{{data.value}}</span>
                                <div *ngIf="data.type=='object'">
                                    <span class="text-muted ms-auto" *ngIf="data.value"> {{data.value.length}} {{'content.entries' | translate}} </span>
                                    <span class="text-muted ms-auto" *ngIf="!data.value"> 0 {{'content.entries' | translate}} </span>
                                </div>
                                <div *ngIf="data.type=='array'"> 
                                    <span class="text-muted ms-auto" *ngIf="data.value"> {{data.value.length}} {{'content.entries' | translate}} </span>
                                    <span class="text-muted ms-auto" *ngIf="!data.value"> 0 {{'content.entries' | translate}} </span>
                                </div>
                                <span *ngIf="data.type=='number'" class="text-muted ms-auto">{{data.value}}</span>

                                <div *ngIf="data.type=='boolean'">
                                    <span class="material-symbols-outlined ms-auto" *ngIf="!data.value">
                                        check_box_outline_blank
                                    </span>
                    
                                    <span class="material-symbols-outlined ms-auto" *ngIf="data.value">
                                        check_box
                                    </span>
                                </div>
                                <span *ngIf="data.type=='date'" class="text-muted ms-auto">{{isDateTime(data.value)}}</span>
                            </div>
                        </td>
                        </ng-container>
                    
                        <!-- creationDate Column -->
                        <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{'creation_date' | translate}} </th>
                        <!-- <td mat-cell *matCellDef="let data"> {{isDateTime(data.creationDate)}} </td> -->
                        <td mat-cell *matCellDef="let data"> {{data?.creationDate | date:'dd.MM.yyy, HH:mm'}} </td>
                        </ng-container>
                    
                        <!-- timeStamp Column -->
                        <ng-container matColumnDef="timeStamp">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{'last_update' | translate}} </th>
                        <td mat-cell *matCellDef="let data"> {{isDateTime(data.timeStamp )}} </td>
                        <!-- <td mat-cell *matCellDef="let data"> {{data?.timeStamp| date:'dd.MM.yyy, HH:mm'}} </td> -->
                        </ng-container>
            
                        <ng-container matColumnDef="deleteContent">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let data"> 
                                <button mat-icon-button class="button-task"  (click)="deleteContent(data);" [disabled]="!contentUserRights_delete">
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>
                                </button>
                            </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">{{'no_matching_data' | translate}} </td>
                            </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of elements" showFirstLastButtons class="pb-2"></mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>
</section>