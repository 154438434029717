export const languagesList = {
    // MARK: - Languages
    "be":"Belarusian",
    "bg":"Bulgarian",
    "bs":"Bosnian",
    "ca":"Catalan",
    "cs":"Czech",
    "da":"Danish",
    "de":"German",
    "el":"Greek",
    "en":"English",
    "es":"Spanish",
    "fi":"Finnish",
    "fr":"French",
    "gl":"Galician",
    "gsw":"Swiss German",
    "hr":"Croatian",
    "hu":"Hungarian",
    "it":"Italian",
    "lmo":"Lombard",
    "lt":"Lithuanian",
    "nap":"Neapolitan",
    "nl":"Dutch",
    "no":"Norwegian",
    "pl.":"Polish",
    "pt":"Portuguese",
    "ro":"Romanian",
    "ru":"Russian",
    "scn":"Sicilian",
    "sk":"Slovak",
    "sl":"Slovene",
    "sr":"Serbian",
    "sv":"Swedish",
    "tr":"Turkish",
    "tt":"Tatar",
    "uk":"Ukrainian",
    "vec":"Venetian"

}