<section>
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 15px; margin-top: 10px; z-index:100; position: absolute;  color:white;">
        finance_chip
    </span>
    
    <!-- twoHorizontal -->
    <div *ngIf="layout == 'twoHorizontal'" class="d-flex align-items-stretch px-2 flex-row flex-nowrap justify-content-around align-items-center w-100">
        <div *ngFor="let im of images; let index=index" class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">
            <a *ngIf="im.linkURL && linkURL[index]" href="{{linkURL[index]}}" target="_blank">
                <div class="image-container">
                    <img *ngIf="!url[index]" [src]="im.url ? im.url : './../../assets/img/default.jpg'" alt="..." class="stretched-img">
                    <img *ngIf="url[index]" [src]="url[index] ? url[index] : './../../assets/img/default.jpg'" alt="..." class="stretched-img">
                    <div *ngIf="im.title" class="text-container">
                        <span id="title" class="image-title-text">{{im.title}}</span>
                    </div>
                </div>
            </a>
            <a *ngIf="!im.linkURL && !linkURL[index]">
                <div class="image-container">
                    <img *ngIf="!url[index]" [src]="im.url ? im.url : './../../assets/img/default.jpg'" alt="..." class="stretched-img">
                    <img *ngIf="url[index]" [src]="url[index] ? url[index] : './../../assets/img/default.jpg'" alt="..." class="stretched-img">
                    <div *ngIf="im.title" class="text-container">
                        <span id="title" class="image-title-text">{{im.title}}</span>
                    </div>
                </div>
            </a>
        </div>
        <div *ngIf="images.length == 0" class="image-wrapper">
            <div class="image-container">
                <img src="./../../assets/img/default.jpg" alt="..." class="stretched-img">
            </div>
            <div class="image-container">
                <img src="./../../assets/img/default.jpg" alt="..." class="stretched-img">
            </div>
        </div>
    </div>
    
    <!-- twoVertical -->
    <div *ngIf="layout=='twoVertical'"  class="px-2 flex-row justify-content-around align-items-center w-100">
        <div *ngFor="let im of images; let index=index;"  class="image-wrapper p-1 col-12 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">
            <a *ngIf="im.linkURL && linkURL[index]" href="{{linkURL[index]}}" style="width:100%;" target="_blank">
                <div class="image-container">
                    <img *ngIf="!url[index]" [src]="im.url ? im.url : './../../assets/img/default.jpg'" alt="..." style="height:140px" class="stretched-img">
                    <img *ngIf="url[index]" [src]="url[index] ? url[index] : './../../assets/img/default.jpg'" alt="..." style="height:140px" class="stretched-img">
                    <div *ngIf="im.title" class="text-container">
                        <span id="title" class="image-title-text">{{im.title}}</span>
                    </div>
                </div>
            </a>
            <a *ngIf="!im.linkURL && !linkURL[index]" style="width:100%;">
                <div class="image-container">
                    <img *ngIf="!url[index]" [src]="im.url ? im.url : './../../assets/img/default.jpg'"  style="height:140px" alt="..." class="stretched-img">
                    <img *ngIf="url[index]" [src]="url[index] ? url[index] : './../../assets/img/default.jpg'"  style="height:140px" alt="..." class="stretched-img">
                    <div *ngIf="im.title" class="text-container">
                        <span #title id="title" class="image-title-text">{{im.title}}</span>
                    </div>
                </div>
                
            </a>
        </div>    
        <div *ngIf="images.length==0" class="image-wrapper">
            <img src='./../../assets/img/default.jpg' alt="..." class="stretched-img" style="height:140px" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img  src='./../../assets/img/default.jpg' alt="..." class="stretched-img" style="height:140px" onerror="this.src='./../../assets/img/default.jpg'"/>
        </div>
    </div>
     
    <!-- threeLayout1 -->
    <div *ngIf="layout=='threeLayout1'" class="d-flex align-items-stretch px-2 flex-row flex-nowrap justify-content-around align-items-center w-100">
        <!-- column 1 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- image[0] --> <!---url : url!=pass://-->
            <div *ngIf="images[0].title && !url[0]" style="height:100; width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]"  class="image-container">
                    <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" target="_blank">
                <img *ngIf="!images[0].title && !url[0]" src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[0].title && !url[0] && !linkURL[0]" src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />

            <!-- image[0] --> <!---url : url==pass://-->
            <div *ngIf="images[0].title && url[0]" style="height:100%; width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{url[0]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]"  class="image-container">
                    <img src="{{url[0]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" target="_blank" style="height: 100%; width: 100%;">
                <div *ngIf="!images[0].title && url[0]" class="image-container">
                    <img src="{{url[0]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[0].title && url[0] && !linkURL[0]" class="image-container">
                <img src="{{url[0]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

        </div>
        <!-- column 2 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- row 1-->
            <!-- image[1] --><!--url: url!=pas::// -->
            <div *ngIf="images[1].title && !url[1]" style="width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;">
                        <img src="{{images[1].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container" style="height: 80px;">
                    <img src="{{images[1].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[1].title && !url[1]" class="image-container">
                    <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[1].title && !url[1] && !linkURL[1]" class="image-container" style="height: 80px;">
                <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[1] --><!--url: url==pas::// -->
            <div *ngIf="images[1].title && url[1]" style="width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;" >
                        <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container" style="height: 80px;" >
                    <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[1].title && url[1]" class="image-container" style="height: 80px;">
                    <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div  *ngIf="!images[1].title && url[1] && !linkURL[1]" class="image-container" style="height: 80px;">
                <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- row 2 -->
            <!-- image[2] --><!--url: url!= pass://-->
            <div *ngIf="images[2].title && !url[2]" style="width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" style="height:auto;" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>

            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[2].title && !url[2]" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && !url[2] && !linkURL[2]"class="mt-2 image-container">
                <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[2] --><!--url: url== pass://-->
            <div *ngIf="images[2].title && url[2]" style="height:auto;width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" style="height:auto;width:100%;"target="_blank">
                <div *ngIf="!images[2].title && url[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && url[2] && !linkURL[2]" style="height:auto;" class="mt-2 image-container">
                <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>
    </div>

    <!-- threeLayout2 -->
    <div *ngIf="layout=='threeLayout2'" class="d-flex align-items-stretch px-2 flex-row flex-nowrap justify-content-around align-items-center w-100">
        <!-- column 1 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- row 1-->
            <!-- image[0] --><!--url: url!=pas::// -->
            <div *ngIf="images[0].title && !url[0]" style="width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;">
                        <img src="{{images[0].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" class="image-container" style="height: 80px;">
                    <img src="{{images[0].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[0].title && !url[0]" class="image-container">
                    <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[0].title && !url[0] && !linkURL[0]" class="image-container" style="height: 80px;">
                <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[0] --><!--url: url==pas::// -->
            <div *ngIf="images[0].title && url[0]" style="width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;" >
                        <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" class="image-container" style="height: 80px;" >
                    <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[0].title && url[0]" class="image-container" style="height: 80px;">
                    <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div  *ngIf="!images[0].title && url[0] && !linkURL[0]" class="image-container" style="height: 80px;">
                <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- row 2 -->
            <!-- image[2] --><!--url: url!= pass://-->
            <div *ngIf="images[2].title && !url[2]" style="width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" style="height:auto;" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>

            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[2].title && !url[2]" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && !url[2] && !linkURL[2]"class="mt-2 image-container">
                <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[2] --><!--url: url== pass://-->
            <div *ngIf="images[2].title && url[2]" style="height:auto;width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" style="height:auto;width:100%;"target="_blank">
                <div *ngIf="!images[2].title && url[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && url[2] && !linkURL[2]" style="height:auto;" class="mt-2 image-container">
                <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>
        <!-- column 2 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- image[1] --> <!---url : url!=pass://-->
            <div *ngIf="images[1].title && !url[1]" style="height:100; width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container">
                    <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" target="_blank">
                <img *ngIf="!images[1].title && !url[1]" src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[1].title && !url[1] && !linkURL[1]" src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />

            <!-- image[1] --> <!---url : url==pass://-->
            <div *ngIf="images[1].title && url[1]" style="height:100%; width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{url[1]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]"  class="image-container">
                    <img src="{{url[1]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" target="_blank" style="height: 100%; width: 100%;">
                <div *ngIf="!images[1].title && url[1]" class="image-container">
                    <img src="{{url[1]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[1].title && url[1] && !linkURL[1]" class="image-container">
                <img src="{{url[1]}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

        </div>
    </div>

    <!-- fourLayout1 -->
    <div *ngIf="layout=='fourLayout1'" class="d-flex align-items-stretch px-2 flex-row flex-nowrap justify-content-around align-items-center w-100">
        <!-- column 1 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- image 1-->
            <!-- image[0] --><!--url: url!=pas::// -->
            <div *ngIf="images[0].title && !url[0]" style="width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;">
                        <img src="{{images[0].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" class="image-container" style="height: 80px;">
                    <img src="{{images[0].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[0].title && !url[0]" class="image-container">
                    <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[0].title && !url[0] && !linkURL[0]" class="image-container" style="height: 80px;">
                <img src="{{images[0].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[0] --><!--url: url==pas::// -->
            <div *ngIf="images[0].title && url[0]" style="width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;" >
                        <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" class="image-container" style="height: 80px;" >
                    <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[0].title && url[0]" class="image-container" style="height: 80px;">
                    <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div  *ngIf="!images[0].title && url[0] && !linkURL[0]" class="image-container" style="height: 80px;">
                <img src="{{url[0]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image 2 -->
            <!-- image[2] --><!--url: url!= pass://-->
            <div *ngIf="images[2].title && !url[2]" style="width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" style="height:auto;" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>

            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[2].title && !url[2]" class="mt-2 image-container">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && !url[2] && !linkURL[2]"class="mt-2 image-container">
                <img src="{{images[2].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[2] --><!--url: url== pass://-->
            <div *ngIf="images[2].title && url[2]" style="height:auto;width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" style="height:auto;width:100%;"target="_blank">
                <div *ngIf="!images[2].title && url[2]" class="mt-2 image-container">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && url[2] && !linkURL[2]" style="height:auto;" class="mt-2 image-container">
                <img src="{{url[2]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>
        <!-- column 2 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">

            <!-- image 3 -->
            <!-- image[1] --><!--url: url!= pass://-->
            <div *ngIf="images[1].title && !url[1]" style="width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{images[1].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" style="height:auto;" class="image-container">
                    <img src="{{images[1].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[1].title && !url[1]" class="image-container">
                    <img src="{{images[1].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[1].title && !url[1] && !linkURL[1]"class="image-container">
                <img src="{{images[1].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[3] --><!--url: url== pass://-->
            <div *ngIf="images[1].title && url[1]" style="height:auto;width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                    <div class="image-container">
                        <img src="{{url[1]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container">
                    <img src="{{url[1]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" style="height:auto;width:100%;"target="_blank">
                <div *ngIf="!images[1].title && url[1]" class="image-container">
                    <img src="{{url[1]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[1].title && url[1] && !linkURL[1]" style="height:auto;" class="image-container">
                <img src="{{url[1]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image 4-->
            <!-- image[3] --><!--url: url!=pas::// -->
            <div *ngIf="images[3].title && !url[3]" style="width: 100%;">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="mt-2 image-container" style="height: 80px;">
                        <img src="{{images[3].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[3]" class="mt-2 image-container" style="height: 80px;">
                    <img src="{{images[3].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[3].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && !url[3]" href="{{linkURL[3]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[3].title && !url[3]" class="mt-2 image-container">
                    <img src="{{images[3].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[3].title && !url[3] && !linkURL[3]" class="mt-2 iimage-container" style="height: 80px;width:100%">
                <img src="{{images[3].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[1] --><!--url: url==pas::// -->
            <div *ngIf="images[3].title && url[3]" style="width: 100%;">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="mt-2 image-container" style="height: 80px;" >
                        <img src="{{url[3]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[3].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[3]" class="mt-2 image-container" style="height: 80px;" >
                    <img src="{{url[3]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[3].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && url[3]" href="{{linkURL[3]}}" style="height:80px;" target="_blank">
                <div *ngIf="!images[3].title && url[3]" class="mt-2 image-container" style="height: 80px;">
                    <img src="{{url[3]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[3].title && url[3] && !linkURL[3]" class="mt-2 image-container" style="height: 80px;">
                <img src="{{url[3]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>
    </div>

    <!-- fourLayout2 -->
    <div *ngIf="layout=='fourLayout2'" class="d-flex align-items-stretch px-2 flex-row flex-nowrap justify-content-around align-items-center w-100">
       
        <!-- column 1 -->

        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">
            <!-- image 1 -->
            <!-- image[0] --><!--url: url!= pass://-->
            <div *ngIf="images[0].title && !url[0]" style="width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="image-container">
                        <img src="{{images[0].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" style="height:auto;" class="image-container">
                    <img src="{{images[0].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[0].title && !url[0]" class="image-container">
                    <img src="{{images[0].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[0].title && !url[0] && !linkURL[0]"class="image-container">
                <img src="{{images[0].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[0] --><!--url: url== pass://-->
            <div *ngIf="images[0].title && url[0]" style="height:auto;width: 100%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                    <div class="image-container">
                        <img src="{{url[0]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[0].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[0]" class="image-container">
                    <img src="{{url[0]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[0].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[0].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" style="height:auto;width:100%;"target="_blank">
                <div *ngIf="!images[0].title && url[0]" class="image-container">
                    <img src="{{url[0]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[0].title && url[0] && !linkURL[0]" style="height:auto;" class="image-container">
                <img src="{{url[0]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image 2-->
            <!-- image[2] --><!--url: url!=pas::// -->
            <div *ngIf="images[2].title && !url[2]" style="width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="mt-2 image-container" style="height: 80px;">
                        <img src="{{images[2].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" class="mt-2 image-container" style="height: 80px;">
                    <img src="{{images[2].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[2].title && !url[2]" class="mt-2 image-container">
                    <img src="{{images[2].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && !url[2] && !linkURL[2]" class="mt-2 iimage-container" style="height: 80px;width:100%">
                <img src="{{images[2].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[2] --><!--url: url==pas::// -->
            <div *ngIf="images[2].title && url[2]" style="width: 100%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="mt-2 image-container" style="height: 80px;" >
                        <img src="{{url[2]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[2].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[2]" class="mt-2 image-container" style="height: 80px;" >
                    <img src="{{url[2]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[2].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[2].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" style="height:80px;" target="_blank">
                <div *ngIf="!images[2].title && url[2]" class="mt-2 image-container" style="height: 80px;">
                    <img src="{{url[2]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[2].title && url[2] && !linkURL[2]" class="mt-2 image-container" style="height: 80px;">
                <img src="{{url[2]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>

        <!-- column 2 -->
        <div class="image-wrapper px-1 col-6 d-flex align-content-stretch flex-wrap align-items-center justify-content-center">
            <!-- image 3 -->
            <!-- image[1] --><!--url: url!=pas::// -->
            <div *ngIf="images[1].title && !url[1]" style="width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;">
                        <img src="{{images[1].url}}"alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container" style="height: 80px;">
                    <img src="{{images[1].url}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[1].title && !url[1]" class="image-container">
                    <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" style="height: 80px;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[1].title && !url[1] && !linkURL[1]" class="image-container" style="height: 80px;">
                <img src="{{images[1].url}}" class="" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>

            <!-- image[1] --><!--url: url==pas::// -->
            <div *ngIf="images[1].title && url[1]" style="width: 100%;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" style="height:80px;width: 100%;" target="_blank">
                    <div class="image-container" style="height: 80px;" >
                        <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[1].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[1]" class="image-container" style="height: 80px;" >
                    <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[1].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[1].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" style="height:80px;width:100%;" target="_blank">
                <div *ngIf="!images[1].title && url[1]" class="image-container" style="height: 80px;">
                    <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div  *ngIf="!images[1].title && url[1] && !linkURL[1]" class="image-container" style="height: 80px;">
                <img src="{{url[1]}}" alt="..." class="stretched-img" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image 2 -->
            <!-- image[3] --><!--url: url!= pass://-->
            <div *ngIf="images[3].title && !url[3]" style="width: 100%;">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" style="height:auto;width: 100%;" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{images[3].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[3].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[3]" style="height:auto;" class="mt-2 image-container">
                    <img src="{{images[3].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[3].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                    </div>
                </div>

            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && !url[3]" href="{{linkURL[3]}}" style="height:auto;width: 100%;" target="_blank">
                <div *ngIf="!images[3].title && !url[3]" class="mt-2 image-container">
                    <img src="{{images[3].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[3].title && !url[3] && !linkURL[3]" class="mt-2 image-container">
                <img src="{{images[3].url}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
            <!-- image[3] --><!--url: url== pass://-->
            <div *ngIf="images[3].title && url[3]" style="height:auto;width: 100%;">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" target="_blank">
                    <div class="mt-2 image-container">
                        <img src="{{url[3]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                        <div *ngIf="images[3].title" class="text-container">
                            <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!linkURL[3]" class="mt-2 image-container">
                    <img src="{{url[3]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <div *ngIf="images[3].title" class="text-container">
                        <span #title id="title" class="image-title-text">{{images[3].title}}</span>
                    </div>
                </div>
            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && url[3]" href="{{linkURL[3]}}" style="height:auto;width:100%;" target="_blank">
                <div *ngIf="!images[3].title && url[3]" class="mt-2 image-container">
                    <img src="{{url[3]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
                </div>
            </a>
            <div *ngIf="!images[3].title && url[3] && !linkURL[3]" style="height:auto;" class="mt-2 image-container">
                <img src="{{url[3]}}" alt="..." class="stretched-img" style="aspect-ratio:1;height:auto;" onerror="this.src='./../../assets/img/default.jpg'" />
            </div>
        </div>

    </div>

</section>
