import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { deepCopy } from '../../../../backend/utils/object';

@Component({
  selector: 'app-content-type-display-template-field-dialog',
  templateUrl: './content-type-display-template-field-dialog.component.html',
  styleUrls: ['./content-type-display-template-field-dialog.component.css']
})
export class ContentTypeDisplayTemplateFieldDialogComponent {
  fieldData:any
  fieldForm!:FormGroup
  typesOptions:any=[
    {
      name: 'text',
      icon:'text_format',
      translations:'taskTemplates.add_text',
      type:'text'
    },
    {
      name: 'longText',
      icon:'subject',
      translations:'taskTemplates.add_longText',
      type:'longText'
    },
    {
      name: 'number',
      icon:'pin',
      translations:'taskTemplates.add_number',
      type:'number'
    },
    {
      name: 'decimal',
      icon:'numbers',
      translations:'taskTemplates.add_decimal',
      type:'decimal'
    },
    {
      name: 'currency',
      icon:'euro',
      translations:'taskTemplates.add_currency',
      type:'currency'
    },
    {
      name: 'date',
      icon:'calendar_today',
      translations:'taskTemplates.add_date',
      type:'date'
    },
    {
      name: 'time',
      icon:'access_time',
      translations:'taskTemplates.add_time',
      type:'time'
    },
    {
      name: 'dateTime',
      icon:'calendar_month',
      translations:'taskTemplates.add_dateTime',
      type:'dateTime'
    },
    {
      name: 'boolean',
      icon:'toggle_on',
      translations:'taskTemplates.add_switch',
      type:'boolean'
    },
    {
      name: 'weight',
      icon:'weight',
      translations:'taskTemplates.add_weight',
      type:'weight'
    }

  ]
  constructor(public dialogRef: MatDialogRef<ContentTypeDisplayTemplateFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
            private fb: FormBuilder,
    public dialog: MatDialog,
    private settingsService:SettingsService,
    private commentsService: CommentsService,
    private translate:TranslateService ){
      dialogRef.disableClose = true;
  }
  
  ngOnInit():void{
    console.log(this.data)
    
    this.fieldForm=this.fb.group({
      type:[,Validators.required],
      key:[,Validators.required],
      id:[,Validators.required],
      title:[,Validators.required]
    })

    if(this.data.values){
      this.fieldData = deepCopy(this.data.values)
      const {type, title, key, id} = this.fieldData

      this.fieldForm.patchValue({
        type:type ? type : undefined,
        key: key ? key : undefined,
        id: id ? id :undefined,
        title:title ? title : undefined
      })
    }else{
      this.fieldForm.patchValue({
        type:undefined,
        key:undefined,
        id:undefined,
        title: undefined
      })
    }
    
  }

  onSaveEdit(){
    const data = this.fieldForm.value
    this.dialogRef.close([true, data])
  }

  onCancelEdit(){
    this.dialogRef.close([false, this.fieldData])
  }

}
