import { Image } from "./image.dto"

export interface ISplashVideo{
    id:string
    url: string
    previewPicture?: Image
    copyright?: string
    title?:string
    type:string
    linkURL?:String
    autoPlay?: boolean
    autoRePlay?: boolean
    muted?:boolean
    videoControls?:boolean
    size?:number[]
}

export class SplashVideo{

    id: string|undefined = undefined
    url: string|undefined = undefined
    previewPicture: Image|undefined = undefined
    copyright: string|undefined = undefined
    title: string|undefined = undefined
    type: string|undefined = undefined
    linkURL: string|undefined = undefined
    autoPlay: boolean|undefined = false
    autoRePlay: boolean|undefined = false
    muted: boolean| undefined = false
    videoControls: boolean | undefined = false
    size: number[] | undefined = undefined

    constructor(){}

    setFromAny(element:any){
        const {id, url, type, previewPicture, copyright, title, linkURL, autoPlay, autoRePlay, muted, videoControls, size} = element
        this.id = id ? id : this.id
        this.url = url ? url : this.url
        this.previewPicture = previewPicture ? previewPicture : this.previewPicture
        this.copyright = copyright ? copyright : this.copyright
        this.title = title ? title : this.title
        this.type = type ? type : this.type
        this.linkURL = linkURL ? linkURL : this.type
        this.autoPlay = autoPlay ? autoPlay : false,
        this.autoRePlay = autoRePlay ? autoRePlay : false,
        this.muted = muted ? muted : false,
        this.videoControls = videoControls ? videoControls : false
        this.size = size ? size : this.size
    }


    getId(){
        if(!this.id) return undefined

        return this.id
    }


    getUrl(){
        if(!this.url) return undefined

        return this.url
    }

   
    getPreviewPicture(){
        if(!this.previewPicture) return undefined

        return this.previewPicture
    }

    getCopyright(){
        if(!this.copyright) return undefined

        return this.copyright
    }
}