import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private settingsService: SettingsService,
    private firestore: AngularFirestore,) { }


    async createContent(newContent:any):Promise<string>{
      return new Promise(async (resolve, reject) => {
        const contentRef = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_CONTENT).doc().ref

        const newId = contentRef.id
          newContent['creationDate'] = new Date()
          newContent['timeStamp'] = new Date()
          newContent['id'] = newId

          try{
            this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
            .doc(this.settingsService.contextId$)
            .collection(NetworkConstants.COLLECTION_CONTENT).doc(newId)
            .set({...newContent})
            resolve(newId)
          }catch(error){
            reject(error)
          }
      })  

    }
  
    addIdToNewContent(contentId:string):Promise<void>{
      return new Promise(async (resolve, reject) => {
        const data = {id: contentId}
        try{
        
          await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_CONTENT)
          .doc(contentId)
          .update(data)
          
          resolve()
        }catch(error){
          reject(error)
        }
      }) 
    }


    updateContent(contentId:string, data:any):Promise<void>{
      return new Promise(async (resolve, reject) => {
       
        try{
          data['timeStamp']= new Date()
          await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_CONTENT)
          .doc(contentId)
          .update(data)

          resolve()
        }catch(error){
          reject(error)
        }
      }) 
    }

    deletecontent(contentId):Promise<void>{
      return new Promise(async (resolve, reject) => {
        try {
          const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_CONTENT)
          .doc(contentId)
          .delete()
          resolve(d)
        } catch (error) {
          reject(error)  
          console.log(error)      
        } 
      })
    }

}
