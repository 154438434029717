import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { InterfacesService } from '../service/interfaces.service';
import { InterfacesDataMappingDialogComponent } from '../interfaces-data-mapping-dialog/interfaces-data-mapping-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-interfaces-data-dialog',
  templateUrl: './interfaces-data-dialog.component.html',
  styleUrls: ['./interfaces-data-dialog.component.css']
})
export class InterfacesDataDialogComponent {
  interfaceForm!:FormGroup
  interfaceData:any
  type:any
  allContentTypes: any = []
  interfacesUserRights_update:any

  constructor(
    public dialogRef: MatDialogRef<InterfacesDataDialogComponent>,
    private fb: FormBuilder,
    private interfaceService: InterfacesService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private commentsService: CommentsService,
    private settingsService: SettingsService,
    private translate: TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(){
    this.interfacesUserRights_update = this.settingsService.userRolesRights$['interfaces']['U']

    if(!this.interfacesUserRights_update){
      document.getElementById("btn-mapping-id").style.color="#e0e0e0"
      document.getElementById("btn-mapping-media").style.color="#e0e0e0"
    }
    if(this.data['type']){
      this.type=this.data['type']
    }

    this.interfaceForm = this.fb.group({
      contentType:[],
      contentTypeId:[],
      contentTypePath:[],
      displayName:[],
      id:[],
      info:[],
      itemArrayPath:[],
      name:[],
      payloadMapping:[],
      ids:[],
      media:[],
      headerKey:[]
    })

    if(this.data['info']){
      this.interfaceData = this.data['info']
      const {contentTypeId, displayName, id, info, itemArrayPath, name, payloadMapping, contentTypePath,headerKey} = this.interfaceData
      let contentType = ""
      if(contentTypeId){
        contentType = "id"
      }
      if(contentTypePath){
        contentType = "path"
      }
      this.interfaceForm.patchValue({
        contentType: contentType ? contentType : undefined,
        contentTypeId: contentTypeId ? contentTypeId : undefined,
        displayName:displayName ? displayName : undefined,
        id:id ? id : undefined,
        info:info ? info : undefined,
        itemArrayPath:itemArrayPath ? itemArrayPath : undefined,
        name:name ? name : undefined,
        payloadMapping: payloadMapping ? payloadMapping : {"ids":[], "media":[]},
        ids: payloadMapping.ids ? payloadMapping.ids : [],
        media: payloadMapping.media ? payloadMapping.media : [],
        contentTypePath : contentTypePath ? contentTypePath : undefined,
        headerKey: headerKey ? headerKey : undefined
      })
    }else{
      this.interfaceData = undefined
      this.interfaceForm.patchValue({
        contentTypeId:  undefined,
        displayName:undefined,
        id: undefined,
        info:undefined,
        itemArrayPath: undefined,
        name:undefined,
        payloadMapping: {"ids":[], "media":[]},
        ids: [],
        media: [],
        contentTypePath: undefined
      })
    }

    if(this.settingsService.settingsContentTypesWithId.length==0){
      this.getContentTypes()
    }else{
      this.allContentTypes = this.settingsService.settingsContentTypesWithId
    }
  }

  async getContentTypes(){
    try{
      this.allContentTypes = await this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
    }catch(error){
      console.log(error)
    }
  }

  dialogCancel(){
    this.dialogRef.close([false, this.interfaceData])
  }

  async dialogSave(){
    const {contentTypeId,contentTypePath, displayName,id,  info, itemArrayPath, name,  payloadMapping} = this.interfaceForm.value
      
    const data = {
      contentTypeId,
      contentTypePath,
      displayName,
      id,
      info,
      itemArrayPath,
      name,
      payloadMapping
    }

    removeUndefinedValuesFromObject(data)

    if(this.type=="edit"){
      try{
        const form = this.interfaceForm.value
        const data = removeUndefinedValuesFromObject(form)
        this.commentsService.progressSpin.emit(true)
        await this.interfaceService.updateInterface(data)
        this.dialogRef.close([true, data])
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
        this.commentsService.addSnackBar.emit(message)

      }catch(error){
        console.log(error)
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
        this.commentsService.addSnackBar.emit(message)
      }
    }

    if(this.type=="create"){
      try{
        this.commentsService.progressSpin.emit(true)
        const newId = await this.interfaceService.createInterface(data)
        
        this.dialogRef.close([true, data])
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.CREATE_OK")
        this.commentsService.addSnackBar.emit(message)

      }catch(error){
        console.log(error)
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
        this.commentsService.addSnackBar.emit(message)
      }
    }
  }

  addMappingMedia(){
    let dialogRef = this.dialog.open(InterfacesDataMappingDialogComponent, {
      data: { 
        info:'',
        type: 'media',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        let medias= this.interfaceForm.value.media
        medias.push(result[1])
        this.interfaceForm.patchValue({media: medias})
        let payload = this.interfaceForm.value.payloadMapping
        payload['media'] = medias
        this.interfaceForm.patchValue({payloadMaping: payload})

      }
    });
  }

  addMappingId(){
    let dialogRef = this.dialog.open(InterfacesDataMappingDialogComponent, {
      data: { 
        info:'',
        type: 'ids',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        let ids= this.interfaceForm.value.ids
        ids.push(result[1])
        this.interfaceForm.patchValue({ids: ids})
        let payload = this.interfaceForm.value.payloadMapping
        payload['ids'] = ids
        this.interfaceForm.patchValue({payloadMaping: payload})

      }
    });
  }


  deleteId(idSelected, indexSelected){
    let ids = []
    this.interfaceForm.value.ids.forEach( (id, index)=> {
      if(index!=indexSelected){
        ids.push(id)
      }
    })

    this.interfaceForm.patchValue({ ids: ids})
    let payload = this.interfaceForm.value.payloadMapping
    payload['ids'] = ids
    this.interfaceForm.patchValue({ payloadMapping: payload})
  }

  deleteMedia(mediaSelected, indexSelected){
    let medias = []
    this.interfaceForm.value.media.forEach( (media, index)=> {
      if(index!=indexSelected){
        medias.push(media)
      }
    })

    this.interfaceForm.patchValue({ media: medias})
    let payload = this.interfaceForm.value.payloadMapping
    payload['media'] = medias
    this.interfaceForm.patchValue({ payloadMapping: payload})
  }

  editId(id, index){
    if(this.interfacesUserRights_update){
      let dialogRef = this.dialog.open(InterfacesDataMappingDialogComponent, {
        data: { 
          info:id,
          type: 'ids',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          let ids= []
          this.interfaceForm.value.ids.forEach( (id, ind)=> {
            if(ind == index) {
              ids.push(result[1])
            }else{
              ids.push(id)
            }
          })
          this.interfaceForm.patchValue({ids: ids})
          let payload = this.interfaceForm.value.payloadMapping
          payload['ids'] = ids
          this.interfaceForm.patchValue({payloadMaping: payload})
        }
      });
    }
    
  }

  editMedia(media, index){
    if(this.interfacesUserRights_update){
      let dialogRef = this.dialog.open(InterfacesDataMappingDialogComponent, {
        data: { 
          info:media,
          type: 'media',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          let medias= []
          this.interfaceForm.value.media.forEach( (media, ind)=> {
            if(ind == index) {
              medias.push(result[1])
            }else{
              medias.push(media)
            }
          })
          this.interfaceForm.patchValue({media: medias})
          let payload = this.interfaceForm.value.payloadMapping
          payload['media'] = medias
          this.interfaceForm.patchValue({payloadMaping: payload})
        }
      });
    }
    
  }
}