import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy, removeUndefined, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { WorkTaskTemplatesService } from '../service/work-task-templates.service';

@Component({
  selector: 'app-task-template-create-dialog',
  templateUrl: './task-template-create-dialog.component.html',
  styleUrls: ['./task-template-create-dialog.component.css']
})
export class TaskTemplateCreateDialogComponent {
  newTaskTemplateForm!:FormGroup
  taskTemplateGroups:any
  emptyList:any

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateCreateDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private settingService: SettingsService,
    private commentService: CommentsService,
    private translate: TranslateService,
    private wtaskTemplateService: WorkTaskTemplatesService
    ){
      dialogRef.disableClose = true;
    }

    ngOnInit():void{
      this.newTaskTemplateForm = this.fb.group({
        title:[ undefined, Validators.required],
        groupId:[undefined]
      })

      if(this.settingService.settingsWorkTaskTemplatesGroups.length!=0){
        this.taskTemplateGroups = this.settingService.settingsWorkTaskTemplatesGroups
      }else{
        this.getTasksTemplatesGroups()
      }
    }


    getTasksTemplatesGroups(){
      try{
        this.commentService.progressSpin.emit(true)
        this.taskTemplateGroups = this.settingService.observeContextsWorkTasksTemplatesGroupsFirestoreWithId()
        if(this.taskTemplateGroups.length==0){
          this.emptyList=true
        }      
        this.commentService.progressSpin.emit(false)
    
      }catch(error){
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }

    cancelNewTask(){
      this.dialogRef.close([false])
    }

    saveNewTask(){
      try{
        if(this.newTaskTemplateForm.value.title){
          this.commentService.progressSpin.emit(true)
          const data = deepCopy(this.newTaskTemplateForm.value)
          removeUndefinedValuesFromObject(data)
          const newtaskId = this.wtaskTemplateService.createWorkTaskTemplate(data)
          newtaskId.then( value => {
            this.dialogRef.close([true,value ])
            this.commentService.progressSpin.emit(false)
            const message = this.translate.instant("SnackBarConstants.CREATE_WORK_TASK_OK")
            this.commentService.addSnackBar.emit(message)
          })
        }else{
          const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
          this.commentService.addSnackBar.emit(message)
        }
      }catch(error){
        const message = this.translate.instant("SnackBarConstants.CREATE_WORK_TASK_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }

}
