<section>
    <div id="spacer{{spacerData.id}}">

    </div>
</section>
    
    
<section *ngIf="divider">
    <mat-divider></mat-divider>
</section>
    
    