import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterFinished'
})
export class FilterFinishedPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
        if(it.id){
            if(it.payload && it.payload.name){
                if(it.title){
                    return it.title.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                }else{
                    return it.id.toLowerCase().includes(searchText) || it.payload.name.toLowerCase().includes(searchText);
                }
            }else{
                if(it.title){
                    return it.title.toLowerCase().includes(searchText) || it.id.toLowerCase().includes(searchText);
                }else{
                    return it.id.toLowerCase().includes(searchText);
                }
            }
        }else
            return ""

    });
   }
}