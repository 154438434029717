import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/service/settings.service';
import { isDateTime, isDateTimeGetTimeSeconds } from '../../../../../backend/src/filter';

@Component({
  selector: '[app-countdown-phone]',
  templateUrl: './countdown-phone.component.html',
  styleUrls: ['./countdown-phone.component.css']
})
export class CountdownPhoneComponent {
  @Input('app-countdown-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()

  idToShow:any
  routerUrl:any
  countdownData:any
  widgetNumber:any
  editMode:any
  countdownRunning = false
  isMoreThanWeek = false
  isMoreThanDay = false
  targetDate: Date 
  timeLeft: any = {};  
  intervalId: any;
  countdownAlignmment:string = 'center'
  stringDate:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.countdownData = this.data[0]

    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    if(this.countdownData.targetDate){
      this.targetDate = new Date(this.countdownData.targetDate)
      const date = isDateTimeGetTimeSeconds(this.countdownData.targetDate)
      this.stringDate = new Date(date).toJSON()
      this.targetDate = new Date(this.stringDate)
      this.calculatetimeLeft();
      this.startCountdown();
    }

    if(this.countdownData.alignment){
      switch(this.countdownData.alignment){
        case 'left':{
          this.countdownAlignmment = 'start'
          break;
        }
        case 'center':{
          this.countdownAlignmment = 'center'
          break;
        }

        case 'right':{
          this.countdownAlignmment = 'right'
          break;
        }
      }
    }

  }


  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startCountdown(): void {
    this.intervalId = setInterval(() => {
      this.calculatetimeLeft();
    }, 1000);
  }

  calculatetimeLeft(): void {
    const now = new Date();
    this.targetDate = new Date(this.stringDate)
    const timeDifference = this.targetDate.getTime() - now.getTime();

    if (timeDifference <= 0) {
      clearInterval(this.intervalId);
      this.timeLeft = { weeks: '00', days: '00', hours:'00', minutes: '00', seconds: '00' };
      this.countdownRunning = false
      return;
    }else
    this.countdownRunning = true

   
    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    let weeks = Math.floor(days / 7)
    let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

    if(days>7){
      days = days - (weeks*7)//Math.floor(days/7)
    }

    this.timeLeft = {
      weeks: String(weeks).padStart(2,'0'),
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes:String(minutes).padStart(2, '0') ,
      seconds: String(seconds).padStart(2, '0')
    }

    this.isMoreThanWeek = this.timeLeft.weeks >= '01';
    // this.isMoreThanWeek = this.timeLeft.days > '07';
    this.isMoreThanDay = this.timeLeft.days >= '01';
  }

}
